import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Col,
  InputBasic,
  InputSelect,
} from "../../../Index";
import axiosRequest from "../../../../utils/axiosConfig";

import { Table } from "../../../Table/styled";


const Informe = ({selectToShow, filtro, setFiltro, error, setError, btnLimpiar, btnDisabled, setBtnDisabled,setBtnLimpiar}) => {
    const filtroInicial = {
        producto:'',
        estado:'',
        desde:'',
        hasta:'',
        campania: ''
    }

    const [datosTabla, setDatosTabla] = useState([])
    
    const addField = (e)=>{
        const {name, value} = e.target;
        setFiltro({...filtro,[name]: value})
    }



    const buscarInforme = async () => {
        setBtnDisabled(true)
        try {
            const send = await axiosRequest.get(`/campanias/informes?producto=${filtro.producto}&estado=${filtro.estado}&campania=${filtro.campania}&desde=${filtro.desde}&hasta=${filtro.hasta}`);
            setDatosTabla(send.data)
            if(send.data.length === 0){
                setError({type: 'warning', msg:'No se encontraron coincidencias'})
            }
        } catch (error) {
            setError({type: 'error', msg:error})
        }
        finally{
            setBtnDisabled(false)
        }
    }

    const limpiarForm = ()=>{
        setFiltro(filtroInicial)
        setDatosTabla([])
        setBtnLimpiar(false)
        setBtnDisabled(true)
    }

    return(<> 
                <Col desktop={12}>
                    <Subtitulo>Informe de campañas por producto y estado</Subtitulo>
                </Col>
                <Col desktop={3}>
                    <InputBasic
                        type={"date"}
                        name={"desde"}
                        labelName={"Desde"}
                        value={filtro.desde}
                        onChange={addField}
                    />
                </Col>
                <Col desktop={3}>
                    <InputBasic
                        type={"date"}
                        name={"hasta"}
                        labelName={"Hasta"}
                        value={filtro.hasta}
                        onChange={addField}
                    />
                </Col>
                <Col desktop={6}>
                    <InputSelect
                        name={"campania"}
                        labelName={"Campaña"}
                        options={selectToShow.selectCampanias}
                        value={filtro.campania}
                        onChange={addField}
                        disabled={selectToShow.selectCampanias.length===0}
                    />
                </Col>
                <Col desktop={3}>
                    <InputSelect
                        name={"producto"}
                        labelName={"Producto"}
                        options={selectToShow.selectProductos}
                        value={filtro.producto}
                        onChange={addField}
                    />
                </Col>
                <Col desktop={3}>
                    <InputSelect
                        name={"estado"}
                        labelName={"Estado"}
                        options={selectToShow.selectEstados}
                        value={filtro.estado}
                        onChange={addField}
                    />
                </Col>
                <Col desktop={12}>
                    <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        icon={false}
                        onClick={buscarInforme}
                        disabled={btnDisabled}
                    >
                        Buscar
                    </Button>
                    {
                        btnLimpiar?
                        <Button
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={limpiarForm}
                        >
                            Limpiar
                        </Button>
                        : null
                    }
                </Col>
                <Col desktop={12}>
                    {
                        datosTabla.length!==0?
                        <Table >
                            <thead>
                                                <tr>
                                                    <th>Producto</th>
                                                    <th>Campaña</th>
                                                    <th>Estado</th>
                                                    <th>Cotizaciones</th>
                                                    <th>Venta</th>
                                                </tr>
                            </thead>
                            <tbody>
                             {datosTabla.map(d => {
                                 const label =d.estado.indexOf('-')!== -1? d.estado.slice(d.estado.indexOf('-')+1): d.estado
                                 return (
                                     <>
                                                <tr>
                                                    <td>{d.producto}</td>
                                                    <td>{d.campaign}</td>
                                                    <td>{label}</td>
                                                    <td colSpan={label==='Efectividad'?2:1}>{d.cotizaciones}</td>
                                                    <td>{d.venta}</td>
                                                </tr>
                                     </>
                                 )
                             })}
                             </tbody>
                        </Table>
                        : 
                        error.msg !== '' ? error.msg : null
                    }
                        
                </Col>
    </>)
}

export default Informe;