import { useState } from "react"

export default function useButtonMessage (initialText) {

    const [buttonText, setButtonText] = useState(initialText)
    
    const handleButtonText = (text) => {
        setButtonText(text)
    }

    return {
        buttonText,
        handleButtonText
    }
}
