import { Button, Col } from "../Index";
import { ClearFilter, ClearFilterContainer } from "./styled";
import { ImSearch } from "react-icons/im";
import { MdCleaningServices } from "react-icons/md";
import NewButton from "../NewButton";

const NewFilters = ({ inputs, values, params, handleChange, handleSearch, clearSearch, required }) => {

    const isDisabled = required ? !Object.values(values).every(item => item !== '') : Object.values(values).every(item => item === '')

    return(
        <>
            {inputs && inputs.map(input => {
                return (
                    <Col desktop={3}>
                        <input.component type={input.type} labelName={input.labelName} placeholder={input.placeholder} name={input.name} onChange={handleChange} value={values[input.name]} options={input.options ? input.options : ''} />
                    </Col>
                )
            })}
            <Col desktop={4} alignBottom={true}>
              <NewButton
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={handleSearch}
                disabled={isDisabled}
              >
                <span><ImSearch></ImSearch></span>
                Buscar
              </NewButton>
              {params ? 
              <Col desktop={4} alignBottom={true}>
                <ClearFilterContainer onClick={clearSearch}>
                  <NewButton
                    borderColor={true}
                    textColor={true}
                    onClick={clearSearch}
                    target={"_blank"}
                    tamaño="chico"
                  >
                    <span>
                      <MdCleaningServices></MdCleaningServices>
                    </span>
                    Limpiar filtros
                  </NewButton>
                </ClearFilterContainer>
              </Col>
            : null}
            </Col>

            
        </>
    )
}

export default NewFilters