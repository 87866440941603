import React, { useContext, useState } from 'react'
import { useAuthContext } from './AuthContext';
import axiosRequest from '../utils/axiosConfig';
import { useEffect } from 'react';
import { obtenerNotificaciones } from '../services/Login';
import { marcarNotificacionLeida, marcarNotificacionesLeidas } from '../services/Notificaciones';
import { useSocket } from './SocketProvider';

const NotificacionesContext = React.createContext()

export function useNotificaciones() {
  return useContext(NotificacionesContext)
}

export function NotificacionesProvider({ children }) {
  const [notificaciones, setNotificaciones] = useState([])
  const [notificacionesLeidas, setNotificacionesLeidas] = useState([])
  const { authState } = useAuthContext()
  const socket = useSocket()

  const handleNotificaciones = (notificacion) => {
    if(notificacion.userId !== authState.userInfo.id_usuario){
      const notificacionesCopia = [...notificaciones]
      notificacionesCopia.unshift(notificacion)
      setNotificaciones(notificacionesCopia)
    }
  }

  const handleLoginNotificaciones = (notificaciones) => {
    const notifLeidas = notificaciones.filter(item => item.leida)
    setNotificaciones(notificaciones.filter(item => !item.leida))
    setNotificacionesLeidas(notifLeidas)
    //setNotificaciones(notificaciones)
  }

  const abrirNotificacion = async (notificacionIndex, notificacion) => {
    const notificacionesCopia = [...notificaciones]
    const newNotificaciones = notificacionesCopia.filter((notificacion, index) => index !== notificacionIndex)
    setNotificaciones(newNotificaciones)

    if(notificacion.id_notificacion){
      try {
        await marcarNotificacionLeida(notificacion.id_usuario, notificacion.id_notificacion)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const marcarComoLeidas = async () => {
    try {
      setNotificaciones([])
      await marcarNotificacionesLeidas(authState.userInfo.id_usuario)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getNotificaciones = async () => {
      try {
        const notificaciones = await obtenerNotificaciones(authState.userInfo.id_usuario)
        const notifLeidas = notificaciones.filter(item => item.leida)
        setNotificaciones(notificaciones.filter(item => !item.leida))
        setNotificacionesLeidas(notifLeidas)
      } catch (error) {
        console.log(error)
      }
    }
    getNotificaciones()
  }, [])

  const getNotificacionesLeidas = async () => {
    try {
      const notificaciones = await obtenerNotificaciones(authState.userInfo.id_usuario)
      const notifLeidas = notificaciones.filter(item => item.leida)
      setNotificacionesLeidas(notifLeidas)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (socket) {
      socket.on("notificaciones", (notificacion) => {
        if(notificacion.id_usuario) {
          if(notificacion.id_usuario === authState.userInfo.id_usuario){
            handleNotificaciones(notificacion);
          }
        }
        if(notificacion.roles) {
          if (notificacion.roles.includes(authState.userInfo.id_rol)) {
            handleNotificaciones(notificacion);
          }
        }
        if(!notificacion.roles && !notificacion.id_usuario){
          handleNotificaciones(notificacion);
        }
      });

      socket.on('error-mail-rechazos', (notificacion) => {
        if(notificacion.roles) {
          if (notificacion.roles.includes(authState.userInfo.id_rol)) {
            handleNotificaciones(notificacion);
          }
        }
      })
    }
  }, [socket, notificaciones]);

  return (
    <NotificacionesContext.Provider value={{ notificaciones, notificacionesLeidas, handleNotificaciones, handleLoginNotificaciones, abrirNotificacion, marcarComoLeidas, getNotificacionesLeidas }}>
      {children}
    </NotificacionesContext.Provider>
  )
}