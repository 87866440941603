import React from "react";
import PropTypes from "prop-types";
import { Div, Span } from "./styled";
import Button from "../Button";

function PopUp({ backgroundColor, icono, mensaje, closeModal }) {
  return (
    <Div data-backgroundcolor={backgroundColor}>
      <Span>
        <figure>
          <img src={icono} alt={"Check"} />
        </figure>
        {mensaje}
      </Span>
    </Div>
  );
}

export default PopUp;
