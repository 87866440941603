import { leerColor, colores } from "."

export const DARK_THEME = {
    title: leerColor(colores.blanco),
    subtitle: leerColor(colores.blanco),
    little_icon: leerColor(colores.blanco),
    border: '#243158',
    background: '#0b132b',
    strong: '#7398ff',
    big_icon: leerColor(colores.azulRebranding),
    input_background: "#142046",
    calendar_days: leerColor(colores.blanco),
    table_header: '#121c3b',
    table_header_text: leerColor(colores.blanco),
    total_card_background: "#223363",
    total_card_border: "#789aff",
    tab_text_color: leerColor(colores.blanco),
    hover_background: '#243158',
    border_input_file: '#243158',
    selected_table: "#152041",
    selected_fichaje: "#152041",
    not_selected_tab: "#abb3cc",
    disabled: '#0f1420',
    skeleton_background: '#152041',
    skeleton_gradient: 'linear-gradient(90deg, #152041, #223363, #152041)',
    tag_background: "#223363",
    tag_text_color:leerColor(colores.blanco),
    
    /* ESTADOS */
    naranja_fondo: "#27221a",
    verde_fondo: "#14361c",
    verde_texto: "#57e378",
    celeste_fondo: "#112c3b",
    celeste_texto: "#51c1ff",
    rojo_fondo: "#250e0e",
    gris_texto: "#dfdfdf",
    gris_fondo: "#282828",

    /* BOTONES */
    secondary_button_background: "#131f41",
    secondary_button_text: leerColor(colores.blanco),

    pagination_text: leerColor(colores.blanco)
}

export const LIGHT_THEME = {
    title: leerColor(colores.negro),
    subtitle: leerColor(colores.grisEstados),
    little_icon: leerColor(colores.negro),
    border: '#eaeaea',
    background: leerColor(colores.blanco),
    strong: leerColor(colores.azulRebranding),
    big_icon: leerColor(colores.azulRebranding),
    input_background: leerColor(colores.blanco),
    calendar_days: leerColor(colores.grisEstados),
    table_header: "#fdfdfd",
    table_header_text: "#6d6d6d",
    total_card_background: leerColor(colores.negro),
    total_card_border: leerColor(colores.negro),
    tab_text_color: leerColor(colores.azulRebranding),
    hover_background: '#f4f6ff',
    border_input_file: '#bab8b8',
    selected_table: '#fafafa',
    selected_fichaje: "#f8faff",
    disabled: "#fcfcfc",
    not_selected_tab: "#6d6d6d",
    skeleton_background: '#eaeaea',
    skeleton_gradient: 'linear-gradient(90deg, #eaeaea, #f5f5f5, #eaeaea)',
    tag_background: "#ecf1ff",
    tag_text_color:leerColor(colores.azulRebranding),

    /* ESTADOS */
    naranja_fondo: "#fff7ed",
    verde_fondo: "#edfff2",
    verde_texto: leerColor(colores.verde),
    celeste_fondo: "#e1f5ff",
    celeste_texto: leerColor(colores.celeste),
    rojo_fondo: "#fff6f6",
    gris_texto: leerColor(colores.grisEstados),
    gris_fondo: "#f6f6f6",

    /* BOTONES */
    secondary_button_background:leerColor(colores.blanco),
    secondary_button_text: leerColor(colores.azulRebranding),

    pagination_text: leerColor(colores.azulRebranding)
}