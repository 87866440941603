import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../../constants';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  border-top: 1px solid #eaeaea;
  padding: 120px 0;

  p {
    margin: 0;
  }

  strong {
    color: ${leerColor(colores.azul)}
  }
`

export const Icon = styled.span`
    font-size: 60px;
    display: flex;
    color: ${leerColor(colores.celeste)}
`