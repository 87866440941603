import React from 'react';
import { Styled } from "./styled";

export const Loader = () => {
  return (
    <Styled>
      <div className="overlay">
        <div className="loader"></div>
      </div>
    </Styled>
  );
};
