import React, { useContext } from 'react'
import { FiBell, FiMoon, FiSun } from 'react-icons/fi'
import { Container } from './styled'
import Notificaciones from '../Notification'
import ModalFotoPerfil from '../Modals/Usuarios/ModalFotoPerfil'
import useModal from '../../hooks/useModal'
import { AuthContext } from '../../context/AuthContext'
import { useTheme } from '../../context/ThemeContext'

const NewHeader = ({ children }) => {
    const modalPerfil = useModal()
    const { authState} = useContext(AuthContext)
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const avatar = authState.userInfo.avatar_usuario ? authState.userInfo.avatar_usuario : 'https://files.gyssrl.ar/api/v1/storage/image/808edc57-82e6-4461-93e2-e2b4d1322b9c'
    const { theme, handleToggleTheme } = useTheme()

    console.log(user)

    return (
        <>
        <ModalFotoPerfil modal={modalPerfil} />
        <Container>
            <div className="realContainer">
                <div className="container">
                    <div className='usuario-container'>
                        <img className="avatar" src={avatar} alt="foto de perfil del usuario" onClick={() => modalPerfil.handleModal()}/>
                        <div>
                        <h3>¡Hola {user.nombre_operador.split(" ")[0]}! 😄</h3>
                        <span>{user.nombre_rol} - {user.usuario_usuario}</span>
                        </div>
                    </div>
                    <div className="container-acciones">
                        <span className='icon-theme' onClick={() => handleToggleTheme(theme === "light" ? "dark" : "light")}>{theme === "light" ? <FiMoon /> : <FiSun />}</span>
                        <Notificaciones />
                    </div>
                </div>
                {children}
            </div>
        </Container>
        </>
    )
}

export default NewHeader