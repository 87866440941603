import React, { useState, useEffect, useContext } from "react";
import { Column, Container, Frame, BtnFrame, DivDetailsImg } from './styled';
import axios from 'axios'
import Modal from "../Modal";
import { DetailsMain } from "../Details/styled";
import { Grid, Col } from "../Grid";
import { Button, FrameLoad, InputFileRec, InputSelect } from "../Index";
import { NoHayBusquedas } from "../InfoBoard/new-styled";
import dayjs from 'dayjs'
import axiosRequest from "../../utils/axiosConfig";
import { BiTrash } from "react-icons/bi";

const Gallery = ({ images, getPublicFiles, user, proyectos }) => {
    const [frames, setFrames] = useState([]);
    const [openModalDetalles, setOpenModalDetalles] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [detalles, setDetalles] = useState({});
    const [fileToDelete, setFileToDelete] = useState(null);
    const [forceDelete, setForceDelete] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [files, setFiles] = useState('');
    const [form, setForm] = useState({
        proyecto: ''
    })

    // construye cada Frame con los datos de las imagenes
    const loadGallery = () => images.map((img, index) => {
        return (
            <FrameLoad
                img={img}
                key={index}
                setOpenModalDetalles={setOpenModalDetalles}
                setDetalles={setDetalles}
            />
        )
    })

    const showDeleteFile = async (href) => {
        setFileToDelete(href);
        setOpenModalDetalles(false)
        setOpenModalDelete(true)
    }

    const deleteFIle = async () => {
        try {
            const url = forceDelete ? `${process.env.REACT_APP_URL_API_STORAGE}api/v1/storage/${fileToDelete}?force=true` : `${process.env.REACT_APP_URL_API_STORAGE}api/v1/storage/${fileToDelete}`;
            await axios.delete(url)
            setOpenModalDelete(false)
            getPublicFiles()
        } catch (error) {
            console.log(error)
        }
    }

    const addField = (e) => {
        const { id, value } = e.target;
        setForm({ ...form, [id]: value });
    };

    const showUpdateModal = () => {
        setOpenModalDetalles(false);
        setOpenModalUpdate(true);
    }


    const updateFile = async () => {
        setUpdating(true)
        if (files) {
            let bodyFormData = new FormData();

            // Se agrega información al formData en base a la cantidad de propiedades que existan en el state 'files'. 
            const key = Object.keys(files);
            if (!Array.isArray(files[key[0]])) {
                // Si no es un array
                for (const key in files) {
                    bodyFormData.append('recfile', files[key].image);
                }
            } else {
                // Si es un array
                files[key[0]].forEach(file => {
                    bodyFormData.append('recfile', file.image);
                })
            }
            const config = {
                headers: {
                    'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69',
                    'project': form.proyecto || 'seguroweb',
                    'scope': 'public',
                }
            }
            try {
                const sendFiles = await axios.put(`${process.env.REACT_APP_URL_API_STORAGE}api/v1/storage/public/${detalles.id}`, bodyFormData, config);
                getPublicFiles();
            } catch (error) {
                console.log('Error al cargar nuevo archivo.')
            }
            finally {
                setUpdating(false)
            }
        }
    }

    useEffect(() => {
        if (!openModalDelete) setForceDelete(false);
    }, [openModalDelete])

    // si existen imagenes inicia la carga de los frames
    useEffect(() => {
        if (images.length > 0) {
            const imgs = loadGallery()
            setFrames(imgs)
        }
    }, [images])

    /* ******************* MODAL DETALLE CODIGO ANTERIOR 

                {
                openModalDetalles ?
                    <Modal
                        isModalOpen={openModalDetalles}
                        setIsModalOpen={setOpenModalDetalles}
                        hideCross={false}
                    >
                        <DetailsMain>
                            <div className="modalMail">
                                <div className={"lds-ring"}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <h2 id="titulo">Detalles del archivo</h2>
                                <br />
                            </div>
                            <Col desktop={12}>
                                {Object.values(detalles).length > 0 ?
                                    <DivDetailsImg>
                                        <div className="description">
                                            <div>Nombre: <b>{detalles.name}</b></div>
                                            <div>Identificador: <b>{detalles.id}</b></div>
                                            <div>Carpeta del proyecto: <b>{detalles.project}</b></div>
                                            <div>Alcance: <b>{detalles.is_private === 's' ? 'Privado' : 'Público'}</b></div>
                                            <div>Fecha y Hora de Creación: <b>{dayjs(detalles.fecha_creacion).format('YYYY-MM-DD HH:mm')}</b></div>
                                            <div>Formato: <b>{detalles.formato}</b></div>
                                            <div>Estado: <b>{detalles.activo === 's' ? 'Activo' : 'Inactivo'}</b></div>
                                            <div>Tamaño en disco: <b>{Math.floor(parseInt(detalles.size) / 1024)} kbytes</b></div>
                                            <br />
                                        </div>
                                        <img src={detalles.src}></img>
                                        <br />
                                        <br />
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            href={detalles.src}
                                            target={"_blank"}
                                            tamaño="chico"
                                        >
                                            Ver en otra pestaña
                                        </Button>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            onClick={showUpdateModal}
                                            tamaño="chico"
                                        >
                                            Reemplazar
                                        </Button>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            onClick={() => navigator.clipboard.writeText(detalles.url)}
                                            tamaño="chico"
                                        >
                                            Copiar URL
                                        </Button>
                                        {
                                            [1, 15].includes(user.id_rol) ?
                                                <Button
                                                    borderColor={false}
                                                    backgroundColor={true}
                                                    textColor={false}
                                                    onClick={() => showDeleteFile(detalles.id)}
                                                    tamaño="chico"
                                                >
                                                    Borrar Archivo
                                                </Button>
                                                : null
                                        }
                                    </DivDetailsImg>

                                    : null}
                            </Col>
                        </DetailsMain>
                    </Modal>
                    : null
            }

    */

    return (
        <Col desktop={12}>
            <Container> {frames.length > 0 ? frames :
                <>
                    <NoHayBusquedas>
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9593 0C9.85123 0 0.000976562 9.85025 0.000976562 21.9583C0.000976562 34.0663 9.85123 43.9166 21.9593 43.9166C34.0673 43.9166 43.9176 34.0663 43.9176 21.9583C43.9176 9.85025 34.0674 0 21.9593 0ZM21.9593 41.3333C11.2746 41.3333 2.58427 32.643 2.58427 21.9583C2.58427 11.2736 11.2746 2.58329 21.9593 2.58329C32.644 2.58329 41.3343 11.2736 41.3343 21.9583C41.3343 32.643 32.644 41.3333 21.9593 41.3333Z" fill="#787878" />
                            <path d="M61.6211 59.7962L37.485 35.66C36.9813 35.1563 36.1623 35.1563 35.6586 35.66C35.1548 36.1638 35.1548 36.9827 35.6586 37.4865L59.7946 61.6225C60.0478 61.8731 60.3784 61.9997 60.7091 61.9997C61.0398 61.9997 61.3704 61.8732 61.6211 61.6225C62.1248 61.1188 62.1248 60.3 61.6211 59.7962Z" fill="#787878" />
                            <path d="M31.1667 23H12.8333C12.3733 23 12 22.552 12 22C12 21.448 12.3733 21 12.8333 21H31.1667C31.6267 21 32 21.448 32 22C32 22.552 31.6267 23 31.1667 23Z" fill="#787878" />
                        </svg>
                        <h3>Aún no se ingresaron archivos públicos.</h3>
                    </NoHayBusquedas>
                </>

            } </Container>

            
            {
                openModalDetalles ?
                    <Modal
                        isModalOpen={openModalDetalles}
                        setIsModalOpen={setOpenModalDetalles}
                        hideCross={false}
                    >
                        {/* Details Main */}
                        <DetailsMain>
                            <div className="modalMail">
                                <div className={"lds-ring"}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <h2 id="titulo">Detalles del archivo</h2>
                                <br />
                            </div>
                            <Grid colGap={21} rowGap={21} narrow={true}>
                            {Object.values(detalles).length > 0 ?
                                <div className="description">
                                    <Col desktop={4}>
                                        <h4>Nombre</h4>
                                        <span>{detalles.name}</span>
                                    </Col>
                                    <Col desktop={4}>
                                        <h4>Identificador</h4>
                                        <span>{detalles.id}</span>
                                    </Col>
                                    <Col desktop={4}>
                                        <h4>Carpeta del proyecto</h4>
                                        <span>{detalles.project}</span>
                                    </Col>
                                    <Col desktop={4}>
                                        <h4>Alcance</h4>
                                        <span>{detalles.is_private === 's' ? 'Privado' : 'Público'}</span>
                                    </Col>
                                    <Col desktop={4}>
                                        <h4>Fecha y Hora de Creación:</h4>
                                        <span>{dayjs(detalles.fecha_creacion).format('YYYY-MM-DD HH:mm')}</span>
                                    </Col>
                                    <Col desktop={4}>
                                        <h4>Formato</h4>
                                        <span>{detalles.formato}</span>
                                    </Col>
                                    <Col desktop={4}>
                                        <h4>Estado</h4>
                                        <span>{detalles.activo === 's' ? 'Activo' : 'Inactivo'}</span>
                                    </Col>
                                    <Col desktop={4}>
                                        <h4>Tamaño en disco</h4>
                                        <span>{Math.floor(parseInt(detalles.size) / 1024)} kbytes</span>
                                    </Col>
                                </div>
                            :   
                                null 
                            }
                            </Grid>
                            <Col desktop={12}>
                                {Object.values(detalles).length > 0 ?
                                    <DivDetailsImg>
                                        <div className="description">
                                            <div>Nombre: <b>{detalles.name}</b></div>
                                            <div>Identificador: <b>{detalles.id}</b></div>
                                            <div>Carpeta del proyecto: <b>{detalles.project}</b></div>
                                            <div>Alcance: <b>{detalles.is_private === 's' ? 'Privado' : 'Público'}</b></div>
                                            <div>Fecha y Hora de Creación: <b>{dayjs(detalles.fecha_creacion).format('YYYY-MM-DD HH:mm')}</b></div>
                                            <div>Formato: <b>{detalles.formato}</b></div>
                                            <div>Estado: <b>{detalles.activo === 's' ? 'Activo' : 'Inactivo'}</b></div>
                                            <div>Tamaño en disco: <b>{Math.floor(parseInt(detalles.size) / 1024)} kbytes</b></div>
                                            <br />
                                        </div>
                                        <img src={detalles.src}></img>
                                        <br />
                                        <br />
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            href={detalles.src}
                                            target={"_blank"}
                                            tamaño="chico"
                                        >
                                            Ver en otra pestaña
                                        </Button>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            onClick={showUpdateModal}
                                            tamaño="chico"
                                        >
                                            Reemplazar
                                        </Button>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            onClick={() => navigator.clipboard.writeText(detalles.url)}
                                            tamaño="chico"
                                        >
                                            Copiar URL
                                        </Button>
                                        {
                                            [1, 15].includes(user.id_rol) ?
                                                <Button
                                                    borderColor={false}
                                                    backgroundColor={true}
                                                    textColor={false}
                                                    onClick={() => showDeleteFile(detalles.id)}
                                                    tamaño="chico"
                                                >
                                                    Borrar Archivo
                                                </Button>
                                                : null
                                        }
                                    </DivDetailsImg>

                                    : null}
                            </Col>
                        </DetailsMain>
                    </Modal>
                    : null
            }

            {
                openModalDelete ?
                    <Modal
                        isModalOpen={openModalDelete}
                        setIsModalOpen={setOpenModalDelete}
                        hideCross={false}
                    >
                        {/* Details Main */}
                        <DetailsMain>
                            <div className="modalMail">
                                <div className={"lds-ring"}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <h2 id="titulo">Borrar archivo {detalles.name}</h2>
                            </div>
                            <Col desktop={12}>
                                <br /><br />
                                <div>
                                    <input
                                        type="checkbox"
                                        id="forceDelete"
                                        name="forceDelete"
                                        value="forceDelete"
                                        checked={forceDelete}
                                        onChange={() => setForceDelete(!forceDelete)}
                                    />
                                    Borrar archivo de forma permanente
                                </div>
                                <br /><br />
                                {Object.values(detalles).length > 0 ?
                                    <DivDetailsImg>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            target={"_blank"}
                                            tamaño="chico"
                                            onClick={deleteFIle}
                                        >
                                            Borrar
                                        </Button>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            tamaño="chico"
                                            onClick={() => setOpenModalDelete(false)}
                                        >
                                            Cancelar
                                        </Button>
                                    </DivDetailsImg>
                                    : null}
                            </Col>
                        </DetailsMain>
                    </Modal>
                    : null
            }
            {
                openModalUpdate ?
                    <Modal
                        isModalOpen={openModalUpdate}
                        setIsModalOpen={setOpenModalUpdate}
                        hideCross={false}
                    >
                        {/* Details Main */}
                        <DetailsMain>
                            <div className="modalMail">
                                <h2 id="titulo">Actualizar Archivo: {detalles.name}</h2>
                            </div>
                            <Col desktop={12}>
                                <br /><br />
                                <DivDetailsImg>
                                    <img src={detalles.src}></img>


                                    <br />
                                    <br />
                                </DivDetailsImg>
                                <div className="description">

                                    <div>Vista de archivo anterior</div>
                                    <hr />
                                </div>
                                {Object.values(detalles).length > 0 ?
                                    <>
                                        <div id="input-container">
                                            <InputFileRec
                                                disabled={false}
                                                multiple={false}
                                                type={"file"}
                                                name={"newfile"}
                                                required={true}
                                                id="newfile"
                                                files={files}
                                                text={"Cargar archivo"}
                                                setFiles={setFiles}
                                            ></InputFileRec>
                                            {files.newfile ? (
                                                <div id="archivo-cargado">
                                                    <p>{files.newfile.name}</p>
                                                    <a onClick={() => setFiles({})}>
                                                        <BiTrash></BiTrash>
                                                    </a>
                                                </div>
                                            ) : null}
                                        </div>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            target={"_blank"}
                                            tamaño="chico"
                                            onClick={updateFile}
                                            disabled={updating}
                                        >
                                            {
                                                updating ? 'Actualizando' : 'Actualizar Archivo'
                                            }
                                        </Button>
                                        <Button
                                            borderColor={false}
                                            backgroundColor={true}
                                            textColor={false}
                                            tamaño="chico"
                                            onClick={() => setOpenModalUpdate(false)}
                                            disabled={updating}
                                        >
                                            Cancelar
                                        </Button>
                                    </>
                                    : null}
                            </Col>
                        </DetailsMain>
                    </Modal>
                    :
                    null
            }
        </Col >

    )
}

export default React.memo(Gallery)
