import React from 'react';
import PropTypes from 'prop-types';

import { TituloNombre } from './styled';

function Titulo({ children, textColor, margin }) {

  return (
    <TituloNombre data-textcolor={textColor} margin={margin} >
      {children}
    </TituloNombre>
  );
}

Titulo.propTypes = {
  children: PropTypes.any.isRequired
};

export default Titulo;
