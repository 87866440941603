import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';


export const LoginDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
   
  background: none;
`

export const Left = styled.div` 
  display: flex;
  flex-direction: column;
  width: 60%;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  background-color: ${leerColor(colores.azul)};
  /* margin: 0 auto; */
  img.ilustracion{
    width: 90%; // 55%;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100vh;
  background-color: ${leerColor(colores.blanco)};
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img{
    width: 250px;
    margin: 0 auto;
    position: absolute;
    top:50px;
  }
  .title{
    color: ${leerColor(colores.celeste)};
  }
  div{
    //margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  div h2{
    padding-bottom: 20px;
  }
  /*
  div input{
    width: 330px;
    justify-content: center;
    color: ${leerColor(colores.blanco)};
  }
  div input:last-of-type{
    margin-bottom: 45px;
  }
  div label{
    color: ${leerColor(colores.blanco)};
  }
  div a{
    width: 86%;
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }
  */
`;

export const ContainerLogin = styled.div` 
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 50px;

  p { 
    margin: 10px 0 0 0;
  }
`;

export const Error = styled.span`
   
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:0;
`;