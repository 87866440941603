import { useState, useEffect } from 'react'
import { Container, Page, ResultsAmount } from './styled';
import Button from "../Button"
import ArrowLeft from "../../assets/iconos/arrow-left.svg";
import ArrowRight from "../../assets/iconos/arrow-right.svg";

const Pagination = ({ data, dataLength, setPageSelected }) => {
    
    const [pages, setPages] = useState([])
    const [selectedPage, setSelectedPage] = useState(1)

    const limit = 10
    const lastPage = Math.floor(dataLength / limit) + 1

    useEffect(() => {
        setPages([])
        if(lastPage == 3){
            setPages([2])
        }
      if(lastPage >= 4){
        setPages([2, 3])
      }
      if(selectedPage >= 3 && (lastPage !== 3 && lastPage !== 4)){
        setPages([2, 3, 4])
      }
      if(selectedPage >= 4 && (lastPage !== 4 && lastPage !== 5)){
        setPages([2, 3, 4, 5])
      }
      if(lastPage !== 1 && lastPage > 4 && selectedPage == lastPage){
        setPages([lastPage -3, lastPage - 2, lastPage -1])
      }
      if(selectedPage >= 5 && selectedPage >= pages[pages.length - 1] && selectedPage !== lastPage){
        if(selectedPage < lastPage - 1){
            let paginationPages = []
            let init = pages[pages.length - 1] - 1
            let lenght = pages[pages.length - 1] + 1
            for(let i = init; i <= lenght; i++){
                paginationPages.push(i)
            }
            setPages(paginationPages)
        } else {
            setPages(pages)
        }
      } else if(selectedPage >= 5 && selectedPage < pages[pages.length - 1] && selectedPage !== lastPage) {
            if(selectedPage < lastPage - 1){
                let paginationPages = []
                let init = pages[0] - 1
                let lenght = pages[pages.length - 1] - 1
                for(let i = init; i <= lenght; i++){
                    paginationPages.push(i)
                }
                setPages(paginationPages)
            } else {
                setPages(pages)
            }
      }
    }, [lastPage, selectedPage])


    const nextPage = () => {
        setSelectedPage(prevSelectedPage => prevSelectedPage + 1)
        setPageSelected(prevSelectedPage => prevSelectedPage + 1)
    }

    const backPage = () => {
        setSelectedPage(prevSelectedPage => prevSelectedPage - 1)
        setPageSelected(prevSelectedPage => prevSelectedPage - 1)
    }

    const selectPage = (page) => {
        setSelectedPage(page)
        setPageSelected(page)
    }

    return (
        <>
        <Container>
            <Button textColor={true} icon={ArrowLeft} direction={"before"} onClick={backPage} disabled={lastPage == 1 || selectedPage == 1 ? true : false} className={lastPage == 1 || selectedPage == 1 ? "disabled" : ""}>Anterior</Button>
            <Page active={selectedPage == 1 ? true : false} onClick={() => selectPage(1)}>1</Page>
            {lastPage !== 1 && selectedPage > 4 && <span>...</span>}
            {pages && lastPage !== 1 && pages.map(page => {
                return (
                    <Page active={selectedPage == page ? true : false} onClick={() => selectPage(page)}>{page}</Page>
                )
            })}
            {lastPage > 4 && selectedPage <= lastPage - 2 && <span>...</span>}
            {lastPage > 1 && <Page active={selectedPage == lastPage ? true : false} onClick={() => selectPage(lastPage)}>{lastPage}</Page>}
            <Button textColor={true} icon={ArrowRight} direction={"after"} onClick={nextPage} disabled={lastPage == 1 || selectedPage == lastPage ? true : false} className={lastPage == 1 || selectedPage == lastPage ? "disabled" : ""}>Siguiente</Button>
        </Container>
        <ResultsAmount>Cantidad de resultados: {dataLength}</ResultsAmount>
        </>
    )
}

export default Pagination