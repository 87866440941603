import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const TabDiv = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  color: ${leerColor(colores.grisEstados)};
  font-size: 16px;

  img {
    width: auto;
    max-width: 206px;
    height: 29px;
  }

  //padding: 40px 0;
  .tabList{
    padding: 0;
    //border-bottom: 1px solid ${leerColor(colores.celeste)};
    border-bottom: 1px solid #eaeaea;
    display: flex;
    gap: 20px;
    overflow: hidden;
    /* outline: 1px dashed #000;
    outline-offset: -10px; */
    li{
      padding: 20px 40px;
      border-radius: 5px 5px 0 0;
    }
  }    
  .selectedTab{
    //background-color: ${leerColor(colores.celeste)}!important;
    //background-color: #e9f7ff;
    background-color: #f7f7f7;
    //color: ${leerColor(colores.blanco)};
    color: ${leerColor(colores.azul)};
    border-bottom: 1px solid ${leerColor(colores.celeste)};
  }
`;
export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  div.buttonsRight{
    display: flex;
    flex-direction: row;
  }
  div.buttonsRight a:first-of-type{
    margin-right: 20px;
  }
  ${props => props.hayBotones === false && css`
    &{
      display: none;
    }
  `}
`