import { Grid, Col } from "../Grid"
import Subtitulo from "../Subtitulo"
import { ImFilter } from "react-icons/im";
import FiltersNew from "../FiltersNew";
import NewFilters from "../NewFilters";

const NewFiltersSection = ({ section, inputs, values, params, handleChange, handleSearch, clearSearch, required }) => {
    return(
        <Grid colGap={21} rowGap={21} narrow={false}>            
            <NewFilters inputs={inputs} params={params} values={values} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} required={required} />
        </Grid>
    )
}

export default NewFiltersSection