import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 20px 0 20px 0;
  }

  p {
    margin: 0;
    color: ${leerColor(colores.grisEstados)};
  }

  .landings-container {
    display: flex;
    gap: 40px;
  }

  img {
    width: auto;
    max-width: 206px;
    height: 29px;
  }
`;

export const Landing = styled.div`
    padding: 25px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.border};
    filter: ${({ selected }) => (selected ? "grayscale(0)" : "grayscale(1)")};
    cursor: pointer;
    transition: 0.3s;
    color: ${({ theme}) => theme.title};

    :hover {
     filter: grayscale(0);
    }
`;