import Modal from "../../../Modal";
import Titulo from "../../../Titulo";
import Subtitulo from "../../../Subtitulo";
import IconButton from "../../../IconButton";
import DeleteIcon from "../../../../assets/iconos/delete.svg";
import InputSelect from "../../../InputSelect";
import Button from "../../../Button";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import { FiFile, FiTrash2, FiUser } from "react-icons/fi";
import { Grid, Col } from "../../../Grid";
import { Container, OperadorItem, RechazoContainer } from "./styled";
import NewTitle from "../../../NewTitle";
import NewButton from "../../../NewButton";
import { LOGOS_COMPANIAS } from "../../../../constants/logos-companias";
import NewTabs from "../../../NewTabs"
import { useTabs } from "../../../../hooks/useTabs";
import Divider from "../../../Divider";
import { useEffect } from "react";
import Success from "../../../Success";

const ModalNotificar = ({
  modal,
  loading,
  success,
  handleSuccess
}) => {

  const { isModalOpen, setIsModalOpen, handleModal } = modal

  const closeModal = () => {
    handleSuccess()
    handleModal()
  }
 
  return (
    <>
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <RechazoContainer>
                <div className="header-container">
              <NewTitle>
                  Notificar
              </NewTitle>
              <p>Envía una notificación al asegurado</p>
                </div>
                <Divider />
                {!success ?
                <>
                <div className="data-container">
                    <SpinnerLoading />
                </div>   
                </>
                : 
                  <Success title="¡Gestión realizada!" description="Rechazos notificados correctamente" handleModal={closeModal} disableMargin={true} />
                }            
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalNotificar;

