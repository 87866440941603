import React from 'react';
import PropTypes from 'prop-types';
import { InnerContainer } from './styled';

const NewInner = ({ children }) => {
  return (
    <InnerContainer>
      {children}
    </InnerContainer>
  );
}

export default NewInner;