import styled from "styled-components";
import { leerColor, colores } from "../../constants";

export const Container = styled.div`
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const Header = styled.div`

    padding: 14px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    display: flex;
    justify-content: space-between;

    h3 {
        font-size: 16px;
    color: ${({ theme }) => theme.title};
    font-weight: 500;
    margin: 0;
    }

    span {
        font-size: 14px;
        color: ${({ theme }) => theme.subtitle}; 
    }

    .periodo {
        background-color: ${({ theme }) => theme.tag_background};
        border-radius: 8px;
        padding: 4px 16px;
        box-sizing: border-box;
        color: ${({ theme }) => theme.tag_text_color};
    }
`;

export const AcuerdoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 14px 20px;
    justify-content: center;
    /* border-bottom: 1px solid ${({ theme }) => theme.border}; */

    .periodo-titulo {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .icon-seccion {
        font-size: 16px;
        color: ${leerColor(colores.azulRebranding)};
    }

    span {
        font-size: 14px;
        color: ${({ theme }) => theme.subtitle};
    }
`;

export const PeriodoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 14px 20px;
    justify-content: center;
    /* border-bottom: 1px solid ${({ theme }) => theme.border}; */

    .periodo-titulo {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .icon-seccion {
        font-size: 16px;
        color: ${leerColor(colores.azulRebranding)};
    }

    span {
        font-size: 14px;
        color: ${({ theme }) => theme.subtitle};
    }
`;

export const FechasContainer = styled.div`
    display: flex;
    gap: 20px;

    span {
        font-size: 14px;
        color: ${({ theme }) => theme.title};
        font-weight: 500;
    }
`;

export const Motivo = styled.span`
        font-size: 14px;
        color: ${({ theme }) => theme.title};
        font-weight: 500;

`;