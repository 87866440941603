import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  FileLoaded,
  InputSelect,
  ProgressBar,
  Menu,
  Wrapper,
  Modal,
  NewProgressBar,
  BurgerMenu,
  NavMenu,
  InputTextarea,
  InputFileRec,
  InputFileRec2,
} from "../components/Index";
import { CargaSolicitud } from "../components/ProgressBar/styled";
import {
  ClearFilter,
  ClearFilterContainer,
  EditOperadorIcon,
  SolEmisionTitleContainer,
  ModalEditarOperador,
  ModalBorrarExcepcion,
  OperadorItem,
  GridCumplimientoContainer,
  GridCumplimientoContainerHeader,
} from "./styled";
import axiosRequest from "../utils/axiosConfig";
import IconButton from "../components/IconButton";
import editIcon from "../assets/iconos/edit.svg";
import deleteIcon from "../assets/iconos/delete.svg";
import Filters from "../components/Filters";
import ModalSuccess from "../components/ModalSuccess";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import { anios, meses } from "../constants/data";
import Pagination from "../components/Pagination";
import SpinnerLoading from "../components/SpinnerLoading";
import docImage from "../assets/images/doc_cuadrado.png";

const ObjetivosCobranzas = () => {
  // Context
  const authContext = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const allowedRoles = [1, 8];

  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const history = useHistory(); // Hook de router

  const [objetivos, setObjetivos] = useState([]);
  const [companias, setCompanias] = useState([]);
  const [ramos, setRamos] = useState([]);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [hayBusquedas, setHayBusquedas] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [isSuccessPopUpOpen, setIsSuccessPopUpOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [postObjetivosLoading, setPostObjetivosLoading] = useState(false);

  const [csvFile, setCsvFile] = useState();

  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({
    id: "",
    objetivo: "",
    estado: "",
  });
  const [isCrearObjetivoModalOpen, setIsCrearObjetivoModalOpen] =
    useState(false);
  const [isEditarObjetivoModalOpen, setIsEditarObjetivoModalOpen] =
    useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isDeleteObjetivoModalOpen, setIsDeleteObjetivoModalOpen] =
    useState(false);
  const [deleteObjetivoData, setDeleteObjetivoData] = useState("");

  // Pagination
  const [pageSelected, setPageSelected] = useState(1);
  const [solicitudesLength, setSolicitudesLength] = useState(0);
  const [filterParams, setFilterParams] = useState("");

        // Chequear si el usuario está autenticado, caso contrario lo manda al login. 
        useEffect(() => {
          if(!authContext.isAuthenticated()){
            history.push("/");
          }
        }, [])
      
    
        // Chequear el rol del usuario autenticado para saber si puede acceder a la página
        useEffect(() => {
          if(user){
            if(!allowedRoles.includes(user.id_rol)){
              history.push("/");
            }
          }
        }, [])
  

  const values = {
    anio: "",
    mes: "",
    id_compania: "",
    id_ramo: "",
  };

  const inputs = [
    {
      name: "anio",
      labelName: "Año",
      options: anios,
      component: InputSelect,
    },
    {
      name: "mes",
      labelName: "Mes",
      options: meses,
      component: InputSelect,
    },
    {
      name: "id_compania",
      labelName: "Compañia",
      options: companias,
      component: InputSelect,
    },
    {
      name: "id_ramo",
      labelName: "Ramos",
      options: ramos,
      component: InputSelect,
    },
  ];

  const headers = [
    "Id",
    "Año",
    "Mes",
    "Compañia",
    "Ramo",
    "Objetivo",
    "Acciones",
  ];

  // Chequear si el usuario está autenticado, caso contrario lo manda al login.
  useEffect(() => {
    if (!authContext.isAuthenticated()) {
      history.push("/");
    }
  }, []);

  // Chequear el rol del usuario autenticado para saber si puede acceder a la página
  useEffect(() => {
    if (user) {
      if (!allowedRoles.includes(user.id_rol) && user.id_rol != 8) {
        history.push("/Rechazadas");
      } else if (!allowedRoles.includes(user.id_rol) && user.id_rol == 8) {
        history.push("/Rechazos");
      }
    }
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  }

  // const getObjetivos = async () => {
  //   try {

  //     const allObjetivos = await axiosRequest.get(
  //       `/objetivos_cobros/objetivos?page=${pageSelected}&${filterParams}`
  //     );

  //     allObjetivos.data.forEach((objetivo) => {
  //       if (objetivo.anio === currentYear && objetivo.mes === currentMonth) {
  //         objetivo.acciones = (
  //           <div className="actionsContainer">
  //             <IconButton
  //               backgroundColor={false}
  //               borderColor={false}
  //               textColor={true}
  //               icon={editIcon}
  //               tooltipText="Editar Objetivo"
  //               action={() => openEditModal(objetivos)}
  //             />
  //             <IconButton
  //               backgroundColor={false}
  //               borderColor={false}
  //               textColor={true}
  //               icon={deleteIcon}
  //               tooltipText="Borrar Objetivo"
  //               action={() => openDeleteModal(objetivo)}
  //             />
  //           </div>
  //         );
  //       } else {
  //         objetivo.acciones = "-";
  //       }
  //       const monthName = meses.find(
  //         (mes) => parseInt(mes.value) + 1 === objetivo.mes
  //       );
  //       objetivo.mes = monthName.label;
  //     });

  //     setObjetivos(allObjetivos.data);
  //     setHayBusquedas(false);
  //   } catch (error) {
  //     console.log(error);
  //     setHayBusquedas(false);
  //   }
  // };

  // useEffect(() => {
  //   getObjetivos();
  // }, [pageSelected, filterParams, inputValues.objetivo]);
  const getData = async () => {
    try {
      const allObjetivos = await axiosRequest.get(
        `/objetivos_cobros/objetivos/?page=${pageSelected}&${filterParams}`
      );
      allObjetivos.data.forEach((objetivo) => {
        if (objetivo.anio === currentYear && objetivo.mes === currentMonth) {
          objetivo.acciones = (
            <div className="actionsContainer">
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={editIcon}
                tooltipText="Editar Objetivo"
                action={() => openEditModal(objetivo)}
              />
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={deleteIcon}
                tooltipText="Borrar Objetivo"
                action={() => openDeleteModal(objetivo)}
              />
            </div>
          );
        } else {
          objetivo.acciones = "-";
        }
        const monthName = meses.find(
          (mes) => parseInt(mes.value) + 1 === objetivo.mes
        );
        objetivo.mes = monthName.label;
      });

      const companiasParaSelect = [];

      setObjetivos(allObjetivos.data);
      setHayBusquedas(false);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    (async () =>{
    let allCompanias = await axiosRequest.get('/objetivos_cobros/companias')
    let allRamos = await axiosRequest.get('/objetivos_cobros/secciones')
  
    const ciasParaSelect = [];
  
    allCompanias.data.forEach((elem) => {
      ciasParaSelect.push({
        label: elem.nombre,
        value: elem.id,
        key: elem.id,
      });
    });

    const ramoParaSelect = [];

    allRamos.data.forEach((elem) => {
      ramoParaSelect.push({
        label: elem.seccion,
        value: elem.id,
        key: elem.id,
      });
    });
  

    setCompanias(ciasParaSelect)
    setRamos(ramoParaSelect)
      })()
  }, [])



  useEffect(() => {
    getData();
  }, [pageSelected, filterParams, inputValues.objetivo, deleteObjetivoData]);


  // useEffect(()=> {
  // getObjetivos()
  // }, [objetivos])

  useEffect(() => {
    if (!isCrearObjetivoModalOpen) {
      setInputValues({
        id: "",
        objetivo: "",
        estado: ""
      });
      setErrors("");
      setEditMode(false);
    }
  }, [isCrearObjetivoModalOpen]);

  const validateObjetivoData = () => {
    let errors = {};

    if (!inputValues.objetivo) {
      errors.objetivo = "Este campo es requerido.";
    }

    setErrors(errors);

    if (Object.keys(errors).length == 0) {
      return true;
    }
  };

  const cargarObjetivos = async () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      const headers = text.slice(0, text.indexOf("\n")).split(",");
      let obj = headers[6].slice(0, -1);
      headers[6] = obj;

      const content = text
        .slice(52, text.lastIndexOf(text.slice(-1)))
        .split("\n");

      const objetivos = content.map((obj) => {
        const values = obj.split(",");
        // let aux = values[6] == "\r" ? "" : values[6].slice(0, -1);
        return {
          anio: values[0],
          mes: values[1],
          id_compania: values[2],
          id_ramo: values[4],
          objetivo: values[6],
        };
      });

      let num = 0;

      if (objetivos.length > 0) {
        objetivos.forEach(async (obj, index) => {
          num = index;
          setPostObjetivosLoading(true);
          try {
            await axiosRequest.post("/objetivos_cobros/objetivos", obj);
          } catch (error) {
            console.log(error);
          }
        });
      }

      if (num == objetivos.length - 1) {
        setPostObjetivosLoading(false);
        getData();
        setIsCrearObjetivoModalOpen(false);
        setPopUpMessage("¡Objetivos cargados!");
        setIsSuccessPopUpOpen(true);
        setTimeout(() => {
          setIsSuccessPopUpOpen(false);
        }, 1200);
      }
    };
    reader.readAsText(file);
  };

  const openEditModal = (objetivo) => {
    setEditMode(true);
    setInputValues({
      id: objetivo.id,
      objetivo: objetivo.objetivo,
      estado: objetivo.estado
    });
    setIsEditarObjetivoModalOpen(true);
  };

  const openDeleteModal = (objetivo) => {
    setDeleteObjetivoData(objetivo);
    setIsDeleteObjetivoModalOpen(true);
  };

  const editObjetivo = async () => {
    const validation = validateObjetivoData();
    if (validation) {
      let aux = [inputValues];
      let num = 0;
      if (aux.length > 0) {
        aux.forEach(async (obj, index) => {
          num = index;
          setPostObjetivosLoading(true);
          try {
            await axiosRequest.put(
              `/objetivos_cobros/objetivos/${inputValues.id}`,
              obj
            );
          } catch (error) {
            console.log(error);
          }
        });
      }

      if (num === aux.length - 1) {
        getData();
        setIsEditarObjetivoModalOpen(false);
        setPopUpMessage("!Objetivo editado!");
        setIsSuccessPopUpOpen(true);
        setTimeout(() => {
          setIsSuccessPopUpOpen(false);
        }, 1200);
      }
    }
  };

  const deleteObjetivo = async () => {
    let aux = ["aux"];
    let num = 0;

    if (aux) {
      aux.forEach(async (obj, index) => {
        num = index;
        setPostObjetivosLoading(true);
        try {
          await axiosRequest.delete(
            `/objetivos_cobros/objetivos/${deleteObjetivoData.id}`
          );
        } catch (error) {
          console.log(error);
        }
      });
    }
    if (num === aux.length - 1) {
      setDeleteObjetivoData({
        ...deleteObjetivoData,
        estado: 0
      })

      setIsDeleteObjetivoModalOpen(false);
      setPopUpMessage("¡Objetivo eliminado!");
      setIsSuccessPopUpOpen(true);
      setTimeout(() => {
        setIsSuccessPopUpOpen(false);
      }, 1200);
    }
  };

  return (
    <div>
      <BurgerMenu open={open} setOpen={setOpen} />
      <NavMenu
        open={open}
        setOpen={setOpen}
        user={user}
        active={"objetivos-cobranzas"}
      />

      <ModalSuccess
        isSuccessPopUpOpen={isSuccessPopUpOpen}
        setIsSuccessPopUpOpen={setIsSuccessPopUpOpen}
        popUpMessage={popUpMessage}
      />

      {isCrearObjetivoModalOpen ? (
        <Modal
          isModalOpen={isCrearObjetivoModalOpen}
          setIsModalOpen={setIsCrearObjetivoModalOpen}
        >
          <ModalEditarOperador>
            <Titulo margin={true}>Cargar Objetivos de Cobranzas</Titulo>
            <Grid colGap={21} rowGap={21}>
              {!postObjetivosLoading ? (
                <>
                  <Col desktop={12}>
                    <InputFileRec2
                      type={"file"}
                      name={"objetivos"}
                      onChange={(e) => {
                        setCsvFile(e.target.files[0]);
                        e.target.value = "";
                      }}
                      accept={".csv"}
                      text={"Cargar archivo .csv"}
                    />
                    {csvFile ? (
                      <FileLoaded
                        filename={csvFile.name}
                        file={docImage}
                        link={URL.createObjectURL(csvFile)}
                        deleteFile={() => setCsvFile("")}
                      />
                    ) : null}
                  </Col>

                  <Col desktop={12}>
                    <div className="buttonsContSiniestro">
                      <Button
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        icon={false}
                        onClick={() => {
                          setCsvFile("");
                          setIsCrearObjetivoModalOpen(false);
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        icon={false}
                        onClick={cargarObjetivos}
                        disabled={csvFile ? false : true}
                      >
                        Cargar Objetivos
                      </Button>
                    </div>
                  </Col>
                </>
              ) : (
                <Col desktop={12}>
                  <SpinnerLoading text="Cargando objetivos..." />
                </Col>
              )}
            </Grid>
          </ModalEditarOperador>
        </Modal>
      ) : null}

      {isEditarObjetivoModalOpen ? (
        <Modal
          isModalOpen={isEditarObjetivoModalOpen}
          setIsModalOpen={setIsEditarObjetivoModalOpen}
        >
          <ModalEditarOperador>
            <Titulo margin={true}>Editar Objetivo</Titulo>
            <Grid colGap={21} rowGap={21}>
              <Col desktop={6}>
                <InputBasic
                  type={"text"}
                  name={"objetivo"}
                  labelName={"Objetivo"}
                  placeholder={"Describa el objetivo..."}
                  onChange={handleChange}
                  value={inputValues.objetivo}
                />
                {errors.objetivo && (
                  <span className="error">{errors.objetivo}</span>
                )}
              </Col>
              <Col desktop={12}>
                <div className="buttonsContSiniestro">
                  <Button
                    backgroundColor={false}
                    borderColor={true}
                    textColor={true}
                    icon={false}
                    onClick={() => setIsEditarObjetivoModalOpen(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    icon={false}
                    onClick={editObjetivo}
                  >
                    Guardar cambios
                  </Button>
                </div>
              </Col>
            </Grid>
          </ModalEditarOperador>
        </Modal>
      ) : null}

      {isDeleteObjetivoModalOpen ? (
        <Modal
          isModalOpen={isDeleteObjetivoModalOpen}
          setIsModalOpen={setIsDeleteObjetivoModalOpen}
        >
          <ModalBorrarExcepcion>
            <h2>¿Desea borrar el objetivo?</h2>
            <div className={"buttonsCont"}>
              <Button
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
                onClick={() => setIsDeleteObjetivoModalOpen(false)}
              >
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={deleteObjetivo}
              >
                Borrar Objetivo
              </Button>
            </div>
          </ModalBorrarExcepcion>
        </Modal>
      ) : null}

      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
              <SolEmisionTitleContainer>
                <Titulo textColor={false}>Objetivos de Cobranzas</Titulo>
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={() => setIsCrearObjetivoModalOpen(true)}
                >
                  Cargar objetivos
                </Button>
              </SolEmisionTitleContainer>
            </Col>
            <Col desktop={12}>
              <Subtitulo>Filtrar objetivos</Subtitulo>
            </Col>
            <Filters
              inputs={inputs}
              values={values}
              data={data}
              setData={setData}
              setHayBusquedas={setHayBusquedas}
              setFilterParams={setFilterParams}
              setPageSelected={setPageSelected}
            />
          </Grid>
        </Inner>
        <InfoBoard
          borderTop={true}
          hayBusquedas={hayBusquedas}
          headers={headers}
          data={objetivos}
          loading={hayBusquedas}
          page="Landings"
        />
        {solicitudesLength > 10 && (
          <Pagination
            data={data}
            dataLength={solicitudesLength}
            setPageSelected={setPageSelected}
          />
        )}
      </Wrapper>
    </div>
  );
};

export default ObjetivosCobranzas;
