import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  position: relative; 
  
  .dropDown{
    width: 70px;
    border-radius: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    //bottom: 168%; //95%;
   // right: ${props => (props.isOperatorVisible ? '-1' : '0%')};
    top: -190%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%); 
    z-index: 99;
    padding: 20px;
    background-color: ${leerColor(colores.blanco)};
    -webkit-box-shadow: 12px 12px 30px 0px rgba(177,178,180,0.2);    
    box-shadow: 12px 12px 30px 0px rgba(177,178,180,0.2);     
    color: ${leerColor(colores.grisModales)};
    font-family: 'DM Sans', sans-serif;
    border: ${({ theme }) => `1px solid ${theme.border}`};
    //background-color: ${leerColor(colores.azul)};
    background-color: ${({ theme }) => theme.background};
    transition: all 0.5s;


    &::after{
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: ${({ theme }) => theme.background};
      transition: all 0.5s;
      //background-color: ${leerColor(colores.azul)};
      bottom: -10px;
      left: 50%;
      z-index: 99;
      -webkit-transform: translate(-50%, 0) rotate(45deg);
      -ms-transform: translate(-50%, 0) rotate(45deg);
      transform: translate(-50%, 0) rotate(45deg);
      animation: example 0.2s linear;
    }

    @keyframes example {
        0% {height: 0px;}
        100% {height: 20px;}
    }

    span:hover{
      color: ${leerColor(colores.azulRebranding)};
      font-weight: 700;
      cursor: pointer;
    }
  }
  .dropDown span, .dropDown a{
    text-decoration: none; 
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    text-align: left;
    color: ${({ theme }) => theme.subtitle};
    font-family: 'DM Sans', sans-serif;
  }
  .dropDown span:last-of-type{
    padding-bottom: 0;
  }
  h5{
    color: ${leerColor(colores.celeste)};
     
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 40px 20px;
    margin: 0;
  }
  .dropUp{
    display: none;
  }

  .icono {
    color: ${leerColor(colores.azulRebranding)};
    font-size: 24px;
    cursor: pointer;
    display: flex;
    box-sizing: border-box;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${leerColor(colores.azulRebranding)};
  }

  .icono:hover {
    opacity: 0.7;
  }
`

export const ButtonAction = styled.a`
  cursor: pointer; 
  // 
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 12px;
  border-radius: 5px; 
  background-color: ${props => (props['data-backgroundcolor'] ? leerColor(colores.celeste) : leerColor(colores.blanco))}!important;
  border: 1px solid ${props => (props['data-bordercolor'] ? leerColor(colores.celeste) : 'none')};
  color: ${props => (props['data-textcolor'] ? leerColor(colores.celeste) : leerColor(colores.blanco))}!important;

  ${props => props.icon && css`
    content: url(${props => (props.icon)});
  `}

  :hover{
    opacity:0.7;
  }
`