import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Section = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: rgb(120, 120, 120);
    list-style-type: none;
  }

  li {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      display: flex;
      font-size: 20px;
      cursor: pointer;
      color: ${leerColor(colores.rojo)}
    }
  }

  .agregar-container {
    display: flex;
    flex-direction: column;
  }

  .input-agregar-container {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .botones-container {
    display: flex;
    gap: 10px;
  }
  .plan-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 20px;

    h3 {
      margin: 0;
      font-size: 14px;
    }

    span {
      color: ${leerColor(colores.grisEstados)};
    }
  }

  .empty-state {
    color: ${leerColor(colores.grisEstados)};
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #eaeaea;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .lista-titulo {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: ${leerColor(colores.grisEstados)};
    font-weight: 500;
  }

  .section-title {
    margin-bottom: ${({ noMargin }) => noMargin ? "0px" : "20px"};
    //margin-bottom: 20px;
    h2 {
      margin-bottom: 6px;
      color: ${leerColor(colores.azul)};
      display: flex;
      align-items: center;

      .icon {
        color: ${leerColor(colores.celeste)};
        display: flex;
      }
    }
    p {
      margin: 0;
    }
  }

  .switch-calculo {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${leerColor(colores.grisEstados)};
  }
`;
