import { useState } from "react"

export default function useAutocomplete (listToFilter, handleState, withTags, tagsRemove) {
    const [autocomplete, setAutocomplete] = useState()
    const [autocompleteActiveSuggestionIndex, setAutocompleteActiveSuggestionIndex] = useState(null)

    console.log(listToFilter)

    const handleChangeAutocomplete = (value) => {
        if(value.length >= 1){
            setAutocompleteActiveSuggestionIndex(0)
            console.log(listToFilter)
            const filteredList = listToFilter.filter(item => {
                if(typeof(item) === 'string'){
                    return item.toLowerCase().includes(value.toLowerCase()) 
                } else {
                    console.log(item)
                    console.log(value)
                    const format = item.toString()
                    return format.toLowerCase().includes(value.toLowerCase()) 
                }
            })
            console.log(filteredList)
            setAutocomplete(filteredList)
        } else if(value.length < 2){
            setAutocomplete([])
            setAutocompleteActiveSuggestionIndex(null)
        }
    }

    // Keyboard functionality
    const changeAutocompleteSuggestion = (e) => {
        console.log(e.keyCode)

        switch(e.keyCode) {
            case 13:
                console.log(autocomplete[autocompleteActiveSuggestionIndex])
                handleState(e.target.name, autocomplete[autocompleteActiveSuggestionIndex], 2)
                setAutocomplete([])
                setAutocompleteActiveSuggestionIndex(null)
                break
            case 8: 
                if(withTags) tagsRemove()
                break 
            case 38:
                if(autocompleteActiveSuggestionIndex != null && autocompleteActiveSuggestionIndex > 0){
                    setAutocompleteActiveSuggestionIndex(prev => prev - 1)
                }
                break
            case 40:
                if(autocompleteActiveSuggestionIndex != null && autocompleteActiveSuggestionIndex < autocomplete.length - 1){
                    setAutocompleteActiveSuggestionIndex(prev => prev + 1)
                } else {
                    setAutocompleteActiveSuggestionIndex(0)
                }
                break
            default:
                break
        }
    }

    return {
        autocomplete,
        autocompleteActiveSuggestionIndex,
        changeAutocompleteSuggestion, 
        setAutocompleteActiveSuggestionIndex,
        setAutocomplete,
        handleChangeAutocomplete
    }
}