import axiosRequest from "./axiosConfig";
import valid from 'card-validator'

const createWordFile = async (id) => {
    // ID de la solicitud que llego para poder conseguir la información de los demás campos  
    const solicitud = await axiosRequest.get(`/solicitudes/${id}`);
    // Información del asegurado
    const cliente = await axiosRequest.get(`/asegurados/${solicitud.data.id_asegurado}`);

    // Información del medio de pago
    const datos_facturacion = await axiosRequest.get(`/datos_facturacion/${solicitud.data.id_dato_facturacion}`);
    // Busco el medio de pago con el ID que viene en datos_facturación para saber su nombre y guardarlo en el state
    const medio_pago = await axiosRequest.get(`/medios_pagos/${datos_facturacion.data.id_medio_pago}`);
    const datosDeFacturacion = {...datos_facturacion.data, medio: medio_pago.data.descripcion_medio_pago}

    // Información de la cobertura asegurada
    const cobertura_asegurada = await axiosRequest.get(`/coberturas_aseguradas/${solicitud.data.id_cobertura_asegurada}`);

    // Información del bien asegurado
    let bienesData = {}
    const bienes_asegurados = await axiosRequest.get(`/bienes_asegurados/${id}`);
    // Agrupo la data en un objeto para guardarla en el state y que sea más fácil acceder a sus propiedades
    bienes_asegurados.data.forEach(bien => {
      return bienesData[bien.item_bien_asegurado] = bien.descripcion_bien_asegurado
    })
    // Obtener adjunto
    const adjuntos = await axiosRequest.get(`/asegurados_adjuntos`);
    const findAdjunto = adjuntos.data.find(adjunto =>{
        return cliente.data.id_asegurado === adjunto.id_asegurado
    })
    if(findAdjunto){
      bienesData['adjunto'] = findAdjunto.url_adjunto
    }

    let content = {
      asegurado: cliente.data,
      bienesAsegurados: bienesData,
      datosDeFacturacion: datosDeFacturacion,
      coberturaAsegurada: cobertura_asegurada.data,
      id_solicitud: id
    }

    if(datosDeFacturacion.medio === "Tarjeta de Crédito"){
      const validNumber = valid.number(datosDeFacturacion.num_dato_facturacion);
      const creditCardType = validNumber.card.niceType;
      content.datosDeFacturacion.marca = creditCardType
      if(datosDeFacturacion.num_dato_facturacion.startsWith("5895")){
        content.datosDeFacturacion.marca = "Naranja"
      }
    }

    try{
      const createDoc = await axiosRequest.post('/create_doc', { content: content });
      return createDoc.data
    } catch (error){
      console.log("Error al intentar crear el Word", error)
    }
  }

export default createWordFile