import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const InputDiv = styled.div`
  cursor: pointer; 
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 26px;
  border-radius: 5px; 
  background-color: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.celeste)};
  color: ${leerColor(colores.celeste)};
  label{
    cursor: pointer;
    width: 100%;
  }
  label img{
    padding-left: 10px;
  }
  span{
    display: flex;
  }
`
export const Input = styled.input` 
  display: none;
  /*  
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 20px;
  border: 1px solid ${leerColor(colores.gris)};
  &::placeholder{
    color: ${leerColor(colores.gris)};
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  } */
  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    
  }
  @media (min-width: ${breakpoints.notebook}) {

  }
`;
