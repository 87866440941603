import Modal from "../../../Modal"
import { Container } from "./styled"
import Button from "../../../Button"
import { VscError } from "react-icons/vsc";

const ModalError = ({ isModalOpen, setIsModalOpen, message }) => {
    return(
        <>
            {isModalOpen ?
                <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} hideCross={true}>
                    <Container>
                        <span className="icon"><VscError /></span>
                        <h3>{message}</h3>
                        <span>La información del error fue enviada al equipo de desarrollo para ser revisada.</span>
                        <Button textColor={true} onClick={() => setIsModalOpen(!isModalOpen)}>Cerrar</Button>
                    </Container>
                </Modal>
                : 
                null
            }
        </>
    )
}

export default ModalError