import Modal from "../Modal"
import { motion, AnimatePresence } from "framer-motion"
import Check from "../../assets/iconos/tic.svg";
import PopUp from "../Popup";

const ModalSuccess = ({isSuccessPopUpOpen, setIsSuccessPopUpOpen, popUpMessage}) => {
    return(
          <Modal isModalOpen={isSuccessPopUpOpen} setIsModalOpen={setIsSuccessPopUpOpen}>
            <PopUp
              icono={Check}
              backgroundColor={true}
              mensaje={popUpMessage}
            />
          </Modal>
    )
}

export default ModalSuccess