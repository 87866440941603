import Modal from "../../../Modal"
import { ModalBorrarExcepcion } from "../../../../pages/styled"
import { Container } from "./styled"
import Titulo from "../../../Titulo"
import { Grid, Col } from "../../../Grid"
import Button from "../../../Button"
import InputBasic from "../../../InputBasic"
import { ContainerBotones, Usuario, Icon } from "./styled"
import ErrorInput from "../../../ErrorInput"
import { InputFileRec2 } from "../../../Index"
import FileLoadedNew from "../../../FileLoadedNew"
import SpinnerLoading from "../../../SpinnerLoading"
import InputSelect from "../../../InputSelect"
// Icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { VscLock } from 'react-icons/vsc'
import { FaUserCircle } from 'react-icons/fa'
import { BsCreditCard2Back, BsCart3, BsPersonCircle } from 'react-icons/bs'
import docImage from '../../../../assets/images/doc_cuadrado.png'
// React
import { useState } from "react"
import Subtitulo from "../../../Subtitulo"
import { meses } from "../../../../constants/data"

const ModalBorrarObjetivoLeads = ({ isModalOpen, setIsModalOpen, data }) => {

    const { objetivo, deleteObjetivo } = data
    
    return (
      <div>
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} >
            <Container>
            <Titulo>¿Desea borrar el objetivo?</Titulo>
            {objetivo ?
            <Grid colGap={21} rowGap={8}>
                <Col desktop={4}>
                  <h4>Canal</h4>
                  <span>{objetivo.canal}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Producto</h4>
                  <span>{objetivo.producto}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Mes</h4>
                  <span>{meses.find(mes => mes.value + 1 === objetivo.mes).label}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Año</h4>
                  <span>{objetivo.anio}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Leads</h4>
                  <span>{objetivo.leads}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Operaciones</h4>
                  <span>{objetivo.operaciones}</span>
                </Col>
            </Grid>
            : objetivo}
            <div className={'buttonsCont'}>
                <Button backgroundColor={false} borderColor={true} textColor={true} icon={false} onClick={() => setIsModalOpen(false)}> Cancelar </Button>
                <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={deleteObjetivo}>Borrar Objetivo</Button>
            </div>
            </Container>
        </Modal>
      </div>
    );
}

export default ModalBorrarObjetivoLeads
