import styled from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

const DetailsMain = styled.div`
  //width: 100%;
  padding: 35px;
  margin: ${(props) => (props.narrow ? "0 auto" : "")};
  justify-items: ${(props) => (props.centerItems ? "center" : "")};
  background-color: #fff;

  .modalMail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;

    .modal-title-container {
      margin-bottom: 20px;
    }

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .buttonsContainer{
      display: flex;
      place-content: flex-end;
    }

    .subtitulo {
      color: ${leerColor(colores.azul)};
      margin: 20px 0 0 0;
    }

    .buttonsCont {
      display: flex;
      width: 100%;
      place-content: flex-end;
      margin: 20px 0 10px 0;

      a {
        margin: 0 6px;
      }
    }
  }
  
  .modalReject .form div{
    margin-top:20px;
  } 

  #titulo {
    width: 100%;
     
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 19px;
    color: ${leerColor(colores.grisModales)};
  }

  #subtitulo {
    width: 100%;
    margin: 20px 0px 10px 0px;
     
    font-weight: 600;
    font-size: 15px;
    color: ${leerColor(colores.grisModales)};
  }

  .text-container {
    width: 100%;
    margin: 0px 0px 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-items: flex-start;

    p {
      width: auto;
      margin: 9px 15px 9px 0px;
       
      font-weight: 800;
      font-size: 14px;
      color: ${leerColor(colores.grisModales)};

      span {
        margin: 0px 0px 0px 4px;
        font-weight: 400;
      }
    }
  }

  #error {
    width: 100%;
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  #input-container {
    width: 100%;
    margin: 30px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  .select-container {
    width: 100%;
    max-width: 260px;
    margin: 20px 20px 23px 0px;
    position: relative;
  }

  // Select Label
  .select-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .select-container select {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
  }

  .select-container select {
    width: 100%;
    height: 35px;
    padding: 0px 15px 0px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
     
    font-style: normal;
    font-weight: normal;
    font-size: 0.8em;
    color: ${leerColor(colores.gris)};
    border: 1px solid ${leerColor(colores.gris)};
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px !important;
    background-color: white;
  }

  .select-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 15px;
    line-height: 0.75;
    font-size: 0.8em;
    font-weight: 400;
    color: ${leerColor(colores.gris)};
    cursor: text;
    z-index: 60;
  }

  .select-container select:focus + label {
    top: -0.6em;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: ${leerColor(colores.celeste)} !important;
    z-index: 59 !important;
  }

  .select-container select:focus {
    border: 1px solid ${leerColor(colores.celeste)};
    outline: none;
    z-index: 59 !important;
  }

  // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: ${leerColor(colores.gris)} !important;
    z-index: 59 !important;
  }

  // Select Container
  .select-container {
    position: relative;
    width: 100%;
    height: 35px;
  }

  // Select
  .select-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 100%;
    padding: 0px 15px 0px 15px;
    margin: 0px 0px 0px 0px;
    height: 100%;
    cursor: pointer;
    outline: 0;
    border: 1px solid ${leerColor(colores.gris)};
     
    font-style: normal;
    font-weight: normal;
    color: ${leerColor(colores.gris)};
    position: relative;
    transition: all 0.25s ease;
    z-index: 60;
    background-color: transparent;
    border-radius: 6px;
    -webkit-appearance: none;
    box-shadow: 0;
    border-radius: 3px;
    font-size: 0.75em;
  }

  // Select Arrow
  .select-container i {
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);
    width: 7px;
    height: 7px;
    display: block;
    background-color: ${leerColor(colores.blanco)};
    border-left: 3px solid ${leerColor(colores.gris)};
    border-bottom: 3px solid ${leerColor(colores.gris)};
    transform: rotate(-45deg);
    z-index: 59;
    border-radius: 10%;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: ${leerColor(colores.negro)};
  }

  #archivo-cargado {
    width: 100%;
    margin: 15px 0px 15px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    p {
      width: auto;
       
      margin: 0px 10px 0px 0px;
      font-weight: 600;
      font-size: 15px;
      color: ${leerColor(colores.grisModales)};
    }

    a {
      margin: 0px 0px 0px 0px;
      font-size: 20px;
      color: red;
      opacity: 0.6;
    }
  }

  #table-container {
    width: 100%;
    margin: 20px 0px 40px 0px;

    table {
      width: 100%;
      margin: 0px 0px 0px 0px;
      text-decoration: none;
      border: none;
      border-collapse: collapse;

      thead {
        width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
      }

      tr {
        width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
      }

      th,
      td {
        width: fit-content;
        font-size: 13.4px;
        margin: 0px 0px 0px 0px;
        padding: 12px;
        text-align: left;
        color: ${leerColor(colores.celeste)};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      th {
        text-transform: uppercase;
      }

      td {
        color: #333;
        border-top: 1px solid ${leerColor(colores.gris)};
      }

      .colum-nombre {
        border-left: 0px;
      }

      .colum-compañia {
        border-right: 0px;
      }
    }
  }

  &.historial {
    width: 900px;
    padding: 10px 0;
  }

  #botones-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    a {
      margin: 0px 10px 0px 0px;
    }
  }

  .checkbox-usuario {
    background-color: red;
    font-size: 100px;
  }

  #table-container-cobros {
    width: 100%;
    margin: 20px 0px 40px 0px;

    table {
      width: 100%;
      margin: 0px 0px 0px 0px;
      text-decoration: none;
      border: none;
      border-collapse: collapse;

      thead {
        width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
      }

      tr {
        width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
      }

      th,
      td {
        width: fit-content;
         
        font-size: 13.4px;
        margin: 0px 0px 0px 0px;
        padding: 10px 0px 10px 0px;
        text-align: center;
        border-top: 1px solid ${leerColor(colores.gris)};
        border-left: 1px solid ${leerColor(colores.gris)};
        border-right: 1px solid ${leerColor(colores.gris)};
        color: ${leerColor(colores.celeste)};
      }

      td {
        color: #333;
      }

      .colum-nombre {
        border-left: 0px;
      }

      .colum-compañia {
        border-right: 0px;
      }

      .first-tr {
        background-color: #E6EFFF;
      }

      .first-tr th:nth-child(1) {
        width: 65%;
      }

      .second-tr th:nth-child(n) {
        width: 9%;
        background-color: #E6EFFF;
      }

      .second-tr th:nth-child(1) {
        width: 9.3%;
        background-color: #E6EFFF;
      }

      .second-tr th:nth-child(6) {
        width: 2.55%;
        background-color: #E6EFFF;
      }

      .third-tr th:nth-child(n) {
        width: 8%;
        background-color: gainsboro;
      }
      .third-tr th:nth-child(1) {
        width: 17.5%;
      }
      .third-tr th:nth-child(10) {
        /* width: 17.5%; */
        color: gainsboro;
      }

      .total {
        background-color: #E6EFFF;
        font-weight: bold;
      }

      .totales {
        background-color: #E6EFFF;
        font-weight: bold;
        border-top: 3px solid ${leerColor(colores.gris)};
        border-bottom: 3px solid ${leerColor(colores.gris)};
      }

      .totales td:nth-child(10) {
        background-color: gainsboro;
      }

      .tipos-premios th:nth-child(n) {
        width: 5%;
        background-color: gainsboro;
      }


    }
  }

`;

const Span = styled.span`
  margin: 5px;
  margin-right: 100px;
  font-weight: 600;
`;

const ScrollX = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
  //height: 320px;
  white-space: nowrap;
  text-align: left;
  justify-items: ${(props) => (props.centerItems ? "center" : "")};

  hr {
    opacity: 0.2;
  }
`;

export { DetailsMain, Span, ScrollX };
