import { useState } from "react";
import useModal from "./useModal";
import dayjs from "dayjs";
import axiosRequest from "../utils/axiosConfig";
import { useLoading } from "./useLoading";
import { useSocket } from "../context/SocketProvider";
import { useAuthContext } from "../context/AuthContext";
import { sendMailManually } from "../services/Rechazos";

export const useAsignacionRechazos = (rechazos, refresRechazosTotales) => {
  // User Data
  const { authState } = useAuthContext()
  const { userInfo } = authState
  const [success, setSuccess] = useState(false)
  const socket = useSocket()
  const [rechazosSeleccionados, setRechazosSeleccionados] = useState([])
  const [solicitudesAsignadas, setSolicitudesAsignadas] = useState(false)
  const [operadorId, setOperadorId] = useState(null)
  const { isModalOpen, handleModal } = useModal()
  const { loading, setLoading } = useLoading()

  const handleChangeOperador = (value) => {
    setOperadorId(value)
  }

  const seleccionarRechazo = (event, item) => {
    const { checked } = event.target
    if(item.id_estado === 8 || item.id_estado === 11){
      if(checked){
        setRechazosSeleccionados(prev => [...prev, item])
    } else {
        setRechazosSeleccionados(prev => prev.filter(solicitud => solicitud.id !== item.id))
    }
    }
  }

  const seleccionarTodos = () => {
    // solo rechazos pendientes y notificados
    const rechazosNotificables = rechazos.filter(r => r.id_estado=== 8 || r.id_estado ===11);

    if(rechazosSeleccionados.length !== 0){
      setRechazosSeleccionados([])
    } else {
      setRechazosSeleccionados(rechazosNotificables)
    }
  }

  const removerRechazo = (id) => {
    setRechazosSeleccionados(prev => prev.filter(solicitud => solicitud.id !== id))
  }

  const notificarRechazos = async () => {
    setSuccess(false)
    setLoading(true)
    try {
      const res = await sendMailManually(rechazosSeleccionados, userInfo.id_usuario)
      if(res){
        setSuccess(true)
        refresRechazosTotales()
      }
    } catch (error) {
    } finally {
      setLoading(false)
      handleModal()
    }
  }

  return {
    modal: {
        isModalOpen,
        handleModal,
    },
    success,
    seleccionarTodos,
    seleccionarRechazo,
    rechazosSeleccionados,
    removerRechazo,
    notificarRechazos,
    handleChangeOperador,
    loading,
    solicitudesAsignadas,
    operadorId
  };
}