import axiosRequest from "../utils/axiosConfig";

// Obtener contrataciones
export const obtenerContrataciones = async (filterParams, orderParams, page, userParams) => {
    const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
    const autogestionData = await axiosRequest.get(`/autogestion?${pageFilter}${filterParams}&${orderParams}`)
    return autogestionData.data
}

// Pago
export const desencriptarPago = async (datosParaDesencriptar) => {
    const pago = await axiosRequest.post("/autogestion/desencript", datosParaDesencriptar);
    return pago.data
}

// Archivos
export const getAutogestionArchivos = async (idOperacion) => {
    const archivos = await axiosRequest.get(`/autogestion_archivos/${idOperacion}`);
    return archivos.data
}

// Data
export const getDataAutogestion = async (idOperacion) => {
    const data = await axiosRequest.get(`/autogestion/${idOperacion}/1`);
    return data.data
}

// Data
export const getDataPagoAutogestion = async (idOperacion) => {
    const data = await axiosRequest.get(`/autogestion_pago/${idOperacion}/1`);
    return data.data
}