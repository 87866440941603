import { SolicitudProvider } from "../../../context/SolicitudContext"
import Wizard from "../../Wizard"
import AseguradoForm from "../AseguradoForm"
import EndosoForm from "../EndosoForm"
import EnviarSolicitud from "../EnviarSolicitud"
import ObservacionForm from "../ObservacionForm"

const EndosoSolicitudForm = ({ solicitud }) => {
  return (
    <SolicitudProvider solicitud={solicitud} >
      <Wizard> 
          <AseguradoForm />
          <EndosoForm />
          <ObservacionForm />
          <EnviarSolicitud />
      </Wizard>
  </SolicitudProvider>
  )
}

export default EndosoSolicitudForm