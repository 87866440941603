import React, { useState, useEffect } from "react";
import {
  InputBasic,
  InputSelect,
  InputCheckbox,
  InputFileRec,
  InputTextarea,
  ErrorInput,
  FileLoaded,
  Subtitulo,
  Grid,
  Col,
} from "../../Index";
import { useSolicitudContext } from "../../../context/SolicitudContext";
import NewInputSelect from "../../NewInputSelect";
import NewInputBasic from "../../NewInputBasic";
import NewInputTextarea from "../../NewInputTextarea";
import Subtitle from "../../Subtitle";
import { FiShield } from "react-icons/fi";

const SeguroForm = () => {
  const { seguro } = useSolicitudContext();
  const { values, formErrors, handleChange, data } = seguro;
  const { companias, productos, coberturas, vigencias, cuotas } = data;

  const vigenciasFacturaciones = ["Mensual", "Bimensual", "Trimestral", "Cuatrimestral", "Semestral", "Anual"]

  return (
    <div>
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={12}>
          <Subtitle
            title="Seguro"
            description="Completa los datos del seguro"
            icon={<FiShield />}
          />
        </Col>
        <Col desktop={4}>
          <NewInputSelect
            name={"cia_cobertura_asegurada"}
            labelName={"Compañía"}
            color={formErrors.cia_cobertura_asegurada ? "error" : "normal"}
            placeholder="Compañia"
            onChange={(e) => handleChange(e, true)}
            options={companias}
            value={values.cia_cobertura_asegurada}
          />
          {formErrors.cia_cobertura_asegurada && (
            <ErrorInput>{formErrors.cia_cobertura_asegurada}</ErrorInput>
          )}
        </Col>
        <Col desktop={4}>
          <NewInputSelect
            name={"producto_cobertura_asegurada"}
            labelName={"Producto"}
            color={formErrors.producto_cobertura_asegurada ? "error" : "normal"}
            placeholder="Producto"
            onChange={(e) => handleChange(e, true)}
            options={productos}
            value={values.producto_cobertura_asegurada}
            disabled={!values.cia_cobertura_asegurada}
          />
          {formErrors.producto_cobertura_asegurada && (
            <ErrorInput>{formErrors.producto_cobertura_asegurada}</ErrorInput>
          )}
        </Col>
        {/*
                <Col desktop={4}>
                    <NewInputSelect name={"cobertura_cobertura_asegurada"} labelName={"Cobertura"} color={formErrors.cobertura_cobertura_asegurada ? "error" : "normal"} placeholder="Cobertura" onChange={(e) => handleChange(e, true)} options={coberturas} value={values.cobertura_cobertura_asegurada} disabled={!values.producto_cobertura_asegurada} />
                    {formErrors.cobertura_cobertura_asegurada && <ErrorInput>{formErrors.cobertura_cobertura_asegurada}</ErrorInput>}
                </Col>
                <Col desktop={12}>
                    <NewInputTextarea type={"text"} name={"descripcion_cobertura_asegurada"} labelName={"Descripción"} color={formErrors.descripcion_cobertura_asegurada ? "error" : "normal"} placeholder={"Esta es la descripción de la cobertura"} onChange={handleChange} value={values.descripcion_cobertura_asegurada} disabled={true} readOnly={true} />
                    {formErrors.descripcion_cobertura_asegurada && <ErrorInput>{formErrors.descripcion_cobertura_asegurada}</ErrorInput>}
                </Col>
                */}
        <Col desktop={4}>
          <NewInputBasic
            type={"text"}
            name={"cobertura_cobertura_asegurada"}
            labelName={"Cobertura"}
            color={formErrors.cobertura_cobertura_asegurada ? "error" : "normal"}
            placeholder={"Descripción de la cobertura"}
            onChange={handleChange}
            value={values.cobertura_cobertura_asegurada}
          />
          {formErrors.valor_cobertura_asegurada && (
            <ErrorInput>{formErrors.valor_cobertura_asegurada}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"valor_cobertura_asegurada"}
            labelName={"Premio"}
            color={formErrors.valor_cobertura_asegurada ? "error" : "normal"}
            placeholder={"Valor del premio"}
            onChange={handleChange}
            value={values.valor_cobertura_asegurada}
          />
          {formErrors.valor_cobertura_asegurada && (
            <ErrorInput>{formErrors.valor_cobertura_asegurada}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            name={"vigencia_cobertura_asegurada"}
            labelName={"Vigencia"}
            color={formErrors.vigencia_cobertura_asegurada ? "error" : "normal"}
            placeholder="Vigencia"
            onChange={handleChange}
            options={vigencias}
            value={values.vigencia_cobertura_asegurada}
          />
          {formErrors.vigencia_cobertura_asegurada && (
            <ErrorInput>{formErrors.vigencia_cobertura_asegurada}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            name={"tipo_facturacion_cobertura_asegurada"}
            labelName={"Tipo de facturación"}
            color={formErrors.tipo_facturacion_cobertura_asegurada ? "error" : "normal"}
            placeholder="Tipo de facturación"
            onChange={handleChange}
            options={vigencias}
            value={values.tipo_facturacion_cobertura_asegurada}
          />
          {formErrors.tipo_facturacion_cobertura_asegurada && (
            <ErrorInput>{formErrors.tipo_facturacion_cobertura_asegurada}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            name={"cuotas_cobertura_asegurada"}
            labelName={"Cuotas"}
            color={formErrors.cuotas_cobertura_asegurada ? "error" : "normal"}
            placeholder="Cuotas"
            onChange={handleChange}
            options={cuotas}
            value={values.cuotas_cobertura_asegurada}
          />
          {formErrors.cuotas_cobertura_asegurada && (
            <ErrorInput>{formErrors.cuotas_cobertura_asegurada}</ErrorInput>
          )}
        </Col>
      </Grid>
    </div>
  );
};

export default SeguroForm;
