import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputDiv, Input } from "./styled";

function InputCheckbox({ labelName, type, name, onChange, readOnly, calendarPicker, cancelReminder, color, value, disabled, checked }) {

  return (
    <InputDiv>
      <input
        type='checkbox'
        name={name}
        id={name}
        value={value}
        color={color}
        onChange={onChange}
        readOnly={readOnly}
        calendarPicker={calendarPicker}
        cancelReminder={cancelReminder}
        disabled={disabled}
        checked={checked}
      />
      <label htmlFor={name} color={color}>{labelName}</label>
    </InputDiv>
  )
}

export default InputCheckbox;
