import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Col,
  InputBasic,
  InputSelect,
  Vendedores,
} from "../../../Index";
import axiosRequest from "../../../../utils/axiosConfig";
import { Table } from "../../../Table/styled";

const ExternosNuevos = ({
  selectToShow,
  filtro,
  setFiltro,
  error,
  btnLimpiar,
  btnDisabled,
  datosTabla,
  limpiarForm,
  buscarInforme,
  precioFormato
}) => {

  const addField = (e) => {
    const { name, value } = e.target;
    setFiltro({ ...filtro, [name]: value });
  };

  console.log(datosTabla)

  return (
    <>
      <Col desktop={12}>
        <Subtitulo>Informe de vendedores externos nuevos</Subtitulo>
      </Col>
      <Col desktop={3}>
        <InputSelect
          name={"anio"}
          labelName={"Año"}
          options={selectToShow.anios}
          value={filtro.anio}
          onChange={addField}
        />
      </Col>
      <Col desktop={3}>
        <InputSelect
          name={"mes"}
          labelName={"Mes"}
          options={selectToShow.meses}
          value={filtro.mes}
          onChange={addField}
        />
      </Col>
      <Col desktop={3}>
        <InputSelect
          name={"vendedor"}
          labelName={"Vendedor"}
          options={selectToShow.vendedores}
          value={filtro.vendedor}
          onChange={addField}
        />
      </Col>
      <Col desktop={12}>
        <Button
          backgroundColor={true}
          borderColor={false}
          textColor={false}
          icon={false}
          onClick={buscarInforme}
          disabled={btnDisabled}
        >
          Buscar
        </Button>
        {btnLimpiar ? (
          <Button
            backgroundColor={true}
            borderColor={false}
            textColor={false}
            icon={false}
            onClick={limpiarForm}
          >
            Limpiar
          </Button>
        ) : null}
      </Col>
      <Col desktop={12}>
        {datosTabla?.length !== 0 ? (
          <Table>
          <thead>
            <tr>
              <th rowSpan={2}>Vendedor</th>
              <th rowSpan={2}>Quincena</th>
              <th rowSpan={2}>Bici</th>
              <th rowSpan={2}>Auto</th>
              <th rowSpan={2}>Moto</th>
              <th rowSpan={2}>Hogar</th>
              <th rowSpan={2}>Prima</th>
              <th rowSpan={2}>Ref y Cross</th>
              <th rowSpan={2}>Operaciones</th>
              <th rowSpan={2}>Total</th>
            </tr>

          </thead>
          <tbody>
            {datosTabla.map((d) => {
              return (
                <>
                  <tr>
                    <td>{d.nombreVendedor}</td>
                    <td>{d.primeraQuincena ? `$${precioFormato(d.primeraQuincena)}` : '-'}</td>
                    <td>{d.premioBici ? `$${precioFormato(d.premioBici)}` : '-'}</td>
                    <td>{d.premioAuto ? `$${precioFormato(d.premioAuto)}` : '-'}</td>
                    <td>{d.premioMoto ? `$${precioFormato(d.premioMoto)}` : '-'}</td>
                    <td>{d.premioHogar ? `$${precioFormato(d.premioHogar)}` : '-'}</td>
                    <td>{d.premioPrima ? `$${precioFormato(d.premioPrima)}` : '-'}</td>
                    <td>{d.premioRefYCross ? `$${precioFormato(d.premioRefYCross)}` : '-'}</td>
                    <td>{d.premioOperaciones ? `$${precioFormato(d.premioOperaciones)}` : '-'}</td>
                    <td>{`$${precioFormato(d.primeraQuincena + d.premioBici + d.premioAuto + d.premioMoto + d.premioHogar + d.premioPrima + d.premioRefYCross + d.premioOperaciones)}`}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      ) : error.msg !== "" ? (
          error.msg
        ) : null}
      </Col>
    </>
  );
};

export default ExternosNuevos;
