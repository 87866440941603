// Components
import Modal from "../../../Modal";
import { Grid, Col } from "../../../Grid";
import NewInputTextarea from "../../../NewInputTextarea";
// Hooks
import { useForm } from "../../../../hooks/useForm";
// Utils
import { gestionarRechazoReglas, modalRechazoReglas } from "../../../../utils/formRules";
import { gestionarRechazoForm, modalRechazoForm } from "../../../../utils/formInitialValues";
// Services
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import Success from "../../../Success";
import Divider from "../../../Divider";
import NewInputSelect from "../../../NewInputSelect";
import InputFile from "../../../InputFile";
import { updateReject } from "../../../../services/Rechazos";
import { useState } from "react";
import { ContainerHistorial, ModificacionHistorial } from "../../../../pages/new/Rechazo/styled";
import { FiUser, FiX } from "react-icons/fi";
import dayjs from "dayjs";
import { meses } from "../../../../constants/data";
import CardSinResultados from "../../../CardSinResultados"
import { HiOutlineMailOpen } from "react-icons/hi";

const ModalHistorialRechazos = ({ modal, historial, loading }) => {

  const { isModalOpen, handleModal } = modal;

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Historial del rechazo</NewTitle>
            <p>Modificá los detalles del rechazo</p>
          </div>
          <Divider />
            <div className="data-container">
            {loading && historial.length === 0 ? <SpinnerLoading /> : null}
            <ContainerHistorial>
                {historial.length > 0 && historial.map((item, index) => {
                  /*
                  const mesModificacion = dayjs(item.createdAt).format("M");
                  const mesModificacionAnterior =
                  index > 0 ? dayjs(historial[index - 1].createdAt).format("M") : "";
                  */

                  const diaModificacion = dayjs(item.createdAt).format("DD");
                  const diaModificacionAnterior =
                    index > 0 ? dayjs(historial[index - 1].createdAt).format("DD") : "";

                  return (
                    <>
                  {diaModificacion != diaModificacionAnterior ? (
                    <h4 className="subtitulo-historial">
                      {dayjs(item.createdAt).format("dddd D MMMM, YYYY")}
                    </h4>
                  ) : null}
                    <ModificacionHistorial>
                    <div className="icono">
                      <span>
                        {item.mailgun_status === "opened" && item.id_user === 66 ? <HiOutlineMailOpen color="#3d9f54" /> : null}
                        {item.mailgun_status === "failed" && item.id_user === 66 ? <FiX color="red" /> : null}
                        {!item.mailgun_status && item.id_user ? <FiUser /> : null}
                      </span>
                    </div>
                    <div className="modificacion">
                      <div className="modificacion-titulo">
                        {item.mailgun_status ? 
                          <span className="notificacion-sin-comentario">{item.comentario}</span>
                        :
                        <span>
                          <strong>{item.nombre_usuario} </strong> 
                          modificó el estado a <strong className={`estado ${item.estado.toLowerCase().replaceAll(" ", "-")}`}> {item.estado}</strong>. 
                          {item.via_contacto ? <> Comunicación con el asegurado mediante <strong className="whatsapp">{item.via_contacto.toLowerCase().replaceAll("_", " ")}</strong>. </> : null} 
                          
                          {item.veces_notificado ? <> El asegurado fue notificado {item.veces_notificado > 0 ? `${item.veces_notificado} veces.`  : `${item.veces_notificado} vez.`} </> : null}
                        </span>
                        }

                      </div>
                      {item.comentario && !item.mailgun_status ?
                      <div className="calculo-container">
                        <span>{item.comentario}</span>
                      </div>
                      : null}
                      <div>
                        <span className="modificacion-hora">
                          Modificación realizada a las{" "}
                          {dayjs(item.createdAt).format("HH:mm")}hs
                        </span>
                      </div>
                    </div>
                  </ModificacionHistorial>
                  {index + 1 !== historial.length ? <Divider /> : null}
                  </>
                  )
                })}
              </ContainerHistorial>
            </div>
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalHistorialRechazos;
