import {
    Chart as ChartJS,
    CategoryScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LinearScale
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import { Container } from './styled';
  
  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend
  );
    
  const LineChart = ({ data, options }) => {
    return (
    <Line options={options} data={data} />

    )
  }
  
  export default LineChart
  