import { useState, useEffect, useReducer } from "react"
import { useLoading } from "./useLoading"
import { useAuthContext } from "../context/AuthContext"
import { getEstadisticasProductoCanal, getProductos } from "../services/Estadisticas"
import { meses } from "../constants/data";
import url from "../utils/url"
import { descargarCSV } from "../services/Descarga";
import { borrarArchivo } from "../services/Archivos";

export default function useProductoCanal() {

  // User Data
  const { authState } = useAuthContext()
  const { userInfo } = authState
  const userParams = userInfo.id_rol === 6 ? `user=${userInfo.nombre_operador}` : ``

  const [productos, setProductos] = useState([])
  const [mesAnterior, setMesAnterior] = useState('')
  const [marketing, setMarketing] = useState([])
  const [subtotalMarketing, setSubtotalMarketing] = useState({})
  const [comercial, setComercial] = useState([])
  const [subtotalComercial, setSubtotalComercial] = useState({})
  const [otrosCanales, setOtrosCanales] = useState([])
  const [subtotalOtrosCanales, setSubtotalOtrosCanales] = useState({})
  const [total, setTotal] = useState({})

  const { loading, setLoading } = useLoading()

  const [formatoDescarga, setFormatoDescarga] = useState('csv')
  const [filterValues, setFiltersValues] = useState({})

  useEffect(() => {
    const getInitialData = async () => {
      try {
        const productos = await getProductos()
        setProductos(productos)
      } catch (error) {
        console.log(error)
      }
    }
    getInitialData()
  }, [])

  const descargarEstadisticas = async () => {
    const producto = productos.find(
      (producto) => producto.value == filterValues.producto
    );

    const csvHeaders = [
      { header: "Canal", key: "canal", width: 24 },
      {
        header: `No Gestionados ${mesAnterior}`,
        key: `no_gestionados_${mesAnterior.toLocaleLowerCase()}`,
        width: 24,
      },
      { header: "Leads", key: "leads", width: 13 },
      { header: "Proyectado Leads", key: "proyectado_leads", width: 20 },
      { header: "Operaciones", key: "operaciones", width: 15 },
      {
        header: "Proyectado Operaciones",
        key: "proyectado_operaciones",
        width: 24,
      },
      { header: "Emisiones", key: "emisiones", width: 15 },
      { header: "Cierre", key: "cierre", width: 13 },
      { header: "Objetivos Leads", key: "objetivos_leads", width: 20 },
      {
        header: "Objetivos Operaciones",
        key: "objetivos_operaciones",
        width: 22,
      },
      { header: "Conversion", key: "conversion", width: 15 },
    ];

    try {
      const csvData = {
        desde: filterValues.desde,
        hasta: filterValues.hasta,
        producto: producto.label,
        headers: csvHeaders,
        marketing: marketing,
        subtotalMarketing,
        comercial: comercial,
        subtotalComercial,
        otros: otrosCanales,
        subtotalOtrosCanales,
        total: total,
        formato: formatoDescarga,
      };
      const csv = await descargarCSV(csvData)
      if (csv.status === 200) {
        window.location.href = `${url}${csv.data.path}`;
        
        setTimeout(async () => {
          await borrarArchivo(csv.data.path)
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormatoDescarga = (formato) => setFormatoDescarga(formato)

  const getMesAnterior = (mesFiltro) => {
    let desdeArray = mesFiltro.split("-");
    const mes = meses.find((mes) => mes.value === parseInt(desdeArray[1]) - 1)
    setMesAnterior(mes.label);
  }

  const getEstadisticas = async (filters) => {
    const { desde, hasta, producto } = filters
    getMesAnterior(desde)
    setFiltersValues(filters)
    setLoading(true)
    setMarketing([])
    try {
        const data = await getEstadisticasProductoCanal(producto, desde, hasta)
        console.log(data)
        setMarketing(data[0].marketing)
        setSubtotalMarketing(data[0].subtotalMarketing);
        setComercial(data[0].comercial)
        setSubtotalComercial(data[0].subtotalComercial)
        setOtrosCanales(data[0].otros)
        setSubtotalOtrosCanales(data[0].subtotalOtros)
        setTotal(data[0].totalTablas)
        console.log(data[0].totalTablas)
        setLoading(false)
    } catch (error) {
        console.log(error)
    }
  }

  return {  
    data: {
        productos: productos,
        mesAnterior: mesAnterior,
        comercial: comercial,
        subtotalComercial: subtotalComercial,
        marketing: marketing, 
        subtotalMarketing: subtotalMarketing,
        otrosCanales: otrosCanales,
        subtotalOtrosCanales: subtotalOtrosCanales,
        total: total
    },
    getEstadisticas,
    loading,
    descarga: {
      descargarEstadisticas: descargarEstadisticas,
      handleFormatoDescarga: handleFormatoDescarga,
      formatoDescarga: formatoDescarga
    }
  }
}
