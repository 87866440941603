import React from "react";
import { FiArrowRight, FiUser } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import NewButton from "../NewButton";

const CardVendedorPremio = ({ data }) => {
  const { grupo, vendedor, mes } = data
  const formatter = new Intl.NumberFormat("es-ES", {});
  const location = useLocation()

  return (
    <>
      <div className="vendedor-container-2">
        <div className="vendedor-info-container">
          <div className="info-cont">
            <span className="userIcon">
              <FiUser />
            </span>
            {grupo ? (
              <Link
                to={{
                  pathname: `/Premios/Vendedores/Grupos/${grupo.id_vendedores_grupo}`,
                  state: grupo,
                }}
              >
                <span className="grupo">{vendedor.nombre_grupo}</span>
              </Link>
            ) : null}
            <h3>{vendedor.vendedor}</h3>

            <span>Total: ${formatter.format(vendedor.total)}</span>
          </div>
          <div className="info-cont">
            <Link
              to={{
                pathname: `/Premio/Vendedor/${vendedor.id_vendedor}`,
                state: {
                  nombre: vendedor.vendedor,
                  data: vendedor,
                  mes: mes,
                  previousUrl: `${location.pathname}${location.search}`
                },
              }}
            >
              <NewButton backgroundColor={true}>
                Ver <FiArrowRight />
              </NewButton>
            </Link>
          </div>
        </div>
        <div className="premio-valores-container-2">
          <div className="grid-premios">
            {vendedor.premios.map((item) => {
              if (
                item !== "vendedor" &&
                item !== "id_vendedor" &&
                item !== "total"
              ) {
                return (
                  <div className="premio-valor">
                    <span className="premio-label">{item.nombre}</span>
                    <span>${formatter.format(item.premio)}</span>
                  </div>
                );
              }
            })}
          </div>
          {vendedor.premios.length === 0 ? (
            <span>Los premios de {vendedor.vendedor} están desactivados</span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CardVendedorPremio;
