//recibe un arreglo de strings y devuelve un arreglo de objetos con formato
function formaterObject (CSVData, formato) {
        let formated = [];      // datos validos
        let errores = [];       //errores
        let csvContent = null;
        let columnas = null;
        let length = 0;
        const headerColumns = (CSVData.split("\n")[0]).split(';'); // -> nombre de cada columna

        ////////////// CABECERA DEL ARCHIVO
        // solo si es FEDPATCBU -> no tiene cabecera
        if( formato === 'FEDPAT'){
            csvContent = CSVData.trim().split("\n");
        } else {
            const firstLineBreak = CSVData.indexOf("\n"); // -> nombre de cada columna
            // Separa las líneas del CSV -> array de strings
            csvContent = CSVData.slice(firstLineBreak).trim().split("\n");
        }

        if ( formato === 'FEDPAT' && headerColumns.length !== 3 ) return {formated, errores, length,incorrectFormat:"Formato incorrecto"}
        if ( formato === 'GENERICO' && (! headerColumns.length === 2 && ! headerColumns.length === 14) ) return {formated, errores, length,incorrectFormat:"Formato incorrecto"}
        if ( formato === 'FEDPAT_EFECTIVO' && headerColumns.length !== 18 ) return {formated, errores, length,incorrectFormat:"Formato incorrecto"}
        if ( formato === 'PRUDENCIA' && headerColumns.length !== 14 ) return {formated, errores, length,incorrectFormat:"Formato incorrecto"}
        // if ( csvContent[0].includes(`\\u00`) ) return {formated, errores, length,incorrectFormat:"formato incorrecto"}
        try {
            // carga de un csv normal
            csvContent.forEach((string, index) => {
            // los campos no pueden estar vacios y tener un minimo de 3 caracteres
            if (string && string.length >= 3) {

                // separa las columnas en un arreglo
                const arregloTemporal = string.split(';');

                ///////////// COLUMNAS PARA CADA CAMPO
                switch (formato) {
                    case 'FEDPAT': columnas = { colPoliza: 0,colImporte: 1, colMotivo: 2}; break;
                    case 'GENERICO': columnas = { colPoliza: 0,colImporte: 1, colMotivo: 2}; break;
                    case 'FEDPAT_EFECTIVO': columnas = { colPoliza: 4,colImporte: 12}; break;
                    case 'PRUDENCIA': columnas = { colPoliza: 4,colImporte: 11, colMotivo: 9}; break;
                }
                

                let [objetoNuevo, error] = formaterRow(arregloTemporal, index, columnas)
                if(error) {
                    errores.push(error)
                } else {
                    formated.push(objetoNuevo);
                }
            }
            });
            return {
                formated, // polizas a buscar
                errores,    //filas con errores
                length: formated.length + errores.length // cantidad de registros
            };
        } catch (error) {
            console.log(error)
            return []
        }
}

const formaterRow = (rowCsv, index, {colPoliza, colImporte, colMotivo}) => {
    let error; 
    let poliza = parseInt(rowCsv[colPoliza]);
    let importe = Number(rowCsv[colImporte].replace(',','.'));
    let motivo = rowCsv[colMotivo] || null;


    if ( isNaN(poliza)){ error = { error : `error en fila ${index + 1 } formato póliza.`} }
    if ( isNaN(importe)){ error =  { error : `error formato importe fila ${index + 1 } dato: ${rowCsv[colImporte]}` } }
    if ( ! importe || importe === 0 )  { error =  { error : `error formato, importe es un campo requerido`  } }
    return [ {
        poliza,
        importe,
        motivo
    } ,
        error
    ]
}


export default formaterObject;