import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function useFiltersNew(filterValues, callbackRequired){
    const location = useLocation();
    const history = useHistory()

    const initialFilterValues = {...filterValues}
    const urlParams = location.search ? location.search.split("?")[1] : null

    if (urlParams?.includes("&")) {
        const filters = urlParams.split("&");
        const filtersDos = filters.map((item) => item.split("="));
        if(filtersDos){
            filtersDos.forEach(item => {
                initialFilterValues[item[0]] = decodeURI(item[1])
            })
        }
      } else if(urlParams){
        const filters = urlParams.split("=");
        const arr = [filters[0], filters[1]]
        initialFilterValues[arr[0]] = decodeURI(arr[1])
      }
     
    const [params, setParams] = useState(urlParams)
    const [inputValues, setInputValues] = useState(initialFilterValues)

    // Completar campos de los filtros
    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prev) => ({ ...prev, [name]: value }));
    };

    // Completar campos de los filtros
    const handleChangeExternal = (e, name, value) => {
        //setInputValues((prev) => ({ ...prev, [name]: value }))
        const data = { ...inputValues, [name]: value }
        setInputValues((prev) => ({ ...prev, [name]: value }));
        handleSearch(e, data)
    }
    
    // Buscar con filtros
    const handleSearch = async (e, data) => {
        const filters = data ? data : inputValues
        if(callbackRequired){
            callbackRequired(inputValues)
        } else {
            let valuesCompleted = [];
            for (const value in filters) {
                if (filters[value] !== "") {
                  valuesCompleted.push(value);
                }
            }
        
            let query = "";
            valuesCompleted.forEach((value, index) => {
                query += `${value}=${filters[value]}`;
                if (index !== valuesCompleted.length - 1) query += `&`;
            });
        
            history.replace({ pathname: location.pathname, search: query.toString() });       
            setParams(query)
        }
    };

    // Limpiar filtros
    const clearSearch = () => {
        setParams("")
        setInputValues(filterValues)
        history.replace({ pathname: location.pathname });       
    };

    return {
        inputValues,
        params,
        handleChange,
        handleSearch,
        clearSearch,
        handleChangeExternal
    }
}
