// Sube archivos al server
const formatSelectData = (data, value, label, key) => {
    const selectItem = data.map(item => {
        return {
            'label': Array.isArray(label) ? `${item[label[0]]} ${item[label[1]]}` : `${item[label]}`,
            'value': Array.isArray(value) ? `${item[value[0]]} ${item[value[1]]}` : `${item[value]}`,
            'key': item[key]
        }
    })
    return selectItem
}

export default formatSelectData
