import { useEffect, useState } from "react";
import { postBienAseguradoData } from "../services/Emision";
import sendFiles from "../utils/uploadFile";
import axiosRequest from "../utils/axiosConfig";

// Custom Hooks
import useFiles from "./useFiles"

export const useBienAsegurado = () => {

  const postearBienAseguradoForm = async (data, campos, solicitudID) => {

    console.log("DATA")
    console.log(data)
    console.log("CAMPOS")
    console.log(campos)

    for (const key in data) {
      if (data[key] !== "") {
        let file;
        // Si el campo es un archivo lo sube en el server
        if (key.startsWith("archivo") && data[key] !== "" && typeof(data[key]) !== "string") {
          try {
            file = await sendFiles(data[key]);
          } catch (error) {
            console.log("LOG DE ERROR BIEN ASEGURADO ARCHIVO")
            console.log(error)
            console.log(error.stack)
            throw new Error(error)
          }
        }
    
        // Obtener label del campo
        const label = campos.campos.find((field) => {
          return key === field.name_campo;
        });
    
        // Si el campo no está vació lo postea en la base
        
          let bienAsegurado = {
            id_solicitud: solicitudID,
            item_bien_asegurado: key,
            label_bien_asegurado: label.label_campo,
            descripcion_bien_asegurado: file ? file : data[key],
            estado_bien_asegurado: 1,
          };
          try{
            await postBienAseguradoData(bienAsegurado)
          } catch (error){
            console.log(error)
          }
      }
    }
  }

  // Edición de solicitud --> Si los datos del Step 2 cambian, entonces los datos del Step 3 se tienen que postear, no editar. 
  const postNewFieldsStepThree = async (solicitud, campos, bienAsegurado) => {
    try{
      // Borrar los campos de la cobertura anterior
      await axiosRequest.delete(`/bienes_asegurados/${solicitud.id}`)

      // Postear los campos del bien asegurado correspondiente a la nueva cobertura
      await postearBienAseguradoForm(bienAsegurado, campos, solicitud.id)
    } catch (error){
      console.log(error)
      console.log("Error al intentar borrar los campos del Step 3.")
    }
  }

const editarBienAseguradoForm = async (asegurado, seguro, seguroAnterior, bienAsegurado, bienAseguradoAnterior, campos, solicitud) => {

    if(bienAseguradoAnterior){
        // Si se hacen cambios en la COBERTURA (indirectamente también en la compania o producto) del Step 2, los campos del Step 3 se tienen que POSTEAR en la base, no editar. 
        if(seguroAnterior.cobertura_cobertura_asegurada != seguro.cobertura_cobertura_asegurada){
          postNewFieldsStepThree(solicitud, campos, bienAsegurado)
        } else {
          // EDITAR SOLO LOS CAMPOS QUE CAMBIARON DE VALOR
          bienAseguradoAnterior.forEach(async (bien) => {

            if(bien.descripcion_bien_asegurado !== bienAsegurado[bien.item_bien_asegurado]){

                let file;
                if(bien.item_bien_asegurado.startsWith('archivo') && bienAsegurado[bien.item_bien_asegurado] != ''){
                  file = await sendFiles(bienAsegurado[bien.item_bien_asegurado])
                }
                let data;
                // Si se elimino un campo que antes existia --> se pasa su estado a 0 
                if(bienAsegurado[bien.item_bien_asegurado] === '' || bienAsegurado[bien.item_bien_asegurado] === false){
                  data = {
                    item_bien_asegurado: bien.item_bien_asegurado,
                    descripcion_bien_asegurado: bien.descripcion_bien_asegurado,
                    estado_bien_asegurado: 0
                  }
                } 
                // Si se reemplazo un valor existente por uno nuevo
                else {
                  data = {
                    item_bien_asegurado: bien.item_bien_asegurado,
                    descripcion_bien_asegurado: file ? file : bienAsegurado[bien.item_bien_asegurado],
                    estado_bien_asegurado: 1
                  }
                }

                                
                try{
                  await axiosRequest.put(`/bienes_asegurados/${bien.id_bien_asegurado}`, data)
                } catch (error){
                    console.log(error)
                }
            }
          })
  
          // CHEQUEAR SI EXISTEN CAMPOS NUEVOS Y, EN CASO DE QUE EXISTAN, POSTEARLOS.
          for (const key in bienAsegurado) {
            const findValue = bienAseguradoAnterior.some(bien => {
              return bien.item_bien_asegurado === key
            })

            // Si la propiedad no existe dentro de Bienes Asegurados entonces el campo es nuevo y hay que postearlo en la base. 
            if(!findValue){
              let file;
              // Si el campo es un archivo lo sube en el server
              if(key.startsWith('archivo') && bienAsegurado[key] != ''){
                file = await sendFiles(bienAsegurado[key])
              }
              // Obtener label del campo
              const label = campos.campos.find(field => {
                console.log(field)
                return key === field.name_campo
              })

              if (bienAsegurado[key] !== "") {
                let data = {
                  id_solicitud: solicitud.id,
                  item_bien_asegurado: key,
                  label_bien_asegurado: label.label_campo,
                  descripcion_bien_asegurado: file ? file : bienAsegurado[key],
                  estado_bien_asegurado: 1
                }
                try{
                  await axiosRequest.post(`/bienes_asegurados`, data)
                } catch (error){
                  console.log(error)
                }
              }
            }
          }
        }
      }
}

  return { 
    postearBienAseguradoForm,
    editarBienAseguradoForm
  }
};
