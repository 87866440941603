import { Grid, Col } from "../../../components/Grid"
import Inner from "../../../components/Inner"
import Sidebar from "../../../components/Sidebar"
import TableInfo from "../../../components/TableInfo"
import Wrapper from "../../../components/Wrapper"
import TitlePageSection from "../../../components/TitlePageSection"
import FilterSection from "../../../components/FiltersSection"
import Button from "../../../components/Button"
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import { useTable } from "../../../hooks/useTable"
import { objetivosLeadsTable } from "../../../utils/tableData"
import { useObjetivosLeads } from "../../../hooks/useObjetivosLeads"
// Filters
import { filtersObjetivosLeads } from "../../../utils/filtersPages";
import { filterObjetivosLeadsInitialValues } from "../../../utils/filtersPages";
import useModal from "../../../hooks/useModal"
import ModalCargarObjetivosLeads from "../../../components/Modals/ObjetivosLeads/ModalCargarObjetivosLeads"
// Icons
import { MdFileUpload } from 'react-icons/md'
import ModalEditarObjetivoLeads from "../../../components/Modals/ObjetivosLeads/ModalEditarObjetivoLead"
import ModalBorrarObjetivoLeads from "../../../components/Modals/ObjetivosLeads/ModalBorrarObjetivoLead"

const ObjetivosLeads = () => {

    const { order, handleTableOrder } = useTable()

    // Filtros
    const { inputValues, params, handleSearch, handleChange, clearSearch } = useFiltersNew(filterObjetivosLeadsInitialValues)

    // Autogestion
    const { data, cargaObjetivosLeads, editarObjetivoLead, borrarObjetivoLead, loading, pagination } = useObjetivosLeads(params, order)

    const filters = filtersObjetivosLeads(data)

    const tableData = objetivosLeadsTable(editarObjetivoLead, borrarObjetivoLead)

    return (
        <>

        <ModalCargarObjetivosLeads isModalOpen={cargaObjetivosLeads.isModalOpen} setIsModalOpen={cargaObjetivosLeads.setIsModalOpen} data={cargaObjetivosLeads} />

        <ModalEditarObjetivoLeads isModalOpen={editarObjetivoLead.isModalOpen} setIsModalOpen={editarObjetivoLead.setIsModalOpen} form={editarObjetivoLead.form} data={data} />

        <ModalBorrarObjetivoLeads isModalOpen={borrarObjetivoLead.isModalOpen} setIsModalOpen={borrarObjetivoLead.setIsModalOpen} data={borrarObjetivoLead} />

        <Sidebar active="objetivos" />
          
        <Wrapper>
          <Inner>
            <Grid colGap={21} rowGap={21} narrow={true}>
              
              <Col desktop={12}>
                <TitlePageSection title="Objetivos Leads">
                    <Button backgroundColor={true} textColor={false} borderColor={false} onClick={() => cargaObjetivosLeads.setIsModalOpen(true)}><span><MdFileUpload></MdFileUpload></span>Cargar Objetivos Leads</Button>
                </TitlePageSection>
              </Col>
  
              <Col desktop={12}>
                <FilterSection section="objetivos" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} />
              </Col>
            </Grid>
  
            <TableInfo columns={tableData} data={data.objetivos} loading={loading} order={handleTableOrder} orderParams={order} pagination={pagination} />
  
          </Inner>
        </Wrapper>
      </>
    )
}

export default ObjetivosLeads