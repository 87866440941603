import { Grid, Col } from "../Grid";
import Subtitulo from "../Subtitulo";
import { ICONOS_ARCHIVOS_AUTOGESTION } from "../../constants/labels";
import { IMAGE_FILE_TYPE } from "../../constants/data";

const GridSectionFile = ({ data, labels, sectionTitle, columns }) => {

  return (
    <div>
      <Subtitulo>{sectionTitle}</Subtitulo>
      <Grid colGap={21} rowGap={21} narrow={true}>
        {data.length
          ? data.map((archivo, index) => {
              return (
                <Col desktop={columns} key={index}>
                  <a href={archivo.url} target="_blank" rel="noreferrer">
                    <h4>{labels[archivo.nombre_campo]}</h4>
                    <img
                      src={
                        archivo.type === IMAGE_FILE_TYPE
                          ? archivo.url
                          : ICONOS_ARCHIVOS_AUTOGESTION[archivo.type]
                      }
                      alt={archivo.nombre_campo}
                    />
                  </a>
                </Col>
              );
            })
          : null}
      </Grid>
    </div>
  );
};

export default GridSectionFile;
