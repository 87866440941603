import { Container, Tooltip, ButtonAction } from "./styled"

const IconButton = ({icon, action, tooltipText, styleClass, size, className }) => {
    
    return(
        <Container>
            <span className="tooltip">{tooltipText}</span>
            {typeof(icon) === "string" 
                ? <ButtonAction data-backgroundcolor={false} data-bordercolor={false} data-textcolor={true} icon={icon} onClick={action} styleClass={styleClass && styleClass} />
                : <span className={size === "small" ? `small ${className && className}` : `size ${className && className}`} onClick={action ? action : null}>{icon}</span>
            }
        </Container>
    )
}

export default IconButton
