import { createContext, useState, useContext } from "react";

const AuthContext = createContext();
const { Provider } = AuthContext;

const useAuthContext = () => {
    const wizard = useContext(AuthContext)
    return wizard
}

const AuthProvider = ({children}) => {
    // Setea el valor de userInfo que se encuentra en localStorage
    const userInfo = localStorage.getItem('userInfo')
    const expiresIn = localStorage.getItem('expiresIn')

    const [authState, setAuthState] = useState({
        token: null,
        expiresIn: expiresIn ? expiresIn : null,
        userInfo: userInfo ? JSON.parse(userInfo) : ''
    })

    const isAuthenticated = () => {
        if(!authState.token && !authState.expiresIn){
            return false
        }

        return new Date().getTime() / 1000 < authState.expiresIn
    }

    return (
        <Provider value={{ authState, setAuthState, isAuthenticated }} >
            { children }
        </Provider>
    )
}

export { AuthContext, AuthProvider, useAuthContext }