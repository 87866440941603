import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Error = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
`;

export const ArchivoCargado = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.negro)};
  font-weight: 600;
`;

export const NombreArchivoCargado = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.negro)};
  width: ${props => (props.isLarger ? "170px" : '120px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputCheckbox = styled.div`
  >div{ flex-direction: row!important };
`;

export const ModalRecordatorio = styled.div`
    width: 620px;
    align-items: center;
    padding: 42px 50px 36px;
    
    div {align-items: center; }
  p {
    margin: 0;
    font-family: 'DM Sans',sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 34px;
    text-align: center;
    color: ${leerColor(colores.grisModales)};
  }
  .buttonCont {
    display: flex;
    place-content: flex-end;
  }
`;

export const ThumbnailContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  background-color: hsl(0,0%,100%);
    -webkit-box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  color: hsl(0,0%,32.2%);
  width: ${props => (props.isLarger ? "400px" : '')};
  margin: ${props => (props.isLarger ? "15px 0 10px 0" : '15px 0 0 0')};
  border: 1px solid #f5f5f5;

  &:first-of-type{
    margin: ${props => (props.isLarger ? "10px 0 0 0" : '15px 0 0 0')};
  }

/*
  .cross{
    display:flex;
    padding: ${props => (props.isLarger ? "15px 25px" : '25px 20px')};
    cursor:pointer;
    border-left: 1px solid #f1f1f1;

    :hover{
      opacity:0.7;
    }
  }
  */

  .file-container{
    display: flex;
    align-items: center;
    margin-left:15px;
  }

  .file{
    display:flex;
    padding: ${props => (props.isLarger ? "0" : '0')};;
  }
`;

/*
export const ThumbnailTextContainer = styled.div`
  display:flex; 
  flex-direction:column;
  align-items: flex-start;
`;
*/

export const ThumbnailTextContainer = styled.div`
  display:flex; 
  flex-direction:column;
  align-items: flex-start;
  padding: ${props => (props.isLarger ? "0px 0px" : '0px 0px 0px 0px ')};
  margin-left:15px;
`;

export const ThumbnailImage = styled.img`
  max-width: 40px;
  max-height: 40px;
  cursor:pointer;
  
  :hover{
    opacity: 0.7;
  }
`;

export const PreviewFile = styled.img`
  width:500px;
`;

export const Video = styled.video`  
  width: auto;
  height:50vh;
  margin: 0 auto;
`

export const Icon = styled.img`
  width: 22px;
`;

export const IconContainer = styled.div`
  display:flex;
  padding: ${props => (props.isLarger ? "15px 25px" : '25px 20px')};
  cursor:pointer;
  border-left: 1px solid #f1f1f1;

  :hover{
    opacity:0.7;
  }
`;