import { Button, Col } from "../Index";
import { ClearFilter, ClearFilterContainer } from "../../pages/styled";
import { ImSearch } from "react-icons/im";
import { MdCleaningServices } from "react-icons/md";

const FiltersNew = ({ inputs, values, params, handleChange, handleSearch, clearSearch, required }) => {

    const isDisabled = required ? !Object.values(values).every(item => item !== '') : Object.values(values).every(item => item === '')

    return(
        <>
            {inputs && inputs.map(input => {
                return (
                    <Col desktop={3}>
                        <input.component type={input.type} labelName={input.labelName} placeholder={input.placeholder} name={input.name} onChange={handleChange} value={values[input.name]} options={input.options ? input.options : ''} />
                    </Col>
                )
            })}
            <Col desktop={1} alignBottom={true}>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={handleSearch}
                disabled={isDisabled}
                tamaño="chico"
              >
                <span><ImSearch></ImSearch></span>
                Buscar
              </Button>
            </Col>

            {params ? 
              <Col desktop={2} alignBottom={true}>
                <ClearFilterContainer onClick={clearSearch}>
                  <Button
                    borderColor={true}
                    textColor={true}
                    onClick={clearSearch}
                    target={"_blank"}
                    tamaño="chico"
                  >
                    <span>
                      <MdCleaningServices></MdCleaningServices>
                    </span>
                    Limpiar filtros
                  </Button>
                </ClearFilterContainer>
              </Col>
            : null}
        </>
    )
}

export default FiltersNew