import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "./styled";

const BackButton = ({ url, text }) => {
  return (
    <Container>
      <Link to={url} className="back-button">
        <FiArrowLeft />
        Volver a {text}
      </Link>
    </Container>
  );
};

export default BackButton;
