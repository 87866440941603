import { Container } from "./styled";
const Subtitle = ({ icon, title, description, disableDivider }) => {
  return (
    <Container>
      <div className={"sectionTitle"}>
        <span className={"icon"}>{icon}</span>
        <h4>{title}</h4>
      </div>
      {description && <p>{description}</p>}
      {!disableDivider && <hr />}
    </Container>
  );
};

export default Subtitle;
