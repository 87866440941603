import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    a {
        white-space: nowrap;
    }

    div select {
        width: 165.83px;
    }
`;
