import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const MenuDiv = styled.div`
  ${props => props.active === 'solicitudEmision' && css`
    .solicitudEmision {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'emisiones' && css`
    .emisiones {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'clientes' && css`
    .clientes {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'estadisticas' && css`
    .estadisticas {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'consultas' && css`
    .consultas {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'siniestros' && css`
    .siniestros {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
`;

export const StyledBurger = styled.button`
  position: fixed;
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background: ${leerColor(colores.celeste)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const StyledMenu = styled.div`
  
  position: fixed;
  width: 340px;
   
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${leerColor(colores.azul)};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  text-align: left;
  z-index: 5;
  top: 0;
  left: 0;
  
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  -ms-transform: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out; 
  
  h2 {
    font-size: 36px;
    color: ${leerColor(colores.blanco)};
    margin-bottom: 42px;
    margin-top: 120px;
    padding: 0 2rem;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: -webkit-fill-available;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: unset;
    height: 100%;
  }
  ul li {
    transition: 0.2s;
  }
  ul li:hover {
    background-color: rgba(50, 137, 185, 30%);
    transition: 0.2s;
  }
  ul li:last-of-type {
    margin: auto 0 10px;
  }
  ul li a {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 12px 2rem;
    font-weight: 600;
    color: ${leerColor(colores.blanco)};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  ul li a figure {
    margin: 0;
    width: 15%;
    text-align: center;
  }
  ul li a span {
    padding-left: 20px;
  }
`;
