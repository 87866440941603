// Components
import Sidebar from "../../components/Sidebar";
import Wrapper from "../../components/Wrapper";
import Inner from "../../components/Inner";
import { Grid, Col } from "../../components/Grid";
import ModalHistorial from "../../components/Modals/SolicitudEmision/ModalHistorial";
import TitlePageSection from "../../components/TitlePageSection";
import NewTable from "../../components/NewTable";
// Custom Hooks
import useEmisiones from "../../hooks/useEmisiones";
import useFiltersNew from "../../hooks/useFiltersNew";
import useHistorialNew from "../../hooks/useHistorialNew";
import { useDescarga } from "../../hooks/useDescarga";
import { useTable } from "../../hooks/useTable";
import { useActionButton } from "../../hooks/useActionButton";
// Filters
import { filtersEmisiones } from "../../utils/filtersPages";
import { filterEmisionesInitialValues } from "../../utils/filtersPages";
// Table
import { emisionesTable } from "../../utils/tableData";
import ModalLoading from "../../components/Modals/General/ModalLoading";
import NewFiltersSection from "../../components/NewFiltersSection";
import { Container } from "./EmisionesRefactor/styled";
import { useState } from "react";
import NewButton from "../../components/NewButton";
import { FiFilter, FiX } from "react-icons/fi";
import { useEffect } from "react";
import CardStats from "../../components/CardStats";
import Divider from "../../components/Divider";
import { useSingleOpen } from "../../hooks/useSingleOpen";
import Layout from "../../components/Layout";
import NewInner from "../../components/NewInner";
import NewWrapper from "../../components/NewWrapper";
import NewTitlePageSection from "../../components/NewTitlePageSection";
import NewTableRedesign from "../../components/NewTableRedesign";

const NewLayout = () => {
  const [filtersApplied, setFiltersApplied] = useState([]);

  // Modales
  const {
    historial,
    isModalOpen,
    handleModal,
    handleModalHistorial,
    solicitudData,
  } = useHistorialNew();
  const { modal, descargarSolicitud } = useDescarga();

  const {
    inputValues,
    params,
    handleSearch,
    handleChange,
    handleChangeExternal,
    clearSearch,
  } = useFiltersNew(filterEmisionesInitialValues);

  const { order, handleTableOrder } = useTable();

  const { data, loading, pagination } = useEmisiones(params, order);

  const filters = filtersEmisiones(data);
  // Botones
  const { isActionButtonOpen, handleToggle } = useActionButton();

  const tableData = emisionesTable(
    handleModalHistorial,
    descargarSolicitud,
    isActionButtonOpen,
    handleToggle
  );
  const { isOpen: isFiltersOpen, handleOpen: handleFiltersOpen } =
    useSingleOpen();

  useEffect(() => {
    if (params) {
      let filterArr = [];
      if (params.includes("&")) {
        const filters = params.split("&");
        const filtersDos = filters.map((item) => item.split("="));
        filterArr.push(filtersDos);
        setFiltersApplied(filtersDos);
      } else {
        const filters = params.split("=");
        const arr = [filters[0], filters[1]];
        filterArr.push(arr);
        setFiltersApplied([arr]);
      }
    }
  }, [params]);

  const removeFilter = (e, filterName) => {
    handleChangeExternal(e, filterName, "");
  };

  return (
    <>

      <ModalLoading
        isModalOpen={modal.isModalOpen}
        setIsModalOpen={modal.setIsModalOpen}
        message={modal.modalMessage}
      />

      <ModalHistorial
        isModalOpen={isModalOpen}
        setIsModalOpen={handleModal}
        historial={historial}
        solicitudData={solicitudData}
      />

      <Layout>
        <NewWrapper>
        <NewInner>
          <Container>
            <Grid colGap={21} rowGap={21}>
              <Col desktop={12}>
                <NewTitlePageSection
                  title="Emisiones"
                  description="Lista de todas las solicitudes de emisión emitidas"
                >
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={() => handleFiltersOpen(!isFiltersOpen)}
                  >
                    <FiFilter /> Filtros
                    {params?.split("=").length > 1 ? (
                      <span className="filtros-aplicados">
                        {params?.split("=").length - 1}
                      </span>
                    ) : null}
                  </NewButton>
                </NewTitlePageSection>
              </Col>
              {isFiltersOpen ? (
                <Col desktop={12}>
                  <NewFiltersSection
                    section="solicitudes emitidas"
                    inputs={filters}
                    values={inputValues}
                    params={params}
                    handleChange={handleChange}
                    handleSearch={handleSearch}
                    clearSearch={clearSearch}
                  />
                </Col>
              ) : null}
            </Grid>

            <Grid colGap={21} rowGap={21}>
              <Col desktop={2}>
                <CardStats
                  title={"Nuevas solicitudes"}
                  value={data.estadisticas?.nuevas}
                  filter={(e) => handleChangeExternal(e, "tipo_solicitud", 1)}
                  filterText="nuevas"
                />
              </Col>
              <Col desktop={2}>
                <CardStats
                  title={"Anulaciones"}
                  value={data.estadisticas?.anuladas}
                  filter={(e) => handleChangeExternal(e, "tipo_solicitud", 2)}
                  filterText="anuladas"
                />
              </Col>
              <Col desktop={2}>
                <CardStats
                  title={"Endoso"}
                  value={data.estadisticas?.endosos}
                  filter={(e) => handleChangeExternal(e, "tipo_solicitud", 3)}
                  filterText="endosos"
                />
              </Col>
              <Col desktop={2}>
                <CardStats
                  title={"Mails sin entregar"}
                  value={data.estadisticas?.no_entregados}
                  filter={(e) =>
                    handleChangeExternal(e, "estado_mail_poliza", 3)
                  }
                  filterText="solicitudes"
                />
              </Col>
              <Col desktop={2}>
                <CardStats
                  title={"Autogestión"}
                  value={data.estadisticas?.autogestion}
                  filter={(e) =>
                    handleChangeExternal(e, "vendedor", "Vendedor Autogestión")
                  }
                  filterText="autogestion"
                />
              </Col>
            </Grid>

            {filtersApplied.length > 0 && params && data.solicitudes ? (
              <div className="filtros-aplicados-container">
                <span>
                  {pagination.dataLength} resultados encontrados con los
                  siguientes filtros:{" "}
                </span>
                <div>
                  {filtersApplied.map((item) => {
                    const tiposSolicitud = {
                      1: "Nueva solicitud",
                      2: "Anulación por reemplazo",
                      3: "Endoso",
                    };
                    let nombreFiltro = item[0];
                    let valorFiltro =
                      item[0] === "tipo_solicitud"
                        ? tiposSolicitud[item[1]]
                        : item[1];
                    valorFiltro =
                      item[0] === "estado_mail_poliza"
                        ? "No entregados"
                        : valorFiltro;
                    nombreFiltro =
                      item[0] === "estado_mail_poliza"
                        ? "Estado de mail de solicitudes emitidas"
                        : nombreFiltro;
                    return (
                      <span
                        className="filtro-aplicado"
                        onClick={(e) => removeFilter(e, item[0])}
                      >
                        {nombreFiltro.charAt(0).toUpperCase() +
                          nombreFiltro.slice(1).replaceAll("_", " ")}
                        : {valorFiltro} <FiX />
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <NewTableRedesign
              columns={tableData}
              data={data.solicitudes}
              loading={loading}
              order={handleTableOrder}
              orderParams={order}
              pagination={pagination}
            />
          </Container>
        </NewInner>
        </NewWrapper>
      </Layout>
    </>
  );
};

export default NewLayout;
