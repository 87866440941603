import { useState } from "react";

export const useTabs = () => {

  const [tabSelected, setTabSelected] = useState(0);
  const [tabName, setTabName] = useState("")

  const handleTab = (index, tab) => {
    setTabSelected(index)
    setTabName(tab)
  }

  return { tabSelected, tabName, handleTab }

}