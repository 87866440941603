import axiosRequest from "../utils/axiosConfig";
import formatSelectData from "../utils/formatSelectData";

// Postear mensaje historial
export const getEstadisticasProductoCanal = async (producto, desde, hasta) => {
  const estadisticas = await axiosRequest.get(
    `/estadisticas-data?producto=${producto}&desde=${desde}&hasta=${hasta}`
);
  return estadisticas.data
}

// Postear mensaje historial
export const getProductos = async () => {
  const productos = await axiosRequest.get("/estadisticas-productos");
  const productosList = productos.data.map((producto) => {
    return {
      label: producto.grupo == 8 ? "Dispositivos" : producto.descripcion,
      nombre_producto: producto.descripcion,
      value: producto.grupo,
      key: producto.numero,
    };
  });
  productosList.push({
    label: "Todos",
    nombre_producto: "Todos",
    value: "Todos",
    key: productosList.length + 1,
  });
  return productosList
}
