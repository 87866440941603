import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const ContainerSwitch = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    label {
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        color: ${({ theme }) => theme.title}; 
        font-weight: 500;
        display: flex;
        align-content: center;
        gap: 6px;
    }
`;
