import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding:20px 0;

  input[type="checkbox"] {
    position:absolute;
    left:0;
  }

  .operador-highlight-purple{
    font-weight: 600;
    color: #7625ad;
  }

  .operador-highlight-blue{
    font-weight: 600;
    color: #0a60aa;
  }

  .operador-highlight-green{
    font-weight: 600;
    color: #5a8965;
  }

  .operador-highlight-brown{
    font-weight: 600;
    color: #8d2a2a;
  }

  .operador-highlight-pink{
    font-weight: 600;
    color: #ce6eb9;
  }

  .breakWord {
    overflow-wrap: break-word;
  }

  // Estados // 
  .emitido{
    color: ${leerColor(colores.verde)};
    font-weight: 700;
  }
  .pendiente{
    color: ${leerColor(colores.naranjaEstados)};
    font-weight: 700;
  }
  .rechazado{
    color: ${leerColor(colores.rojo)};
    font-weight: 700;
  }
  .emisiónprovisoria{
    color: ${leerColor(colores.celesteEstados)};
    font-weight: 700;
  }
  .pendientesuscripción{
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
  }
  .pendienteaprobación{
    color: ${leerColor(colores.azul)};
    font-weight: 700;
  }

  .positiveDifference{
    color: ${leerColor(colores.verde)};
    font-weight: 600;
  }

  .negativeDifference{
    color: ${leerColor(colores.rojo)};
    font-weight: 600;
  }

  .subtotal{
    color: ${leerColor(colores.azul)};
    font-weight: 800;
  }

  a{
    font-size: 16px;
    text-decoration: none;
    color: #525252;
    font-weight: 400;
    // 
    font-family: 'DM Sans', sans-serif;
    transition: 0.2s;

    :hover{
      color: ${leerColor(colores.celeste)};
      font-weight: 600;
    }
  }
`