import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Container } from "./styled";
import { CgCloseO } from "react-icons/cg";
import { useTheme } from "../../context/ThemeContext";

function Modal({ children, setIsModalOpen, isModalOpen, hideCross }) {
  const {theme} = useTheme()
  console.log(theme)
  return (
    <Container className={!isModalOpen ? "closeCarousel" : ""}>
      <div id="boton-cerrar-container" onClick={() => setIsModalOpen(!isModalOpen)}>
        <CgCloseO color={theme === "dark" ? "#fff" : "#202020"}></CgCloseO>
      </div>
      <Box>{children}</Box>
    </Container>
  );
}

Modal.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Modal;
