import { useState, useEffect } from "react";
import { useSocket } from "../context/SocketProvider";
import { useAuthContext } from "../context/AuthContext";

export const useNotificacionesPage = (eventos, refreshData) => {
  const [notificaciones, setNotificaciones] = useState([]);
  const [mensaje, setMensaje] = useState(null)
  const socket = useSocket();
  const { authState } = useAuthContext();

  const generarMensaje = (notificacion) => {
    setMensaje(`${notificacion}. Hacé click para volver a cargar la tabla.`) 
  }


  const resetNotificaciones = () => {
    refreshData();
    setNotificaciones([]);
  };

  const addNotificacions = (data) => {
    const notifs = [...notificaciones];
    notifs.push(data);
    setNotificaciones(notifs);
  };

  useEffect(() => {
    if (socket) {
      eventos.forEach((evento) => {
        if (evento === "solicitud-creada") {
          socket.on(evento, (data) => {
            if (data?.roles?.includes(authState.userInfo.id_rol)) {
              addNotificacions(data);
              generarMensaje(data.notificacion)
            }
          });
        }

        if(evento === "autogestión") {
          socket.on(evento, data => {
            addNotificacions(data);
            generarMensaje(data.notificacion)
          })
        }

        socket.on(evento, (data) => {
          if (data.id_usuario === authState.userInfo.id_usuario) {
            addNotificacions(data);
            generarMensaje(data.notificacion)
          }
        });
      });
    }
  }, [socket, notificaciones]);
 
  return {
    notificaciones,
    resetNotificaciones,
    mensaje
  };
};
