import React, { useState } from "react";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import { meses } from "../../../../constants/data";
import Subtitle from "../../../Subtitle";
import { FiDollarSign, FiRefreshCcw } from "react-icons/fi";
import NewButton from "../../../NewButton";
import SpinnerLoading from "../../../SpinnerLoading";
import axiosRequest from "../../../../utils/axiosConfig";
import Divider from "../../../Divider";
import CardVendedorPremio from "../../../CardVendedorPremio";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PremiosVendedoresTab = ({data}) => {

  const formatter = new Intl.NumberFormat("es-ES", {});
  const [ordenVendededores, setOrdenVendedores] = useState("Nombre");
  const { mes, setMes, loading, totalPremios, vendedores, grupos, setVendedores, setLoading } = data
  const location = useLocation()
  const history = useHistory()

  const reordenarVendedores = (orden) => {
    if (orden === "Nombre") {
      const ordenar = vendedores.sort((a, b) =>
        a.vendedor.localeCompare(b.vendedor)
      );
      setVendedores(ordenar);
    }
    if (orden === "Total") {
      const ordenar = vendedores.sort((a, b) => b.total - a.total);
      setVendedores(ordenar);
    }
    if (orden === "Grupo") {
      const sinGrupo = [...vendedores].filter((vendedor) => !vendedor.id_grupo);
      const ordenar = [...vendedores]
        .filter((vendedor) => vendedor.id_grupo)
        .sort((a, b) => a.nombre_grupo.localeCompare(b.nombre_grupo));
      setVendedores([...ordenar, ...sinGrupo]);
    }
    setOrdenVendedores(orden);
  };

  const actualizarCalculoMes = async () => {
    setLoading(true);
    try {
      const calculo = await axiosRequest.get(
        `/premios/actualizar_calculo/${parseInt(mes) + 1}`
      );
      setVendedores(calculo.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMes = (event) => {
    const { value } = event.target
    history.replace({ pathname: location.pathname, search: `mes=${value}` });       
    setMes(value)
  }

  return (
      <>
        <Grid colGap={21} rowGap={21} narrow={false}>
          <Col desktop={2}>
            <NewInputSelect
              name={"mes"}
              labelName={"Mes"}
              onChange={handleMes}
              placeholder={"Mes"}
              options={meses}
              value={mes}
            />
          </Col>
        </Grid>
        <Divider />

        {!loading ? (
          <>
            {totalPremios ? (
              <>
                <Subtitle
                  icon={<FiDollarSign />}
                  title={`Total a pagar en ${meses
                    .find((m) => m.value == mes)
                    ?.label.toLocaleLowerCase()}`}
                  description={
                    "Valor a pagar de la sumatoria de todos los premios obtenidos por los vendedores"
                  }
                  disableDivider={true}
                />
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={3}>
                    <div className={`total-container total`}>
                      <span>Total a pagar</span>
                      <h2>${formatter.format(totalPremios)}</h2>
                    </div>
                  </Col>
                </Grid>
                <hr />
              </>
            ) : null}

            <div className="actualizacion-container">
              <div className="premios-botones-container">
                <h4>
                  Resultados obtenidos para el mes de{" "}
                  {meses.find((m) => m.value == mes)?.label.toLowerCase()}
                </h4>
                <p>
                  La actualización automática del cálculo de premios del mes se
                  realiza todos los días a la medianoche.
                </p>
              </div>
              <div className="buttons-cont">
                <NewButton
                  borderColor={true}
                  textColor={true}
                  onClick={actualizarCalculoMes}
                >
                  <FiRefreshCcw />
                  Actualizar
                </NewButton>
              </div>
            </div>
            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={2}>
                <NewInputSelect
                  name={"orden"}
                  labelName={"Ordenar por"}
                  onChange={(e) => reordenarVendedores(e.target.value)}
                  placeholder={"Ordenar por"}
                  options={[
                    { name: "grupo", label: "Grupo", key: 1 },
                    {
                      name: "alfabeticamente",
                      label: "Nombre",
                      key: 2,
                    },
                    { name: "total", label: "Total", key: 3 },
                  ]}
                  value={ordenVendededores}
                />
              </Col>
            </Grid>

            <Grid colGap={21} rowGap={21} narrow={false}>
              {vendedores.map((vendedor) => {
                const grupo = grupos.find(
                  (item) => item.id_vendedores_grupo === vendedor.id_grupo
                );
                return (
                  <Col desktop={12}>
                    <CardVendedorPremio data={{ grupo, vendedor, mes }} />
                  </Col>
                );
              })}
            </Grid>
          </>
        ) : (
          <SpinnerLoading text={"Calculando premios..."} />
        )}
      </>
  );
};

export default PremiosVendedoresTab;
