import axiosRequest from "../utils/axiosConfig";
import formatSelectData from "../utils/formatSelectData";

// Siniestros
export const getSiniestros = async (filterParams, orderParams, page) => {
  const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
  const siniestros = await axiosRequest.get(`/siniestros_new?${pageFilter}${filterParams}&${orderParams}`)
  return siniestros.data
}

export const getSiniestroSingle = async (id) => {
  const siniestro = await axiosRequest.get(`/siniestro/${id}`)
  return siniestro.data
}

// Postear siniestro
export const postSiniestro = async (siniestroData) => {
  return await axiosRequest.post("/siniestro", siniestroData);
}

// Actualizar siniestro
export const updateSiniestro = async (id, updateData) => {
  return await axiosRequest.put(`/siniestro/${id}`, updateData);
}

export const updateEstadoOperacionSiniestro = async (id_operacion) => {
  return await axiosRequest.put(`/operacion_siniestro/${id_operacion}`);
}

// Postear adjunto siniestro
export const postAdjuntoSiniestro = async (adjuntosData) => {
  return await axiosRequest.post("/siniestros_adjuntos", adjuntosData);
}

// Traer adjuntos siniestros
export const getAdjuntosSiniestros = async (id) => {
  const adjuntos = await axiosRequest.get(`/siniestros_adjuntos/${id}`);
  return adjuntos.data
}

// Borrar adjunto siniestro
export const deleteAdjuntoSiniestro = async (adjunto) => {
  return await axiosRequest.delete(`/siniestros_adjuntos/${adjunto.id_siniestro_adjunto}`)
}

// Obtener información de póliza
export const getPolizaInformation = async (poliza, dni) => {
  return await axiosRequest.get(`/siniestros/${poliza}/${dni}`)
}

// Obtener información de póliza
export const getProductoSiniestroId = async (producto) => {
  console.log(producto)
  return await axiosRequest.get(`/producto_siniestro_id/${producto}`)
}

// Asignar siniestro
export const assignSiniestro = async (data) => {
  return await axiosRequest.post("/operacion_siniestro", data)
}

// Borrar siniestro
export const deleteSiniestro = async (siniestro) => {
  return await axiosRequest.delete(`/siniestro/${siniestro.id}`)
}

// Compañias
export const getCompanias = async () => {
    const companias = await axiosRequest.get(`/companias_siniestros`);
    const companiasList = companias.data.map((compania) => {
      const name = compania.nombre.split(" ");
      const fullName = name.length > 1 ? name[0] + " " + name[1] : name[0];
      return {
        label: fullName,
        value: compania.numero,
        key: compania.numero,
      };
    });
    return companiasList
}

// Productos
export const getProductos = async () => {
  const productos = await axiosRequest.get(`/siniestros_productos`);
  console.log(productos)
  const productosList = formatSelectData(
    productos.data,
      "numero",
      "descripcion",
      "numero"
    );
  return productosList
}

// Tipos
export const getTipos = async () => {
    const tipos = await axiosRequest.get(`/tipos_siniestros`);
    const tiposList = formatSelectData(
        tipos.data,
        "id_tipo_siniestro",
        "nombre_tipo_siniestro",
        "id_tipo_siniestro"
      );
    return tiposList
}

// Estados
export const getEstados = async () => {
    const estados = await axiosRequest.get(`/estados_siniestros`);
    const estadosList = formatSelectData(
      estados.data,
      "nombre_estado",
      "nombre_estado",
      "id_estado"
    );
    return estadosList
}

// Ramos
export const getRamos = async () => {
    const ramos = await axiosRequest.get(`/siniestros/ramos`)
    const ramosList = formatSelectData(
      ramos.data,
      "descrip",
      "descrip",
      "numero"
    );
    return ramosList
}