import { useState, useEffect, useRef } from "react"
import { getRechazos, getEstados, getCompanias, getSecciones, getEstadisticasRapidas, downloadCsv} from "../services/Rechazos"
import { useLoading } from "./useLoading"
import usePagination from "./usePagination"
import { useAuthContext } from "../context/AuthContext"
import dayjs from "dayjs";
import { getOperadores } from "../services/Rechazos"

export default function useRechazos(filterParams, orderParams) {
  // User Data
  const { authState } = useAuthContext()
  const { userInfo } = authState
  const userParams = userInfo.id_rol === 6 ? `user=${userInfo.nombre_operador}` : ``
  const [mes, setMes] = useState();

  // metadatos
  const [estados, setEstados] = useState([]);
  const [companias, setCompanias] = useState([]);
  const [secciones, setSecciones] =  useState([]);
  const [rechazos, setRechazos] =  useState([]);
  const [operadores, setOperadores] = useState([]);
  const [rechazosLength, setRechazosLength] = useState(0);
  const [estadisticas, setEstadisticas] = useState({})
  const [selectOperadores, setSelectOperadores] = useState([])

  
  // Paginación -> pasar el largo de la data
  const pagination = usePagination(rechazosLength)

  // Filter ref
  const filtrosRef = useRef(null)

  const { loading, setLoading } = useLoading()

  const refresRechazosTotales = async () => {
    setLoading(true)
    setRechazos([])
    let page = filtrosRef.current !== filterParams ? 1 : pagination.currentPage
    if(filtrosRef.current !== filterParams){
      filtrosRef.current = filterParams
      pagination.selectPage(1)
    }
    try {
      const { rechazos, pagination_res } = await getRechazos(filterParams, orderParams, page)
      setRechazos(rechazos);
      setRechazosLength( pagination_res.count )
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const downloadResults = async () => {
    setLoading(true)
    try {
      const res = await downloadCsv(filterParams, orderParams)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(()=>{
    if ( filterParams && filterParams.includes('mes') ){
      const mesFormated = filterParams.slice( filterParams.indexOf('mes')+4)
      const getData = async () => {
        setEstadisticas({})
        setMes(mesFormated)
        const estadisticasRapidas = await getEstadisticasRapidas( mesFormated ) // espera el mes para cambiar
        setEstadisticas(estadisticasRapidas)
      }
      getData()
    }

  },[filterParams])

  const getOperadoresBack = async () => {
    try {
      const ops = await getOperadores()

      if (userInfo.id_rol === 8) {
        setSelectOperadores([ops.find(o => o.key == userInfo.id_usuario)])
      } else {
        setSelectOperadores(ops)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // refresca la peticion de rechazos si cambian los parametros
    refresRechazosTotales()
  }, [filterParams, orderParams, pagination.currentPage])

  useEffect(() => {
    const getInitialData = async () => {
        setLoading(true)
        // carga metadatos -> solo al principio
        const companias = await getCompanias();
        const secciones = await getSecciones();
        const estados = await getEstados();
        const operadores = await getOperadores();
        if(!filterParams){
          const { rechazos, pagination_res } = await getRechazos(null, null, 1)
          setRechazos(rechazos);
          setRechazosLength( pagination_res.count )
          setLoading(false)
        }
        setOperadores(operadores)
        setCompanias(companias)
        setSecciones(secciones)
        setEstados(estados)
    }
    getInitialData()
    getOperadoresBack()
  }, [])




  return {  
    data: {
        rechazos,
        estados,
        companias,
        secciones,
        operadores,
        estadisticas
    },
    loading,
    pagination,
    refresRechazosTotales,
    downloadResults,
    mes,
    setMes,
    selectOperadores
  }

}
