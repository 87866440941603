// Components
import Modal from "../../../Modal";
import { Grid, Col } from "../../../Grid";
import NewInputTextarea from "../../../NewInputTextarea";
// Hooks
import { useForm } from "../../../../hooks/useForm";
// Utils
import { gestionarRechazoReglas, modalRechazoReglas } from "../../../../utils/formRules";
import { gestionarRechazoForm, modalRechazoForm } from "../../../../utils/formInitialValues";
// Services
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import Success from "../../../Success";
import Divider from "../../../Divider";
import NewInputSelect from "../../../NewInputSelect";
import InputFile from "../../../InputFile";
import { updateReject, getRechazos } from "../../../../services/Rechazos";
import { useEffect, useState } from "react";
import { LOGOS_COMPANIAS } from "../../../../constants/logos-companias";
import { OperadorItem } from "../ModalNotificar/styled";
import IconButton from "../../../IconButton";
import { FiTrash2 } from "react-icons/fi";

const ModalAsignarMultiplesRechazos = ({ modal, queryParams, asignOperador, selectOperadores}) => {
  const [operadorAsignado, setOperadorAsignado] = useState(null);
  const { isModalOpen, handleModal } = modal;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [success, setSuccess] = useState(false)
  const { loading, setLoading } = useLoading()
  const [ rechazosToAsign, setRechazosToAsign ] = useState([])


  const closeModal = () => {
    setSuccess(false)
    handleModal()
  }

  const asign = async (operadorAsignado, rechazosToAsign) => {
    setLoading(true);
    try {
      const res = await asignOperador(
        rechazosToAsign,
        operadorAsignado,
        userInfo.id_usuario 
      )
      setSuccess(true);
    } catch (e){
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const removeItem = (id) => {
    const auxRej = rechazosToAsign.filter( r => r.id!==id)
    setRechazosToAsign(auxRej)
  }

  const getRechazosBack = async (params) => {
    setLoading(true);
    try{
      return await getRechazos(params,null,null,true)
    }catch(e){
      console.log(e)
    } finally {
      setLoading(false);
    }
  }


  useEffect(async ()=>{
      if(queryParams) {
        const {rechazos} = await getRechazosBack(queryParams)
        setRechazosToAsign(rechazos)
      }
      if(! isModalOpen ) {
        setRechazosToAsign([])
        setOperadorAsignado(null)
      }
  },[isModalOpen])

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Gestionar rechazo</NewTitle>
            <p>Modificá los detalles del rechazo</p>
          </div>
          <Divider />
          { !loading && !success &&
          <>
            <div className="data-container">
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={6}>
                  <NewInputSelect
                    type="number"
                    labelName="Operadores Disponibles"
                    name="estado"
                    placeholder="Sin Asignar"
                    options={selectOperadores}
                    onChange={e=>setOperadorAsignado(e.target.value)}
                  />
                </Col>
              </Grid>
            </div>
            <Divider />

            <div className="data-container">
                <div className="solicitudes-container">
                {rechazosToAsign.map((item, index) => {
                  const logo = LOGOS_COMPANIAS.find(logo => logo.nombre.toLocaleLowerCase() === item.compania.toLowerCase())?.url
                  const operador = selectOperadores.find( op => op.key == item.id_operador_asignado)?.label
                  return (
                    <>
                    <OperadorItem>
                      <>
                        <div className="solicitud">
                          <div className="logo-container">
                            <img src={logo} alt="Logo de compañia" />
                          </div>
                          <div className="solicitud-data-container">
                            <div className="header-solicitud-data">
                              <span>
                                ID: {item.id} - Póliza: {item.poliza}
                              </span>
                              <span className="asegurado">
                                {item.nombre} - {item.compania}
                              </span>
                            </div>  
                            <div className="operador-solicitud-data">
                              <span>
                                Operador Asignado: {operador}
                              </span>
                            </div>
                          </div>
                        </div>
                        <IconButton
                          action={() => removeItem(item.id)}
                          icon={<FiTrash2 />}
                          tooltipText="Quitar de la lista"
                          size="small"
                        />
                      </>
                    </OperadorItem>
                    {index + 1 !== rechazosToAsign.length ? <Divider /> : null}
                    </>
                  );
                })}
                </div>
              </div>


            <div className={"actionButtonsCont"}>
              <div className={"buttonsCont"}>
                <NewButton
                  onClick={handleModal}
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                  icon={false}
                >
                  Cancelar
                </NewButton>
                {
                  operadorAsignado &&  rechazosToAsign.length > 0 &&
                  <NewButton
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    icon={false}
                    onClick={()=>asign(operadorAsignado, rechazosToAsign)}
                    disabled={loading}
                  >
                    {loading ? "Asginando..." : "Asignar"}
                  </NewButton>
                }
              </div>
            </div>
          </>
          }
          {
          success && <Success title="Asignación realizada!" description="El rechazo fue gestionado correctamente" handleModal={closeModal} />
          }
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalAsignarMultiplesRechazos;
