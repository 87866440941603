import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import { FiArrowLeft, FiGlobe } from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import dayjs from "dayjs";
import { LOGOS_COMPANIAS } from "../../../constants/logos-companias";
import { useForm } from "../../../hooks/useForm";
import { editarSiniestrosReglas } from "../../../utils/formRules";
import NewButton from "../../../components/NewButton";
import Estado from "../../../components/Estado";
import { getAdjuntosSiniestros } from "../../../services/Siniestros";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import useSiniestros from "../../../hooks/useSiniestros";
import { useLoading } from "../../../hooks/useLoading";
import SiniestroInformacionTab from "../../../components/Tabs/Siniestro/Informacion";
import SiniestroDocumentacionTab from "../../../components/Tabs/Siniestro/Documentacion";
import BackButton from "../../../components/BackButton";
import { SINIESTRO_TABS } from "../../../constants/tabs";
import Divider from "../../../components/Divider";
import Success from "../../../components/Success";
import axiosRequest from "../../../utils/axiosConfig";
import SpinnerLoading from "../../../components/SpinnerLoading";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";

const Siniestro = () => {
  const siniestroId = useParams();
  const location = useLocation();

  console.log(location)

  const [documentacion, setDocumentacion] = useState([]);
  const [siniestro, setSiniestro] = useState(location?.state?.siniestro ? location.state.siniestro : [])

  const { loading, setLoading } = useLoading()
  const { handleTab, tabSelected } = useTabs();
  const { data, operaciones, success } = useSiniestros();
  const { estados, productos } = data;
  const { actualizarSiniestro } = operaciones
  const loadingSiniestro = useLoading()

  // Custom Hooks
  const { inputValues, handleChange, handleChangeExternal, handleValidation, formErrors } = useForm(handleSubmit, editarSiniestrosReglas, siniestro);

  const compania = LOGOS_COMPANIAS.find((logo) => logo?.nombre?.toLowerCase() === siniestro?.compania?.toLowerCase());
  const producto = productos.find((item) => item.value == siniestro.producto_siniestro);
  const nombreProducto = producto ? producto.label : null
  const descripcion = siniestro.tipo_carga_siniestro === 2 ? "Denuncia mediante autogestión." : "Ingresado manualmente desde Siniestros."

  useEffect(() => {
    const getAdjuntos = async () => {
      try {
        const adjuntos = await getAdjuntosSiniestros(siniestroId.id_siniestro);
        setDocumentacion(adjuntos);
      } catch (error) {
        console.log(error);
      }
    };
    getAdjuntos();
  }, [siniestroId]);

  useEffect(() => {
    const getSiniestro = async () => {
      if(!location.state && siniestroId){
        try {
          loadingSiniestro.setLoading(true)
          const siniestro = await axiosRequest.get(`/siniestro/${siniestroId.id_siniestro}`)
          setSiniestro(siniestro.data)
          loadingSiniestro.setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }
    }
    getSiniestro()
  }, [siniestroId])


  async function handleSubmit() {
    setLoading(true)
    try {
      await actualizarSiniestro({
        ...inputValues,
        estado_previo: siniestro.estado,
      });
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }

  return (
    <div>
      <div>
        {/* <Sidebar /> */}
        <Layout>
          <NewWrapper>
            <NewInner>
              <Container>
                {!loadingSiniestro.loading ?
                <>
                  <div>
                    <BackButton url={location.state?.previousUrl ?? "/Siniestros"} text="Siniestros"/>
                    <NewTitlePageSection
                      title={`Siniestro - ${siniestro.asegurado} - ${siniestro.compania}`}
                      description={`${descripcion} ${siniestro.fecha_cambio_estado_siniestro ? `Estado modificado por última vez el ${dayjs(siniestro.fecha_cambio_estado_siniestro).format("DD/MM/YYYY")} a las ${dayjs(siniestro.fecha_cambio_estado_siniestro).format("HH:mm")}hs` : ''}`}
                    ></NewTitlePageSection>
                  </div>
                {!success ?
                  <>
                  <div className="header-section">
                    <img src={compania?.url} className="logo-compania" alt="logo de la compañia" />
                    <span className="header-info">
                      Estado actual: <Estado estado={siniestro.estado} />
                    </span>
                    {siniestro.estado_previo_siniestro ? <span className='header-info'>Estado previo: <Estado estado={siniestro.estado_previo_siniestro} /></span> : null}
                    {siniestro.tipo_carga_siniestro === 2 ? <span className="header-info autogestion">
                      <span><FiGlobe /></span> Autogestión
                    </span> : null}
                  </div>
                  <NewTabs
                    tabs={SINIESTRO_TABS}
                    tabSelected={tabSelected}
                    handleTab={handleTab}
                  />
                  {tabSelected === 0 ? (
                    <SiniestroInformacionTab form={{ inputValues, handleChange, formErrors }} data={{ siniestro, nombreProducto, estados }} />
                  ) : null}

                  {tabSelected === 1 ? (
                    <SiniestroDocumentacionTab form={{ inputValues, handleChangeExternal }} data={{ documentacion }} />
                  ) : null}
                  <Divider />
                  <div className="buttons-container">
                  <NewButton backgroundColor={true} disabled={loading} onClick={handleValidation}>
                    {loading ? "Actualizando siniestro..." : "Actualizar siniestro"}
                  </NewButton>
                  <Link to="/Siniestros">
                      <NewButton borderColor={true} textColor={true}>
                        <FiArrowLeft /> Volver a Siniestros
                      </NewButton>
                    </Link>
                  </div>
                  </>
                : 
                  <>
                    <Divider />
                    <Success title="¡Siniestro actualizado!" description={`Los datos del siniestro se modificaron correctamente`} link={"/Siniestros"} page="Siniestros" />
                  </>
                }
                </>
                : <SpinnerLoading />
                }
              
              </Container>
            </NewInner>
          </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default Siniestro;
