import { useEffect, useState } from "react"

export default function usePagination (dataLength, pageName) {

    const pageLimit = pageName ? 15 : 10
    const lastPage = Math.ceil(dataLength / pageLimit)

    const [currentPage, setCurrentPage] = useState(1)

    const previousPage = () => {
        setCurrentPage(prev => prev - 1)
    }

    const nextPage = () => {
        setCurrentPage(prev => prev + 1)
    }

    const selectPage = (page) => {
        setCurrentPage(page)
    }

    return { 
        currentPage,
        pageLimit,
        lastPage,
        previousPage,
        nextPage,
        dataLength,
        selectPage
    }
}