import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import axios from "axios";
import { Boton } from "../Button/styled";
import { useLoading } from "../../hooks/useLoading";
import SpinnerLoading from "../SpinnerLoading";
import { Container } from "./styled";
import axiosRequest from "../../utils/axiosConfig";

const DocumentGys = ({ url }) => {
    const [doc, setDoc] = useState(null)
    const { loading, setLoading } = useLoading(true)

    console.log("DOCUMENT GYS")
    console.log(url)
    console.log(loading)

    const getFile = async (url) => {

        const config = {
            headers: { 'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69' },
            responseType: 'blob',
        }

        try {

            //const file = await axiosRequest.post('/descargar_api_gys', { url: url })
            // obtiene el documento como un blob
            const file = await axios.get(url, config)
            console.log("URL DEL ARCHIVO DESCARGADO")
            console.log(file.data)

            let src;

            var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
            if ( isChrome ){
               src = window.webkitURL.createObjectURL(file.data);
            } else {
                src = window.URL.createObjectURL(file.data);
            }
            
            //const src = window.URL.createObjectURL(file.data);

            console.log(src)

            const docs = [
                { uri: src }, // Remote file
            ];

            console.log(docs)

            return docs;
        } catch (error) {
            console.log(error)
            // throw new Error('Error al crear document')
        }
    }

    // obtiene la imagen de la api
    useEffect(() => {
        const getFileAPI = async () => {
            try {
                const file = await getFile(url)
                console.log("FILE")
                console.log(file)
                setDoc(file)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        getFileAPI()
    }, [])

    return (
        <Container>
            {loading ?
                <SpinnerLoading text={"Cargando documento"} />
            :
                <DocViewer
                    documents={doc}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                        header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                        },
                    
                        csvDelimiter: ",", // "," as default,
                        pdfZoom: {
                        defaultZoom: 1.0, // 1 as default,
                        zoomJump: 0.1, // 0.1 as default,
                        },
                        pdfVerticalScrollByDefault: false, // false as default
                    }}
                />
            }
        </Container>
    )
}

export default DocumentGys;