import { InputDiv, Input, Icon } from "./styled";
import { MdInfoOutline } from "react-icons/md";

function NewInputTextarea({
  labelName,
  type,
  name,
  placeholder,
  onChange,
  color,
  height,
  value,
  readOnly,
  maxLength,
  showIcon,
  iconImage,
}) {
  return (
    <InputDiv height={height}>
      <label
        htmlFor={name}
        color={color}
      >
        {labelName}
      </label>
      <textarea
        type={type}
        name={name}
        color={color}
        height={height}
        value={value}
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        readOnly={readOnly}
        maxLength={maxLength}
      />
    </InputDiv>
  );
}

export default NewInputTextarea;
