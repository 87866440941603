import React, { useState, useEffect } from "react";
import {
    Titulo,
    Grid,
    Col,
    Inner,
    Wrapper,
    NavMenu,
    BurgerMenu,
    Button,
    Modal,
    InputTextarea,
} from "../components/Index";
import { MdFileUpload } from "react-icons/md";
import InputSelect from "../components/InputSelect";
import axiosRequest from "../utils/axiosConfig";
import { DetailsMain, ScrollX } from "../components/Details/styled";
import { Table } from "../components/Table/styled";
import dayjs from "dayjs";

const Capacitaciones = () => {
    const user = JSON.parse(localStorage.getItem('userInfo'))
    const allowedRoles = [1, 2, 6];

    // menu 
    const [open, setOpen] = useState(false)

    // datos select
    const [productos, setProductos] = useState([])
    const [vendedores, setVendedores] = useState([])
    const [productosSelect, setProductosSelect] = useState([])
    const [vendedoresSelect, setVendedoresSelect] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModalHistorial, setShowModalHistorial] = useState(false)
    const [errorForm, setErrorForm] = useState({})
    const [infoCapa, setInfoCapa] = useState([])
    const [capaSeleccionada, setCapaSeleccionada] = useState(null)
    const [historial, setHistorial] = useState([])
    const [modoEdicion, setModoEdicion] = useState(false)
    const [msg, setMsg] = useState('')
    const [tipoTabla, setTipoTabla] = useState('')

    const tipos = [
        {
            'label': "Práctico",
            'value': "practico",
            'key': "practico"
        },
        {
            'label': "Teórico",
            'value': "teorico",
            'key': "teorico"
        }
    ]

    // estructura del filtro
    const filtroStruct = {
        producto: '',
        vendedor: ''
    }

    const nuevaCapaStruct = {
        producto: '',
        vendedor: '',
        tipo: '',
        comentario: ''
    }
    // cargando
    const [loading, setLoading] = useState(false)

    // estructura tabla
    const [datosTabla, setDatosTabla] = useState([]);

    const [filtro, setFiltro] = useState({ ...filtroStruct })
    const [nuevaCapa, setNuevaCapa] = useState({ ...nuevaCapaStruct })

    const validateForm = () => {
        let errors = {};
        if (nuevaCapa.producto === "") errors.producto = "Campo Requerido";
        if (nuevaCapa.vendedor === "") errors.vendedor = "Campo Requerido";
        if (nuevaCapa.tipo === "") errors.tipo = "Campo Requerido";
        if (nuevaCapa.comentario === "") errors.comentario = "Campo Requerido";
        setErrorForm(errors);

        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const filtrarRenovaciones = (e) => {
        const { name, value } = e.target;
        setFiltro({ ...filtro, [name]: value })
    }

    const handleNuevaCapa = (e) => {
        const { name, value } = e.target;
        setNuevaCapa({ ...nuevaCapa, [name]: value })
    }

    const getVendedores = async () => {
        const send = await axiosRequest.get(`/vendedores`);
        setVendedores(send.data)
    }

    const getProductos = async () => {
        const send = await axiosRequest.get(`/productos`);
        setProductos(send.data)
    }

    const getHistorial = async (id) => {
        try {
            const send = await axiosRequest.get(`/capacitaciones/historial?id=${id}`,)
            if (send.status === 200) {
                setHistorial(send.data)
            }
        } catch (error) {

        }
    }

    const getCapacitaciones = async () => {
        const { vendedor, producto } = filtro;
        try {
            const send = await axiosRequest.get(`/capacitaciones?vendedor=${vendedor}&producto=${producto}`,)
            if (send.status === 200) {
                setInfoCapa(send.data.data)
                setTipoTabla(send.data.tipo)
            }
        } catch (error) {

        }
    }

    const handleMondal = () => {
        setShowModal(true)
    }

    const uploadCapa = async () => {
        setLoading(true)
        try {
            // validar el formulario
            if (validateForm()) {
                let send;
                let msg
                if (modoEdicion) {
                    send = await axiosRequest.put(`/capacitaciones`, nuevaCapa)
                    msg = 'Capacitacion actualizada correctamente'
                } else {
                    send = await axiosRequest.post(`/capacitaciones`, nuevaCapa)
                    msg = 'Nueva Capacitacion ingresada correctamente'
                }
                if (send.status === 200) {
                    limpiarFormulario()
                    setMsg(msg)
                }
            }
        } catch (error) {
            // manejar el error
        } finally {
            setLoading(false)
        }
    }

    const limpiarFormulario = () => {
        if (modoEdicion) {
            setNuevaCapa(prev => { return { ...prev, comentario: '' } })
        } else {
            setNuevaCapa(nuevaCapaStruct)
        }
        setErrorForm({})
        setMsg('')
    }

    const openModalHistorial = (idCapa) => {
        setShowModalHistorial(true)
        getHistorial(idCapa)
    }

    const setUpdateCapa = (capa) => {
        const { id_producto, id_vendedor, id_capacitacion } = capa
        setNuevaCapa({
            ...capa,
            producto: id_producto,
            vendedor: id_vendedor,
            id_capacitacion: id_capacitacion
        })
        setModoEdicion(true)
        setShowModal(true)
    }


    useEffect(() => {
        if (vendedores.length !== 0) {
            let toSelect = vendedores.map(v => {
                return {
                    'label': v.nombre,
                    'value': v.numero,
                    'key': v.numero
                }
            })
            setVendedoresSelect(toSelect)
        }
    }, [vendedores])

    useEffect(() => {
        if (productos.length !== 0) {
            let toSelect = productos.map(v => {
                return {
                    'label': v.nombre_producto,
                    'value': v.id_producto,
                    'key': v.id_producto
                }
            })
            toSelect.push({
                'label': 'Todos',
                'value': 99999,
                'key': 99999
            })
            setProductosSelect(toSelect)
        }
    }, [productos])

    useEffect(async () => {
        // trae los vendedores
        await getVendedores()
        // trae los productos
        await getProductos()
    }, [])

    useEffect(() => {
        if (filtro.producto !== '' && filtro.vendedor !== '') {
            getCapacitaciones()
        }
    }, [filtro])

    useEffect(() => {
        if (!showModal) {
            setNuevaCapa(nuevaCapaStruct)
            setModoEdicion(false)
            getCapacitaciones()
            setMsg('')
        }
    }, [showModal])

    return (
        <div className="App">
            <BurgerMenu open={open} setOpen={setOpen} />
            <NavMenu open={open} setOpen={setOpen} user={user} active={"Usuarios"} />

            <Wrapper>
                <Inner>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12} spaced={true}>
                            <Titulo textColor={false}>Capacitaciones</Titulo>
                            <Button
                                backgroundColor={true}
                                borderColor={false}
                                textColor={false}
                                onClick={handleMondal}
                                target={"_blank"}
                                disabled={()=>user.id_rol === 4}
                            >
                                <span>
                                    <MdFileUpload></MdFileUpload>
                                </span>
                                Cargar datos
                            </Button>
                        </Col>
                        <Col desktop={6}>
                            <InputSelect
                                name={"vendedor"}
                                labelName={"Vendedor"}
                                id={"vendedor"}
                                options={vendedoresSelect}
                                onChange={filtrarRenovaciones}
                                value={filtro.vendedor}
                            />
                        </Col>
                        <Col desktop={6}>
                            <InputSelect
                                name={"producto"}
                                labelName={"Producto"}
                                id={"producto"}
                                options={productosSelect}
                                onChange={filtrarRenovaciones}
                                value={filtro.producto}
                            />
                        </Col>
                        <Col desktop={12}>
                            {
                                infoCapa.length !== 0 ?
                                    <Table>
                                        <thead>
                                            <tr>
                                                {
                                                    tipoTabla === 'all' ?
                                                        <th>Producto</th>
                                                        : null
                                                }
                                                <th>Tipo</th>
                                                <th>Estado</th>
                                                <th>Fecha</th>
                                                <th>Comentario</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                infoCapa.map(c => {
                                                    return (
                                                        <tr>
                                                            {
                                                                tipoTabla === 'all' ?
                                                                    <td>{c.nombre_producto}</td>
                                                                    : null
                                                            }
                                                            <td>{c.tipo}</td>
                                                            <td> cargado </td>
                                                            <td> {dayjs(c.fecha_actualizacion, 'es', true).format('YYYY-MM-DD  hh:mm')} </td>
                                                            <td>{c.comentario}</td>
                                                            <td>
                                                                <Button
                                                                    backgroundColor={true}
                                                                    borderColor={false}
                                                                    textColor={false}
                                                                    onClick={() => {
                                                                        openModalHistorial(c.id_capacitacion);
                                                                    }}
                                                                    disabled={loading}
                                                                >
                                                                    historial
                                                                </Button>
                                                                <Button
                                                                    id={c.id}
                                                                    backgroundColor={true}
                                                                    borderColor={false}
                                                                    textColor={false}
                                                                    onClick={() => setUpdateCapa(c)}
                                                                    target={"_blank"}
                                                                    disabled={loading || user.id_rol === 4}
                                                                >
                                                                    Editar
                                                                </Button>

                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </Table>
                                    :
                                    <div>
                                        {
                                            filtro.producto !== '' && filtro.vendedor !== '' ?
                                                'No existen todavia capacitaciones ingresadas para el vendedor y el producto seleccionados.'
                                                : null
                                        }
                                    </div>
                            }
                        </Col>
                    </Grid>
                    {showModal ? (
                        <Modal
                            isModalOpen={showModal}
                            setIsModalOpen={setShowModal}
                            hideCross={true}
                            loader={true}
                        >
                            <DetailsMain>
                                <div className="modalMail">
                                    <h2 id="titulo">
                                        {modoEdicion ? 'Actualizar' : 'Cargar nueva capacitación'}
                                    </h2>
                                </div>
                                <div>
                                    <Col desktop={6}>
                                        <InputSelect
                                            name={"vendedor"}
                                            labelName={"Vendedor"}
                                            id={"vendedor"}
                                            options={vendedoresSelect}
                                            onChange={handleNuevaCapa}
                                            value={nuevaCapa.vendedor}
                                            disabled={loading || modoEdicion}
                                        />
                                    </Col>
                                    <Col desktop={6}>
                                        <InputSelect
                                            name={"producto"}
                                            labelName={"Producto"}
                                            id={"producto"}
                                            options={productosSelect}
                                            onChange={handleNuevaCapa}
                                            value={nuevaCapa.producto}
                                            disabled={loading || modoEdicion}
                                        />
                                    </Col>
                                    <Col desktop={6}>
                                        <InputSelect
                                            name={"tipo"}
                                            labelName={"Tipo"}
                                            id={"tipo"}
                                            options={tipos}
                                            onChange={handleNuevaCapa}
                                            value={nuevaCapa.tipo}
                                            disabled={loading || modoEdicion}
                                        />
                                    </Col>
                                    <Col desktop={6}>
                                        <InputTextarea
                                            name={"comentario"}
                                            labelName="Comentario"
                                            height={'80px'}
                                            id={"comentario"}
                                            onChange={handleNuevaCapa}
                                            value={nuevaCapa.comentario}
                                            disabled={loading}
                                        >

                                        </InputTextarea>
                                    </Col>
                                    <Col desktop={6}>
                                        <br />
                                        <Button
                                            backgroundColor={true}
                                            borderColor={false}
                                            textColor={false}
                                            onClick={uploadCapa}
                                            target={"_blank"}
                                            disabled={loading}
                                        >
                                            {modoEdicion ? 'Actualizar' : 'Ingresar Capacitación'}
                                        </Button>
                                        <Button
                                            backgroundColor={false}
                                            borderColor={true}
                                            textColor={true}
                                            onClick={limpiarFormulario}
                                            target={"_blank"}
                                        >
                                            Limpiar Formulario
                                        </Button>

                                    </Col>
                                    <Col desktop={6}>
                                        {Object.keys(errorForm).length !== 0 ?
                                            <div>Complete los campos requeridos.</div>
                                            : null}
                                        {
                                            msg.length !== 0 ?
                                                msg : null
                                        }
                                    </Col>
                                </div>
                            </DetailsMain>
                        </Modal>
                    ) : null}
                    {
                        showModalHistorial ?
                            <Modal
                                isModalOpen={showModalHistorial}
                                setIsModalOpen={setShowModalHistorial}
                                hideCross={true}
                                loader={true}
                            >
                                <DetailsMain>
                                    <div className="modalMail">
                                        <h2 id="titulo">Historial de cambios</h2>
                                    </div>
                                    <ScrollX>
                                    <div>
                                        <Col>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Fecha de modificación</th>
                                                        <th>Comentario</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        historial.map(h => {
                                                            return (
                                                                <tr>
                                                                    <td> {dayjs(h.fecha_actualizacion, 'es', true).format('YYYY-MM-DD  hh:mm')} </td>
                                                                    <td>{h.comentario}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </div>
                                    </ScrollX>
                                </DetailsMain>
                            </Modal>
                            : null
                    }
                </Inner>
            </Wrapper>
        </div>
    );
};

export default Capacitaciones;