const Color = require("color");

export const breakpoints = {
  mobileGrande: "468px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  notebook: "1920px",
};

// Funcion para leer el color, devuelve el valor del color en hsl para que sea constante en distintas pantallas.

export const leerColor = (color) => color.hsl().string();

/**
 * Para definir un color utilizamos la librería colors. (Importada arriba). Esto nos permite tener colores exactos a traves de las distintas pantallas.
 * Además podemos hacer ajustes sobre la opacidad, brillo y otras variables del color.
 *
 * Documentación: https://github.com/Qix-/color#readme [IMPORTANTE LEER]
 *
 */
export const colores = {
  azulRebranding: Color.rgb(26, 86, 255),
  negro: Color.rgb(45, 45, 45),
  azul: Color.rgb(0, 15, 63),
  celeste: Color.rgb(50, 137, 185),
  violeta: Color.rgb(151, 72, 224),
  gris: Color.rgb(186, 184, 184),
  verde: Color.rgb(61, 159, 84),
  naranja: Color.rgb(255, 186, 82),
  rojo: Color.rgb(255, 33, 33),
  grisEstados: Color.rgb(120, 120, 120),
  celesteEstados: Color.rgb(109, 199, 249),
  celestePasos: Color.rgb(205, 237, 255),
  celesteNot: Color.rgb(237, 249, 255),
  naranjaNot: Color.rgb(255, 250, 242),
  rojoNot: Color.rgb(255, 241, 241),
  grisNot: Color.rgb(252, 252, 252),
  grisModales: Color.rgb(82, 82, 82),
  blanco: Color.rgb(255, 255, 255),
  naranjaEstados: Color.rgb(255, 186, 82),
  naranjaEstadosSuscripcion: Color.rgb(246, 134, 31)
};
