import { useEmision } from "../../../hooks/useEmision";
import { DivApp, EmisionData, ModalContainerEmision } from "../../styled.js";
import { Grid, Col } from "../../../components/Index";
import {
  aseguradoLabels,
  seguroLabels,
  pagoLabels,
  anulacionLabels,
  endosoLabels,
} from "../../../constants/emision";
import EmisionSeccion from "../../../components/EmisionSeccion";
import Sidebar from "../../../components/Sidebar";
import useModal from "../../../hooks/useModal";
import ModalRechazo from "../../../components/Modals/Emision/ModalRechazo";
import ModalRevertir from "../../../components/Modals/Emision/ModalRevertir";
import { useHistory } from "react-router-dom";
import SpinnerLoading from "../../../components/SpinnerLoading";
import CarouselSlider from "../../../components/CarouselSlider";
import Estado from "../../../components/Estado";
import {
    Container,
  ContainerEstados,
  ContainerFile,
  EmptyDocumentacion,
  LinkURL,
  Prioridad,
} from "./styled";
import {
  FiImage,
  FiFileText,
  FiUser,
  FiShield,
  FiDollarSign,
  FiBox,
  FiArrowLeft,
  FiSlash,
  FiFilePlus,
  FiFile,
  FiEdit,
  FiX,
  FiZap,
  FiGlobe,
  FiArrowUpRight,
} from "react-icons/fi";
import docVideo from "../../../assets/images/docVideo.png";
import ImgGys from "../../../components/ImgGys/Index";

import { useState } from "react";
import ModalError from "../../../components/Modals/General/ModalError";
import DocumentGys from "../../../components/DocumentGys";
import { useTabs } from "../../../hooks/useTabs";
import ModalEmisionManual from "../../../components/Modals/Emision/ModalEmisionManual/index.js";
import ModalEstadoEmision from "../../../components/Modals/Emision/ModalEstadoEmision/index.js";
import NewButton from "../../../components/NewButton/index.jsx";
import Subtitle from "../../../components/Subtitle/index.jsx";
import NewTabs from "../../../components/NewTabs/index.jsx";
import NewTitle from "../../../components/NewTitle/index.jsx";
import NewHistorial from "../../../components/NewHistorial/index.jsx";
import { useEffect } from "react";
import ImgGys2 from "../../../components/ImgGys2/index.js";
import { HiSparkles } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import axiosRequest from "../../../utils/axiosConfig.js";
import { postMensajeHistorial } from "../../../services/Emision.js";
import dayjs from "dayjs";
import { HiOutlineMailOpen } from "react-icons/hi";
import { TbMailbox } from "react-icons/tb";
import { EMISION_TABS } from "../../../constants/tabs.js";
import HeaderTitleSection from "../../../components/HeaderTitleSection/index.js";
import NewWrapper from "../../../components/NewWrapper/index.js";
import NewInner from "../../../components/NewInner/index.js";
import Layout from "../../../components/Layout/index.js";
import BackButton from "../../../components/BackButton/index.js";
import NewTitlePageSection from "../../../components/NewTitlePageSection/index.js";
import Divider from "../../../components/Divider/index.js";

const NewEmision = () => {
  const { tabSelected, tabName, handleTab } = useTabs();
  // Hook de router
  const history = useHistory();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Modales
  const rechazo = useModal();
  const revertir = useModal();
  const exito = useModal();
  const error = useModal();
  const emisionManual = useModal();
  const cambiarEstado = useModal();
  const location = useLocation()

  const { solicitudEmision, getSolicitud, loading } = useEmision();
  const {
    asegurado,
    seguroInfo,
    pago,
    bienAseguradoInfo,
    observacion,
    solicitud,
    poliza,
    anulacion,
    endoso,
    seguro,
    certificado,
  } = solicitudEmision;

  const [openCarousel, setOpenCarousel] = useState(false);
  const [documentacion, setDocumentacion] = useState([]);
  const [emailStatus, setEmailStatus] = useState({
    entregado: false,
    abierto: false,
    noEntregado: false,
  });
  const [emailStatusCertificado, setEmailStatusCertificado] = useState({
    entregado: false,
    abierto: false,
    noEntregado: false,
  });

  useEffect(() => {
    const getDocumentacion = () => {
      let documentacion = bienAseguradoInfo
        ? bienAseguradoInfo.filter((bien) => {
            if (bien.item_bien_asegurado.startsWith("archivo")) {
              return { file: bien.descripcion_bien_asegurado };
            }
          })
        : null;

      let endosoArchivos = [];
      if (endoso) {
        for (const key in endoso) {
          console.log(endoso[key]);
          if (key.startsWith("archivo") && endoso[key] !== null) {
            endosoArchivos.push({
              label_bien_asegurado: key.replace("_", " "),
              descripcion_bien_asegurado: endoso[key],
            });
          }
        }
      }

      let dataToShow = [];

      if (asegurado.archivo_estatuto_empresa_asegurado) {
        dataToShow.push({
          label_bien_asegurado: "Estatuto empresa",
          descripcion_bien_asegurado:
            asegurado.archivo_estatuto_empresa_asegurado,
        });
      }
      if (documentacion) {
        dataToShow = [...dataToShow, ...documentacion];
      }
      if (endoso) {
        dataToShow = [...dataToShow, ...endosoArchivos];
      }
      setDocumentacion(dataToShow);
    };
    getDocumentacion();
  }, [bienAseguradoInfo, anulacion, endoso]);

  useEffect(() => {
    const getStatus = async () => {
      if (poliza) {
        try {
          const id_mailgun = poliza.poliza_id_mail;
          const estado_mail = await axiosRequest(
            `/mails_emision/${asegurado.email_asegurado}/${id_mailgun}`
          );
          if (poliza.estado_mail_poliza !== estado_mail.data.estado_email) {
            switch (estado_mail.data.estado_email) {
              case 1:
                const entregado = {
                  id_solicitud_poliza: poliza.id_solicitud_poliza,
                  estado_email_poliza: estado_mail.data.estado_email,
                };
                await axiosRequest.put(
                  `/solicitudes_polizas/estado_email`,
                  entregado
                );
                const entregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: `El cliente recibió el email de la solicitud emitida.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(entregadoMensaje);

                setEmailStatus((prev) => ({ ...prev, entregado: true }));
                break;
              case 2:
                const abierto = {
                  id_solicitud_poliza: poliza.id_solicitud_poliza,
                  estado_email_poliza: estado_mail.data.estado_email,
                };
                await axiosRequest.put(
                  `/solicitudes_polizas/estado_email`,
                  abierto
                );
                const abiertoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: `Email de póliza emitida abierto por el cliente.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(abiertoMensaje);

                setEmailStatus((prev) => ({
                  ...prev,
                  abierto: true,
                  entregado: true,
                }));
                break;
              case 3:
                const noEntregado = {
                  id_solicitud_poliza: poliza.id_solicitud_poliza,
                  estado_email_poliza: estado_mail.data.estado_email,
                };
                await axiosRequest.put(
                  `/solicitudes_polizas/estado_email`,
                  noEntregado
                );

                let mensaje = null;
                const NO_STORAGE_CODE = 452;
                const MAIL_DOESNT_EXIST_CODE = 550;
                if (estado_mail.data.codigo === NO_STORAGE_CODE) {
                  mensaje =
                    "El cliente no pudo recibir el email con la póliza emitida. El correo electrónico indicado en la solicitud no tiene espacio disponible en su bandeja de entrada.";
                } else if (estado_mail.data.codigo === MAIL_DOESNT_EXIST_CODE) {
                  mensaje =
                    "El cliente no pudo recibir el email con la póliza emitida. El correo electrónico de la solicitud es inexistente.";
                } else {
                  mensaje =
                    "El cliente no pudo recibir el email con la póliza emitida. Error al entregar el email.";
                }

                const noEntregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: mensaje,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(noEntregadoMensaje);

                setEmailStatus((prev) => ({ ...prev, noEntregado: true }));
                break;
              default:
                break;
            }
          } else {
            if (estado_mail.data.estado_email === 1) {
              setEmailStatus((prev) => ({ ...prev, entregado: true }));
            }
            if (estado_mail.data.estado_email === 2) {
              setEmailStatus((prev) => ({
                ...prev,
                abierto: true,
                entregado: true,
              }));
            }
            if (estado_mail.data.estado_email === 3) {
              setEmailStatus((prev) => ({ ...prev, noEntregado: true }));
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    getStatus();
  }, [poliza]);

  useEffect(() => {
    const getStatus = async () => {
      if (certificado) {
        try {
          const id_mailgun = certificado.certificado_id_mail;
          const estado_mail = await axiosRequest(
            `/mails_emision/${asegurado.email_asegurado}/${id_mailgun}`
          );
          if (
            certificado.estado_mail_certificado !==
            estado_mail.data.estado_email
          ) {
            switch (estado_mail.data.estado_email) {
              case 1:
                const entregado = {
                  id_solicitud_certificado:
                    certificado.id_solicitud_certificado,
                  estado_email_certificado: estado_mail.data.estado_email,
                };
                await axiosRequest.put(
                  `/solicitudes_certificados/estado_email`,
                  entregado
                );
                const entregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: `El cliente recibió el email con el certificado emitido.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(entregadoMensaje);

                setEmailStatusCertificado((prev) => ({
                  ...prev,
                  entregado: true,
                }));
                break;
              case 2:
                const abierto = {
                  id_solicitud_certificado:
                    certificado.id_solicitud_certificado,
                  estado_email_certificado: estado_mail.data.estado_email,
                };
                await axiosRequest.put(
                  `/solicitudes_certificados/estado_email`,
                  abierto
                );
                const abiertoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: `Email de certificado emitido abierto por el cliente.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(abiertoMensaje);

                setEmailStatusCertificado((prev) => ({
                  ...prev,
                  abierto: true,
                  entregado: true,
                }));
                break;
              case 3:
                const noEntregado = {
                  id_solicitud_certificado:
                    certificado.id_solicitud_certificado,
                  estado_email_certificado: estado_mail.data.estado_email,
                };
                await axiosRequest.put(
                  `/solicitudes_certificados/estado_email`,
                  noEntregado
                );

                let mensaje = null;
                const NO_STORAGE_CODE = 452;
                const MAIL_DOESNT_EXIST_CODE = 550;
                if (estado_mail.data.codigo === NO_STORAGE_CODE) {
                  mensaje =
                    "El cliente no pudo recibir el email con el certificado emitido. El correo electrónico indicado en la solicitud no tiene espacio disponible en su bandeja de entrada.";
                } else if (estado_mail.data.codigo === MAIL_DOESNT_EXIST_CODE) {
                  mensaje =
                    "El cliente no pudo recibir el email con el certificado emitido. El correo electrónico de la solicitud es inexistente.";
                } else {
                  mensaje =
                    "El cliente no pudo recibir el email con el certificado emitido. Error al entregar el email.";
                }

                const noEntregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: mensaje,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(noEntregadoMensaje);

                setEmailStatusCertificado((prev) => ({
                  ...prev,
                  noEntregado: true,
                }));
                break;
              default:
                break;
            }
          } else {
            if (estado_mail.data.estado_email === 1) {
              setEmailStatusCertificado((prev) => ({
                ...prev,
                entregado: true,
              }));
            }
            if (estado_mail.data.estado_email === 2) {
              setEmailStatusCertificado((prev) => ({
                ...prev,
                abierto: true,
                entregado: true,
              }));
            }
            if (estado_mail.data.estado_email === 3) {
              setEmailStatusCertificado((prev) => ({
                ...prev,
                noEntregado: true,
              }));
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    getStatus();
  }, [certificado]);

  const handleBack = () => history.goBack();

  let tabs = [
    {
      name: "Solicitud",
    },
    {
      name: "Historial",
    },
    {
      name: "Documentación",
    },
  ];
  if (poliza) {
    tabs.push({ name: "Póliza Emitida" });
  }
  if (certificado) {
    tabs.push({ name: "Certificado de cobertura" });
  }

  return (
    <>
      {/* <Sidebar /> */}
        <div>
          <ModalError
            isModalOpen={error.isModalOpen}
            setIsModalOpen={error.setIsModalOpen}
            message={error.modalMessage}
          />

          {/* Modales */}
          {revertir.isModalOpen ? (
            <ModalRevertir
              isModalOpen={revertir.isModalOpen}
              handleModal={revertir.handleModal}
              solicitud={solicitud}
              successModal={exito.handleModal}
              errorModal={error}
              getSolicitud={getSolicitud}
            />
          ) : null}

          {rechazo.isModalOpen ? (
            <ModalRechazo
              isModalOpen={rechazo.isModalOpen}
              handleModal={rechazo.handleModal}
              asegurado={asegurado}
              seguro={seguroInfo}
              solicitud={solicitud}
              successModal={exito.handleModal}
              errorModal={error}
              getSolicitud={getSolicitud}
            />
          ) : null}

          {emisionManual.isModalOpen && (
            <ModalEmisionManual
              modal={emisionManual}
              solicitud={solicitud}
              getSolicitud={getSolicitud}
              asegurado={asegurado}
              seguro={seguroInfo}
              producto={seguro?.producto_cobertura_asegurada}
            />
          )}

          {cambiarEstado.isModalOpen ? (
            <ModalEstadoEmision
              isModalOpen={cambiarEstado.isModalOpen}
              handleModal={cambiarEstado.handleModal}
              asegurado={asegurado}
              seguro={seguroInfo}
              solicitud={solicitud}
              successModal={exito.handleModal}
              errorModal={error}
              getSolicitud={getSolicitud}
            />
          ) : null}

          <Layout>
            <NewWrapper>
                <NewInner>
                {!loading ? (
            <Container>
              <div>
                <BackButton url={location.state?.previousUrl ?? "/SolicitudEmision"} text="solicitud emisión"/>

                {/* {solicitud.prioridad == 1 ? (
                  <Col desktop={12}>
                    <Prioridad>
                      Prioridad <HiSparkles />
                    </Prioridad>
                  </Col>
                ) : null} */}
                
                <Col desktop={12}>
                  <ContainerEstados>
                    {/* <span>
                      ・ Estado de la solicitud:{" "}
                      <Estado
                        estado={solicitud.estado}
                        operador={solicitud.operador_solicitud}
                        previo={solicitud.id_estado_previo}
                      />
                    </span> */}
                    {solicitud.id_estado_previo === 3 &&
                      solicitud.id_estado === 1 && (
                        <span>
                          ・ Estado previo: <Estado estado="Rechazado" />
                        </span>
                      )}
                  </ContainerEstados>
                </Col>
                <Col desktop={12}>
                    <NewTitlePageSection
                      title={`${solicitud.tipo_solicitud} - ${asegurado?.nombre_asegurado}
                    ${asegurado?.apellido_asegurado} -
                    ${seguroInfo?.cia_cobertura_asegurada}
                    ${seguroInfo?.producto_cobertura_asegurada}`}
                      description={`Operación: ${observacion?.operacion}`}
                    ></NewTitlePageSection>
                </Col>
              </div>

              <HeaderTitleSection compania={seguroInfo?.cia_cobertura_asegurada} vendedor={solicitud.vendedor_solicitud} estado={solicitud.estado} prioridad={solicitud.prioridad == 1} />

              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

              {Object.values(emailStatus).some((value) => value) ||
              Object.values(emailStatusCertificado).some((value) => value) ? (
                <Col desktop={12}>
                  <div className="poliza-mail-container">
                    {emailStatus.entregado ? (
                      <div>
                        <span className="icon">
                          <TbMailbox />
                        </span>
                        Email de solicitud emitida recibido por el cliente.
                      </div>
                    ) : null}
                    {emailStatus.abierto ? (
                      <div>
                        <span className="icon">
                          <HiOutlineMailOpen />
                        </span>{" "}
                        El cliente abrió el email con su póliza.
                      </div>
                    ) : null}
                    {emailStatus.noEntregado ? (
                      <div className="no-entregado-mensaje">
                        <span className="icon no-entregado">
                          <FiX />
                        </span>{" "}
                        Email de póliza no entregado. Revisar historial de la
                        solicitud.
                      </div>
                    ) : null}

                    {emailStatusCertificado.entregado ? (
                      <div>
                        <span className="icon">
                          <TbMailbox />
                        </span>
                        Email del certificado emitido recibido por el cliente.
                      </div>
                    ) : null}
                    {emailStatusCertificado.abierto ? (
                      <div>
                        <span className="icon">
                          <HiOutlineMailOpen />
                        </span>{" "}
                        El cliente abrió el email con su certificado.
                      </div>
                    ) : null}
                    {emailStatusCertificado.noEntregado ? (
                      <div className="no-entregado-mensaje">
                        <span className="icon no-entregado">
                          <FiX />
                        </span>{" "}
                        Email de certificado no entregado. Revisar historial de
                        la solicitud.
                      </div>
                    ) : null}
                  </div>
                </Col>
              ) : null}

              {tabName === "Certificado de cobertura" && certificado ? (
                <DocumentGys url={certificado?.url_certificado} />
              ) : null}

              {tabName === "Póliza Emitida" && poliza ? (
                <DocumentGys url={poliza?.url_poliza} />
              ) : null}

              {tabSelected === 2 ? (
                documentacion.length > 0 ? (
                  <>
                    <Grid>
                      {documentacion.map((archivo, index) => {
                        let file = null;
                        if (
                          archivo.descripcion_bien_asegurado.includes("image")
                        ) {
                          file = (
                            <ImgGys
                              url={archivo.descripcion_bien_asegurado}
                              index={index}
                              descarga={false}
                            />
                          );
                        } else if (
                          archivo.descripcion_bien_asegurado.includes("video")
                        ) {
                          file = (
                            <img
                              src={docVideo}
                              alt={"documento"}
                              descarga={false}
                            />
                          );
                        } else {
                          file = (
                            <ImgGys2
                              url={archivo.descripcion_bien_asegurado}
                              index={index}
                              descarga={false}
                            />
                          );
                        }
                        return (
                          <Col desktop={3}>
                            <ContainerFile>
                              <label>{archivo.label_bien_asegurado.replaceAll("_", " ")}</label>
                              {file}
                            </ContainerFile>
                          </Col>
                        );
                      })}
                    </Grid>
                  </>
                ) : (
                  <EmptyDocumentacion>
                    <span className="icon">
                      <FiFile />
                    </span>
                    <h3>No hay archivos disponibles</h3>
                    <span className="descripcion">
                      La solicitud no tiene documentación cargada
                    </span>
                  </EmptyDocumentacion>
                )
              ) : null}

              {tabSelected === 1 ? (
                <NewHistorial solicitud={solicitudEmision} />
              ) : null}

              {tabSelected === 0 ? (
                <>
                  {solicitud.id_tipo_solicitud !== 3 ? (
                    <>
                      {solicitud.id_tipo_solicitud == 2 ? (
                        <>
                        <EmisionSeccion
                          title="Datos anulación"
                          data={anulacion}
                          label={anulacionLabels}
                          icon={<FiSlash />}
                        />
                        <Divider />
                        </>
                      ) : null}

                      <EmisionSeccion
                        title="Datos del Cliente"
                        data={asegurado}
                        label={aseguradoLabels}
                        icon={<FiUser />}
                      />
                      <Divider />
                      <EmisionSeccion
                        title="Datos del Seguro"
                        data={seguroInfo}
                        label={seguroLabels}
                        icon={<FiShield />}
                      />
                      <Divider />
                      <Grid colGap={21} rowGap={21} narrow={false}>
                        <Col desktop={12} spaced={true}>
                          <Subtitle
                            title={"Datos del Bien Asegurado"}
                            icon={<FiBox />}
                            disableDivider={true}
                          />
                        </Col>
                        {bienAseguradoInfo.length > 0 &&
                          bienAseguradoInfo.map((bien, index) => {
                            const isString =
                              typeof bien.descripcion_bien_asegurado ===
                              "string";
                            const isLink = isString
                              ? bien.descripcion_bien_asegurado.includes("http")
                              : null;
                            const linebreak = isString
                              ? bien.descripcion_bien_asegurado.includes("\n")
                              : null;
                            const hasLineBreak = isString
                              ? bien.descripcion_bien_asegurado.split("\n")
                              : null;
                            const column = linebreak ? 12 : 3;
                            if (
                              !bien.item_bien_asegurado.startsWith("archivo")
                            ) {
                              return (
                                <Col
                                  desktop={column}
                                  key={bien.id_bien_asegurado}
                                >
                                  <div key={bien.id_bien_asegurado} className="seccion-container">
                                    <span>{bien.label_bien_asegurado}</span>
                                    {hasLineBreak && linebreak
                                      ? hasLineBreak.map((item) => (
                                          <h2>{item}</h2>
                                        ))
                                      : null}
                                    {isLink ? (
                                      <LinkURL
                                        href={bien.descripcion_bien_asegurado}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Ver link <span className="icon-link"><FiArrowUpRight /></span>
                                      </LinkURL>
                                    ) : null}
                                    {!isLink && !linebreak ? (
                                      <h2>
                                        {bien.item_bien_asegurado.startsWith(
                                          "check"
                                        )
                                          ? bien.descripcion_bien_asegurado == 1
                                            ? "Sí"
                                            : "No"
                                          : bien.descripcion_bien_asegurado}
                                      </h2>
                                    ) : null}
                                  </div>
                                </Col>
                              );
                            }
                          })}

                        {bienAseguradoInfo.filter((bien) =>
                          bien.item_bien_asegurado.startsWith("archivo")
                        ).length > 0 ? (
                          <Col desktop={12} alignBottom={true}>
                            <NewButton
                              backgroundColor={false}
                              borderColor={true}
                              textColor={true}
                              //icon={ArrowRight}
                              direction={"after"}
                              onClick={() => setOpenCarousel(!openCarousel)}
                            >
                              <span>
                                <FiImage />
                              </span>
                              Ver adjuntos
                            </NewButton>
                          </Col>
                        ) : null}
                      </Grid>

                      <Divider />

                      <EmisionSeccion
                        title="Forma de Pago"
                        data={pago}
                        label={pagoLabels}
                        icon={<FiDollarSign />}
                      />
                    </>
                  ) : (
                    <>
                      <EmisionSeccion
                        title="Datos del Cliente"
                        data={asegurado}
                        label={aseguradoLabels}
                        icon={<FiUser />}
                      />
                      <EmisionSeccion
                        title="Datos endoso"
                        data={endoso}
                        label={endosoLabels}
                        icon={<FiFilePlus />}
                      />
                    </>
                  )}
                </>
              ) : null}

              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <EmisionData apiDisabled={false}>
                    <div className={"actionButtonsCont"}>
                      <NewButton
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        direction={"before"}
                        onClick={handleBack}
                      >
                        <FiArrowLeft /> Volver
                      </NewButton>

                      {userInfo.id_rol !== 10 && userInfo.id_rol !== 18 && userInfo.id_rol !== 22 ? (
                        <div className={"buttonsCont"}>
                          {parseInt(solicitud.id_estado) !== 2 &&
                          solicitud.operador_solicitud !== 66 ? (
                            <NewButton
                              backgroundColor={false}
                              borderColor={false}
                              textColor={true}
                              icon={false}
                              onClick={cambiarEstado.handleModal}
                            >
                              <FiZap /> Cambiar estado
                            </NewButton>
                          ) : null}

                          {parseInt(solicitud.id_estado) !== 2 &&
                          parseInt(solicitud.id_estado) !== 2 &&
                          parseInt(solicitud.id_estado) !== 4 &&
                          parseInt(solicitud.id_estado) !== 3 && solicitud.vendedor_solicitud !== 192
                           ? (
                            <NewButton
                              backgroundColor={
                                solicitud.operador_solicitud === 66
                                  ? true
                                  : false
                              }
                              borderColor={
                                solicitud.operador_solicitud === 66
                                  ? false
                                  : true
                              }
                              textColor={
                                solicitud.operador_solicitud === 66
                                  ? false
                                  : true
                              }
                              icon={false}
                              onClick={rechazo.handleModal}
                            >
                              Rechazar
                            </NewButton>
                          ) : null}

                      {((userInfo.id_rol === 10 || userInfo.id_rol === 22) &&
                      parseInt(solicitud.id_estado) === 3) || solicitud.vendedor_solicitud === 192 ? (
                        <Link
                          to={{
                            pathname: `/EditarSolicitudEmision/${solicitud.id}`,
                            state: { data: solicitud.id },
                            search: `?tipoSolicitud=${solicitud.id_tipo_solicitud}`,
                          }}
                        >
                          <NewButton backgroundColor={solicitud.vendedor_solicitud === 192 ? false : true} textColor={solicitud.vendedor_solicitud === 192 ? true : false} borderColor={solicitud.vendedor_solicitud === 192 ? true : false}>
                            <FiEdit /> Editar Solicitud
                          </NewButton>
                        </Link>
                      ) : null}

                          {(parseInt(solicitud.id_estado) == 1 ||
                            parseInt(solicitud.id_estado) == 4 ||
                            parseInt(solicitud.id_estado) == 5 ||
                            parseInt(solicitud.id_estado) == 6 ||
                            parseInt(solicitud.id_estado) == 7) &&
                          solicitud.operador_solicitud !== 66 ? (
                            <NewButton
                              backgroundColor={true}
                              borderColor={false}
                              textColor={false}
                              icon={false}
                              onClick={emisionManual.handleModal}
                            >
                              Emitir solicitud
                            </NewButton>
                          ) : null}

                          {solicitud.id_estado == "3" ? (
                            <NewButton
                              backgroundColor={true}
                              borderColor={false}
                              textColor={false}
                              icon={false}
                              onClick={revertir.handleModal}
                            >
                              Revertir{" "}
                            </NewButton>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </EmisionData>
                </Col>
              </Grid>

              {openCarousel ? (
                <CarouselSlider
                  items={bienAseguradoInfo.filter((bien) =>
                    bien.item_bien_asegurado.startsWith("archivo")
                  )}
                  openCarousel={openCarousel}
                  setOpenCarousel={setOpenCarousel}
                />
              ) : null}
            </Container>
          ) : (
            <SpinnerLoading text="Cargando solicitud..." />
          )}
                </NewInner>
            </NewWrapper>
          </Layout>

        </div>
    </>
  );
};

export default NewEmision;
