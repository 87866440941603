import { Grid, Col } from "../../Grid";
import InputBasic from "../../InputBasic";
import { useForm } from "../../../hooks/useForm";
import { useState, useEffect } from "react";
import useButtonMessage from "../../../hooks/useButtonMessage";
import { limitesReglas } from "../../../utils/formRules";
import ErrorInput from "../../ErrorInput";
import Button from "../../Button";
import SpinnerLoading from "../../SpinnerLoading";
import axiosRequest from "../../../utils/axiosConfig";
import { Container, LimiteCard } from "./styled";
import { formatNumber } from "../../../utils/formatter";
import NewButton from "../../NewButton";
import Divider from "../../Divider";
import NewTabs from "../../NewTabs";
import NewInputBasic from "../../NewInputBasic";
import Success from "../../Success";
import Subtitle from "../../Subtitle";
import { FiShield } from "react-icons/fi";

const LimitesForm = ({ limites, productoSeleccionado, productos }) => {
  const [initialValues, setInitialValues] = useState({});
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(
    handleSubmit,
    limitesReglas,
    initialValues
  );
  const { buttonText, handleButtonText } =
    useButtonMessage("Actualizar limites");
  const formatter = new Intl.NumberFormat("es-ES", {});
  const [success, setSuccess] = useState(false);

  console.log(limites);

  console.log(productoSeleccionado);
  const producto = productos.find(
    (producto) => productoSeleccionado === producto.numero
  )?.descripcion;
  useEffect(() => {
    const initialValues = limites.reduce((total, item) => {
      const key = `${item.nombre.toLowerCase().replace(" ", "_")}_${
        item.id_landing_limite_producto_compania
      }`;
      return {
        ...total,
        [key]: item.limite,
      };
    }, {});
    setInitialValues(initialValues);
  }, [limites]);

  async function handleSubmit() {
    handleButtonText("Actualizando...");
    try {
      const actualizar = await axiosRequest.put(
        "/limites_producto_compania",
        inputValues
      );
      if (actualizar) {
        handleButtonText("Limites actualizados correctamente!");
        setSuccess(true);
        setTimeout(() => {
          handleButtonText("Actualizar límites");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <div>
        {!success ? (
          <Grid colGap={21} rowGap={40} narrow={false}>
            {limites.length > 0 ? (
              <>
                {limites.map((limite, index) => {
                  const key = `${limite.nombre
                    .toLowerCase()
                    .replace(" ", "_")}_${
                    limite.id_landing_limite_producto_compania
                  }`;

                  const producto = limite.id_producto;
                  const productoAnterior =
                    index > 0 ? limites[index - 1].id_producto : null;

                  return (
                    <>
                      {producto !== productoAnterior ? (
                        <>
                                                  <Col desktop={12}>
                            <Divider />
                          </Col>
                          <Col desktop={12}>
                            <Subtitle
                              icon={<FiShield />}
                              title={limite.descripcion}
                              description={`Límite para cada compañia del producto`}
                              disableDivider={true}
                            />
                          </Col>

                        </>
                      ) : null}
                      <Col desktop={3} key={limite.id}>
                        <LimiteCard>
                          <div className="container-limite-card">
                            <div className="logo-container">
                              <h3>{limite.nombre}</h3>
                              {/* <img src={limite.logo} alt={limite.nombre} /> */}
                            </div>
                            <Divider />
                            {/* <span>{limite.nombre}</span> */}
                            <span>Límite</span>
                            <h3>$ {formatter.format(limite.limite)}</h3>
                          </div>
                          <Divider />
                          <NewInputBasic
                            placeholder={`Limite de ${limite.nombre}`}
                            name={key}
                            value={inputValues[key]}
                            onChange={handleChange}
                          />
                          {/* <div className="limite-container">
                    <div className="logo-container">
                      <img src={limite.logo} />
                    </div>
                    <hr className="divider"/>
                    <span><strong>Empresa:</strong> {limite.nombre}</span>
                    <span><strong>Producto:</strong> {producto}</span>
                    <span><strong>Límite actual:</strong> ${formatNumber.format(limite.limite)}</span>
                    <hr className="divider"/>
                    <InputBasic
                      labelName={limite.nombre}
                      placeholder={`Limite de ${limite.nombre}`}
                      name={key}
                      value={
                        inputValues[key]
                      }
                      onChange={handleChange}
                    /> */}
                          {formErrors[inputValues[key]] && (
                            <ErrorInput>
                              {formErrors[inputValues[key]]}
                            </ErrorInput>
                          )}
                        </LimiteCard>
                        {/* </div> */}
                      </Col>
                    </>
                  );
                })}
                <Col desktop={12}>
                  <Divider />
                </Col>
                <Col desktop={12}>
                  <NewButton backgroundColor={true} onClick={handleValidation}>
                    {buttonText}
                  </NewButton>
                </Col>
              </>
            ) : (
              <Col desktop={12}>
                <SpinnerLoading text="Cargando limites" />
              </Col>
            )}
          </Grid>
        ) : (
          <Success
            title="¡Valores actualizados!"
            description="Los límites se actualizaron correctamente"
            link={"/Landings"}
            page="Landings"
          />
        )}
      </div>
    </Container>
  );
};

export default LimitesForm;
