import React, { useState, useEffect } from "react";
import { Card } from "./styled";
import valid from 'card-validator'
import Visa from '../../assets/images/visa-component.svg'
import Maestro from '../../assets/images/maestro-component.svg'
import Mastercard from '../../assets/images/mastercard-component.svg'
import AmericanExpress from '../../assets/images/americanexpress-component.svg'
import Diners from '../../assets/images/diners-component.svg'
import Naranja from '../../assets/images/naranja-component.svg'
import Cabal from '../../assets/images/cabal-component.jpg'
//mport Discover from '../../assets/images/discover-component.svg'
import UnionPay from '../../assets/images/unionpay-component.svg'
//import Jcb from '../../assets/images/jcb-component.svg'
import Elo from '../../assets/images/elo-component.svg'
//import Hipercard from '../../assets/images/hipercard-component.svg'
import Mir from '../../assets/images/mir-component.svg'
import Hiper from '../../assets/images/hiper-component.png'
import ChipCard from '../../assets/images/chip-card.svg'

const CreditCardNew = ({ titular, vencimiento, numero }) => {

    const [brand, setBrand] = useState('')
    const [formattedNumber, setFormattedNumber] = useState('••••••••••••••••')
    const [formattedExpirationDate, setFormattedExpirationDate] = useState('••/••')

    // Info de las tarjetas
    const cardsData = {
        visa: {
            image: Visa, 
        },
        maestro: {
            image: Maestro
        },
        mastercard: {
            image: Mastercard
        },
        americanexpress: {
            image: AmericanExpress
        },
        dinersclub: {
            image: Diners
        },
        naranja: {
            image: Naranja
        },
        cabal: {
            image: Cabal
        },
        discover: {
            image: ''
        },
        unionpay: {
            image: UnionPay
        },
        jcb: {
            image: ''
        },
        elo: {
            image: Elo
        },
        hipercard: {
            image: ''
        },
        mir: {
            image: Mir
        },
        hiper: {
            image: Hiper
        }
    }

    // Número 
    useEffect(() => {
        if(numero){
            // Seleccionar tipo de tarjeta, espacios entre los numeros y cantidad de números.
            const selectBrand = valid.number(numero); 
            const gaps = selectBrand.card ? selectBrand.card.gaps : [4, 8, 12];
            const creditCardLength = selectBrand.card === null || brand === "Naranja" || brand === "Cabal" ? 16 : selectBrand.card.lengths[0]
            if(numero.length <= creditCardLength){
                const numbers = numero
                const limit = creditCardLength - numero.length
                let dots = "•"
                for(let i=1; i<limit;i++){
                    dots = dots + "•"
                }
                const fullNumbers = limit > 0 ? numbers + dots : numbers
                let formatCreditCard = fullNumbers;
                if(gaps){
                    gaps.forEach((gap, index) => {
                        if(gap === gaps[0]){
                            let partOne = formatCreditCard.substring(0, gap)
                            let partTwo = formatCreditCard.substring(gap)
                            formatCreditCard = partOne + " " + partTwo
                        } else {
                            let partOne = formatCreditCard.substring(0, gap + index)
                            let partTwo = formatCreditCard.substring(gap + index)
                            formatCreditCard = partOne + " " + partTwo
                        }
                    })
                }
                setFormattedNumber(formatCreditCard)
            } 

            if(selectBrand.card !== null){
                setBrand(selectBrand.card.niceType)
            }
            if(numero.startsWith(5895)){
                setBrand("Naranja")
            }
            if(numero.startsWith(6042)){
                setBrand("Cabal")
            }
        } else {
            // Resetear
            setFormattedNumber('•••• •••• •••• ••••')
            setBrand('')
        }
    }, [numero])

    // Vencimiento
    useEffect(() => {
        if(vencimiento){
            // Seleccionar tipo de tarjeta, espacios entre los numeros y cantidad de números.
            const expirationDateLength = 5
            if(vencimiento.length <= expirationDateLength){
                const expirationDate = vencimiento
                const limit = expirationDateLength - vencimiento.length
                let dots = "•"
                for(let i=1; i<limit;i++){
                    dots = dots + "•"
                }
                const fullExpirationDate = limit > 0 ? expirationDate + dots : expirationDate
                setFormattedExpirationDate(fullExpirationDate)
            } 
        } else {
            // Resetear
            setFormattedExpirationDate('••/••')
        }
    }, [vencimiento])

	return (
		<div>
			<Card brand={brand.replace(/\s/g, '').toLowerCase()}>
                <img className="chip" src={ChipCard} alt="chip" />
                {brand && <img class="logo" src={cardsData[brand.replace(/\s/g, '').toLowerCase()].image} alt="logo" />}
                <div className="credit-card-content-container">
                    <div className="credit-card-number-container">
                        <span>{formattedNumber}</span>
                    </div>
                    <div className="credit-card-name-container">
                        <div className="credit-card-name">{titular ? titular : "NOMBRE DEL TITULAR"}</div>
                        <div className="credit-card-expiration-date">
                            <span>Hasta:</span>
                            <span>{formattedExpirationDate}</span>
                        </div>
                    </div>
                </div>
            </Card>
		</div>
	);
};

export default CreditCardNew;
