import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const SelectDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  label{
     
    color: ${leerColor(colores.negro)};
    font-size: 18px;
    padding-bottom: 10px;
  }
`
export const Select = styled.select` 
   
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 20px;
  border: 1px solid ${leerColor(colores.gris)};
  option {
    color: ${leerColor(colores.negro)};
    background: white;
    display: flex;
    white-space: pre;
    min-height: 40px;
    padding: 12px 20px;
  }
  &::-ms-expand {
    display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  }
  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    
  }
  @media (min-width: ${breakpoints.notebook}) {

  }
`;
