import { FiStar } from "react-icons/fi";
import { Col, Grid } from "../../Grid";
import Subtitle from "../../Subtitle";
import ErrorInput from "../../ErrorInput";
import NewButton from "../../NewButton";
import { useForm } from "../../../hooks/useForm";
import NewInputBasic from "../../NewInputBasic";
import { useEffect, useState } from "react";
import axiosRequest from "../../../utils/axiosConfig";
import { useLoading } from "../../../hooks/useLoading";
import { Container, ActivePlan } from "./styled";
import Switch from "../../Switch";
import dayjs from "dayjs"
import { meses } from "../../../constants/data";

const EditarPremioForm = ({ prem, vendedorId, vendedor, mes, refreshPremios }) => {
  const [initial, setInitial] = useState({});
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
    handleChangeCheckbox,
  } = useForm(null, null, initial);
  const { loading, loadingText, setLoading, changeLoadingText } = useLoading();
  const [active, setActive] = useState(prem.data[0].activo ? true : false)
  const user = JSON.parse(localStorage.getItem('userInfo'))

  useEffect(() => {
    let obj = {};
    prem.data.forEach((item, index) => {
      const keys = Object.keys(item).filter(
        (item) =>
          item !== "id_premio" &&
          item !== "estado" &&
          item !== "id_vendedor_premio"
      );
      keys.forEach((key) => {
        if (prem[key] === 1) {
          obj[key + "_" + index] = item[key];
        }
      });
    });
    setInitial(obj);
  }, []);

  const handleChangeActive = async () => {
    setActive(!active)

    console.log(prem)

    try {
      await axiosRequest.put('/premio/estado_premio', { activo: !active, data: prem.data })

      const historial = {
        mensaje: `${user.nombre_operador} editó el estado del premio {premio} del mes de ${meses.find(item => item.value === mes).label.toLowerCase()} para ${vendedor}. ${prem.nombre}: ${!active === true ? "Activo" : "Inactivo"}`,
        dia: new Date(),
        id_vendedor: vendedorId,
        id_premio: prem.data[0].id_vendedor_premio,
        id_usuario: user.id_operador,
        id_tipo_modificacion: 1
      }
      console.log(historial)
  
      const historialModificaciones = await axiosRequest.post(
        `/premios/historial_modificaciones`,
        historial
      );
      //refreshPremios()
    } catch (error) {
      console.log(error)
    }
  }

  const actualizarPremio = async () => {
    changeLoadingText("Guardando cambios...");
    setLoading(true);
    const dataToSend = prem.data.map((item, index) => {
      let obj = {};
      const values = Object.keys(inputValues).filter((item) =>
        item.includes(index)
      );
      values.forEach((item) => {
        const key = item.split(`_${index}`)[0];
        obj[key] = inputValues[key + "_" + index];
      });

      return {
        id_parametro: item.id_parametro,
        ...obj,
      };
    });
  
    try {
      const update = await axiosRequest.put(
        `/premios/vendedor/${vendedorId}/${prem.id_vendedor_premio}/${mes}`,
        dataToSend
      );
      if (update.status === 200) {
        let firstPart = `${user.nombre_operador} editó los valores del premio {premio} del mes de ${meses.find(item => item.value === mes).label.toLowerCase()} para ${vendedor}. Nuevos valores para el cálculo:`
        let message = ``
        dataToSend.forEach(item => {
          const data = Object.entries(item)
          console.log(data)
          message += ` ${data.filter(item => item[0] !== "id_parametro").map(valor => {
              const propiedad = valor[0].replaceAll("_", " ")
              const value = valor[0].includes("valor") ? `$${valor[1]}` : valor[1]
              return `${propiedad}: ${value}`
          }).join(", ")}`
        })
    
        const historial = {
          mensaje: `${firstPart} ${message}`,
          dia: new Date(),
          id_vendedor: vendedorId,
          id_premio: prem.data[0].id_vendedor_premio,
          id_usuario: user.id_operador,
          id_tipo_modificacion: 1
        }
        console.log(historial)
    
        const historialModificaciones = await axiosRequest.post(
          `/premios/historial_modificaciones`,
          historial
        );

        changeLoadingText("¡Cambios guardados!");
        setLoading(false);
        setTimeout(() => {
          changeLoadingText(null);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Col desktop={12}>
        <Subtitle
          icon={<FiStar />}
          title={`Premio - ${prem.nombre}`}
          description={
            "Personalizá los valores y parámetros que conforman el premio"
          }
          disableDivider={true}
        />
      </Col>
      <Col desktop={12}>
        <ActivePlan>
          <Switch
            checked={active}
            name="activo"
            onChange={handleChangeActive}
          ></Switch>
          <div className="container">
            {inputValues.estado === 1 ? <div className="blob"></div> : null}
            <span className={`${!active && "plan-inactivo"}`}>
              {active
                ? `El premio está activo. El vendedor puede ver el premio y su valor se contabiliza para el total del mes.`
                : "El premio está inactivo. El vendedor no puede ver el premio y su valor no se contabiliza para el total del mes."}
            </span>
          </div>
        </ActivePlan>
      </Col>
      <Col desktop={12}>
        <Container>
          {prem.data.map((item, index) => {
            const keys = Object.keys(item).filter(
              (item) =>
                item !== "id_premio" &&
                item !== "estado" &&
                item !== "id_vendedor_premio"
            );
            return (
              <>
                <Grid colGap={21} rowGap={21} narrow={false}>
                  {keys.map((key) => {
                    if (prem[key] === 1) {
                      return (
                        <Col desktop={3}>
                          <NewInputBasic
                            type={"text"}
                            name={key + "_" + index}
                            labelName={
                              key.charAt(0).toUpperCase() +
                              key.slice(1).replaceAll("_", " ")
                            }
                            placeholder={ key.charAt(0).toUpperCase() +
                              key.slice(1).replaceAll("_", " ")}
                            onChange={handleChange}
                            value={inputValues[key + "_" + index]}
                          />
                          {formErrors[key] && (
                            <ErrorInput>{formErrors[key]}</ErrorInput>
                          )}
                        </Col>
                      );
                    }
                  })}
                </Grid>
              </>
            );
          })}
        </Container>
      </Col>
      <Col desktop={12}>
        <div className="botones-container">
          <NewButton
            backgroundColor={true}
            onClick={() => actualizarPremio(prem.data)}
            disabled={loading}
          >
            {loadingText ? loadingText : "Guardar cambios"}
          </NewButton>
        </div>
      </Col>
      <hr />
    </Container>
  );
};

export default EditarPremioForm;
