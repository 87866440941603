import { useState } from "react";

export const useSingleOpen = () => {

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(!isOpen)

  return { isOpen, handleOpen }

}