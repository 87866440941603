import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
    width: 100%;

    .container {
      padding: 20px 40px;
      height: 80.51px;
      border-bottom: 1px solid ${({ theme }) => theme.border};
      width: 100%;
      position: sticky;
      top: 0;
      color: #202020;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      background-color: ${({ theme }) => theme.background};
      z-index: 100;
      transition: all 0.5s;
    }

    .usuario-container {
        display: flex;
        gap: 10px;
        align-items: center;
    }

.container span {
    font-size: 13px;
    color: ${({ theme }) => theme.subtitle};
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid ${({ theme }) => theme.border};
    cursor: pointer;
}

.container h3 {
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    color: ${({ theme }) => theme.title};
}

.notificacionesContainer {
    display: flex;
    gap: 20px;
}

.notificacionesContainer span {
    font-size: 20px;
    display: flex;
    color: #202020;
    cursor: pointer;
}

.realContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container-acciones {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    .icon-theme {
      font-size: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${({ theme }) => theme.little_icon};
    }

    .notificaciones-icono {
      display: flex;
      border-radius: 500px;
      //border: 1px solid #eaeaea;
      font-size: 20px;
      box-sizing: border-box;
      padding: 6px;
      cursor: pointer;
      position: relative;
      color: ${({ theme }) => theme.little_icon};

      :hover {
        opacity: 0.8;
      }
    }

    .notificaciones-contador {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 200px;
      background-color: ${leerColor(colores.rojo)};
      color: white;
      position: absolute;
      top: 18px;
      right: -8px;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      transition: all 0.5s;
    }

    .notificaciones-container {
      width: 400px;
      max-height: 400px;
      position: absolute;
      top: 40px;
      right: 0;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      border-radius: 6px;
      z-index: 199;
      box-sizing: border-box;
      font-family: "DM Sans", sans-serif;
      // border: 1px solid #eaeaea;
      background-color: ${({ theme }) => theme.background};
      display: flex;
      box-shadow: 0px 5px 30px ${({ theme }) => theme.border};
      transition: all 0.5s;

      .notificaciones-titulo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.border};
        transition: all 0.5s;

        span {
          font-size: 13px;
          color: ${({ theme }) => theme.subtitle};
          padding-right: 20px;
          cursor: pointer;

          :hover {
            opacity: 0.8;
          }
        }
      }

      .empty-state-notificaciones {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        box-sizing: border-box;
        padding: 80px 0;
        gap: 6px;

        h4 {
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          color: ${({ theme }) => theme.title};
        }
      }

      .tabs-container {
        padding: 20px;
      }

      .notif-container {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
      }

      .notif-item {
        font-weight: 500;
      }

      .notif {
        line-height: 1.5;
      }

      .empty-state-notificaciones-icono {
        font-size: 26px;
        color: ${leerColor(colores.azulRebranding)};
      }

      .empty-descripcion {
        color: ${leerColor(colores.grisEstados)};
        font-size: 14px;
        width: 80%;
        text-align: center;
      }

      h3 {
        margin: 0;
        padding: 20px;
        font-size: 16px;
        color: ${({ theme }) => theme.title};
      }

      .fecha-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        box-sizing: border-box;
        border-bottom: 1px solid ${({ theme }) => theme.border};

        span {
          background-color: red;
          box-sizing: border-box;
          padding: 10px 20px;
          background-color: #f8f8f8;
          font-weight: 500;
          color: ${leerColor(colores.grisEstados)};
          border-radius: 100px;
          font-size: 14px;
        }
      }

      .notificacion-item {
        display: flex;
        box-sizing: border-box;
        padding: 20px;
        border-bottom: 1px solid ${({ theme }) => theme.border};
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        transition: all 0.5s;

        .notificacion-contenido-container {
          display: flex;
          gap: 14px;
          align-items: flex-start;
        }

        .notificacion-titulo {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            margin: 0;
            font-size: 14px;
            color: ${({ theme }) => theme.title};
          }
        }

        .notificacion-contenido {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
        }

        .notificacion-hora {
          text-align: right;
        }

        .pagina-icono {
          font-size: 18px;
          box-sizing: border-box;
          border: 1px solid ${({ theme }) => theme.border};
          padding: 10px;
          display: flex;
          border-radius: 100%;
          transition: all 0.5s;
        }

        a {
          color: ${leerColor(colores.azulRebranding)};
          display: flex;
          align-items: center;
          gap: 4px;

          .notificacion-icono {
            display: flex;
          }
        }

        :last-of-type {
          border: none;
        }

        :hover {
          background-color: ${({ theme }) => theme.hover_background};
        }
      }
    }
  }

`;


