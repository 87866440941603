import Modal from "../../../Modal"
import { Container } from "./styled"
import Button from "../../../Button"
import { ModalDescargarEstadisticas } from "../../../../pages/styled"
import { Grid, Col } from "../../../Grid"

const ModalDescargaCSV = ({ isModalOpen, setIsModalOpen, descarga }) => {

    const { descargarEstadisticas, handleFormatoDescarga, formatoDescarga } = descarga

    return(
        <>
            {isModalOpen ?
                <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} hideCross={true}>
                    <ModalDescargarEstadisticas>
                        <h2>Elegir formato para descargar las estadísticas</h2>
                        <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={6}>
                            <div
                            className={
                                formatoDescarga === "excel"
                                ? "estadisticas-formato selected-formato"
                                : "estadisticas-formato"
                            }
                            onClick={() => handleFormatoDescarga("excel")}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path
                                fill={formatoDescarga === "excel" ? "#3289b9" : "#bab8b8"}
                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"
                                />
                            </svg>
                            <span>XLSX (Excel)</span>
                            </div>
                        </Col>
                        <Col desktop={6}>
                            <div
                            className={
                                formatoDescarga === "csv"
                                ? "estadisticas-formato selected-formato"
                                : "estadisticas-formato"
                            }
                            onClick={() => handleFormatoDescarga("csv")}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path
                                fill={formatoDescarga === "csv" ? "#3289b9" : "#bab8b8"}
                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 224H96c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8H96c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40V264c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16H198.4c-7.9 0-14.4 6.4-14.4 14.4c0 5.2 2.8 9.9 7.2 12.5l25.4 14.5c14.4 8.3 23.4 23.6 23.4 40.3c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4c0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5C160.9 302.4 152 287 152 270.4zM280 240v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                                />
                            </svg>
                            <span>CSV</span>
                            </div>
                        </Col>
                        </Grid>
                        <div className={"buttonsCont"}>
                        <Button
                            backgroundColor={false}
                            borderColor={true}
                            textColor={true}
                            icon={false}
                            onClick={() => setIsModalOpen(false)}
                        >
                            {" "}
                            Cancelar{" "}
                        </Button>
                        <Button
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={descargarEstadisticas}
                        >
                            {formatoDescarga
                            ? `Descargar como ${
                                formatoDescarga === "excel" ? ".xlsx (Excel)" : ".csv"
                                }`
                            : "Descargar"}
                        </Button>
                        </div>
                    </ModalDescargarEstadisticas>
                </Modal>
                : 
                null
            }
        </>
    )
}

export default ModalDescargaCSV