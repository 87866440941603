import styled, { css } from "styled-components";

export const TickWrapper = styled.div`
    border: 5px solid #3D9F54;
    padding: 42px;
    border-radius: 50%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 94px;
`;

export const SuccessTitle = styled.div`
   font-size:36px; color: #3D9F54;
    
  font-weight: 700;
`;