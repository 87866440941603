import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Visualización de primas realizadas a lo largo del año',
    },
  },
};

const BarChart = ({ data, optionsData }) => {
  return <Bar options={optionsData ? optionsData : options} data={data} />;
}

export default BarChart
