import axiosRequest from "../utils/axiosConfig";

// Descargar como ZIP
export const descargarZip = async (archivos) => {
   return await axiosRequest.post('/zip_files', archivos)
}

// Descargar como CSV / Excel
export const descargarCSV = async (csvData) => {
   return await axiosRequest.post("/generate-csv", csvData);
}
