import { useEffect, useState, useRef } from "react";
// Custom Hooks
import { desencriptarPago, getAutogestionArchivos, obtenerContrataciones } from "../services/Autogestion";
import { useLoading } from "./useLoading";
import usePagination from "./usePagination";
import downloadFile from "../utils/downloadFile";

export const useAutogestion = (filterParams, orderParams, handleModalPago) => {

    const [contrataciones, setContrataciones] = useState([])
    const [contratacionesLength, setContratacionesLength] = useState(null)
    const [pago, setPago] = useState(null)

    const { loading, setLoading } = useLoading(true)
    const modalLoading = useLoading(true)

    // Paginación
    const pagination = usePagination(contratacionesLength)

    // Filter ref
    const filtrosRef = useRef(null)

    const refreshContrataciones = async () => {
        setLoading(true)
        setContrataciones([])
        let paginacion = filtrosRef.current !== filterParams ? 1 : pagination.currentPage
        if(filtrosRef.current !== filterParams){
          filtrosRef.current = filterParams
          pagination.selectPage(1)
        }
        try {
          const contrataciones = await obtenerContrataciones(filterParams, orderParams, paginacion)
          const contratacionesLength = await obtenerContrataciones(filterParams, orderParams, null)
          setContrataciones(contrataciones)
          setContratacionesLength(contratacionesLength.length)
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
    }

    useEffect(() => {
        refreshContrataciones()
    }, [filterParams, orderParams, pagination.currentPage])

    const abrirModalPago = async (elem) => {
        setPago(null)
        modalLoading.setLoading(true)
        handleModalPago()

        try {
            let desencriptados = await desencriptarPago(elem.pago)
            const archivos = await getAutogestionArchivos(elem.id_operacion)
            const archivosDescargados = archivos.length > 0 ? await Promise.all(archivos.map(async (archivo) => {
              const file = await downloadFile(archivo.url)
              const type = archivo.url.split("/")[6]
              return { ...archivo, url: file, type: type }
            })) : []
            const data = {...elem}

            data.pago = {
              cbu: desencriptados.cbu_decrypted,
              marca_tarjeta: desencriptados.marca_tarjeta_decrypted,
              nro_tarjeta: desencriptados.nro_tarjeta_decrypted,
              nombre_titular: desencriptados.nombre_titular_decrypted,
              dni_titular: desencriptados.dni_titular_decrypted,
              vto_tarjeta: desencriptados.vto_tarjeta_decrypted,
              banco: desencriptados.banco_decrypted,
            }
            data.archivos = archivosDescargados
            setPago(data);
            modalLoading.setLoading(false)
        } catch (error) {
            console.log(error)
            modalLoading.setLoading(false)
        }
    };

    useEffect(() => {
      const getInitialData = async () => {
        if(!filterParams){
          try {
            const contrataciones = await obtenerContrataciones(null, null, 1)
            const contratacionesLength = await obtenerContrataciones(null, null, null)
            setContrataciones(contrataciones)
            setContratacionesLength(contratacionesLength.length)
            setLoading(false)
          } catch (error) {
              console.log(error)
          }
        }
    }
    getInitialData()
    }, [])

  return { 
    data: {
        contrataciones: contrataciones,
        refreshContrataciones: refreshContrataciones,
        pago: pago
    },
    loading,
    pagination,
    abrirModalPago,
    modalLoading: modalLoading.loading
  }
};
