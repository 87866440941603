import { Container, Input, Icon } from "./styled";
import { MdInfoOutline } from "react-icons/md";
// Components
import Autocomplete from "../Autocomplete"

function NewInputBasic({ labelName, type, name, placeholder, onChange, value, disabled, maxLength, color, checked, pattern, onBlur, showIcon, iconImage, iconCallback, labelBackground, autocomplete, autocompleteActive, setAutocomplete, onKeyDown, cleanAutocomplete }) {

  return (
    <Container labelBackground={labelBackground}>
      {labelName ?
      <label
        htmlFor={name}
        color={color}
      >
        {labelName}
      </label>
      : null}
    <input 
      type={type}
      name={name}
      color={color}
      id={name}
      placeholder={placeholder}
      checked={checked}
      onChange={onChange}
      value={value}
      disabled={disabled}
      maxLength={maxLength}
      onBlur={onBlur}
      onWheel={() => document.activeElement.blur()}
      onKeyDown={onKeyDown}
      pattern={pattern}
    />

      {iconImage && 
        <Icon>
          {typeof(icon) === "string" ?
              <img className="tooltip" src={iconImage} alt="icono" />
            :
              <span className="size" onClick={iconCallback}>{iconImage}</span>
          }
        </Icon>
      }

    {autocomplete?.length ?
      <Autocomplete autocomplete={autocomplete} autocompleteActive={autocompleteActive} setAutocomplete={setAutocomplete} name={name} cleanAutocomplete={cleanAutocomplete} />
    : null}
  </Container>
  )
}

export default NewInputBasic;
