import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../../constants';

export const Container = styled.div`
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }

  p {
    margin: 0 0 30px 0;
  }

  h3 {
    margin-top: 0;
    color: ${leerColor(colores.celeste)}
  }

  h4 {
    margin: 10px 0;
  }

  .container-botones {
    display: flex;
    gap: 10px;
  }

  .data-container {
    padding: 20px 0;
    //border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .data-icon {
    font-size: 24px;
  }
`

export const ContainerInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 60px;
  //border-top: 1px solid #eaeaea;
  padding: 120px 0;

  p {
    margin: 0;
  }

  strong {
    color: ${leerColor(colores.azul)}
  }
`

export const Icon = styled.span`
    font-size: 60px;
    display: flex;
    color: ${leerColor(colores.celeste)}
`