import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  InputTextarea,
  ProgressBar,
  Menu,
  Wrapper,
  Modal,
  NewProgressBar,
  BurgerMenu,
  NavMenu,
  InputCalendar,
} from "../components/Index";
import {
  ClearFilter,
  ClearFilterContainer,
  EditOperadorIcon,
  SolEmisionTitleContainer,
  ModalEditarOperador,
  ModalBorrarExcepcion,
  OperadorItem,
  GridCumplimientoContainer,
  GridCumplimientoContainerHeader,
} from "./styled";
import axiosRequest from "../utils/axiosConfig";
import editIcon from "../assets/iconos/edit.svg";
import IconButton from "../components/IconButton";
import { ModalDescargarEstadisticas } from "./styled";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";

const ObjetivosPorVendedor = () => {
  // Context
  const authContext = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const allowedRoles = [1, 4, 13, 14];

  const history = useHistory();

  // Chequear si el usuario está autenticado, caso contrario lo manda al login.
  useEffect(() => {
    if (!authContext.isAuthenticated()) {
      history.push("/");
    }
  }, []);

  // Chequear el rol del usuario autenticado para saber si puede acceder a la página
  useEffect(() => {
    if (user) {
      if (!allowedRoles.includes(user.id_rol)) {
        history.push("/");
      }
    }
  }, []);

  // State que abre y cierra el menú de navegación
  const [open, setOpen] = useState(false);
  // State que almacena la data que se muestra en la tabla
  const [data, setData] = useState([]);
  // State que guarda los params que se van a utilizar para filtrar en el backend
  const [filtro, setFiltro] = useState({
    desde: "",
    hasta: "",
    vendedor: ""
  });
  // State para definir si existen resultados o no (si está en true muestra el loading)
  const [hayBusquedas, setHayBusquedas] = useState(false);
  const [pageSelected, setPageSelected] = useState(1);
  // desactiva el boton
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [error, setError] = useState({});
  const [btnLimpiar, setBtnLimpiar] = useState(false);



  // Headers de la tabla
  const headers = [
    "Nombre",
    "Ops. Mes Actual",
    "Prima Mes Actual",
    "Ops. Mes Ant.",
    "Prima Mes Ant.",
  ];

  // Array de objetos con los inputs que funcionan como filtros.
  const inputs = [
    {
      name: "vendedor",
      labelName: "Vendedor",
      component: InputSelect,
    },
  ];

  const addField = (e) => {
    const { name, value } = e.target;
    setFiltro({ ...filtro, [name]: value });
  };


  const buscar = async () => {
    setBtnDisabled(true);
    try {
      const send = await axiosRequest.get(
        `/objetivos_por_vendedor?desde=${filtro.desde}&hasta=${filtro.hasta}`
      );

      send.data.forEach((elem) => {
        elem.prima_mes_actual = '$ '+precioFormato(elem.prima_mes_actual)
        elem.prima_mes_ant = '$ '+precioFormato(elem.prima_mes_ant)

      })

      setData(send.data);
      if (send.data.length === 0) {
        setError({ type: "warning", msg: "No se encontraron coincidencias" });
      }
    } catch (error) {
      setError({ type: "error", msg: error });
    } finally {
      setBtnDisabled(false);
    }
  };

  const filtroInicial = {
    desde: "",
    hasta: "",
    vendedor: "",
  };


  const limpiarForm = () => {
    setFiltro(filtroInicial);
    setData([]);
    setBtnLimpiar(false);
    setBtnDisabled(true);
  };

  const precioFormato = function (number) {
    return new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };


  return (
    <div>
      <BurgerMenu open={open} setOpen={setOpen} />
      <NavMenu
        open={open}
        setOpen={setOpen}
        user={user}
        active={"objetivos-por-vendedor"}
      />

      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
              <SolEmisionTitleContainer>
                <Titulo textColor={false}>Objetivos Por Vendedor</Titulo>
              </SolEmisionTitleContainer>
            </Col>
            {/* <Col desktop={12}>
              <Subtitulo>
                Contrataciones hechas a través de las landings
              </Subtitulo>
            </Col> */}
            <Col desktop={3}>
              <InputCalendar
                type={"date"}
                name={"desde"}
                labelName={"Desde"}
                value={filtro.desde}
                onChange={addField}
              />
            </Col>
            <Col desktop={3}>
              <InputCalendar
                type={"date"}
                name={"hasta"}
                labelName={"Hasta"}
                value={filtro.hasta}
                onChange={addField}
              />
            </Col>

            <Col desktop={3}>
              <InputSelect
                name={"vendedor"}
                labelName={"Vendedor"}
                // options={productos}
                value={filtro.vendedor}
                onChange={addField}
              />
            </Col>
            <Col desktop={12}>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={buscar}
                disabled={btnDisabled}
              >
                Buscar
              </Button>
              {btnLimpiar ? (
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={limpiarForm}
                >
                  Limpiar
                </Button>
              ) : null}
            </Col>
          </Grid>
        </Inner>
        <InfoBoard
          borderTop={true}
          hayBusquedas={hayBusquedas}
          headers={headers}
          data={data}
          loading={hayBusquedas}
          page="Objetivos Por Vendedor"
        />
      </Wrapper>
    </div>
  );
};

export default ObjetivosPorVendedor;
