import React, { useState, useEffect } from "react";
import {
  Subtitulo,
  Col,
  InputSelect,
  Button,
} from "../../../Index";
//import axios from "axios";
import axiosRequest from '../../../../utils/axiosConfig'
import { Table } from "../../../Table/styled";

const Vendedores = ({mesesSelect, vendedoresSelect}) => {

    // estructura del filtro
    const filtroStruct = {
        vendedor: '',
        mes: ''
    }
    // desactiva el boton
    const [btnDisabled, setBtnDisabled] = useState(false)

    // estructura tabla
    const [datosTabla, setDatosTabla] = useState([]);

    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState({...filtroStruct})
    const [idVendedor, setIdVendedor] = useState(-1)

    const getVentas = async (vendedorId,compania,mes) => {
        setLoading(true)
        try {   
            const send = await axiosRequest.get(`/vendedores/ventas/?compania=${compania}&vendedor=${vendedorId}&mes=${mes}`);
            setDatosTabla(send.data.msg[0].productos)
            setIdVendedor(send.data.msg[0].id_vendedor)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }


    const filtrarVentas = (e)=>{
        const {name, value} = e.target;
        setFiltro({...filtro,[name]: value})
    }

    const downloadExcel = async (vendedorId,compania,mes) => {
        const nombre = vendedoresSelect.find(v => (v.value === vendedorId))
        const send = await axiosRequest({
            method:'GET',
            url: `/vendedores/ventas/?download=true&compania=${compania}&vendedor=${vendedorId}&mes=${mes}`,      
            responseType: 'blob', 
        });
        if(send.status === 200){
            let url;
            var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
            if ( isChrome ){
                url = window.webkitURL.createObjectURL(new Blob([send.data]));
            } else {
                url = window.URL.createObjectURL(new Blob([send.data]));
            }
            
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `emi_sw_${nombre.label||''}_${Date.now()}.xlsx`);
            document.body.appendChild(link)
            link.click();
        }
        setBtnDisabled(false)
    }

    const startDownload = () => {
        setBtnDisabled(true)
        downloadExcel(parseInt(filtro.vendedor),5,parseInt(filtro.mes))
    }

    useEffect(()=>{
        if(filtro.mes!=='' && filtro.vendedor!==''){
            getVentas(parseInt(filtro.vendedor),5,parseInt(filtro.mes))
        }
    },[filtro]);

    return(
        <>
                            
        <Col desktop={12} spaced={true}>
            <Subtitulo>Filtrar</Subtitulo>
            {
                datosTabla.length!==0?
                <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={startDownload} disabled={btnDisabled} >
                    {btnDisabled?'Descargando... ' : 'Descargar'}
                </Button>
                :
                null
            }
        </Col>
        <Col desktop={6}>
                    <InputSelect
                        name={"vendedor"}
                        labelName={"Vendedor"}
                        id={"vendedor"}
                        options={vendedoresSelect}
                        onChange={filtrarVentas}
                        value={filtro.vendedor}
                    />
        </Col>
        <Col desktop={6}>
                    <InputSelect
                        name={"mes"}
                        labelName={"Mes a Comparar"}
                        id={"mes"}
                        options={mesesSelect}
                        onChange={filtrarVentas}
                        value={filtro.mes}
                    />
        </Col>
        <Col desktop={12}>
            {
                            !loading?
                            <Col desktop={12}>
                            {
                                datosTabla.length!==0?
                                <div>
                                    {
                                        datosTabla.map(info => {
                                            return(
                                            <div key={info.id_producto}>
                                            <Subtitulo>{info.descripcion}</Subtitulo>
                                            <Table >
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Marzo</th>
                                                    <th>Mes Actual</th>
                                                    <th>Diferencia</th>
                                                    {idVendedor===99999?
                                                    null :
                                                    <th>{info.id_producto===9999?'Comisión Real':'Comisión'}</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Ventas</td>
                                                    <td>{info.vendidosMarzo}</td>
                                                    <td>{info.vendidosMesActual}</td>
                                                    <td className={info.vendidosDiferencia > 0? 'verde':'rojo'}>{info.vendidosDiferencia}</td>
                                                    {idVendedor===99999?
                                                    null :
                                                    <td className={info.comision > 0? 'verde':'rojo'}>{info.comision}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>Prima</td>
                                                    <td>{info.primaMarzo}</td>
                                                    <td>{info.primaMesActual}</td>
                                                    <td className={info.primaDiferencia > 0? 'verde':'rojo'}>{info.primaDiferencia}</td>
                                                </tr>
                                            </tbody>
                                            </Table>
                                        </div>
                                        )})
                                    }
                                    
                            </div>
                            : 
                            'Seleccione un productor y un mes'
                            }
                            
                        </Col>
                        :
                        <Col desktop={12}><h4>Obteniendo datos...</h4></Col>
                }
        </Col>
        
        </>
    )
}

export default Vendedores;
