import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Info = styled.div`
   
  padding-top: 40px;

  div.button{
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 0;
  }
  .datosPersonales, .polizasVigentes, .consultasAbiertas {
    width: 100%;
    border-bottom: 1px solid ${leerColor(colores.gris)};
    padding: 10px 0 20px;
  }
  .consultasAbiertas {
    border-bottom: none;
  }
  .datosPersonales .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    div {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 16px 0;
      span {
        width: 50px;
        padding-right: 10px;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
      }
      p {
        margin: 0;
        padding: 0 10px;
        border-right: 1px solid ${leerColor(colores.grisEstados)};
      }
      p:first-of-type {
        padding-left: 0;
        font-weight: 600;
      }
      p:last-of-type {
        border-right: 0;
      }
    }
  }
  .polizasVigentes .grid, .consultasAbierta .grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const PolizaConsultas = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 16px 0;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .proximoVencimiento{
    position: relative;
    color: ${leerColor(colores.rojo)};
    svg path{
      fill: ${leerColor(colores.rojo)};
    }
    p{
      border-right-color: ${leerColor(colores.rojo)};
    }
  }
  .proximoVencimiento::before{
    content: '•';
    color: hsl(0,100%,56.5%);
    font-size: 46px;
    position: absolute;
    left: -10%;
  }
  span {
    width: 50px;
    padding-right: 10px;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
  }
  p {
    margin: 0;
    padding: 0 10px;
    border-right: 1px solid ${leerColor(colores.grisEstados)};
  }
  p:first-of-type {
    padding-left: 0;
    font-weight: 600;
  }
  p:last-of-type {
    border-right: 0;
  }
  a {
    text-decoration: none;
  }
  ${(props) =>
    props.icon &&
    css`
      a::after {
        content: url(${(props) => props.icon});
        padding-left: 10px;
        display: inline-block;
        height: 20px;
        width: 20px;
      }
    `}
`;
