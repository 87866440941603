import styled, { css } from 'styled-components';

export const Cobertura = styled.div`
  display: flex-wrap;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.subtitle};

  span {
    font-size: 14px;
    font-weight: 500;
  }

  .cobertura-nombre {
    margin-bottom: 10px;
    display: flex;
  }
`;

export const CoberturasContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
`;
