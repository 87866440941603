import { useEffect, useState } from "react";
import { postBienAseguradoData, postPagoData } from "../services/Emision";
import axiosRequest from "../utils/axiosConfig";
import sendFiles from "../utils/uploadFile";

export const useAnulacion = () => {

  const postearAnulacion = async (anulacion, idSolicitud) => {
      /*
        let poliza = null
        let bodyFormData = new FormData();
        try {
          bodyFormData.append('recfile', anulacion.poliza.image, anulacion.poliza.name);
          const createDoc = await axiosRequest.post('/anulacion_solicitudes/cargar', bodyFormData);
          let path = createDoc.data[0].path
          poliza = path
        } catch (error) {
          console.log(error)
          throw new Error()
        }
        */

        const anulacionData = {
          //poliza: poliza,
          id_compania: anulacion.id_compania,
          numero_poliza: anulacion.numero_poliza,
          id_solicitud: idSolicitud
        };
    
        try{
            const anulacion = await axiosRequest.post('/anulacion_solicitudes', anulacionData)
            return anulacion
        } catch (error){
            console.log(error)
        }
      
  }

  const editarAnulacion = async (values, anulacion) => {
    if(anulacion){
      const anulacionData = {
        id_compania: values.id_compania,
        numero_poliza: values.numero_poliza,
        //poliza: values.poliza
      };

      /*
      if(anulacion.poliza !== values.poliza){
        let bodyFormData = new FormData();
        try {
          bodyFormData.append('recfile', values.poliza.image, values.poliza.name);
          const createDoc = await axiosRequest.post('/anulacion_solicitudes/cargar', bodyFormData);
          let path = createDoc.data[0].path
          anulacionData.poliza = path
        } catch (error) {
          console.log(error)
          throw new Error()
        }
      }
      */

      try{
          const editAnulacion = await axiosRequest.put(`/anulacion_solicitudes/${anulacion.id_anulacion_solicitud}`, anulacionData)
          return editAnulacion
      } catch (error){
          console.log(error)
      }
    }
  }

  return { 
    postearAnulacion,
    editarAnulacion
  }
};
