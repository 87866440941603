import React, { useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { DARK_THEME, LIGHT_THEME } from '../constants/themes'

const ThemeContext = React.createContext()

export function useTheme() {
  return useContext(ThemeContext)
}

export function ThemeModeProvider({ children }) {
  const [theme, setTheme] = useState('light');
  const themeMode = theme === "light" ? LIGHT_THEME : DARK_THEME

  const handleToggleTheme = (mode) => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    localTheme && setTheme(localTheme)
  }, []);
  
  return (
    <ThemeContext.Provider value={{ theme, handleToggleTheme }}>
      <ThemeProvider theme={themeMode}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}