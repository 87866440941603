import { LoadingContainer } from './styled'

const SpinnerLoading = ({text, margin}) => {
    return(
        <LoadingContainer margin={margin} >
            <div className={'lds-ring'}><div></div><div></div><div></div><div></div></div>
            {text && <span>{text}</span>}
        </LoadingContainer>
    )
}

export default SpinnerLoading