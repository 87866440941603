import { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import formatSelectData from "../../../utils/formatSelectData";
import orderAlphabetically from "../../../utils/alphabeticalOrder";
import { Col, Grid } from "../../../components/Grid";
import Subtitle from "../../../components/Subtitle";
import {
  FiArrowLeft,
  FiCalendar,
  FiSun,
  FiThumbsUp,
  FiUser,
} from "react-icons/fi";
import NewInputSelect from "../../../components/NewInputSelect";
import Divider from "../../../components/Divider";
import NewInputCalendar from "../../../components/NewInputCalendar";
import NewInputBasic from "../../../components/NewInputBasic";
import NewButton from "../../../components/NewButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "../../../hooks/useForm";
import { excepcionReglas } from "../../../utils/formRules";
import { excepcionForm } from "../../../utils/formInitialValues";
import ErrorInput from "../../../components/ErrorInput";
import BackButton from "../../../components/BackButton";
import Layout from "../../../components/Layout";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import dayjs from "dayjs";
import Success from "../../../components/Success";
import InputCheckbox from "../../../components/InputCheckbox";
import CardExcepcion from "../../../components/CardExcepcion";

const CrearExcepcion = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [tiposGoceSueldo, setTiposGoceSueldo] = useState([]);
  const [success, setSuccess] = useState(false);
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleChangeCheckbox,
    formErrors,
    handleValidation,
  } = useForm(handleSubmit, excepcionReglas, excepcionForm);

  useEffect(() => {
    const getTiposGoceSueldo = async () => {
      try {
        const tipos = await axiosRequest.get(`/excepciones/tipos_goce_sueldo`);
        const tiposFormatted = formatSelectData(
          tipos.data,
          "id_tipo_goce_sueldo",
          "tipo",
          "id_tipo_goce_sueldo"
        );
        setTiposGoceSueldo(tiposFormatted);
      } catch (error) {
        console.log(error);
      }
    };
    getTiposGoceSueldo();
  }, []);

  async function handleSubmit() {
    const day = dayjs(new Date()).format("YYYY-MM-DD");

    const dataExcepcion = {
      id_usuario_excepcion: inputValues.usuario,
      tipo_excepcion: inputValues.tipo,
      periodo_excepcion: inputValues.periodo,
      periodo_dia_excepcion: inputValues.dia ? inputValues.dia : null,
      periodo_fecha_excepcion: inputValues.fecha ? inputValues.fecha : null,
      periodo_fecha_desde_excepcion: inputValues.fecha_desde
        ? inputValues.fecha_desde
        : null,
      periodo_fecha_hasta_excepcion: inputValues.fecha_hasta
        ? inputValues.fecha_hasta
        : null,
      hora_excepcion: inputValues.hora,
      hora_desde_excepcion: inputValues.hora_desde
        ? dayjs(new Date(`${day} ${inputValues.hora_desde}`)).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : null,
      hora_hasta_excepcion: inputValues.hora_hasta
        ? dayjs(new Date(`${day} ${inputValues.hora_hasta}`)).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : null,
      goce_sueldo: inputValues.check_goce_sueldo ? 1 : 0,
      motivo_sin_goce_sueldo: inputValues.motivo_sin_goce_sueldo
        ? inputValues.motivo_sin_goce_sueldo
        : null,
      motivo_acuerdo: inputValues.motivo_acuerdo
        ? inputValues.motivo_acuerdo
        : null,
      tipo_goce_sueldo: inputValues.tipo_goce_sueldo
        ? inputValues.tipo_goce_sueldo
        : null,
      tipo_goce_sueldo_otros: inputValues.tipo_goce_sueldo_otros
        ? inputValues.tipo_goce_sueldo_otros
        : null,
      estado_excepcion: 1,
    };
    const excepcion = await axiosRequest.post("/excepcion", dataExcepcion);
    setSuccess(true);
  }

  useEffect(() => {
    const getUsuarios = async () => {
      try {
        const usuarios = await axiosRequest.get("/operadores");
        const filterUsuarios = usuarios.data.filter(
          (usuario) => !usuario.nombre_operador.toLowerCase().includes("prueba")
        );
        const formatUsuarios = formatSelectData(
          filterUsuarios,
          "id_usuario",
          ["nombre_operador", "apellido_operador"],
          "id_usuario"
        );
        setUsuarios(formatUsuarios.sort(orderAlphabetically));
      } catch (error) {
        console.log(error);
      }
    };
    getUsuarios();
  }, []);

  const ID_OTROS_TIPO_GOCE_SUELDO = 7;

  const PERIODOS_EXCEPCIONES = [
    {
      label: "Desde / hasta",
      value: "Desde / Hasta",
      key: 1,
    },
    {
      label: "Fecha",
      value: "Fecha",
      key: 2,
    },
    {
      label: "Día",
      value: "Día",
      key: 3,
    },
  ];

  const DIAS_EXCEPCIONES = [
    {
      label: "Lunes",
      value: 1,
      key: 1,
    },
    {
      label: "Martes",
      value: 2,
      key: 2,
    },
    {
      label: "Miércoles",
      value: 3,
      key: 3,
    },
    {
      label: "Jueves",
      value: 4,
      key: 4,
    },
    {
      label: "Viernes",
      value: 5,
      key: 5,
    },
  ];

  const TIPOS_EXCEPCIONES_ICONS = {
    vacaciones: <FiSun />,
    acuerdo: <FiThumbsUp />,
    licencia: <FiCalendar />,
  };

  return (
    <div>
      {/* <Sidebar /> */}
      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <div>
                <BackButton url={"/NewFichajes"} text="Fichajes" />
                <NewTitlePageSection
                  title="Crear Excepción"
                  description="Agregá excepciones para los fichajes de los usuarios"
                />
              </div>
              <Divider />

              {!success ? (
                <>
                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={9}>
                      <Subtitle
                        title="Usuario"
                        description="Elegí el usuario para el cuál crear la excepción"
                        icon={<FiUser />}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={3}>
                      <NewInputSelect
                        name={"usuario"}
                        onChange={handleChange}
                        placeholder={"Usuario"}
                        options={usuarios}
                        value={inputValues.usuario}
                      />
                      {formErrors.usuario && (
                        <ErrorInput className="error">
                          {formErrors.usuario}
                        </ErrorInput>
                      )}
                    </Col>
                  </Grid>

                  <Divider />

                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={12}>
                      <Subtitle
                        title="Tipo de excepción"
                        description="Seleccioná cuál es el tipo de excepción para el usuario"
                        icon={<FiUser />}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={3}>
                      <div
                        className={`excepcion-container ${
                          inputValues.tipo === "Vacaciones" && "selected"
                        }`}
                        onClick={() =>
                          handleChangeExternal("tipo", "Vacaciones")
                        }
                      >
                        <span className="icon">
                          <FiSun />
                        </span>
                        <span>Vacaciones</span>
                      </div>
                    </Col>
                    <Col desktop={3}>
                      <div
                        className={`excepcion-container ${
                          inputValues.tipo === "Acuerdo" && "selected"
                        }`}
                        onClick={() => handleChangeExternal("tipo", "Acuerdo")}
                      >
                        <span className="icon">
                          <FiThumbsUp />
                        </span>
                        <span>Acuerdo</span>
                      </div>
                    </Col>
                    <Col desktop={3}>
                      <div
                        className={`excepcion-container ${
                          inputValues.tipo === "Licencia" && "selected"
                        }`}
                        onClick={() => handleChangeExternal("tipo", "Licencia")}
                      >
                        <span className="icon">
                          <FiCalendar />
                        </span>
                        <span>Licencia</span>
                      </div>
                    </Col>
                    {formErrors.tipo && (
                      <Col desktop={12}>
                        <ErrorInput className="error">
                          {formErrors.tipo}
                        </ErrorInput>
                      </Col>
                    )}

                    {inputValues.tipo === "Acuerdo" ? (
                      <Col desktop={12}>
                        <Grid colGap={21} rowGap={21} narrow={false}>
                          <Col desktop={3}>
                            <NewInputBasic
                              labelName={"Motivo del acuerdo"}
                              name={"motivo_acuerdo"}
                              placeholder={"Turno médico"}
                              onChange={handleChange}
                              value={inputValues.motivo_acuerdo}
                            />
                            {formErrors.motivo_acuerdo && (
                              <ErrorInput className="error">
                                {formErrors.motivo_acuerdo}
                              </ErrorInput>
                            )}
                          </Col>
                        </Grid>
                      </Col>
                    ) : null}

                    {inputValues.tipo === "Licencia" ? (
                      <Col desktop={12}>
                        <Grid colGap={21} rowGap={21} narrow={false}>
                          <Col desktop={12}>
                            <InputCheckbox
                              className={"inputCheckbox"}
                              type={"checkbox"}
                              name={"check_goce_sueldo"}
                              labelName={"Con goce de sueldo"}
                              onChange={handleChangeCheckbox}
                              checked={inputValues.check_goce_sueldo}
                            />
                          </Col>
                          <Col desktop={3}>
                            <NewInputBasic
                              labelName={"Motivo sin goce (opcional)"}
                              name={"motivo_sin_goce_sueldo"}
                              placeholder={"Motivo sin goce de sueldo"}
                              onChange={handleChange}
                              value={inputValues.motivo_sin_goce_sueldo}
                            />
                            {formErrors.motivo_sin_goce_sueldo && (
                              <ErrorInput className="error">
                                {formErrors.motivo_sin_goce_sueldo}
                              </ErrorInput>
                            )}
                          </Col>
                          <Col desktop={3}>
                            <NewInputSelect
                              name={"tipo_goce_sueldo"}
                              labelName={"Tipo goce sueldo"}
                              onChange={handleChange}
                              placeholder={"Tipo goce sueldo"}
                              options={tiposGoceSueldo}
                              value={inputValues.tipo_goce_sueldo}
                            />
                            {formErrors.tipo_goce_sueldo && (
                              <ErrorInput className="error">
                                {formErrors.tipo_goce_sueldo}
                              </ErrorInput>
                            )}
                          </Col>

                          {parseInt(inputValues.tipo_goce_sueldo) ===
                          ID_OTROS_TIPO_GOCE_SUELDO ? (
                            <Col desktop={3}>
                              <NewInputBasic
                                labelName={"Observación"}
                                name={"tipo_goce_sueldo_otros"}
                                placeholder={"Observación"}
                                onChange={handleChange}
                                value={inputValues.tipo_goce_sueldo_otros}
                              />
                              {formErrors.tipo_goce_sueldo_otros && (
                                <ErrorInput className="error">
                                  {formErrors.tipo_goce_sueldo_otros}
                                </ErrorInput>
                              )}
                            </Col>
                          ) : null}
                        </Grid>
                      </Col>
                    ) : null}
                  </Grid>

                  <Divider />

                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={12}>
                      <Subtitle
                        title="Periodo"
                        description="Defíni desde cuando se va a establecer la excepción"
                        icon={<FiUser />}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={3}>
                      <NewInputSelect
                        name={"periodo"}
                        labelName={"Periodo"}
                        onChange={handleChange}
                        placeholder={"Periodo"}
                        options={PERIODOS_EXCEPCIONES}
                        value={inputValues.periodo}
                      />
                      {formErrors.periodo && (
                        <ErrorInput className="error">
                          {formErrors.periodo}
                        </ErrorInput>
                      )}
                    </Col>
                    {inputValues.periodo === "Desde / Hasta" ? (
                      <>
                        <Col desktop={3}>
                          <NewInputCalendar
                            labelName={"Fecha Desde"}
                            name={"fecha_desde"}
                            onChange={handleChange}
                            value={inputValues.fecha_desde}
                          />
                          {formErrors.fecha_desde && (
                            <ErrorInput className="error">
                              {formErrors.fecha_desde}
                            </ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputCalendar
                            labelName={"Fecha Hasta"}
                            name={"fecha_hasta"}
                            onChange={handleChange}
                            value={inputValues.fecha_hasta}
                          />
                          {formErrors.fecha_hasta && (
                            <ErrorInput className="error">
                              {formErrors.fecha_hasta}
                            </ErrorInput>
                          )}
                        </Col>
                      </>
                    ) : null}
                    {inputValues.periodo === "Fecha" ? (
                      <Col desktop={3}>
                        <NewInputCalendar
                          labelName={"Fecha"}
                          name={"fecha"}
                          onChange={handleChange}
                          value={inputValues.fecha}
                        />
                        {formErrors.fecha && (
                          <ErrorInput className="error">
                            {formErrors.fecha}
                          </ErrorInput>
                        )}
                      </Col>
                    ) : null}
                    {inputValues.periodo === "Día" ? (
                      <>
                        <Col desktop={3}>
                          <NewInputSelect
                            name={"dia"}
                            labelName={"Día"}
                            onChange={handleChange}
                            placeholder={"Día"}
                            options={DIAS_EXCEPCIONES}
                            value={inputValues.dia}
                          />
                          {formErrors.dia && (
                            <ErrorInput className="error">
                              {formErrors.dia}
                            </ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputCalendar
                            labelName={"Fecha Desde"}
                            name={"fecha_desde"}
                            onChange={handleChange}
                            value={inputValues.fecha_desde}
                          />
                          {formErrors.fecha_desde && (
                            <ErrorInput className="error">
                              {formErrors.fecha_desde}
                            </ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputCalendar
                            labelName={"Fecha Hasta"}
                            name={"fecha_hasta"}
                            onChange={handleChange}
                            value={inputValues.fecha_hasta}
                          />
                          {formErrors.fecha_hasta && (
                            <ErrorInput className="error">
                              {formErrors.fecha_hasta}
                            </ErrorInput>
                          )}
                        </Col>
                      </>
                    ) : null}
                  </Grid>

                  <Divider />

                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={12}>
                      <Subtitle
                        title="Horario"
                        description="Establecé el horario para la excepción"
                        icon={<FiUser />}
                        disableDivider={true}
                      />
                    </Col>
                    {/* <Col desktop={3}>
                <NewInputSelect
                  name={"hora"}
                  labelName={"Tipo de horario"}
                  onChange={handleChange}
                  placeholder={"Tipo de horario"}
                  options={HORARIOS_EXCEPCIONES}
                  value={inputValues.hora}
                />
                {formErrors.hora && (
                  <ErrorInput className="error">{formErrors.hora}</ErrorInput>
                )}
              </Col> */}

                    <Col desktop={3}>
                      <NewInputBasic
                        labelName={"Hora Desde"}
                        name={"hora_desde"}
                        placeholder={"09:00"}
                        onChange={handleChange}
                        value={inputValues.hora_desde}
                      />
                      {formErrors.hora_desde && (
                        <ErrorInput className="error">
                          {formErrors.hora_desde}
                        </ErrorInput>
                      )}
                    </Col>
                    <Col desktop={3}>
                      <NewInputBasic
                        labelName={"Hora Hasta"}
                        name={"hora_hasta"}
                        placeholder={"18:00"}
                        onChange={handleChange}
                        value={inputValues.hora_hasta}
                      />
                      {formErrors.hora_hasta && (
                        <ErrorInput className="error">
                          {formErrors.hora_hasta}
                        </ErrorInput>
                      )}
                    </Col>
                  </Grid>

                  <Divider />

                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={12}>
                      <Subtitle
                        title="Excepción"
                        description="Revisá los datos cargados antes de publicar la excepción"
                        icon={<FiUser />}
                        disableDivider={true}
                      />
                    </Col>
                    {inputValues.usuario &&
                    inputValues.tipo &&
                    inputValues.periodo ? (
                      <Col desktop={4}>
                        <CardExcepcion
                          excepcion={{
                            id_usuario_excepcion: inputValues.usuario,
                            tipo_excepcion: inputValues.tipo,
                            periodo_excepcion: inputValues.periodo,
                            periodo_dia_excepcion: inputValues.dia
                              ? inputValues.dia
                              : null,
                            periodo_fecha_excepcion: inputValues.fecha
                              ? inputValues.fecha
                              : null,
                            periodo_fecha_desde_excepcion:
                              inputValues.fecha_desde
                                ? inputValues.fecha_desde
                                : null,
                            periodo_fecha_hasta_excepcion:
                              inputValues.fecha_hasta
                                ? inputValues.fecha_hasta
                                : null,
                            hora_excepcion: inputValues.hora,
                            hora_desde_excepcion: inputValues.hora_desde
                              ? inputValues.hora_desde
                              : null,
                            hora_hasta_excepcion: inputValues.hora_hasta
                              ? inputValues.hora_hasta
                              : null,
                            goce_sueldo: inputValues.check_goce_sueldo ? 1 : 0,
                            motivo_sin_goce_sueldo:
                              inputValues.motivo_sin_goce_sueldo
                                ? inputValues.motivo_sin_goce_sueldo
                                : null,
                            motivo_acuerdo: inputValues.motivo_acuerdo
                              ? inputValues.motivo_acuerdo
                              : null,
                            tipo_goce_sueldo: inputValues.tipo_goce_sueldo
                              ? inputValues.tipo_goce_sueldo
                              : null,
                            tipo_goce_sueldo_otros:
                              inputValues.tipo_goce_sueldo_otros
                                ? inputValues.tipo_goce_sueldo_otros
                                : null,
                            estado_excepcion: 1,
                          }}
                        />
                      </Col>
                    ) : null}
                  </Grid>

                  <Divider />

                  <div className="buttons-container">
                    <NewButton
                      backgroundColor={true}
                      onClick={handleValidation}
                    >
                      Crear Excepción
                    </NewButton>
                    <Link to="/NewFichajes">
                      <NewButton borderColor={true} textColor={true}>
                        <FiArrowLeft /> Volver a Fichajes
                      </NewButton>
                    </Link>
                  </div>
                </>
              ) : (
                <Success
                  title="¡Excepción creada!"
                  description={`La nueva excepción fue cargada correctamente`}
                  link={"/NewFichajes"}
                  page="Fichajes"
                />
              )}
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </div>
  );
};

export default CrearExcepcion;
