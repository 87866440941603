import React from "react";
import { DivRowContainer } from "./styled";
// Components

function InputRowContainer({ children, title }) {
  if (title){
    return(<DivRowContainer>
      {children.map(chil => (
        <div>
          {chil}
        </div>
      ))}
    </DivRowContainer>)
  }
  return (
    <DivRowContainer>
      {children}
    </DivRowContainer>
  )
}

export default InputRowContainer;
