import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const Container = styled.div`
  .divider {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 40px 0 40px 0;
  }

  .tipo-solicitud {
    border-radius: 6px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.subtitle};
    border: 1px solid ${({ theme }) => theme.border};
    cursor: pointer;
    box-sizing: border-box;
    height: 193.5px;
    transition: all 0.5s;

    .descripcion {
      font-size: 12px;
    }

    :hover {
      opacity: 0.6;
    }
  }

  .icon {
    display: flex;
    font-size: 24px;
  }

  .selected {
    border: 1px solid ${leerColor(colores.azulRebranding)};
    .icon {
      color: ${leerColor(colores.azulRebranding)};
    }
    .titulo {
      color: ${({ theme }) => theme.title};
      font-weight: 500;
    }
  }

  .not-selected {
    .icon, .titulo, .descripcion {
      color: ${leerColor(colores.gris)};
    }
    border: 1px solid ${({ theme }) => theme.border};
  }

  .down {
    align-items: end;
  }

  p {
    margin: 0;
  }

  .recordatorio {
    font-size: 12px;
    color: ${({ theme }) => theme.subtitle};
    margin-top: 10px;
  }

  hr {
    margin: 10px 0;
  }

  h3 {
    margin-top: 0;
    color: ${leerColor(colores.celeste)}
  }

  h4 {
    margin: 10px 0;
  }

  .cont {
    width: 100%;
    display: flex;
    //justify-content: center;
  }

  .container-botones {
    display: flex;
    gap: 10px;
  }

  .data-container {
    padding: 20px 0;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .data-icon {
    font-size: 24px;
  }
`

export const ContainerInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0;
  gap: 10px;

  h3 {
    color: ${({ theme }) => theme.title} !important;
    margin: 0;
    font-weight: 500;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;
  }

  strong {
    color: ${leerColor(colores.azul)}
  }
`

export const Icon = styled.span`
    font-size: 60px;
    display: flex;
    color: ${leerColor(colores.azulRebranding)}
`