import axiosRequest from "../utils/axiosConfig";
import formatSelectData from "../utils/formatSelectData";

// Obtener contrataciones
export const obtenerObjetivosLeads = async (filterParams, orderParams, page) => {
    const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
    const objetivosLeadsData = await axiosRequest.get(`/objetivos?${pageFilter}${filterParams}&${orderParams}`)
    return objetivosLeadsData.data
}

// Productos
export const obtenerProductos = async () => {
    const productos = await axiosRequest.get(`/objetivos-productos`)
    const formatProductos = formatSelectData(productos.data, 'numero', 'descripcion', 'numero')
    return formatProductos
}

// Canales
export const obtenerCanales = async () => {
    const canales = await axiosRequest.get(`/canales`)
    const formatCanales = formatSelectData(canales.data, 'numero', 'descripcion', 'numero')
    return formatCanales
}

// Postear objetivo
export const postObjetivo = async (objetivo) => {
    return await axiosRequest.post(`/objetivo`, objetivo)
}

// Editar objetivo
export const editarObjetivo = async (id, objetivo) => {
    return await axiosRequest.put(`/objetivo/${id}`, objetivo)
}

// Borrar objetivo
export const borrarObjetivo = async (id) => {
    return await axiosRequest.delete(`/objetivo/${id}`)
}
