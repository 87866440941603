import { useEffect, useState } from "react";
import { getDetails, getHistorial } from "../../functions/requestHttp";
import {
  Grid,
  Col,
  Button,
  Modal,
  TableStyled,
  Historial,
  Confirmation,
  Titulo,
  Subtitulo,
} from "../Index";
import { Select, Option } from "../Form/styled";
import { DetailsMain, Span, ScrollX } from "./styled";
import { ModalContainer } from "../../pages/styled";
import SpinnerLoading from "../SpinnerLoading";

function Details({
  details,
  rejectSelected,
  setDetails,
  showDetails,
  setShowDetails,
  setShowRejectForm,
  setModo,
  companias,
  onlyRead,
  estados
}) {
  const [newState, setNewState] = useState({ id_estado : null });
  const [errorModal, setErrorModal] = useState("");
  const [success, setSuccess] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [nameCompania, setNameCompania] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [historial, setHistorial] = useState([]);
  const [showHistorial, setShowHistorial] = useState(false);
  const [confirmation, setConfirmation] = useState("");

  useEffect(() => {
    if (Object.keys(details).length !== 0) {
      details.deletedAt ? setIsDeleted(true) : setIsDeleted(false);
      setCompania();
    }
  }, [details]);

  // Escucha el cambio del rechazo seleccionado
  useEffect(async () => {
    if (rejectSelected) {
      const result = await getDetails(rejectSelected);
      setDetails(result);
    }
  }, [rejectSelected]);

  const UpdateRejectSelect = () => {
    setModo("edit");
    setShowDetails(false);
    setShowRejectForm(true);
  };

  const setCompania = () => {
    const res = companias.find((cia) => cia.numero === details.id_compania);
    setNameCompania(res.nombre);
  };

  const loadHistorial = async () => {
    const result = await getHistorial(details.id);
    if (result.length !== 0) {
      setHistorial(result);
    } else {
      console.log("error al obtener el historial");
    }
    setShowHistorial(true);
  };

  const confirmDelete = () => {
    setConfirmation("delete");
  };

  const confirmSendMail = () => {
    setConfirmation("sendMail");
  };

  const confirmNewState = () => {
    setConfirmation(newState.id_estado);
  };

  // si se cancela el cambio de estado, lo resetea
  useEffect(() => {
    if (confirmation === "") {
      setNewState({ estado: "" });
    }
  }, [confirmation]);

  const formatoImporte = (importe) => {

    const impSeparadores = importe.toLocaleString('es-AR');
    //Obtengo resto, condicional para decimal
    const importeFormateado = impSeparadores + (importe % 1 === 0 ? '.00' : '');

    return `$ ${importeFormateado}`;
    
  }

  return (
    <Modal
      isModalOpen={showDetails}
      setIsModalOpen={setShowDetails}
      hideCross={false}
      loader={false}
    >
      <DetailsMain>
        {errorModal !== "" ? errorModal : null}
        {Object.keys(details).length === 0 ? (
          <>
            <SpinnerLoading text={"Cargando Detalles"} />
          </>
        ) : success !== "" ? (
          success
        ) : (
          <>
            <h3 id="titulo">
              {details.nombre} | Rechazo Nº {details.id}{" "}
              {details.deletedAt ? " | Registro Eliminado" : null}
            </h3>
            <Grid rowGap={1}>
              <Col>
                {!showHistorial ? (
                  !confirmation ? (
                    <>
                    <ScrollX>
                      <h4 id="subtitulo">Detalle de notificación</h4>
                      <div className="text-container">
                        <p>
                          Correo electrónico:<span>{details.mail}</span>
                        </p>
                        <p>
                          Estado:<span>{(estados.find( e => details.id_estado === e.id)).estado}</span>
                        </p>
                        <p>
                          Ingresado:
                          <span>
                            {new Date(details.createdAt).toLocaleString()}
                          </span>
                        </p>
                        <p>
                          Última actualización:
                          <span>
                            {new Date(details.updatedAt).toLocaleString()}
                          </span>
                        </p>
                      </div>
                      <hr />
                      <h4 id="subtitulo">Detalle de rechazo</h4>
                      <div className="text-container">
                        <p>
                          Nombre completo:<span>{details.nombre}</span>
                        </p>
                        <p>
                          Compañia:<span>{nameCompania}</span>
                        </p>
                        <p>
                          Póliza:<span>{details.poliza}</span>
                        </p>
                        <p>
                        Importe: <span>{formatoImporte(details.importe)}</span>
                        </p>
                      </div>
                      <hr />
                      <h4 id="subtitulo">Opciones</h4>
                      {/* Select Compañias */}
                      <div
                        className="select-container"
                        id="select-estado-container"
                      >
                        <select
                          id="id_estado"
                          name="id_estado"
                          value={newState.id_estado}
                          onChange={(e) =>
                            setNewState({ id_estado: e.target.value })
                          }
                          onFocus={(e) =>
                            setNewState({ id_estado: e.target.value })
                          }
                          onBlur={(e) =>
                            setNewState({ id_estado: e.target.value })
                          }
                          className={"edit-state"}
                        >
                          <option disabled value={null}></option>
                          <option value={4}> Contactado</option>
                          <option value={5}>
                            Resuelto positivo
                          </option>
                          <option value={6}>
                            Resuelto negativo
                          </option>
                        </select>
                        <label
                          className={
                            newState.id_estado == null || newState.id_estado == ""
                              ? "label-down"
                              : "label-top"
                          }
                          htmlFor="id_estado"
                        >
                          Cambiar estado
                        </label>
                        <i></i>
                      </div>
                      <div id="botones-container">
                        <Button
                          value={details.id}
                          disabled={newState.estado === "" || onlyRead}
                          onClick={confirmNewState}
                          backgroundColor={true}
                          borderColor={false}
                          textColor={false}
                        >
                          Actualizar
                        </Button>
                        <Button
                          onClick={confirmSendMail}
                          backgroundColor={true}
                          disabled={isDeleted || onlyRead }
                          borderColor={false}
                          textColor={false}
                        >
                          Notificar
                        </Button>
                        <Button
                          onClick={confirmDelete}
                          backgroundColor={true}
                          disabled={isDeleted || onlyRead}
                          borderColor={false}
                          textColor={false}
                          className={"warning"}
                        >
                          Borrar
                        </Button>
                        <Button
                          onClick={loadHistorial}
                          backgroundColor={true}
                          borderColor={false}
                          textColor={false}
                          className={"warning"}
                        >
                          Historial
                        </Button>
                      </div>
                      </ScrollX>
                    </>
                  ) : (
                    <Confirmation
                      confirmation={confirmation}
                      setConfirmation={setConfirmation}
                      details={details}
                      setSuccess={setSuccess}
                      newstate={newState.id_estado}
                      disabled={onlyRead}
                    />
                  )
                ) : (
                  <Historial
                    historial={historial}
                    setShowHistorial={setShowHistorial}
                    details={details}
                    estados={estados}
                  />
                )}
              </Col>
            </Grid>
          </>
        )}
      </DetailsMain>
    </Modal>
  );
}

export default Details;
