import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import TitlePageSection from "../../../components/TitlePageSection";
import NewButton from "../../../components/NewButton";
import { useLoading } from "../../../hooks/useLoading";
import BackButton from "../../../components/BackButton";
import { useTabs } from "../../../hooks/useTabs.js";
import NewTabs from "../../../components/NewTabs/index.jsx";
import { sendMailManually } from "../../../services/Rechazos.js";
import { useState } from "react";
import { FiMail } from "react-icons/fi";

const NotificacionRechazos = () => {

  const [response, setResponse ] = useState(null)
  const { loading, setLoading } = useLoading()

  const { tabSelected, tabName, handleTab } = useTabs();

  const sendAllPendingMails = async () => {
    setResponse(null)
    setLoading(true)
    try {
      const res = await sendMailManually([])
      setResponse(res.msg)
      return res;
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  let tabs = [
    {
      name: "Nueva Notificación Masiva",
    },
    // {
    //   name: "Historial",
    // }
  ];

  return (
    <div>
      <div>
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
                <BackButton url={"/Rechazos2"} text="Rechazos"/>
                <TitlePageSection
                  title={`Notificar`}
                  description={`Genera notificaciones masivas para los rechazos actuales`}
                ></TitlePageSection>
              </div>
              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />
              <div className="buttons-container">
                {
                <NewButton backgroundColor={true} onClick={sendAllPendingMails} disabled={loading}>
                  <FiMail /> Notificar Rechazos Pendientes
                </NewButton>
                }
              </div>
              {
                response && <p> {response} </p>
              }

            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default NotificacionRechazos;
