import React from "react";
import Subtitle from "../../Subtitle";
import { FiArrowLeft, FiUser } from "react-icons/fi";
import { Col, Grid } from "../../Grid";
import NewInputBasic from "../../NewInputBasic";
import ErrorInput from "../../ErrorInput";
import NewButton from "../../NewButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CrearGrupoForm = ({ form, data }) => {
  const { inputValues, handleChange, formErrors, handleValidation } = form;
  const {
    handleChangeVendedor,
    vendedores,
    vendedoresSeleccionados,
    seleccionarVendedor,
    loading,
  } = data;

  return (
    <>
      <Subtitle
        icon={<FiUser />}
        title={"Datos del grupo"}
        description="Elegí el nombre del grupo"
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"nombre"}
            labelName={"Nombre del grupo"}
            placeholder={"Nombre del grupo"}
            onChange={handleChange}
            value={inputValues.nombre}
          />
          {formErrors.nombre && <ErrorInput>{formErrors.nombre}</ErrorInput>}
        </Col>
      </Grid>
      <hr />
      <Subtitle
        icon={<FiUser />}
        title={"Miembros del grupo"}
        description="Seleccioná los vendedores que formarán parte del grupo"
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={12}>
          <NewInputBasic
            type={"text"}
            name={"nombre"}
            labelName={"Buscar vendedor"}
            placeholder={"Nombre del vendedor"}
            onChange={handleChangeVendedor}
          />
        </Col>
        {vendedores.length > 0
          ? vendedores.map((vendedor) => {
              const seleccionado = vendedoresSeleccionados.find(
                (ven) => ven === vendedor.numero
              );
              return (
                <Col desktop={3}>
                  <div
                    className={`vendedor-container ${
                      seleccionado ? "seleccionado" : ""
                    }`}
                    onClick={() => seleccionarVendedor(vendedor.numero)}
                  >
                    <span className="icon">
                      <FiUser />
                    </span>
                    <span>{vendedor.nombre}</span>
                  </div>
                </Col>
              );
            })
          : null}

        {formErrors.integrantes && (
          <Col desktop={12}>
            <ErrorInput>{formErrors.integrantes}</ErrorInput>
          </Col>
        )}
      </Grid>
      <hr />
      <div className="buttons-container">
        <NewButton
          backgroundColor={true}
          onClick={handleValidation}
          disabled={loading}
        >
          {loading ? "Creando grupo..." : "Crear grupo"}
        </NewButton>
        <Link to="/Premios">
          <NewButton borderColor={true} textColor={true}>
            <FiArrowLeft /> Volver a Premios
          </NewButton>
        </Link>
      </div>
    </>
  );
};

export default CrearGrupoForm;
