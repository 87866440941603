import React from 'react'
import { Container } from './styled'
import NewSidebar from '../NewSidebar'
import NewHeader from '../NewHeader'

const Layout = ({ children }) => {
  return (
    <Container>
        <NewSidebar />
        <NewHeader>
          {children}
        </NewHeader>
    </Container>
  )
}

export default Layout

/*
    <Container>
        <NewSidebar />
        <NewHeader>
          {children}
        </NewHeader>
    </Container>
*/