import axiosRequest from "../utils/axiosConfig";

// Notificaciones
export const marcarNotificacionesLeidas = async (id_usuario) => {
    const notificaciones = await axiosRequest.put(`/notificaciones/${id_usuario}`)
    return notificaciones.data
}

// Notificaciones
export const marcarNotificacionLeida = async (id_usuario, id_notificacion) => {
    const notificaciones = await axiosRequest.put(`/notificacion/${id_usuario}/${id_notificacion}`)
    return notificaciones.data
}

