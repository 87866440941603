import { Grid, Col } from "../Grid"
import Subtitulo from "../Subtitulo"
import { ImFilter } from "react-icons/im";
import FiltersNew from "../FiltersNew";

const FilterSection = ({ section, inputs, values, params, handleChange, handleSearch, clearSearch, required }) => {
    return(
        <Grid colGap={21} rowGap={21} narrow={true}>            
            <Col desktop={12}>
              <Subtitulo>
                <span><ImFilter></ImFilter></span>
                Filtrar {section}
              </Subtitulo>
            </Col>
            <FiltersNew inputs={inputs} params={params} values={values} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} required={required} />
        </Grid>
    )
}

export default FilterSection