import { useState, useEffect } from "react";
import axiosRequest from "../../../../../utils/axiosConfig";
import formatSelectData from "../../../../../utils/formatSelectData";
import { useLoading } from "../../../../../hooks/useLoading";

export const useHistorialPremios = (params) => {
  const [historial, setHistorial] = useState([]);
  const [tiposModificacionesHistorial, setTiposModificacionesHistorial] =
    useState([]);
  const loadingHistorial = useLoading();

  useEffect(() => {
    const getHistorial = async () => {
      setHistorial([]);
      loadingHistorial.setLoading(true);
      try {
        const historial = await axiosRequest.get(
          `/premios/historial_modificaciones?${params}`
        );
        setHistorial(historial.data);
        loadingHistorial.setLoading(false);
      } catch (error) {
        loadingHistorial.setLoading(false);
        console.log(error);
      }
    };
    getHistorial();
  }, [params]);

  useEffect(() => {
    const getHistorial = async () => {
      try {
        const historial = await axiosRequest.get(
          "/premios/historial_modificaciones"
        );
        console.log(historial)
        setHistorial(historial.data);
      } catch (error) {
        console.log(error);
      }
    };
    getHistorial();
  }, []);

  useEffect(() => {
    const getTiposModificaciones = async () => {
      try {
        const tipos = await axiosRequest.get(
          `/premios/tipos_modificaciones_historial?${params}`
        );
        setTiposModificacionesHistorial(
          formatSelectData(tipos.data, "id_tipo", "nombre", "id_tipo")
        );
      } catch (error) {
        console.log(error);
      }
    };
    getTiposModificaciones();
  }, []);

  return { historial, tiposModificacionesHistorial, loadingHistorial };
};
