import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../../constants';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background: none;
`

export const IlustracionContainer = styled.div` 
  display: flex;
  flex-direction: column;
  width: 60%;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  background-color: ${leerColor(colores.azul)};
  /* margin: 0 auto; */
  img.ilustracion{
    width: 90%; // 55%;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img{
    width: 250px;
    margin: 0 auto;
    position: absolute;
    top:50px;
  }
  .title{
    color: ${leerColor(colores.celeste)};
  }
  /*
  div{
    //margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  */
  div h2{
    padding-bottom: 20px;
  }
`;

export const ContainerLogin = styled.div` 
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 50px;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: ${({ theme }) => theme.title};
  }

  .titulo-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  span {
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;
  }

  p { 
    margin: 10px 0 0 0;
  }

`;
