import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const RowsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => (props.rows ? `repeat(${props.rows}, 1fr)` : 'repeat(6, 1fr)')};
  //grid-template-columns: repeat(5, 1fr);
  font-weight: 700;
  color: ${leerColor(colores.celeste)};
  text-align: center;
  margin:${props => (props.margin ? `80px 0 20px 0` : '40px 0 20px 0')};

  .react-loading-skeleton {
    background-color: ${({ theme }) => theme.skeleton_background} !important;
    background-image: ${({ theme }) => theme.skeleton_gradient} !important;
    transition: all 0.5s;
  }
`;
