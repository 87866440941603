import { FiUser } from "react-icons/fi";
import NewButton from "../NewButton";
import Container from "./styled";
import dayjs from "dayjs";
const OperacionCard = ({ operacion, handleValidation }) => {

  return (
    <Container>
      <div className="poliza">
        <div className="poliza-header">
          <div className="poliza-header-titulo">
            <span>Operación número</span>
            <h3>{operacion.numero}</h3>
          </div>
          <div className="poliza-header-titulo">
            <span>Fecha de carga</span>
            <h3>{dayjs(operacion.fecha).format("DD/MM/YYYY")}</h3>
          </div>
        </div>
        <div className="data-container">
          <div className="poliza-datos">
            <span className="icon"><FiUser /></span>
            <span className="capitalize-nombre asegurado">
              {operacion.nombre.toLowerCase()}
            </span>
            
            <span className="capitalize-nombre">
              {operacion.dni} - {operacion.producto.toLowerCase()} - 
            </span>
            <span>{operacion.email} - </span>
            <span className="capitalize-nombre">{operacion.telefono} - </span>
            <span className="capitalize-nombre">{operacion.provincia}</span>
            
          </div>
          <div className="poliza-datos">
            <NewButton backgroundColor={true} onClick={handleValidation}>
              Seleccionar operación
            </NewButton>
          </div>
        </div>
        {/*
        <hr className="divider" />
        <div className="poliza-datos">
          <span className="capitalize-nombre asegurado"> 
            {operacion.nombre.toLowerCase()}
          </span>
          <span className="capitalize-nombre"> 
            DNI: {operacion.dni} - Producto: {operacion.producto.toLowerCase()}
          </span>
          <span>{operacion.email}</span>
          <span className="capitalize-nombre">{operacion.telefono}</span>
          <span className="capitalize-nombre">{operacion.provincia}</span>
        </div>
        <hr className="divider" />
        <div className="cont">
          <div className="vigencia-container">
            <span className="capitalize-nombre"> 
                Fecha de carga: <span className="fecha">{dayjs(operacion.fecha).format("DD/MM/YYYY")}</span>
              </span>
          </div>
          <div className="vigencia-container">
          </div>
        </div>
          <>
            <hr className="divider" />
            <div className="poliza-datos">

            <NewButton backgroundColor={true} onClick={nextStep}>
              Seleccionar operación
            </NewButton>
            </div>
          </>
        */}
      </div>
    </Container>
  );
};

export default OperacionCard;
