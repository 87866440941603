import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const InputDiv = styled.div`
  width: 100%;
  //max-width: 260px;
  //margin: 0px 20px 23px 0px;
  position: relative;

  label{
    font-family: 'DM Sans', sans-serif;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 15px;
    line-height: 0.75;
    font-size: 0.8em;
    font-weight: 400;
    color: ${leerColor(colores.gris)};
    cursor: text;
    z-index: 60;
  }

  input {
    width: 100%;
    height: 35px;
    padding: 0px 15px 0px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    // 
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8em;
    color: ${leerColor(colores.gris)};
    border: 1px solid ${leerColor(colores.gris)};
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px !important;
    background-color: white;

    ::placeholder { 
      opacity: 0; 
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  // Inputs Type Date Icon
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0.5;
    cursor: pointer;
  }

    // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: ${leerColor(colores.gris)} !important;
    z-index: 59 !important;
  }

  input:focus{
    border: 1px solid ${leerColor(colores.celeste)};
    outline: none;
    z-index: 59 !important;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
    }
  }

  input:focus + .label-container label{
    top: -0.6em;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: ${leerColor(colores.celeste)} !important;
    z-index: 59 !important;
  }

/*
  display: flex;
  flex-direction: column;
  label{
     
    color: ${(props) => (props.children[0].props.color === "error" ? `${leerColor(colores.rojo)}` : `${leerColor(colores.negro)}`)};
    font-size: 18px;
    padding-bottom: 10px;
  }
  */
`
export const Input = styled.input` 
   
  font-weight: 400;
  font-size: 16px;
  padding: 12px 20px;
  //border: 1px solid ${leerColor(colores.gris)};
  border: ${(props) => (props.color === "error" ? `1px solid ${leerColor(colores.rojo)}` : `1px solid ${leerColor(colores.gris)}`)};
  &::-webkit-calendar-picker-indicator{
    background: url(${(props) => props.calendarPicker}) center/95% no-repeat;
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  }
  ${(props) => props.cancelReminder && props.readOnly === true && css`
      &::after {
        content: url(${(props) => props.cancelReminder});
        padding-right: 10px;
        display: inline-block;
        height: 20px;
        width: 20px;
      }
      border: none;
  `}
`;
