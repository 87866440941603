import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const DivRowContainer = styled.div`
  max-width: 100%;
  display: flex;
  content-disposition:center;
  margin-top: 10px;
  text-align: center;
  // outline: 1px solid red;
  div {
    width: 200px;
    display: block;
    // outline: 1px solid blue;
  }
`;
