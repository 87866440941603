import React, { useState, useEffect, useContext } from "react";
import BurgerMenu from "../components/BurgerMenu";
import { Col, Grid } from "../components/Grid";
import Inner from "../components/Inner";
import NavMenu from "../components/NavMenu";
import Subtitulo from "../components/Subtitulo";
import Titulo from "../components/Titulo";
import Wrapper from "../components/Wrapper";
import { AuthContext } from "../context/AuthContext";
import axios from 'axios'
import Filters from "../components/Filters";
import InputBasic from "../components/InputBasic";
import InputSelect from "../components/InputSelect";
import Button from "../components/Button";
import { MdFileUpload } from "react-icons/md";
import Gallery from "../components/Gallery";
import Modal from "../components/Modal";
import { DetailsMain } from "../components/Details/styled";
import { BiTrash } from "react-icons/bi";
import InputFileRec from "../components/InputFileRec";
import SpinnerLoading from "../components/SpinnerLoading";
import { NoHayBusquedas } from "../components/InfoBoard/new-styled";
const StorageApi = () => {
    const authContext = useContext(AuthContext);
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const [publicFiles, setPublicFiles] = useState([])
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [dataToFilter, setDataToFilter] = useState('')
    const [hayBusquedas, setHayBusquedas] = useState(true);
    const [openModalNuevo, setOpenModalNuevo] = useState(false)
    const [files, setFiles] = useState('');
    const [filter, setFilter] = useState({
        formato: '',
        tipo: '',
        fecha_creacion: '',
        proyecto: '',
    })
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [archivoCargado, setArchivoCargado] = useState(false);
    const [datosCargados, setDatosCargados] = useState({})
    const [form, setForm] = useState({
        proyecto: ''
    })
    const getPublicFiles = async () => {
        setLoading(true)
        try {
            const result = await axios.get(`${process.env.REACT_APP_URL_API_STORAGE}api/v1/storage`);
            setPublicFiles(result.data);
        } catch (error) {
            setError('error al obtener los archivos')
        }
        finally {
            setLoading(false)
        }
    }

    const deleteFile = () => {
        setFiles({});
    };

    const cargarArchivo = () => {
        setOpenModalNuevo(true)
    }

    const addField = (e) => {
        const { id, value } = e.target;
        setForm({ ...form, [id]: value });
    };
    const enviarArchivo = async () => {
        setUploading(true)
        if (files) {
            let bodyFormData = new FormData();

            // Se agrega información al formData en base a la cantidad de propiedades que existan en el state 'files'. 
            const key = Object.keys(files);
            if (!Array.isArray(files[key[0]])) {
                // Si no es un array
                for (const key in files) {
                    bodyFormData.append('recfile', files[key].image);
                }
            } else {
                // Si es un array
                files[key[0]].forEach(file => {
                    bodyFormData.append('recfile', file.image);
                })
            }
            const config = {
                headers: {
                    'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69',
                    'project': form.proyecto || 'seguroweb',
                    'scope': 'public',
                }
            }
            try {
                const sendFiles = await axios.post(`${process.env.REACT_APP_URL_API_STORAGE}api/v1/storage`, bodyFormData, config);
                setArchivoCargado(true)
                setDatosCargados(sendFiles.data[0])
                setSuccess(`Archivo ${sendFiles.data[0].file} cargado correctamente.`)
                getPublicFiles();
            } catch (error) {
                setError('Error al cargar nuevo archivo.')
            }
            finally {
                setUploading(false)
            }
        }
    }
    useEffect(() => {
        getPublicFiles()
    }, [filter])

    useEffect(() => {
        if (success.length > 0 && !openModalNuevo) getPublicFiles()
    }, [openModalNuevo, success])

    useEffect(() => {
        if (!openModalNuevo) {
            setError('')
            setSuccess('')
            deleteFile()
            setLoading(false)
            setArchivoCargado(false)
            setDatosCargados({})
        }
    }, [openModalNuevo])

    // menu
    const [open, setOpen] = useState(false);


    const values = {
        tipo: '',
        formato: '',
    }


    const tipos = ['image', 'document', 'video'].map((tipo, index) => ({
        label: tipo,
        value: tipo,
        key: index
    }))
    const formatos = ['jpg', 'png', 'svg'].map((format, index) => ({
        label: format,
        value: format,
        key: index
    }))
    const proyectos = ['seguroweb', 'segurobici', 'crm'].map((format, index) => ({
        label: format,
        value: format,
        key: index
    }))

    const inputs = [
        {
            name: "tipo",
            labelName: "Tipo",
            options: tipos,
            component: InputSelect
        },
        {
            name: "formato",
            labelName: "Formato",
            options: formatos,
            component: InputSelect
        },
        {
            name: "proyecto",
            labelName: "Proyecto",
            options: proyectos,
            component: InputSelect
        },
        {
            type: "date",
            name: "fecha",
            labelName: "Fecha de Ingreso",
            component: InputBasic
        },
    ]

    const FormularioSubida = () => {
        if (!archivoCargado) {
            return (<> <Col desktop={12}>
                <div id="input-container">
                    <InputSelect
                        name={"proyecto"}
                        labelName={"Proyecto"}
                        options={proyectos}
                        value={form.proyecto}
                        onChange={addField}
                    />
                    <InputFileRec
                        disabled={false}
                        multiple={false}
                        type={"file"}
                        name={"newfile"}
                        required={true}
                        id="newfile"
                        files={files}
                        text={"Cargar archivo"}
                        setFiles={setFiles}
                    ></InputFileRec>
                    {files.newfile ? (
                        <div id="archivo-cargado">
                            <p>{files.newfile.name}</p>
                            <a onClick={() => deleteFile()}>
                                <BiTrash></BiTrash>
                            </a>
                        </div>
                    ) : null}
                </div>
            </Col>
                <br />
                <Col desktop={12}>
                    <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        onClick={enviarArchivo}
                        disabled={false}
                    >
                        <span>
                            <MdFileUpload></MdFileUpload>
                        </span>
                        Subir Archivo
                    </Button>
                </Col>
            </>)

        } else {
            console.log('cayo aqui', error, success)
            if (error.length > 0) return (<Col desktop={12}>{error}</Col>)
            if (success.length > 0) {
                return (<div>
                    <p>{success}</p>
                    Url: <a href={datosCargados.urlFile} target={"_blank"} alt={"enlace"} rel="noreferrer">{datosCargados.urlFile}</a>
                    <Button
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        onClick={() => navigator.clipboard.writeText(datosCargados.urlFile)}
                        disabled={false}
                    >
                        Copiar Enlace
                    </Button>
                </div>)
            }
            else {
                return null
            }
        }

    }


    return (
        <div className="App">
            <BurgerMenu open={open} setOpen={setOpen} />
            {/* Nav Menu */}
            <NavMenu open={open} setOpen={setOpen} user={user} active={"Rechazos"} />
            <Wrapper>
                <Inner>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12} spaced={true}>
                            <Titulo textColor={false}>Storage API</Titulo>
                            <Button
                                backgroundColor={true}
                                borderColor={false}
                                textColor={false}
                                onClick={cargarArchivo}
                            >
                                <span>
                                    <MdFileUpload></MdFileUpload>
                                </span>
                                Subir Archivo
                            </Button>
                        </Col>
                        <Col desktop={12}>
                            <Subtitulo>Buscar archivos públicos</Subtitulo>
                        </Col>
                        <Filters inputs={inputs} values={values} data={data} setData={setData} dataToFilter={dataToFilter} setHayBusquedas={setHayBusquedas} hayBusquedas={hayBusquedas} />
                        <Col desktop={12}>
                            {
                                loading ?
                                    <SpinnerLoading
                                        text={"Obteniendo archivos"}
                                    />
                                    :
                                    <Gallery images={publicFiles} proyectos={proyectos} getPublicFiles={getPublicFiles} user={user}></Gallery>
                            }

                        </Col>
                        {
                            openModalNuevo ?
                                <Modal
                                    isModalOpen={openModalNuevo}
                                    setIsModalOpen={setOpenModalNuevo}
                                    hideCross={false}
                                    loader={false}
                                >
                                    {/* Details Main */}
                                    <DetailsMain>
                                        <div className="modalMail">
                                            <h2 id="titulo">Ingresar nuevo archivo</h2>
                                        </div>
                                        {
                                            uploading ?
                                                <SpinnerLoading
                                                    text={"Subiendo archivo"}
                                                />
                                                :
                                                <FormularioSubida />

                                        }
                                    </DetailsMain>
                                </Modal>
                                : null
                        }
                    </Grid>
                </Inner>
            </Wrapper>
        </div>
    )
}

export default StorageApi;