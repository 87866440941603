import { useEffect } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import MenuActions from "../../assets/iconos/menuActions.svg";
import { Container, ButtonAction } from "./styled";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axiosRequest from "../../utils/axiosConfig";
import url from "../../utils/url";
import createWordFile from "../../utils/createWordFile"
import createExcelFile from "../../utils/createExcelFile"
import createPDFfile from "../../utils/createPDFfile"
import dayjs from 'dayjs'
import useHistorial from "../../hooks/useHistorial";

const ActionsButton = ({ data, index, buttonsStatus, setButtonsStatus, setIsModalOpenEnviarEmail, setLoadingModalMessage, handleModalEnviarEmail, handleModalHistorial, handleSusurroModal }) => {

    // Context
    const userRol = JSON.parse(localStorage.getItem('userInfo'))  // Roles que pueden ver la opción de "Susurro"
    const allowedRolesSusurro = [14,6]

    const handleClickButton = (event) => {  
        // Pone todos los botones en false excepto el actual
        for (const value in buttonsStatus) {
          if(value != index){
            buttonsStatus[value] = false;
          }
        }
        if(buttonsStatus[index]){
          setButtonsStatus(prev => ({...prev, [index]: false, }))
        } else {
          setButtonsStatus(prev => ({...prev, [index]: true, }))
        }
    }

    const downloadSolicitud = async (id) => {  
      handleModalEnviarEmail();  
      setLoadingModalMessage("Preparando archivos")  
      try{
          // ID de la solicitud que llego para poder conseguir la información de los demás campos  
        const solicitud = await axiosRequest.get(`/solicitudes/${id}`);
        // Información de la cobertura asegurada
        const cobertura_asegurada = await axiosRequest.get(`/coberturas_aseguradas/${solicitud.data.id_cobertura_asegurada}`);
        // Información del asegurado
        const cliente = await axiosRequest.get(`/asegurados/${solicitud.data.id_asegurado}`);
        // Información del bien asegurado
        let bienesData = {}
        const bienes_asegurados = await axiosRequest.get(`/bienes_asegurados/${id}`);
        // Agrupo la data en un objeto para guardarla en el state y que sea más fácil acceder a sus propiedades
        bienes_asegurados.data.forEach(bien => {
          return bienesData[bien.item_bien_asegurado] = bien.descripcion_bien_asegurado
        })
        // Obtener adjunto
        const adjuntos = await axiosRequest.get(`/asegurados_adjuntos`);
        const findAdjunto = adjuntos.data.find(adjunto =>{
            return cliente.data.id_asegurado === adjunto.id_asegurado && adjunto.id_solicitud === id
        })
        if(findAdjunto){
          bienesData['adjunto'] = findAdjunto.url_adjunto
        }
  
        let document; 
        if(cobertura_asegurada.data.cia_cobertura_asegurada === "La Segunda"){
          const excelFilePath = await createExcelFile(id)
          document = excelFilePath;
        } else if(cobertura_asegurada.data.cia_cobertura_asegurada === "Prudencia"){
          const createDoc = await createWordFile(id);
          document = createDoc;
        }
        /*
        if(cobertura_asegurada.data.cia_cobertura_asegurada === "La Segunda"){
          const excelFilePath = await createExcelFile(id)
          document = excelFilePath;
        } else if(cobertura_asegurada.data.cia_cobertura_asegurada === "ATM" && cobertura_asegurada.data.producto_cobertura_asegurada === "Bicicletas"){
          const createPDF = await createPDFfile(id)
          document = createPDF
        } else {
          const createDoc = await createWordFile(id);
          document = createDoc;
        }
        */
  
          let solicitudFiles = { solicitud: document }
          for (const key in bienesData) {
            if(key.startsWith('foto') || key.startsWith('video') || key.startsWith('adjunto')){
              solicitudFiles[key] = bienesData[key]
            }
          }
    
          axiosRequest({
            method: 'post',
            url: '/zip_files',
            data: { 
              files: solicitudFiles, 
              name: `${cliente.data.nombre_asegurado}-${cliente.data.apellido_asegurado}`, 
              id_solicitud: id,
              id_asegurado: cliente.data.id_asegurado
            }
          })
          .then(function (response) {
            if(response.status == 200){
              setIsModalOpenEnviarEmail(false);
              let zipPath = response.data
              window.location.href = `${url}${zipPath}`
            }
          })
          .catch(function (response) {
            console.log(response);
          });
      } catch (error){
        console.log(error)
      }
    }

    return(
        <Container>
            <ButtonAction data-backgroundcolor={false} data-bordercolor={true} data-textcolor={true} icon={MenuActions} onClick={handleClickButton} /> 
            <motion.div className={buttonsStatus[index] ? "dropDown" : "dropUp"}  key="dropdown" initial={{opacity:0, height: 0}} transition={{duration: 0.5, type: 'spring'}} animate={{opacity: 1, height:94}} exit={{ height:0 }}>
                {allowedRolesSusurro.includes(userRol.id_rol) && <motion.a initial={{opacity:0}} transition={{duration: 0.5, type: 'spring'}} animate={{opacity: 1,}} exit={{ opacity:0 }} onClick={() => handleSusurroModal(data, index)}>Susurro</motion.a>}
                <Link
                to={{
                    pathname: `/EmisionRefactor/${data.id}`,
                }}
                >
                  <motion.a initial={{opacity:0}} transition={{duration: 0.5, type: 'spring'}} animate={{opacity: 1,}} exit={{ opacity:0 }}>Ver</motion.a>
                </Link>
                <motion.a initial={{opacity:0}} transition={{duration: 0.5, type: 'spring' }} animate={{opacity: 1,}} exit={{ opacity:0 }} onClick={() => handleModalHistorial(data)}>Historial</motion.a>
                <motion.a initial={{opacity:0}} transition={{duration: 0.5, type: 'spring' }} animate={{opacity: 1,}} exit={{ opacity:0 }} onClick={() => downloadSolicitud(data.id)}>Descargar</motion.a>
            </motion.div>
        </Container>
    )
}

export default ActionsButton