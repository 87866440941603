import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  ProgressBar,
  Menu,
  Wrapper,
  Modal,
  NewProgressBar,
  BurgerMenu,
  NavMenu,
  InputCalendar,
} from "../components/Index";
import { CargaSolicitud } from "../components/ProgressBar/styled";
import {
  SolEmisionTitleContainer,
  Subtotal,
  ModalDescargarEstadisticas,
} from "./styled";
import axios from "axios";
import axiosRequest from "../utils/axiosConfig";
import IconButton from "../components/IconButton";
import editIcon from "../assets/iconos/edit.svg";
import deleteIcon from "../assets/iconos/delete.svg";
import screenshotIcon from "../assets/iconos/screenshot.png";
import url from "../utils/url";
import dayjs from "dayjs";
import Filters from "../components/Filters";
import SpinnerLoading from "../components/SpinnerLoading";
import ModalSuccess from "../components/ModalSuccess";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import { anios, meses } from "../constants/data";
import Pagination from "../components/Pagination";
import { CSVLink, CSVDownload } from "react-csv";
import { rolesEstadisticasProductoCanal } from "../constants/roles";
import { MdFileDownload } from "react-icons/md";

const EstadisticasProductoCanal = () => {
  // Context
  const authContext = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const allowedRoles = rolesEstadisticasProductoCanal;

  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const history = useHistory(); // Hook de router

  const [dataComercial, setDataComercial] = useState([]);
  const [dataMarketing, setDataMarketing] = useState([]);
  const [subtotalMarketing, setSubtotalMarketing] = useState({});
  const [subtotalComercial, setSubtotalComercial] = useState({});
  const [subtotalOtros, setSubtotalOtros] = useState({});
  const [dataOtros, setDataOtros] = useState([]);
  const [dataTotal, setDataTotal] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [hayBusquedas, setHayBusquedas] = useState(false);
  const [productos, setProductos] = useState([]);
  const [filterParams, setFilterParams] = useState("");
  const [requiredFilters, setRequiredFilters] = useState({
    desde: "",
    hasta: "",
    producto: "",
  });
  const [mesAnterior, setMesAnterior] = useState("");
  const [csvFilename, setCsvFilename] = useState("");
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [format, setFormat] = useState("");
  const [btnLimpiar, setBtnLimpiar] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  // const values = {
  //     anio: '',
  //     mes: '',
  //     producto: ''
  // }

  // Chequear si el usuario está autenticado, caso contrario lo manda al login.
  useEffect(() => {
    if (!authContext.isAuthenticated()) {
      history.push("/");
    }
  }, []);

  // Chequear el rol del usuario autenticado para saber si puede acceder a la página
  useEffect(() => {
    if (user) {
      if (!allowedRoles.includes(user.id_rol) && user.id_rol != 8) {
        history.push("/Rechazadas");
      } else if (!allowedRoles.includes(user.id_rol) && user.id_rol == 8) {
        history.push("/Rechazos");
      }
    }
  }, []);

  useEffect(() => {
    if (requiredFilters.desde) {
      let desdeArray = requiredFilters.desde.split("-");

      const mes = meses.find(
        (mes) => mes.value === parseInt(desdeArray[1]) - 1
      );
      setMesAnterior(mes.label);
    } else {
      setMesAnterior("");
    }
  }, [requiredFilters.desde]);

  useEffect(() => {
    if (
      requiredFilters.desde &&
      requiredFilters.hasta &&
      requiredFilters.producto
    ) {
      setBtnLimpiar(true);
      setBtnDisabled(false);
    } else {
      setBtnLimpiar(false);
      setBtnDisabled(true);
    }
  }, [requiredFilters]);

  const preHeaders = ["Leads No gestionados", "Objetivos"];
  const headers = [
    "Canal",
    `No Gestionados ${mesAnterior}`,
    "Leads",
    "Proyectado Leads",
    "Operaciones",
    "Proyectado Operaciones",
    "Emisiones",
    "Cierre",
    "Objetivos Leads",
    "Objetivos Operaciones",
    "Conversion",
  ];
  const csvHeaders = [
    { header: "Canal", key: "canal", width: 24 },
    {
      header: `No Gestionados ${mesAnterior}`,
      key: `no_gestionados_${mesAnterior.toLocaleLowerCase()}`,
      width: 24,
    },
    { header: "Leads", key: "leads", width: 13 },
    { header: "Proyectado Leads", key: "proyectado_leads", width: 20 },
    { header: "Operaciones", key: "operaciones", width: 15 },
    {
      header: "Proyectado Operaciones",
      key: "proyectado_operaciones",
      width: 24,
    },
    { header: "Emisiones", key: "emisiones", width: 15 },
    { header: "Cierre", key: "cierre", width: 13 },
    { header: "Objetivos Leads", key: "objetivos_leads", width: 20 },
    {
      header: "Objetivos Operaciones",
      key: "objetivos_operaciones",
      width: 22,
    },
    { header: "Conversion", key: "conversion", width: 15 },
  ];

  // const inputs = [
  //     {
  //       name: "anio",
  //       labelName: "Año",
  //       options: anios,
  //       component: InputSelect
  //     },
  //     {
  //       name: "mes",
  //       labelName: "Mes",
  //       options: meses,
  //       component: InputSelect
  //     },
  //     {
  //         name: "producto",
  //         labelName: "Producto",
  //         options: productos,
  //         component: InputSelect
  //     },
  // ]

  // useEffect( () => {
  //     getData()
  // }, [requiredFilters])

  useEffect(() => {
    if (
      requiredFilters.desde &&
      requiredFilters.hasta &&
      requiredFilters.producto
    ) {
      setBtnDisabled(false);
    }
  }, [requiredFilters]);

  const getData = async () => {
    try {
      if (
        requiredFilters.desde &&
        requiredFilters.hasta &&
        requiredFilters.producto
      ) {
        setHayBusquedas(true);
        setData([]);
        setDataComercial([]);
        setDataMarketing([]);
        setDataOtros([]);
        setDataTotal([]);
        const data = await axiosRequest.get(
          `/estadisticas-data?producto=${requiredFilters.producto}&desde=${requiredFilters.desde}&hasta=${requiredFilters.hasta}`
        );
        // const mes = meses.find(mes => mes.value === parseInt(requiredFilters.mes))
        // const productoInfo = productos.find(prod => prod.value == requiredFilters.producto)
        // setCsvFilename(`estadisticas-${productoInfo.nombre_producto.toLowerCase()}-${mes.label.toLowerCase()}-${requiredFilters.anio}.csv`)

        const productoInfo = productos.find(prod => prod.value == requiredFilters.producto)
        setCsvFilename(`estadisticas-${productoInfo.nombre_producto.toLowerCase()}-${requiredFilters.desde}-${requiredFilters.hasta}.csv`)

        setDataMarketing(data.data[0].marketing);
        setSubtotalMarketing(data.data[0].subtotalMarketing);
        setDataComercial(data.data[0].comercial);
        setSubtotalComercial(data.data[0].subtotalComercial);
        setDataOtros(data.data[0].otros);
        setSubtotalOtros(data.data[0].subtotalOtros);
        setDataTotal(data.data[0].totalTablas);

        setData(data.data[0].table);

        setHayBusquedas(false);
      }
    } catch (error) {
      console.log(error);
      setData([]);
      setHayBusquedas(false);
    }
  };

  const addField = (e) => {
    const { name, value } = e.target;
    setRequiredFilters({ ...requiredFilters, [name]: value });
  };

  const buscarOperaciones = async () => {
    if (
      requiredFilters.desde &&
      requiredFilters.hasta &&
      requiredFilters.producto
    ) {
      await getData();
    }
  };
  const limpiarForm = async () => {
    setRequiredFilters({
      desde: "",
      hasta: "",
      producto: "",
    });
    setDataComercial([]);
    setDataMarketing([]);
    setSubtotalMarketing({});
    setSubtotalComercial({});
    setSubtotalOtros({});
    setDataOtros([]);
    setDataTotal([]);
    setBtnLimpiar(false);
    setBtnDisabled(true);
  };

  const downloadCSV = async () => {
    const producto = productos.find(
      (producto) => producto.value == requiredFilters.producto
    );
    try {
      const csvData = {
        desde: requiredFilters.desde,
        hasta: requiredFilters.hasta,
        producto: producto.label,
        headers: csvHeaders,
        marketing: dataMarketing,
        subtotalMarketing,
        comercial: dataComercial,
        subtotalComercial,
        otros: dataOtros,
        subtotalOtros,
        total: dataTotal,
        formato: format,
      };
      const csv = await axiosRequest.post("/generate-csv", csvData);
      if (csv.status === 200) {
        window.location.href = `${url}${csv.data.path}`;
        
        setTimeout(async () => {
          await axiosRequest.delete("/delete_file", {
            data: { path: "./app/public/" + csv.data.path },
          });
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const productos = await axiosRequest.get("/estadisticas-productos");
       
        const productosList = productos.data.map((producto) => {
          return {
            label: producto.grupo == 8 ? "Dispositivos" : producto.descripcion,
            nombre_producto: producto.descripcion,
            value: producto.grupo,
            key: producto.numero,
          };
        });
        productosList.push({
          label: "Todos",
          nombre_producto: "Todos",
          value: "Todos",
          key: productosList.length + 1,
        });
       
        setProductos(productosList);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <div>
      <BurgerMenu open={open} setOpen={setOpen} />
      <NavMenu
        open={open}
        setOpen={setOpen}
        user={user}
        active={"estadisticas-producto-canal"}
      />

      {isDownloadModalOpen ? (
        <Modal
          isModalOpen={isDownloadModalOpen}
          setIsModalOpen={setIsDownloadModalOpen}
        >
          <ModalDescargarEstadisticas>
            <h2>Elegir formato para descargar las estadísticas</h2>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={6}>
                <div
                  className={
                    format === "excel"
                      ? "estadisticas-formato selected-formato"
                      : "estadisticas-formato"
                  }
                  onClick={() => setFormat("excel")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill={format === "excel" ? "#3289b9" : "#bab8b8"}
                      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"
                    />
                  </svg>
                  <span>XLSX (Excel)</span>
                </div>
              </Col>
              <Col desktop={6}>
                <div
                  className={
                    format === "csv"
                      ? "estadisticas-formato selected-formato"
                      : "estadisticas-formato"
                  }
                  onClick={() => setFormat("csv")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill={format === "csv" ? "#3289b9" : "#bab8b8"}
                      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 224H96c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8H96c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40V264c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16H198.4c-7.9 0-14.4 6.4-14.4 14.4c0 5.2 2.8 9.9 7.2 12.5l25.4 14.5c14.4 8.3 23.4 23.6 23.4 40.3c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4c0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5C160.9 302.4 152 287 152 270.4zM280 240v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                    />
                  </svg>
                  <span>CSV</span>
                </div>
              </Col>
            </Grid>
            <div className={"buttonsCont"}>
              <Button
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
                onClick={() => setIsDownloadModalOpen(false)}
              >
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={downloadCSV}
              >
                {format
                  ? `Descargar como ${
                      format === "excel" ? ".xlsx (Excel)" : ".csv"
                    }`
                  : "Descargar"}
              </Button>
            </div>
          </ModalDescargarEstadisticas>
        </Modal>
      ) : null}

      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
              <SolEmisionTitleContainer>
                <Titulo textColor={false}>Estadísticas Producto - Canal</Titulo>
                {dataMarketing.length > 0 && dataComercial.length > 0 && (
                  <Button
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    icon={false}
                    onClick={() => setIsDownloadModalOpen(true)}
                  >
                    <span><MdFileDownload></MdFileDownload></span>
                    Descargar estadísticas
                  </Button>
                )}
              </SolEmisionTitleContainer>
            </Col>
            <Col desktop={12}>
              <Subtitulo>Filtrar estadísticas</Subtitulo>
            </Col>
            {/* <Filters inputs={inputs} values={values} data={[dataMarketing, dataComercial, dataOtros, dataTotal]} setData={[setDataMarketing, setDataComercial, setDataOtros, setDataTotal]} setHayBusquedas={setHayBusquedas} setFilterParams={setFilterParams} requiredFilters={setRequiredFilters} />           */}

            <Col desktop={3}>
              <InputCalendar
                type={"date"}
                name={"desde"}
                labelName={"Desde"}
                value={requiredFilters.desde}
                onChange={addField}
              />
            </Col>
            <Col desktop={3}>
              <InputCalendar
                type={"date"}
                name={"hasta"}
                labelName={"Hasta"}
                value={requiredFilters.hasta}
                onChange={addField}
              />
            </Col>
            <Col desktop={3}>
              <InputSelect
                name={"producto"}
                labelName={"Producto"}
                options={productos}
                value={requiredFilters.producto}
                onChange={addField}
              />
            </Col>
            <Col desktop={12}>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={buscarOperaciones}
                disabled={btnDisabled}
              >
                Buscar
              </Button>
              {btnLimpiar ? (
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={limpiarForm}
                >
                  Limpiar
                </Button>
              ) : null}
            </Col>
          </Grid>
        </Inner>
        <Subtitulo margin={true}>Marketing</Subtitulo>
        <InfoBoard
          borderTop={true}
          disabledMarginTop={true}
          hayBusquedas={hayBusquedas}
          preHeaders={preHeaders}
          headers={headers}
          data={dataMarketing}
          loading={hayBusquedas}
          page="Estadísticas Producto Canal"
        >
          {dataMarketing.length > 0 ? (
            <Subtotal columns={Object.values(subtotalMarketing).length}>
              {subtotalMarketing &&
                Object.values(subtotalMarketing).map((value) => (
                  <div>{value}</div>
                ))}
            </Subtotal>
          ) : null}
        </InfoBoard>

        <Subtitulo margin={true}>Comercial</Subtitulo>
        <InfoBoard
          borderTop={true}
          disabledMarginTop={true}
          hayBusquedas={hayBusquedas}
          preHeaders={preHeaders}
          headers={headers}
          data={dataComercial}
          loading={hayBusquedas}
          page="Estadísticas Producto Canal"
        >
          {dataComercial.length > 0 ? (
            <Subtotal columns={Object.values(subtotalComercial).length}>
              {subtotalComercial &&
                Object.values(subtotalComercial).map((value) => (
                  <div>{value}</div>
                ))}
            </Subtotal>
          ) : null}
        </InfoBoard>

        {dataOtros.length > 0 ? (
          <>
            <Subtitulo margin={true}>Otros</Subtitulo>
            <InfoBoard
              borderTop={true}
              disabledMarginTop={true}
              hayBusquedas={hayBusquedas}
              preHeaders={preHeaders}
              headers={headers}
              data={dataOtros}
              loading={hayBusquedas}
              page="Estadísticas Producto Canal"
            >
              {dataOtros.length > 0 ? (
                <Subtotal columns={Object.values(subtotalOtros).length}>
                  {subtotalOtros &&
                    Object.values(subtotalOtros).map((value) => (
                      <div>{value}</div>
                    ))}
                </Subtotal>
              ) : null}
            </InfoBoard>
          </>
        ) : null}

        {Object.keys(dataTotal).length > 0 ? (
          <>
            <Subtitulo margin={true}>Total</Subtitulo>
            <Subtotal columns={Object.values(dataTotal).length} color={true}>
              {dataTotal &&
                Object.values(dataTotal).map((value) => <div>{value}</div>)}
            </Subtotal>
          </>
        ) : null}
      </Wrapper>
    </div>
  );
};

export default EstadisticasProductoCanal;
