import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  p {
    margin: 0;
  }


  .seccion-container {
      display: flex;
      flex-direction: column;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 8px;
      gap: 10px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 100%;
      justify-content: space-between;
      span {
        text-transform: capitalize;
      }
      a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
  
    span {
        font-size: 14px;
        color: ${({ theme }) => theme.title};
        font-weight: 500;
    }
    h2 {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        color: ${({ theme }) => theme.subtitle};
        line-height: 1.5;
    }
    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }
    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }
`;

export const ContainerEstados = styled.div`
  //margin: 20px 0;
  //border: 1px solid #eaeaea;
  padding: 0 0 10px 0;
  color: ${leerColor(colores.grisEstados)};
  border-radius: 6px;

  .header-estado {
    color: ${leerColor(colores.grisEstados)};
  }
`;

export const ContainerFile = styled.div`
  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.border};
    margin-top: 10px;
  }

  label {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: ${({ theme }) => theme.title};
    font-weight: 500;
    text-transform: capitalize;
  }

  .icon {
    width: 100%;
    height: 160px;
    font-size: 100px;
    display: flex;
    align-items: center;
    color: ${leerColor(colores.azulRebranding)};
    justify-content: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.border};
    margin-top: 10px;
    box-sizing: border-box;
  }
  /*
  width: 100px;
  height: 100px;
  font-size: 60px;
  display: flex;
  align-items: center;
  color: ${leerColor(colores.azulRebranding)};
  justify-content: center;
  */
`;

export const Prioridad = styled.div`
    background-color: ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    width: fit-content;
    border-radius: 8px;
    padding: 4px 10px;
    box-sizing: border-box;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    margin-bottom: 10px;
`;


export const EmptyDocumentacion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.border};
    box-sizing: border-box;
    padding: 80px 0;
    border-radius: 8px;

    .icon {
      font-size: 60px;
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
    }

    h3 {
      margin: 0;
      font-size: 20px;
      color: ${leerColor(colores.negro)};
      font-weight: 500;
    }

    .descripcion {
      color: ${leerColor(colores.grisEstados)};
      font-size: 14px;
    }
`;

export const LinkURL = styled.a`
    color: ${leerColor(colores.azulRebranding)} !important;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.2s;
    cursor: pointer;
    text-decoration: none;
    font-weight: 400 !important;
  
  .icon-link {
    display: flex;
    align-items: center;
  }
  :hover {
    opacity: 0.7;
  }
`