import React from "react";
import { Col, Grid } from "../../../Grid";
import EditarPremioForm from "../../../PremiosForm/EditarPremioForm";
import SpinnerLoading from "../../../SpinnerLoading";
import Divider from "../../../Divider";

const VendedorEdicionPremiosTab = ({ data }) => {
  const { premios, vendedor, vendedorNombre, mes } = data  
  return (
    <>
      <Divider />
      <Grid colGap={21} rowGap={21} narrow={false}>
        {premios.length > 0 ? (
          premios.map((prem, index) => {
            return (
              <Col desktop={12}>
                <EditarPremioForm
                  prem={prem}
                  vendedorId={vendedor.id}
                  vendedor={vendedorNombre}
                  mes={mes}
                  /*refreshPremios={refreshPremios}*/
                />
              </Col>
            );
          })
        ) : (
          <SpinnerLoading text={"Cargando información de premios..."} />
        )}
      </Grid>
    </>
  );
};

export default VendedorEdicionPremiosTab;
