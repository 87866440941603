import React, { useState, useEffect, useContext, useMemo, useCallback, useLayoutEffect } from "react";
import {
  Button,
  Subtitulo,
  Grid,
  Col,
  Inner,
  Wrapper,
  NavMenu,
  BurgerMenu,
  InputFileRec2,
  Modal,
  TableStyled,
  FormStyled,
  Details,
  InputSelect,
  InputBasic,
  InputRowContainer
} from "../components/Index";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import TitlePageSection from "../components/TitlePageSection";
// Iconos
import { MdFileUpload, MdDelete, MdCancel  } from "react-icons/md";
import { ImFilter } from "react-icons/im";
import { BiTrash } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { IoIosNotifications } from "react-icons/io";
// Funciones
import formaterObject from "../functions/formaterJson";
import { sendData, getData, sendMailManually, getSecciones, getEstados, getCompanias, deleteRejecstByState, updateMultipleRejects } from "../functions/requestHttp";
import { DetailsMain } from "../components/Details/styled";
import axiosRequest from "../utils/axiosConfig";
import { AiOutlineNotification } from "react-icons/ai";
import SpinnerLoading from "../components/SpinnerLoading";
import { Table } from "../components/Table/styled";
import { downloadCsv } from "../functions/requestHttp";
// Estilos
import { RechazosDescargaContainer } from "./styled";
import { Loader } from "../components/Loader/Loader";


// REVISAR PERMISOS PARA MOSTRAR BOTONES DESHABILITADOS
const Rechazos = (props) => {
  // datos sesion
  const authContext = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const history = useHistory(); // Hook de router

  // menu
  const [open, setOpen] = useState(false);

  const [rechazosTabla, setRechazosTabla] = useState([]);

  // Guarda los CSV cargados en el input file
  const [csvFile, setCsvFile] = useState();

  // Guarda el contenido del CSV
  const [CSVData, setCSVData] = useState("");
  const [tipoCsvUpdate, setTipoCsvUpdate] = useState("");
  const [companiaCsv, setCompaniaCsv] = useState(0);
  const [companiaNameCsv, setCompaniaNameCsv] = useState('')

  // seleccion de rechazos a cargar
  const [selection, setSelection] = useState([]);

  const initialForm = [
    {poliza: null, importe:null},
    {poliza: null, importe:null},
    {poliza: null, importe:null},
    {poliza: null, importe:null},
    {poliza: null, importe:null}
  ];
  // formulario para carga manual
  const [formValues,setFormValues] = useState(initialForm)

  // Notificaciones
  const initialNotification = {type: '', msg: ''}
  const [notification, setNotification] = useState(initialNotification);

  // Estructura para las tablas de resumen de operaciones
  const [informeGeneralCarga, setInformeGeneralCarga] = useState([]);
  
  // preparacion
  const mensajePreparacionInicial = null;
  const [mensajesPreparacion , setMensajesPreparacion ] = useState(mensajePreparacionInicial)

  // Botón deshabilitado cuando no hay archivo ************
  const [isDisabled, setIsDisabled] = useState(true);

  // Modales
  const [showDetails, setShowDetails] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [showFormCsv, setShowFormCsv] = useState(false);
  const [showUpdateCsv, setShowUpdateCsv] = useState(false);
  const [openModalPendientes, setOpenModalPendientes] = useState(false);

  // loader modal
    // Descarga
  const initialLoading = {type:'', msg:''}
  const [loading, setLoading] = useState(initialLoading);
  const [tipoCargaModal, setTipoCargaModal] = useState("file");
  const [disableSteps, setDisableSteps] = useState(false);
  const [disableNextStep, setDisableNextStep] = useState(false);
  // Stepers
  const [stepsUpload, setStepsUpload] = useState({
    total: 5,
    actual: 0
  })


  const uploadCsv = async () => {
    setLoading({type:'modal', msg:'Actualizando Rechazos'});
    setIsDisabled(true);
    setData([]);
    try {
      const { type, msg, data } = await sendData(dataFormated);
      setInformeGeneralCarga(data)
      setNotification({type, msg});
      setData([]);
      setDataFormated([]);
      setIsDisabled(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(initialLoading);
    }
  };
  
  const sendCsvToServer = async () => {
    await uploadCsv();
    nextStep();
  }

  const nextStep = () => {
    setStepsUpload(prev => ({ total: prev.total, actual: prev.actual + 1 }));
    setDisableNextStep(true);
  }
  // pasos para cada flujo
  const stepsForUpload = [
    nextStep,
    nextStep,
    sendCsvToServer,
    nextStep,
  ]

  //Detalles
  const [rejectSelected, setRejectSelected] = useState(null);
  const [details, setDetails] = useState({});

  //info del csv en formato json
  const [data, setData] = useState([]);
  const [dataFormated, setDataFormated] = useState([]);

  //filtro de busqueda y ordenacion ********************
  const [filter, setFilter] = useState("");
  const [order, setOrder] = useState("");

  // METADATOS
  const [estados , setEstados ] = useState([]);
  const [secciones, setSecciones ] = useState([])
  const [companias, setCompanias] = useState([]);

  //pagination
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);

  // funciones para obtencion de datos desde la api

  const getMetadatosApp = async () => {
    const res = {
      estados:[],
      secciones: [],
      companias: [],
    }
    try {
      res.companias = await getCompanias();
      res.estados = await getEstados();
      res.secciones = await getSecciones();
      return res;
    } catch (error) {
      console.log(error)
      return res;
    } finally {
      setCompanias(res.companias);
      setSecciones(res.secciones);
      setEstados(res.estados);
    }
}

const getRejectsApi = async () => {
  setNotification(initialNotification)
  setLoading({type:'main', msg:'Obteniendo rechazos de la base de datos'})
  // cuando se cierran los modales
    try {
      const { rechazos, pagination_res, error } = await getData( filter, order, page );
      setRechazosTabla(rechazos);
      setPagination(pagination_res);
      if (error) {
        console.log(error);
        setNotification({type:'error_main', msg:error})
      }
    } catch (error) {
      setNotification({type:'error_main', msg:'error en la app'})
    } finally {
      setLoading(initialLoading)
    }
}

  const setNewArrayRechazos = async () => {
    // quita las polizas repetidas
    let polizas = [...new Set(data.map((d) => parseInt(d.poliza)))];

    try {
      setLoading({type:'modal', msg:'Obteniendo datos de las polizas'})
      // trae los datos asociados a los numeros de polizas ingresados por csv
      const res = await axiosRequest({
        url: "/rejects/polizas",
        method: "post",
        data: { polizas, companiaCsv },
      });

      const {data: secciones, resumen} = res.data;
      console.log(polizas, secciones)
      setMensajesPreparacion(resumen)
      for ( const listas in secciones ) {
        if ( listas ) {
          let formated = {};
          let temp = [];
          secciones[listas].data.forEach( row => {
            const match = data.find((d) => ( parseInt(d.poliza) === parseInt(row.poliza)));
            const {nombre, poliza, mail, id_compania, id_seccion, respuesta,detalle, producto, telefono, dni, id, estado_anterior} = row;
            formated = {
              nombre,
              poliza,
              importe: match.importe || 0,
              mail: mail,
              id_compania,
              id_seccion,
              respuesta,
              detalle,
              producto,
              telefono,
              estado_anterior,
              dni,
              id_reject: id || null,
              checked: listas === 'rechazos_existentes_pendientes' ? false : true
            }
            temp.push(formated);
            setSelection(prev => [...prev, formated]);
          })
          secciones[listas].data = temp;
        }
      }

      setInformeGeneralCarga(res.data)
    } catch (e) {
      setNotification({type:'error_modal', msg:'Error al formatear datos para la alta de rechazos'})
    } finally {
      setLoading(initialLoading)
    }
  }

  const sendNotificationsRejects = async () => {
    // envia las notificaciones a los rechazos con estado pentiente
    setLoading({type:'modal',msg:'Enviando notificaciones a los rechazos pendientes.'})
    //si no tiene parametros notifica a todos los pendientes
    try {
      const { type, msg, data } = await sendMailManually([]);
      setNotification({type,msg});
      setInformeGeneralCarga(data)
    } catch (error) {
      setNotification({type:'error',msg:'Error en la app'});
    } finally {
      setLoading(initialLoading);
    }
  };

  const deleteRejects = async () => {
    // elimina rechazos por estado (solo disponible para pendientes por el momento)
    setLoading({type:'modal',msg:'Verificando Pendientes a eliminar...'})
    //si no tiene parametros notifica a todos los pendientes
    try {
      const {type , msg} = await deleteRejecstByState("pendiente");
      setNotification({type,msg});
    } catch (error) {
      setNotification({type:'error', msg:"Error al eliminar rechazos con estado pendiente."});
    } finally {
      setLoading(initialLoading);
    }
  };


  const deleteFile = () => {
    setCsvFile("");
    setData([]);
    setDataFormated([]);
    setMensajesPreparacion(mensajePreparacionInicial)
  };

  const updateForCsv = async () => {
    setIsDisabled(true);
    setLoading({type:'modal', msg:'Actualizando rechazos masivamente'})
    try {
      const { type, msg, data } = await updateMultipleRejects({rejects: dataFormated, id_compania: companiaCsv, new_state: tipoCsvUpdate});
      setInformeGeneralCarga(data)
      setNotification({type,msg});
    } catch (error) {
      setNotification({type:'error',msg:'error al actualizar los rechazos masivos'});
    } finally {
      setData([]);
      setDataFormated([]);
      setIsDisabled(true);
      setLoading(initialLoading);
    } 
  };

  const addRechazos = () => {
    setTipoCsvUpdate('')
    setShowFormCsv(true);
  };

  const addUpdateRechazos = () => {
    setShowUpdateCsv(true)
  }

  const handleCheck = (poliza) => {
    let selectAux = [...selection]
    for (const aux of selectAux) {
      if (aux.poliza === poliza)
        aux.checked = ! aux.checked
    }
    setSelection(selectAux)
  };

  const tableCheck = (e,data) => {
    let selectAux = [...selection];
    if(e.target.checked){
      // si se selecciona
      for (const itemData of data) {
        for (const aux of selectAux) {
          if(aux.poliza === itemData.poliza){
            aux.checked = true
          }
        }     
      }
    } else {
      // si se deselecciona
      for (const itemData of data) {
        for (const aux of selectAux) {
          if(aux.poliza === itemData.poliza){
            aux.checked = false
          }
        }  
      }
    }
    setSelection(selectAux);
  }


  const precargar = () => {
    const filtered = formValues.filter(row => (row.poliza !== null))
    setData(filtered)
  }

  const downloadFilteredCsv = async () => {
    setLoading({type:'download', msg: 'Descargando selección actual'})
    try {
      await downloadCsv(filter, order, companias);
    } catch (error) {
      setNotification({type:'error',msg:'Error al descargar archivo'});
    } finally {
      setLoading(initialLoading)
    }
  };

  // efectos y captura de eventos

  //obtiene los datos actuales segun filtro pagina y orden
  useEffect(() => {
    if (!showDetails && !showRejectForm)
    getRejectsApi();
  }, [ filter,order, page, showDetails, showRejectForm ]);

  // Chequear si el usuario está autenticado, caso contrario lo manda al login.
  useLayoutEffect(() => {
    if (!authContext.isAuthenticated()) {
        history.push("/");
    }
    getMetadatosApp();
  }, []);

  //lee los datos del csv
  useEffect(() => {
    if (!csvFile) {
      setIsDisabled(true);
      setCSVData("");
    }
    if (csvFile) {
      setData([]);
      setDataFormated([]);
      // obtiene los datos del CSV
      const file = csvFile;
      const reader = new FileReader();
      reader.onload = function () {
        const text = reader.result;
        setCSVData(text);
      };
      reader.readAsText(file);
    }
  }, [csvFile]);

  // carga los datos del archivo csv
  useEffect(() => {
    if (CSVData !== "") {
      // carga la informacion con formato clave/valor
      setData(formaterObject(CSVData));
      setIsDisabled(false);
      setInformeGeneralCarga([])
    }
  }, [CSVData]);

  // cuando lee los datos del csv
  useEffect(async () => {
    setDataFormated([]);
    setSelection([]);
    if (data.length !== 0 && companiaCsv !== 0) {
        setNewArrayRechazos()
    }
  }, [data, companiaCsv]);

  // cuando lee los datos del csv
  useEffect(async () => {
    setDataFormated([]);
    setNotification(initialNotification);
    if (data.length !== 0) {
      if (['4','5','6'].includes(tipoCsvUpdate))
        setDataFormated(data)
    }
  }, [data, tipoCsvUpdate, companiaCsv]);

  useEffect(()=>{
    if (stepsUpload.actual === 2 ) {
      setLoading({type:'modal', msg: 'Cargando'});
    }
    setLoading(initialLoading);
  },[stepsUpload]);


  useEffect(()=>{
    if(
      // si se cierran se vuelve a los valores por defecto
      ! showDetails || !showFormCsv || !showRejectForm || !showUpdateCsv || ! openModalPendientes
    ){
      deleteFile();
      setNotification(initialNotification);
      setStepsUpload(prev => ({ total: prev.total, actual:0 }));
      setCompaniaCsv(0);
      setFormValues(initialForm);
      setTipoCargaModal('file');
      setSelection([]);
      setCompaniaNameCsv('');
      setData([]);
      setMensajesPreparacion(mensajePreparacionInicial);
      setInformeGeneralCarga([])
      setSelection([])
    }
  },[showDetails,showFormCsv,showRejectForm,showUpdateCsv, openModalPendientes]);


  useEffect(()=>{
    if (stepsUpload.actual === 0){
      setDisableNextStep(false);
    }
    if(stepsUpload.actual===1 && companiaCsv && dataFormated.length > 0 ){
      setDisableNextStep(false);
    }
    if(stepsUpload.actual===2 && selection.length > 0){
      setDisableNextStep(false);
    }
  },[stepsUpload,dataFormated,companiaCsv]);


  useEffect(()=>{
    const match = companias.find( c => parseInt(c.numero) === parseInt(companiaCsv));
    setCompaniaNameCsv( match? match.nombre : '');
  },[companiaCsv])

  useEffect(()=>{
    setDataFormated(selection)
    if(selection.length === 0) setDisableNextStep(true);
  },[selection])


  return (
    <div className="App">
      {/* Botón Menu */}
      <BurgerMenu open={open} setOpen={setOpen} />
      {/* Nav Menu */}
      <NavMenu open={open} setOpen={setOpen} user={user} active={"Rechazos"} />
      {/* Wrapper */}
      <Wrapper>
        <Inner>
          {/* Grilla */}
          <Grid colGap={21} rowGap={21} narrow={true}>
            {/* Columna */}
            <Col desktop={12} spaced={true}>
              <TitlePageSection title="Rechazos">
              <div>
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  onClick={addRechazos}
                  target={"_blank"}
                  disabled={user.id_rol === 7}
                >
                  <span>
                    <MdFileUpload></MdFileUpload>
                  </span>
                  Cargar
                </Button>
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  onClick={addUpdateRechazos}
                  target={"_blank"}
                  disabled={user.id_rol === 7}
                >
                  <span>
                    <MdFileUpload></MdFileUpload>
                  </span>
                  Actualizar
                </Button>
                <Button
                  borderColor={true}
                  textColor={true}
                  onClick={()=> setOpenModalPendientes(true)}
                  target={"_blank"}
                  tamaño="chico"
                  disabled={user.id_rol === 7}
                >
                  <span><AiOutlineNotification></AiOutlineNotification></span>
                  Administrar
                </Button>
              </div>
              </TitlePageSection>
            </Col>
            {/* Columna */}
            <Col desktop={12}>
              {/* Subtitulo */}
              <Subtitulo>
                <span>
                  <ImFilter></ImFilter>
                </span>
                Filtrar rechazos
              </Subtitulo>
            </Col>
            {/* Columna */}
            <Col desktop={12}>
              {/* Filter */}
              <FormStyled
                setFilter={setFilter}
                companias={companias}
                setShowRejectForm={setShowRejectForm}
                estados={estados}
                btnNuevo={true}

              />
            </Col>
          </Grid>
          <RechazosDescargaContainer>
            <Button backgroundColor={true} onClick={downloadFilteredCsv} disabled={loading.type==='download'}><span><FiDownload /></span>{loading.type==='download' ? loading.msg : "Descargar resultados actuales"}</Button>
          </RechazosDescargaContainer>
          { ['download','main'].includes(loading.type) && <Loader /> }
          {/* Tabla */}
          {rechazosTabla.length !== 0 && (
            <TableStyled
              rechazosTabla={rechazosTabla}
              setShowDetails={setShowDetails}
              setRowSelected={setRejectSelected}
              setOrder={setOrder}
              pagination={pagination}
              setPage={setPage}
              companias={companias}
              estados={estados}
              secciones={secciones}
              filter={filter}
              order={order}
              btnOrder={true}
              btnDownload={true}
            />
          )}

          {
            notification.type === 'error_main' && 
            <Col desktop={3}>
              <h3>{notification.msg}</h3>
            </Col>
          }
        </Inner>
      </Wrapper>
      {
        //show details
        showDetails ? (
          <Details
            details={details}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            setShowRejectForm={setShowRejectForm}
            companias={companias}
            rejectSelected={rejectSelected}
            setRejectSelected={setRejectSelected}
            setDetails={setDetails}
            onlyRead={user.id_rol === 7}
            estados={estados}
          />
        ) : null
      }
      {/* Modal Cargar Rechazos */}
      {showFormCsv && (
        <Modal
          isModalOpen={showFormCsv}
          setIsModalOpen={setShowFormCsv}
        >
          {/* Details Main */}
          <DetailsMain>
            <div className="modalReject">
              <h1 id="titulo">Alta de rechazos { companiaNameCsv && <span className="info">{companiaNameCsv}</span>}  { csvFile && csvFile.name && <span className="info">{csvFile.name}</span>} </h1>  
             
              {
                  notification.msg === 'success' && 
                  <div>
                    <p> {notification.msg}</p>
                  </div>
              }
              {stepsUpload.actual === 0 && 
                <div>
                <h4> ¿Cómo desea cargar las polizas?</h4>
                <form onChange={(e) => setTipoCargaModal(e.target.value)}>
                  <input type="radio" value="file" name="modoUpload" defaultChecked /> Por archivo
                  <br></br>
                  <input type="radio" value="form" name="modoUpload"  /> Manualmente
                </form>
              </div>}
              {
              stepsUpload.actual === 1 && tipoCargaModal === "file" &&
              <div className="form">
                <InputSelect
                  labelName={"Compañía"}
                  options={companias.map(c => ({label:c.nombre, value:c.numero}))}
                  value={companiaCsv}
                  onChange={e => setCompaniaCsv( e.target.value )}
                  disabled={loading.type === 'modal'}
                /> 
                <InputFileRec2
                  disabled={loading.type==='modal'}
                  type="file"
                  accept=".csv"
                  id="csvFile"
                  onChange={(e) => {
                    setCsvFile(e.target.files[0]);
                    e.target.value = "";
                  }}
                  name={"CSV"}
                  text={"Seleccionar archivo"}
                ></InputFileRec2>
                {csvFile && (
                  <div id="archivo-cargado">
                    <p>{csvFile.name}</p>
                    { loading.type !== 'modal' && <a onClick={() => deleteFile()}>
                      <BiTrash></BiTrash>
                    </a>}
                  </div>
                )}
                
              </div>
              }
              {
                stepsUpload.actual === 1 && tipoCargaModal === "form" &&
                <>
                <h4>Ingrese manual de rechazos.</h4>
                <br></br>
                  <InputSelect
                  labelName={"Compañía"}
                  options={companias.map(c => ({label:c.nombre, value:c.numero}))}
                  value={companiaCsv}
                  onChange={e => setCompaniaCsv( e.target.value )}
                /> 
                <form id="form_manual">
                  <InputRowContainer title>
                      <span>Póliza (*)</span>
                      <span>Importe</span>
                  </InputRowContainer>
                  
                  {
                    [1,2,3,4,5].map(row => {
                      const namePoliza = 'poliza'+row;
                      const nameImporte = 'importe'+row;
                      return (
                      <InputRowContainer key={row}>
                        <InputBasic type="number" name={namePoliza} maxLength={10}  placeholder="1234" onChange={(e) => setFormValues(prev => {
                          prev[row-1].poliza = parseInt(e.target.value);
                          return (
                            [...prev,]
                          )
                        })}
                        value={formValues[row-1].poliza}
                        ></InputBasic>
                        <InputBasic type="number" name={nameImporte} maxLength={10}  placeholder="1234,56" onChange={(e) => setFormValues(prev => {
                          prev[row-1].importe = parseFloat(e.target.value);
                          return ([...prev, ])})}
                          value={formValues[row-1].importe}
                          ></InputBasic>
                      </InputRowContainer>
                    )})
                  }
                <br></br>
                <Button
                  borderColor={true}
                  textColor={true}
                  onClick={precargar}
                  disabled={false}
                  tamaño={"chico"}
                >
                  Precargar
                </Button>
                <p>Pulse en precargar para preparar las pólizas ingresadas.</p>
                </form>
                </>
                
              }
              {
              stepsUpload.actual === 2 && informeGeneralCarga &&
               <div id="table-container">
                {
                  notification.type === 'error_modal' &&
                  <div>
                    <p> {notification.msg}</p>
                  </div>
                }
                {
                  mensajesPreparacion && 
                  <>
                  <h3>Resumen de la solicitud</h3>
                  <TablaResumen
                    data={mensajesPreparacion}
                    structure={["Nuevos", "Resueltos Positivos", "Notificados","Pendientes","Otros estados","Sin email","No web", "Sin datos"]}
                  />
                  </>
                }
                {
                    informeGeneralCarga.data &&
                    <SeccionDetalleFinalCarga seccion={informeGeneralCarga.data} checkeable selection={selection}  handleCheck={handleCheck} tableCheck={tableCheck} />
                }
              </div>
              }
              {
                stepsUpload.actual === 3 &&
                <>
                <div id="table-container">
                    <div>Detalle de la solicitud de carga de rechazos
                      <br /><br />
                      {
                        informeGeneralCarga.data.cargados &&
                        <SeccionDetalleFinalCarga seccion={informeGeneralCarga.data.cargados} />
                      }
                      {
                        informeGeneralCarga.data.sin_cargar &&
                        <SeccionDetalleFinalCarga seccion={informeGeneralCarga.data.sin_cargar} />
                      }
                    </div>
                    
                </div>
                <Button
                      borderColor={true}
                      textColor={true}
                      onClick={ ()=> setShowFormCsv(false)}
                      disabled={false}
                      tamaño={"chico"}
                    >
                      Finalizar
                    </Button>
                </>
                
              }
              </div>
              <br/>
              {
                stepsUpload.actual !== 3 && 
                <Col desktop={12}>
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  onClick={()=>setStepsUpload(prev => {
                    if(prev.actual===1) 
                    deleteFile();
                    setCompaniaCsv(0)
                    return ({ total: prev.total, actual: prev.actual-1 })
                  })}
                  target={"_blank"}
                  disabled={stepsUpload.actual===0 || loading.type === 'modal'}
                >
                  Anterior
                </Button>
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  onClick={ tipoCargaModal==="file"? stepsForUpload[stepsUpload.actual] : stepsForUpload[stepsUpload.actual]}
                  target={"_blank"}
                  disabled={ disableNextStep || loading.type === 'modal'}
                >
                  {
                    stepsUpload.actual === 1 && loading.type === 'modal' ? 'Procesando' : 'Siguiente'
                  }
                </Button>
                <Button
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                  onClick={()=> setShowFormCsv(false)}
                  disabled={loading.type === 'modal'}
                >
                  {stepsUpload.actual === 3? 'Finalizar': 'Cancelar'}
                </Button>
              </Col>
              }
            
          </DetailsMain>
        </Modal>
      )}
      {showUpdateCsv ? (
        <Modal
          isModalOpen={showUpdateCsv}
          setIsModalOpen={setShowUpdateCsv}
          hideCross={false}
          loader={false}
        >
          {/* Details Main */}
          <DetailsMain>
            <div className="modalMail">
              <h2 id="titulo">Actualizar rechazos desde CSV</h2>
                {loading.type === 'modal' &&
                  <SpinnerLoading text={loading.msg} />
                }
                {
                  notification.type !== '' && 
                  <div>
                    <p> {notification.msg}</p>
                  </div>
                }
                {
                  informeGeneralCarga && informeGeneralCarga.data &&
                  <div id="table-container">
                      <div>Detalle de la solicitud de actualización de rechazos
                        <br /><br />
                        {
                          informeGeneralCarga.data.cargados &&
                          <SeccionDetalleFinalCarga notDetail seccion={informeGeneralCarga.data.cargados} />
                        }
                        {
                          informeGeneralCarga.data.sin_cargar &&
                          <SeccionDetalleFinalCarga notDetail seccion={informeGeneralCarga.data.sin_cargar} />
                        }
                      </div>
                  </div>
                }
                {
                  
                  <div>
                  <div>
                    <div onChange={(e) => setTipoCsvUpdate(e.target.value)}>
                      <br/>
                      Cambiar estado a:
                      <br/>
                      <input type="radio" value="5" name="estado" /> Resuelto Positivo
                      <br/>
                      <input type="radio" value="6" name="estado" /> Resuelto Negativo
                      <br/>
                      <input type="radio" value="4" name="estado" /> Contactado
                      <br/>
                    </div>
                    <br/>
                  </div>
                  
                  <InputSelect
                      labelName={"Compañía"}
                      options={companias.map(c => ({label:c.nombre, value:c.numero}))}
                      value={companiaCsv}
                      onChange={e => setCompaniaCsv( e.target.value )}
                    /> 
                  <div id="input-container">
                    <InputFileRec2
                      disabled={false}
                      type="file"
                      accept=".csv"
                      id="csvFile"
                      onChange={(e) => {
                        setCsvFile(e.target.files[0]);
                        e.target.value = "";
                      }}
                      name={"CSV"}
                      text={"Cargar archivo"}
                    ></InputFileRec2>
                    {csvFile ? (
                      <div id="archivo-cargado">
                        <p>{csvFile.name}</p>
                        <a onClick={() => deleteFile()}>
                          <BiTrash></BiTrash>
                        </a>
                      </div>
                    ) : null}
                  </div>
                  <div id="table-container">
                    {dataFormated.length !== 0 ? (
                      <>
                        <table>
                          <thead>
                            <tr>
                              <th>Poliza</th>
                              <th>Nombre</th>
                              <th>Estado Anterior</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {dataFormated.map((d) => {
                              return (
                                <tr>
                                  <td>{d.poliza}</td>
                                  <td>{d.nombre }</td>
                                  <td>{d.estado_anterior}</td>
                                  
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : null}
                    <Col desktop={12}>
                      <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        onClick={updateForCsv}
                        target={"_blank"}
                        disabled={isDisabled || dataFormated.length === 0}
                      >
                        Cargar datos
                      </Button>
                      <Button
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        onClick={()=> setShowUpdateCsv(false)}
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </div>
                  </div>
                }
            </div>
            
          </DetailsMain>
        </Modal>
      ) : null}
      {
        openModalPendientes && 
        <Modal
          isModalOpen={openModalPendientes}
          setIsModalOpen={setOpenModalPendientes}
        >
          {/* Details Main */}
          <DetailsMain>
            <div className="modalMail">
              <h2 id="titulo">Administrar Rechazos Pendientes</h2>
              {loading.type === 'modal' &&
                <SpinnerLoading text={loading.msg} />
              }
              {
                notification.type !== '' && 
                <div>
                  <p> {notification.msg}</p>
                </div>
              }
              {
                  informeGeneralCarga &&
                  <SeccionDetalleFinalCarga tipoCsv seccion={informeGeneralCarga} />
              }
            </div>
            <br></br>
            <Col desktop={12}>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                onClick={sendNotificationsRejects}
                disabled={loading.type !== ''}
              >
                <span><IoIosNotifications /></span>
                Enviar Notificaciones
              </Button>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                onClick={deleteRejects}
                disabled={loading.type !== ''}
              >
                <span><MdDelete /></span>
                Eliminar Pendientes
              </Button>
              <Button
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                onClick={()=> setOpenModalPendientes(false)}
                disabled={loading.type !== ''}
              >
                <span><MdCancel /></span>
                Cancelar
              </Button>
            </Col>
          </DetailsMain>
        </Modal>
      }
    </div>
  );
};

export default Rechazos;


const TablaResumen = function ({data, structure, indexOn, titleGroup, checkeable, handleCheck, selection, tableCheck }) {
  const [allChecked, setAllChecked] = useState(false);
  const [allAux, setAllAux] = useState(null);

  useEffect(()=>{
    let all = true
    for (const item of data ) {
      if( ! item.checked ){
        all = false
      }
    }
    setAllChecked(all);
  },[selection,data])

  useEffect(()=>{
    setAllAux(data);
  },[])

  return (
      <Table>
        <thead>
          { titleGroup && <caption>{titleGroup}</caption> }
          <tr>
            { indexOn ? <th>#</th> : null }
            { structure.map((column, index) => (<th key={index}>{column}</th>) )}
            { checkeable &&  <th>Seleccionar {data.length} <input type="checkbox" id={`index`} name="subtable" onChange={ (e) => tableCheck(e, allAux)}  checked={allChecked} /></th> }
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            function formatearComoCurrency(argentinaNumber) {
              // Formatea el número como moneda argentina
              const formatoCurrency = new Intl.NumberFormat('es-AR', {
                  style: 'currency',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
              // Devuelve el número formateado
              return "AR" +formatoCurrency.format(argentinaNumber);
          }

            return (
              <tr key={index}>
                { indexOn && <td padding="40px">{index + 1}</td>  }
                { structure.map((column) => {
                  return (<td> 
                    {column==='importe'? formatearComoCurrency(row[column]): row[column]}
                    </td>)

                })}
                {
                  checkeable && <td>
                  {
                    !allChecked  && 
                                      
                    <input type="checkbox" id={`index`} name="selection" checked={selection.find(s => {
                      if (s.poliza === row['poliza']){
                        return s.checked;
                      }
                    })} onChange={()=> handleCheck(row['poliza'])}/>
                  }
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </Table>
  )
}

const SeccionDetalleFinalCarga = ({seccion, notDetail, checkeable, selection, handleCheck, tableCheck}) => {
  const subtablas = [];
  for (const subseccion in seccion) {
    let structure = ["poliza", "nombre","mail"];
    switch (subseccion) {
      case 'rechazos_nuevos': structure = ["poliza", "nombre","mail", "importe"]; break;
      case 'rechazos_existentes': structure = ["poliza", "nombre","mail", "importe", "estado_anterior"]; break;
      case 'polizas_sin_datos': structure = ["poliza", "nombre","mail", "importe", "detalle"]; break;
      case 'rechazos_existentes_positivos':
      case 'rechazos_existentes_notificado':
      case 'rechazos_existentes_pendientes':
      case 'rechazos_existentes':
      case 'rechazos_existentes_pendientes': structure = ["poliza", "nombre","mail", "importe", "estado_anterior",]; break;
      default:
        break;
    }
      if (seccion[subseccion].data && seccion[subseccion].data.length > 0){
        subtablas.push(
        <>
          <h3>{seccion[subseccion].descripcion}</h3>
          {
            notDetail ?
            null :
            <TablaResumen 
            data={seccion[subseccion].data}
            structure={ structure }
            indexOn
            checkeable={checkeable && subseccion!=='polizas_sin_datos' && subseccion!=='rechazos_nuevos'}
            key={subseccion}
            handleCheck={handleCheck}
            tableCheck={tableCheck}
            selection={selection}
          />
          }
        </>
        )
     }
  }
  return subtablas;
}