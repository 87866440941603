// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import ModalHistorial from "../../../components/Modals/SolicitudEmision/ModalHistorial";
import ModalAsignarMultiplesRechazos from "../../../components/Modals/Rechazos/ModalAsignarMultiplesRechazos";
import TitlePageSection from "../../../components/TitlePageSection";
import NewTable from "../../../components/NewTable"
// Custom Hooks
import useEmisiones from "../../../hooks/useEmisiones";
import useFiltersNew from "../../../hooks/useFiltersNew";
import useHistorialNew from "../../../hooks/useHistorialNew";
import { useDescarga } from "../../../hooks/useDescarga";
import { useTable } from "../../../hooks/useTable";
import { useActionButton } from "../../../hooks/useActionButton";
// Filters
import { filterRechazosInitialValues, filtersEmisiones, filtersRechazos } from "../../../utils/filtersPages";
// Table
import { emisionesTable, rechazosTable } from "../../../utils/tableData";
import ModalLoading from "../../../components/Modals/General/ModalLoading";
import NewFiltersSection from "../../../components/NewFiltersSection";
import { Container } from "./styled";
import { useState } from "react";
import NewButton from "../../../components/NewButton";
import { FiFilter, FiX, FiSettings, FiSend } from "react-icons/fi";
import { useEffect } from "react";
import CardStats from "../../../components/CardStats";
import Divider from "../../../components/Divider";
import { useSingleOpen } from "../../../hooks/useSingleOpen";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useRechazos from "../../../hooks/useRechazos";
import ModalNotificacionMasivaRechazos from "../../../components/Modals/Rechazos/ModalNotificacionMasivaRechazos";
import { useAsignacionRechazos } from "../../../hooks/useAsignacionRechazos";
import useModal from "../../../hooks/useModal";
import useRechazoV2 from "../../../hooks/useRechazosV2";
import { getRechazoHistorial, asignOperador } from "../../../services/Rechazos";
import axiosRequest from "../../../utils/axiosConfig";
import ModalHistorialRechazos from "../../../components/Modals/Rechazos/ModalHistorialRechazos";
import { useLoading } from "../../../hooks/useLoading";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import NewInputSelect from "../../../components/NewInputSelect";
import { meses } from "../../../constants/data";
import { FiDownload, FiUserCheck } from "react-icons/fi";

const RechazosV2 = () => {
  const [filtersApplied, setFiltersApplied] = useState([]);
  const [historial, setHistorial] = useState([])
  const [asignable, setAsignable] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  // Modales

  const modal = useModal()
  const modalHistorial = useModal()
  const loadingHistorial = useLoading()
  const modalAsignar = useModal()

  const { inputValues, params, handleSearch, handleChange, handleChangeExternal, clearSearch } = useFiltersNew(filterRechazosInitialValues)

  const { order, handleTableOrder } = useTable()

  //const { data, loading, pagination } = useEmisiones(params, order)
  const { data, loading, pagination, refresRechazosTotales, setMes, mes, downloadResults, selectOperadores } = useRechazos(params, order)

  const aisgnacionRechazos = useAsignacionRechazos(data.rechazos, refresRechazosTotales)

  const filters = filtersRechazos(data)
  // Botones
  const { isActionButtonOpen, handleToggle } = useActionButton()

  const handleHistorial = async (id) => {
    setHistorial([])
    loadingHistorial.setLoading(true)
    modalHistorial.setIsModalOpen(true)
    try {
      const data = await getRechazoHistorial(id)
      const operadores = await axiosRequest.get('operadores')
      const historial = data.map(item => {
        const operador = operadores.data.find(operador => operador.id_usuario === item.id_usuario)
        return { ...item, nombre_usuario: `${operador.nombre_operador} ${operador.apellido_operador}`}
      })
      setHistorial(historial);
      loadingHistorial.setLoading(false)
    } catch (error) {
      console.log(error)
      loadingHistorial.setLoading(false)
    }
  }

  const tableData = rechazosTable(aisgnacionRechazos.seleccionarRechazo, aisgnacionRechazos.rechazosSeleccionados, aisgnacionRechazos.seleccionarTodos, isActionButtonOpen, handleToggle, handleHistorial)
  const { isOpen: isFiltersOpen, handleOpen: handleFiltersOpen } = useSingleOpen()

  const download = async () => {
    await downloadResults();
  }

  useEffect(() => {
    setAsignable(false)
    if (params) {
      let filterArr = [];
      if (params.includes("&")) {
        const filters = params.split("&");
        const filtersDos = filters.map((item) => item.split("="));
        filterArr.push(filtersDos);
        setFiltersApplied(filtersDos);
      } else {
        const filters = params.split("=");
        const arr = [filters[0], filters[1]]
        filterArr.push(arr);
        setFiltersApplied([arr]);
      }
      for(let filtro of filterArr) {
        for(let row of filtro) {
          console.error('EEE',row)
          if(row[0]==='estado'){
            if([8,11,12].includes(parseInt(row[1]))){
              setAsignable(true);
            }
          }
        }
      }
    }
  }, [params]);

  const removeFilter = (e, filterName) => {
    handleChangeExternal(e, filterName, '')
  }

  const handleChangeMes = (e) => {
    const { value } = e.target
    handleChangeExternal(null, "mes", value)
  }

  useEffect(()=>{
    if(! params ){
      const mes = new Date().getMonth()
      handleChangeExternal(null, "mes", mes)
    }
  },[])

  return (
    <>
      <ModalAsignarMultiplesRechazos modal={modalAsignar}  queryParams={params} selectOperadores={selectOperadores} asignOperador={asignOperador}  />
      
      <ModalLoading isModalOpen={modal.isModalOpen} setIsModalOpen={modal.setIsModalOpen} message={modal.modalMessage} />
     
      <ModalLoading isModalOpen={loading} message={''} />

      {modalHistorial.isModalOpen ? <ModalHistorialRechazos modal={modalHistorial} historial={historial} loading={loadingHistorial.loading} /> : null}

      {modal.isModalOpen ? <ModalNotificacionMasivaRechazos modal={modal} asignacion={aisgnacionRechazos} /> : null}

      <Layout>
      <NewWrapper>
        <NewInner>
          <Container>
            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}>
                <NewTitlePageSection title="Cobranzas" description="Lista de Rechazos y sus estados">
                <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={() => handleFiltersOpen(!isFiltersOpen)}
                  >
                    <FiFilter /> Filtros
                    {params?.split("=").length > 1 ? (
                      <span className="filtros-aplicados">
                        {params?.split("=").length - 1}
                      </span>
                    ) : null}
                  </NewButton>
                  <Link to="/Rechazos2/gestionar">
                    <NewButton
                      backgroundColor={true}
                      textColor={false}
                      borderColor={false}
                    >
                      <FiSettings /> Gestionar
                    </NewButton>
                  </Link>
                    <NewButton
                      backgroundColor={true}
                      textColor={false}
                      borderColor={false}
                      onClick={() => modal.setIsModalOpen(true)}
                    >
                      <FiSend /> Notificar
                    </NewButton>
                  {/* </Link> */}
                </NewTitlePageSection>
              </Col>
              {isFiltersOpen ?
              <Col desktop={12}>
                <NewFiltersSection section="solicitudes emitidas" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} />
              </Col>
              : null}
            </Grid>

            <Divider />

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={2}>
                <NewInputSelect
                name={"mes"}
                labelName={"Mes"}
                onChange={handleChangeMes}
                placeholder={"Mes"}
                options={meses}
                value={mes}
              />
              </Col>
            </Grid>

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={2}>
                <CardStats title={"Positivo"} value={data.estadisticas?.total_resueltos_positivos} filter={(e) => handleChangeExternal(e, "estado", 5)} filterText="positivos"/>
              </Col>
              <Col desktop={2}>
                <CardStats title={"Neg. Derivados"} value={data.estadisticas?.total_resueltos_negativos} filter={(e) => handleChangeExternal(e, "estado", 6)} filterText="negativos"/>
              </Col>
              <Col desktop={2}>
                <CardStats title={"Neg. Cerrados"} value={data.estadisticas?.total_resueltos_negativos_cerrados} filter={(e) => handleChangeExternal(e, "estado", 13)} filterText="cerrados" />
              </Col>
              <Col desktop={2}>
                <CardStats title={"Notificados"} value={data.estadisticas?.total_notificados} filter={(e) => handleChangeExternal(e, "estado", 11)} filterText="notificados"/>
              </Col>
              <Col desktop={2}>
                <CardStats title={"Pendientes"} value={data.estadisticas?.total_pendientes} filter={(e) => handleChangeExternal(e, "estado", 8)} filterText="pendientes" />
              </Col>
              <Col desktop={2}>
                <CardStats title={"Promesa de pago"} value={data.estadisticas?.total_promesa_pago} filter={(e) => handleChangeExternal(e, "estado", 12)} filterText="promesas" />
              </Col>
            </Grid>

            {filtersApplied.length > 0 && params && data.rechazos
              ? 
              <div className="filtros-aplicados-container">
                <div className="download-container">
                  <span>{pagination.dataLength} resultados encontrados con los siguientes filtros: </span> 
                  <div>
                    {
                      pagination.dataLength > 0 && 
                      <>
                      {
                        asignable &&
                        <span> <a className="asign" onClick={()=>modalAsignar.setIsModalOpen(true)}> <FiUserCheck />  Asignar </a></span>
                      }
                      <span> <a className="download" onClick={download}> <FiDownload /> Descargar Resultados</a></span>
                      </>
                    }
                  </div>
                </div>
                <div>
                  {filtersApplied.map((item) => {
                      const tiposSolicitud = { 5: "Resuelto Positivo", 6: "Negativo Derivado", 8: "Pendiente", 11: "Notificado", 12: "Promesa de pago" , 13: "Negativo Cerrado" }
                      const meses = {0:'Enero',1:'Febrero',2:'Marzo',3:'Abril',4:'Mayo',5:'Junio',6:'Julio',7:'Agosto',8:'Septiembre',9:'Octubre',10:'Noviembre',11:'Diciembre'}
                      let nombreFiltro = item[0] 
                      let valorFiltro = null
                      switch(item[0]){
                        case "mes":
                          valorFiltro = item[0] === "mes" ? meses[item[1]] : item[1]
                          break
                        case "estado":
                          valorFiltro = item[0] === "estado" ? tiposSolicitud[item[1]] : item[1]
                          break
                        case "compania": 
                          valorFiltro = item[0] === "compania" ? data.companias.find(compania => compania.value === item[1])?.label : item[1]
                          break
                        case "mailgun_status":
                          valorFiltro = item[0] === "mailgun_status" ? "Sin entregar" : item[1]
                          nombreFiltro = item[0] === "mailgun_status" ? "Mails" : item[0]
                          break
                        case "operador":
                          valorFiltro = item[0] === "operador" ? data.operadores.find(operador => operador.value === item[1])?.label : item[1]
                          break
                        default: 
                          valorFiltro = item[1]
                          break
                      }
                      return (
                        <span className="filtro-aplicado" onClick={(e) => removeFilter(e, item[0])}>
                          {nombreFiltro.charAt(0).toUpperCase() + nombreFiltro.slice(1).replaceAll("_", " ")}: {valorFiltro} <FiX/>
                        </span>
                      );
                  })}
                </div>
              </div>
              : null}

            <NewTable columns={tableData} data={data.rechazos} loading={loading} order={handleTableOrder} orderParams={order} pagination={pagination} />
          </Container>
        </NewInner>
      </NewWrapper>
      </Layout>
    </>
  );
};

export default RechazosV2
