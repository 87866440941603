import styled from "styled-components";
import { leerColor, colores } from "../../constants";

export const Container = styled.div`
  .back-button {
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 500;
      margin-bottom: 20px;
      text-decoration: none;
      transition: 0.2s;
    }

  .back-button:hover {
    opacity: 0.7;
  }
`;
