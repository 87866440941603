import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Button,
  Titulo,
  Grid,
  Col,
  Inner,
  /* Menu, */
  Wrapper,
  NavMenu,
  BurgerMenu,
  InputFileRec2,
  Modal,
  TableStyled,
  FormStyled,
  InputSelect
} from "../components/Index";
// Iconos
import { MdFileUpload } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { DetailsMain, ScrollX } from "../components/Details/styled";
import formaterObject from "../functions/formaterJson";
import axiosRequest from "../utils/axiosConfig";
import MenuActions from "../assets/iconos/menuActions.svg";
import { Table } from "../components/Table/styled";
import dayjs from "dayjs"
import { NoHayBusquedas } from "../components/InfoBoard/new-styled";
const Cobranzas = () => {
  // datos sesion
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const [showFormCsv, setShowFormCsv] = useState(false);
  // Guarda los CSV cargados en el input file
  const [csvFile, setCsvFile] = useState();
  const [cobranzas, setCobranzas] = useState([]);
  const [companiaCsv, setCompaniaCsv] = useState(0);
  // menu 
  const [open, setOpen] = useState(false)

  const getCobranzas = async () => {
    const urlFilter = filter ? '&' + filter : ''
    const url = `/cobranzas?page=${page}` + urlFilter;
    try {
      const send = await axiosRequest.get(url);
      if (send.status === 200 || send.status === 201) {
        // si no existe el filtro -> no se ingresaron notificaciones
        if (send.data.msg.length === 0) { return { 
          cobranzas: send.data.msg, 
          pagination: send.data.paginationData, 
          error:  filter !== '' ? 'No existen notificaciones con los filtros seleccionados' :'Todavia no se ingresaron cobranzas' 
        }; }
        // si hay filtro no existen
        return { cobranzas: send.data.msg, pagination: send.data.paginationData };
      }
    } catch (error) {
      if (error.status === 300) {
        return { cobranzas: [], pagination: {}, error: 'No existen datos con los filtros seleccionados' };
      }
      return { cobranzas: [], pagination: {}, error: 'Error al obtener datos del servidor' };
    }
  }

  const [load, setLoad] = useState(false);

  // template
  const [template, setTemplate] = useState(0)

  // habilita el boton para subir elcsv
  const [btnUpAvailable, setBtnUpAvailable] = useState(false)

  // Guarda el contenido del CSV
  const [CSVData, setCSVData] = useState("");

  // Errores
  const [errorCsv, setErrorCsv] = useState("");
  const [errorMain, setErrorMain] = useState("");
  // Exito
  const [successReq, setSuccessReq] = useState("");

  // Botón deshabilitado cuando no hay archivo ************
  const [isDisabled, setIsDisabled] = useState(true);
  const [camposRequeridos, setCamposRequeridos] = useState(['Póliza'])
  const [listado, setListado] = useState('');

  // Modales
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [cobranzatSelected, setCobranzaSelected] = useState(null);

  //info del csv en formato json
  const [data, setData] = useState([]);
  const [dataFormated, setDataFormated] = useState([]);

  //filtro de busqueda y ordenacion ********************
  const [filter, setFilter] = useState("");
  const [message, setMessage] = useState("");
  //pagination
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);

  // Detalles de siteground -> companias ****************
  const [companias, setCompanias] = useState([]);

  // Detalles de notificacion
  const [detallesNotificacion, setDetallesNotificacion] = useState({})

  const plantillas = ['poliza anulada',
    'baja debito automatico',
    'saldo a favor',
    'error en la tarjeta de credito',
    'poliza proxima a vencer'].map((t, index) => ({ value: (index + 1), label: t }))

  const sendNotificacions = async () => {
    setMessage("Verificando Pendientes...");
    //si no tiene parametros notifica a todos los pendientes
    try {
      const result = await axiosRequest.post('/cobranzas/notificacion', { companias, template });
      setSuccessReq('Notificaciones enviadas con exito')
      setMessage('Notificaciones enviadas con exito');
    } catch (error) {
      setMessage("Error al notificar manualmente");
    }
  };

  useEffect(() => {
    // la primera vez que trae las cobranzas setea la paginacion
    getCompanias();
  }, [])

  //lee los datos del csv
  useEffect(() => {
    setSuccessReq("");
    ;
    setErrorCsv("");
    if (!csvFile) {
      setIsDisabled(true);
      setCSVData("");
    }
    if (csvFile) {
      setData([]);
      setDataFormated([]);
      // obtiene los datos del CSV
      const file = csvFile;
      const reader = new FileReader();
      reader.onload = function () {
        const text = reader.result;
        setCSVData(text);
      };
      reader.readAsText(file);
    }
  }, [csvFile]);

  // carga los datos del archivo csv
  useEffect(() => {
    if (CSVData !== "") {
      // carga la informacion con formato clave/valor
      setData(formaterObject(CSVData, { type: 'cobranza', template }));
      setIsDisabled(false);
    }
  }, [CSVData, template]);

  // cuando lee los datos del csv
  useEffect(() => {
    setErrorCsv('');
    setDataFormated([]);
    if (data.length !== 0) {
      setNewArrayCobranzas()
    }
  }, [data, template, companiaCsv]);

  useEffect(() => {
    // Habilitar/deshabilitar botón de Cargar dependiendo si hay archivos cargados
    if (template !== 0) {
      setBtnUpAvailable(true)
    }
  },[template])

  const setNewArrayCobranzas = async () => {
    let polizas;
    polizas = data.map((d) => parseInt(d.poliza));
    try {
      const res = await axiosRequest({
        url: "/rejects/polizas",
        method: "post",
        data: { polizas, companiaCsv },
      });
      if (res.data.length !== 0) {
        // recorre el array de datos formateados desde el csv
        const registroCsv = data.map((reg) => {
          const datos_sistema = res.data.find(c => reg.poliza === c.poliza)
          const match2 = companias.find((c) => c.numero === datos_sistema.compania);
          let auxRow = {
            poliza: reg.poliza,
          };

          if (template === "2") {
            // Baja debito automatico
            auxRow['periodo_adeudado'] = data.find(d => d.poliza === reg.poliza)['periodo_adeudado'] || 'ERROR'
          }

          if (template === "4") {
            // error en la tc
            auxRow['periodo_adeudado'] = data.find(d => d.poliza === reg.poliza)['periodo_adeudado'] || 'ERROR'
            auxRow['motivo_rechazo'] = data.find(d => d.poliza === reg.poliza)['motivo_rechazo'] || 'ERROR'
          }

          if (template === "5") {
            auxRow['importe'] = data.find(d => d.poliza === reg.poliza)['importe'] || 'ERROR'
            auxRow['periodo_adeudado'] = data.find(d => d.poliza === reg.poliza)['periodo_adeudado'] || 'ERROR'
            auxRow['fecha_vencimiento'] = data.find(d => d.poliza === reg.poliza)['fecha_vencimiento'] || 'ERROR'
          }

          auxRow = {
            ...auxRow,
            nombre: datos_sistema.asegurado,
            email: datos_sistema.correo1 || datos_sistema.correo2,
            compania: match2.nombre,
            id_compania: match2.numero,
            id_user: user.id_usuario,
            id_template: template
          }

          if (['4', '2'].includes(template)) {
            auxRow['ramo'] = datos_sistema.ramo
          }
          return auxRow;
        });
        setDataFormated(registroCsv);
      }
      else {
        setDataFormated([])
        setErrorCsv('No existen datos asociados con los números de polizas  ingresados.')
      }
    } catch (e) {
      console.log(e)
      // manejar el error
    }
  }

  const getCompanias = async () => {
    setLoad(true);
    try {
      const send = await axiosRequest.get(`/companias_rej?search=all`);
      if (send.status === 200 || send.status === 201) {
        setCompanias(send.data.msg);
        setLoad(false);
      }
    } catch (error) {
      //console.log(error);
      //setError('Error al obtener detalles')
    }
  };

  const deleteFile = () => {
    setErrorCsv("");
    setCsvFile("");
    setData([]);
    setDataFormated([]);
  };

  const uploadCsv = async () => {
    setIsDisabled(true);
    setErrorCsv("");
    setSuccessReq("");
    setCsvFile("");
    setData([]);
    let result;
    try {
      result = await axiosRequest.post('/cobranzas', { cobranzas: dataFormated, template });
    } catch (error) {
      console.log('error al ingresar cobranzas')
    }

    if (result.status === 500) {
      setErrorCsv('error al ingresar cobranzas');
    } else {
      setSuccessReq('cobranzas cargadas correctamente');
      setCsvFile("");
      setData([]);
      setDataFormated([]);
      setIsDisabled(true);
    }
  };

  const Estados = () => {
    return (<><option disabled></option>
      <option value="">Todos los estados</option>
      <option value="pendiente">Pendiente</option>
      <option value="contactado">Contactado</option>
    </>
    )
  }


  useEffect(() => {
    switch (template) {
      case '3':
      case '1': setCamposRequeridos(['Póliza']); break;
      case '2': setCamposRequeridos(['Póliza', 'Periodo Aduedado']); break;
      case '4': setCamposRequeridos(['Póliza', 'Periodo Aduedado', 'Motivo de Rechazo']); break;
      case '5': setCamposRequeridos(['Póliza', 'Importe', 'Periodo de Poliza Adeudada', 'Fecha Vnto. de la Cuota']); break;
      default:
        break;// setCamposRequeridos(prev => [...prev, 'Motivo de Rechazo'])
    }
  }, [template])

  useEffect(() => {
    setListado(camposRequeridos.join(' / '))
  }, [camposRequeridos])


  useEffect(async () => {
    const { cobranzas, pagination, error } = await getCobranzas();
    setCobranzas(cobranzas)
    setPagination(pagination)
      if (error) { setErrorMain(error) }
  }, [filter, page, successReq])

  useEffect(() => {
    if (!showFormCsv) { setTemplate(null); setBtnUpAvailable(false) }
  }, [showFormCsv])

  useEffect(() => {
    const error = dataFormated.find(element => Object.values(element).includes('ERROR') ? true : false)
    if (error) setIsDisabled(true)
  }, [dataFormated])

  const getDetailsById = async (id) => {
    try {
      const res = await axiosRequest.get(`/cobranzas/${id}`);
      setDetallesNotificacion(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (cobranzatSelected && showDetails) {
      // trae los detalles para el id ingresado
      getDetailsById(cobranzatSelected)
    }
  }, [cobranzatSelected])

  // captura el valor previo del filtro -> si cambia la pagina se setea en 1
  const prevFilterRef = useRef();
  useEffect(() => {
    if (filter !== prevFilterRef.current) {
      setPage(1)
      setErrorMain('')
    }
    prevFilterRef.current = filter

  }, [filter])
  return (
    <div className="App">
      <BurgerMenu open={open} setOpen={setOpen} />
      <NavMenu open={open} setOpen={setOpen} user={user} active={"Usuarios"} />
      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12} spaced={true}>
              <Titulo textColor={false}>Notificaciones - Cobranzas</Titulo>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                onClick={() => setShowFormCsv(true)}
                target={"_blank"}
              >
                <span>
                  <MdFileUpload></MdFileUpload>
                </span>
                Cargar notificaciones
              </Button>
            </Col>
            <Col desktop={12}>
              {/* Filter */}
              <FormStyled
                setFilter={setFilter}
                companias={companias}
                sendNotificacions={sendNotificacions}
                message={message}
                setMessage={setMessage}
                Estados={Estados}
              />
            </Col>
            <Col desktop={12}>
              {cobranzas.length !== 0 ? (
                <TableStyled
                  rechazosTabla={cobranzas}
                  setShowDetails={setShowDetails}
                  setRowSelected={setCobranzaSelected}
                  setOrder={null}
                  pagination={pagination}
                  setPage={setPage}
                  companias={companias}
                  setCompanias={setCompanias}
                  filter={filter}
                  order={null}
                  btnOrder={false}
                  btnDownload={false}
                />
              ) : null}
              {
                    errorMain !== ''  ?
                    <>
                    <NoHayBusquedas>
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9593 0C9.85123 0 0.000976562 9.85025 0.000976562 21.9583C0.000976562 34.0663 9.85123 43.9166 21.9593 43.9166C34.0673 43.9166 43.9176 34.0663 43.9176 21.9583C43.9176 9.85025 34.0674 0 21.9593 0ZM21.9593 41.3333C11.2746 41.3333 2.58427 32.643 2.58427 21.9583C2.58427 11.2736 11.2746 2.58329 21.9593 2.58329C32.644 2.58329 41.3343 11.2736 41.3343 21.9583C41.3343 32.643 32.644 41.3333 21.9593 41.3333Z" fill="#787878" />
                            <path d="M61.6211 59.7962L37.485 35.66C36.9813 35.1563 36.1623 35.1563 35.6586 35.66C35.1548 36.1638 35.1548 36.9827 35.6586 37.4865L59.7946 61.6225C60.0478 61.8731 60.3784 61.9997 60.7091 61.9997C61.0398 61.9997 61.3704 61.8732 61.6211 61.6225C62.1248 61.1188 62.1248 60.3 61.6211 59.7962Z" fill="#787878" />
                            <path d="M31.1667 23H12.8333C12.3733 23 12 22.552 12 22C12 21.448 12.3733 21 12.8333 21H31.1667C31.6267 21 32 21.448 32 22C32 22.552 31.6267 23 31.1667 23Z" fill="#787878" />
                        </svg>
                        <h3>{errorMain}</h3>
                    </NoHayBusquedas>
                    </>
                    : null
                  }
            </Col>
          </Grid>
          <div id="table-container">
            {
              //show details
              showDetails ? (
                <Modal
                  isModalOpen={showDetails}
                  setIsModalOpen={setShowDetails}
                  hideCross={false}
                  loader={false}
                >
                  {/* Details Main */}
                  <DetailsMain>
                    <div className="modalMail">
                      <h2 id="titulo">Detalles de notificación</h2>
                    </div>
                    {
                      Object.values(detallesNotificacion).length > 0 ?
                        <Col desktop={12}>
                          <h3>Datos de envio.</h3>
                          <div>Tipo de Mensaje: <b> {detallesNotificacion.template} </b></div>
                          <div>Nombre y Apellido: <b> {detallesNotificacion.nombre} </b></div>
                          <div>Correo electrónico: <b> {detallesNotificacion.email} </b> | enviado: <b>{detallesNotificacion.estado} </b></div>
                          <div>Fecha de ingreso: <b> {dayjs(detallesNotificacion.createdAt).format("YYYY-MM_DD HH:mm")} </b></div>
                          <div>Estado actual del correo enviado: <b> [En construcción...] </b></div>
                          <br />

                          <h3>Datos del mensaje.</h3>
                          <div>Compañía: <b> {(companias.find(c => c.numero === detallesNotificacion.id_compania)).nombre} </b></div>
                          <div>Póliza: <b> {detallesNotificacion.poliza}</b></div>
                          {detallesNotificacion.importe ? <div>Importe: <b> {detallesNotificacion.importe}</b></div> : null}
                          {detallesNotificacion.fecha_vencimiento ? <div>Fecba de Vencimiento: <b> {detallesNotificacion.fecha_vencimiento}</b></div> : null}
                          {detallesNotificacion.periodo_adeudado ? <div>Periodo Adeudado: <b> {detallesNotificacion.periodo_adeudado}</b></div> : null}
                          {detallesNotificacion.motivo_rechazo ? <div>Motivo de Rechazo: <b> {detallesNotificacion.motivo_rechazo}</b></div> : null}
                          {detallesNotificacion.ramo ? <div>Ramo: <b> {detallesNotificacion.ramo}</b></div> : null}
                        </Col>
                        : null
                    }

                    <Col desktop={12}>
                      <br />
                      <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        onClick={uploadCsv}
                        disabled={true}
                        target={"_blank"}
                      >
                        Editar
                      </Button>
                    </Col>
                  </DetailsMain>
                </Modal>
              ) : null
            }
            {showFormCsv ? (
              <Modal
                isModalOpen={showFormCsv}
                setIsModalOpen={setShowFormCsv}
                hideCross={false}
                loader={false}
              >
                {/* Details Main */}
                <DetailsMain>
                  <div className="modalMail">
                    <h2 id="titulo">Cargar notificaciones desde CSV</h2>
                    <div id="input-container">
                      <InputSelect
                        labelName={'Plantilla'}
                        options={plantillas}
                        name={'plantilla'}
                        onChange={ e => setTemplate(e.target.value)}
                        value={template}
                      />
                      <InputSelect
                        labelName={"Compañía"}
                        options={companias.map(c => ({ label: c.nombre, value: c.numero }))}
                        value={companiaCsv}
                        onChange={e => setCompaniaCsv(e.target.value)}
                      />

                      <div>
                        {btnUpAvailable ?
                          <p>Campos requeridos en el csv para la notificacion: <br /> <b>{listado}</b> </p>
                          : null
                        }
                      </div>
                      <br />
                      <br />
                      {
                        (
                          btnUpAvailable ?
                            <InputFileRec2
                              type="file"
                              accept=".csv"
                              id="csvFile"
                              onChange={(e) => {
                                setCsvFile(e.target.files[0]);
                                e.target.value = "";
                              }}
                              name={"CSV"}
                              text={"Cargar archivo"}
                            ></InputFileRec2>
                            : null
                        )
                      }

                      {csvFile ? (
                        <div id="archivo-cargado">
                          <p>{csvFile.name}</p>
                          <a onClick={deleteFile}>
                            <BiTrash></BiTrash>
                          </a>
                        </div>
                      ) : null}
                      <br />
                      <br />
                    </div>
                  </div>
                  {dataFormated.length !== 0 ? (
                    <>
                      <Table padding={'10px'}>
                        <thead>
                          <tr>
                            {/* muestra la cantidad de columnas segun el modelo del csv */}
                            <th colSpan={camposRequeridos.length}>Datos archivo</th>
                            <th colSpan={4}>Datos sistema</th>
                          </tr>
                          <tr>
                            {camposRequeridos.map(c => <th> {c} </th>)}
                            <th className="colum-nombre">Nombre</th>
                            <th>Correo</th>
                            <th className="colum-compañia">Compañia</th>
                            {['4', '2'].includes(template) ? <th>Ramo</th> : null}
                          </tr>
                        </thead>
                        <tbody>
                          {dataFormated.map((d, index) => {
                            return (
                              <tr key={index}>
                                {
                                  Object.keys(d).map(field => {
                                    if (!['id_compania', 'id_user', 'id_template'].includes(field)) {
                                      return (d[field] === 'ERROR' ? <td className="error_field"> <b>ERROR</b> </td> : <td> {d[field]} </td>)
                                    }
                                    else {
                                      return null;
                                    }
                                  }
                                  )
                                }

                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  ) : null}

                  <Col desktop={12}>
                    <Button
                      backgroundColor={true}
                      borderColor={false}
                      textColor={false}
                      onClick={uploadCsv}
                      disabled={isDisabled || dataFormated.length === 0}
                      target={"_blank"}
                    >
                      Cargar datos
                    </Button>
                    <Button
                      backgroundColor={false}
                      borderColor={true}
                      textColor={true}
                      onClick={() => { deleteFile(); setCompaniaCsv(0); setTemplate(0) }}
                      disabled={isDisabled || dataFormated.length === 0}
                    >
                      Limpiar Formulario
                    </Button>
                  </Col>
                </DetailsMain>
              </Modal>
            ) : null}
          </div>
        </Inner>
      </Wrapper>

    </div>
  )
}

export default Cobranzas;