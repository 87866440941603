import { Button, Grid, Col } from "../../Index";
import {
  TickWrapper,
  SuccessTitle,
  ErrorTitle,
  LoadingContainer,
  IconErrorContainer,
  ErrorContainer,
  SuccessContainer,
} from "./styled.js";
import { useSolicitudContext } from "../../../context/SolicitudContext";
import SpinnerLoading from "../../SpinnerLoading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { VscError } from "react-icons/vsc";
import NewButton from "../../NewButton/index.jsx";
import AnimacionExito from "../../AnimacionExito";
import NewTitle from "../../NewTitle";

const EnviarSolicitud = () => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const { solicitud, asegurado, seguro } = useSolicitudContext();
  const { loading, error, errorMessage, postearSolicitudRetry } = solicitud;

  console.log(errorMessage);

  return (
    <Grid colGap={21} rowGap={21} narrow={true} centerItems={true}>
      {loading && (
        <Col desktop={12}>
          <LoadingContainer>
            <SpinnerLoading text="Enviando solicitud..." />
          </LoadingContainer>
        </Col>
      )}

      {errorMessage && error ? (
        <Col desktop={12}>
          <SuccessContainer>
            <Col desktop={12}>
              <SuccessTitle>
                <IconErrorContainer>
                  <VscError></VscError>
                </IconErrorContainer>
                <NewTitle>Hubo un problema al cargar la solicitud</NewTitle>
                <p>
                  Se produjo un error de conexión al intentar crear la
                  solicitud. ¡Volvé a intentarlo! No hace falta volver a cargar
                  los datos
                </p>
                <NewButton
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={postearSolicitudRetry}
                >
                  Intentar de nuevo
                </NewButton>
              </SuccessTitle>
            </Col>
          </SuccessContainer>
        </Col>
      ) : null}

      {error && !errorMessage ? (
        <Col desktop={12}>
          <ErrorContainer>
            <div>
              <IconErrorContainer>
                <VscError></VscError>
              </IconErrorContainer>
              <ErrorTitle>Error al cargar solicitud</ErrorTitle>
            </div>
            <span>
              El error fue notificado al equipo de desarrollo para ser revisado.
            </span>
            <Link
              to={{
                pathname:
                  user.id_rol === 10 || user.id_rol === 22
                    ? "/HistorialSolicitudes"
                    : "/SolicitudEmision",
              }}
            >
              <NewButton
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
              >
                {" "}
                Volver al inicio{" "}
              </NewButton>
            </Link>
          </ErrorContainer>
        </Col>
      ) : null}

      {!loading && !error && (
        <Col desktop={12}>
          <SuccessContainer>
            <Col desktop={12}>
              <AnimacionExito />
            </Col>
            <Col desktop={12}>
              <SuccessTitle>
                <NewTitle>¡Solicitud enviada a Emisión!</NewTitle>
                <p>
                  Podes ver la solicitud creada en tu historial de solicitudes
                </p>
                <Link
                  to={{
                    pathname:
                      user.id_rol === 10 || user.id_rol === 22
                        ? "/HistorialSolicitudes"
                        : "/SolicitudEmision",
                  }}
                >
                  <NewButton
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    icon={false}
                  >
                    Volver a{" "}
                    {user.id_rol === 10 || user.id_rol === 22
                      ? "Historial de Solicitudes"
                      : "Solicitudes de Emisión"}
                  </NewButton>
                </Link>
              </SuccessTitle>
            </Col>
          </SuccessContainer>
        </Col>
      )}
    </Grid>
  );
};

export default EnviarSolicitud;
