// Icons
import docImage from '../assets/images/doc_cuadrado.png'
import docVideo from '../assets/images/docVideo.png'

export const CLIENTE_LABELS = {
    nombre: 'Nombre',
    dni: 'DNI',
    telefono: 'Teléfono',
    email: 'Email',
}

export const SERVICIO_LABELS = {
    marca: 'Marca',
    modelo: 'Modelo',
    material: 'Material',
    color: 'Color',
    compania: 'Compañia',
    rodado: "Rodado",
    plan: 'Plan',
    valor_contratado: "Valor Contratado",
    suma_asegurada: "Suma Asegurada",
    producto: "Producto",
    transmision: "Marca y mod. transmisión",
    num_serie: "Num. Serie",
    tam_cuadro: "Tamaño Cuadro",
    link_mercadolibre: "Mercado Libre",
    accesorios: "Accesorios",
}

export const PAGO_LABELS = {
    nro_tarjeta: 'Nro. Tarjeta',
    marca_tarjeta: 'Tarjeta',
    vto_tarjeta: 'Vencimiento',
    banco: 'Banco',
    nombre_titular: 'Titular',
    dni_titular: 'DNI Titular',
    cbu: "CBU",
}

export const ARCHIVOS_AUTOGESTION_LABELS = {
    archivo_video: 'Video',
    archivo_factura_bicicleta: 'Factura',
    archivo_frente_dni: 'Frente DNI',
    archivo_num_serie_bicicleta: 'Num. Serie',
    archivo_bicicleta: 'Bicicleta',
}


export const ICONOS_ARCHIVOS_AUTOGESTION = {
    video: docVideo,
    document: docImage,
}

export const PREMIOS_LABELS = {
    cantidaddeoperaciones: "Cant. operaciones",
    competencias: "Competencias",
    operaciones: "Operaciones",
    grande: "Grande",
    camion: "Camión",
    referidoscrosselingautos: "Ref / Cross Autos",
    referidoscrossellingmotos: "Ref / Cross Motos",
    hogar: "Hogar",
    sistemadepuntos: "Sistema de puntos",
    generalgrupal: "General grupal",
    superarmespasado: "Superar mes pasado",
    tarjetadecredito: "Tarjeta de crédito",
    breakfast: "Breakfast",
    total: "Total"
}