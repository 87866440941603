import axiosRequest from "../utils/axiosConfig";
import formatSelectData from "../utils/formatSelectData";

// Planes
export const obtenerPlanes = async (landing, producto) => {
    const planes = await axiosRequest.get(`/planes_landings/${landing}/${producto}`);
    return planes.data
}

// Planes
export const actualizarPlan = async (planId, data, coberturas, detalles, beneficios, beneficiosParaBorrar, detallesParaBorrar, tieneValorCuota) => {
    const plan = await axiosRequest.put(`/planes_landings/${planId}`, { data, coberturas, detalles, beneficios, beneficiosParaBorrar, detallesParaBorrar, tieneValorCuota });
    return plan
}

// Actualizar orden de planes
export const reordenarPlanes = async (planes) => {
    const ordenPlanes = await axiosRequest.put("/orden_planes_landings", planes)
    return ordenPlanes
}

// Obtener companias
export const obtenerCompanias = async () => {
    const companias = await axiosRequest.get("/companias_landings");
    const companiasFormatted = formatSelectData(
        companias.data,
        "numero",
        "nombre",
        "numero"
      );
    return { companias: companias.data, companiasFormatted }
}

// Obtener productos
export const obtenerProductos = async (landing) => {
    const productos = await axiosRequest.get(`/productos_landing/${landing}`);
    const productosFormatted = formatSelectData(
        productos.data,
        "numero",
        "descripcion",
        "numero"
      );
    return productosFormatted
}

// Obtener coberturas
export const obtenerCoberturas = async (landing) => {
    const coberturas = await axiosRequest.get(`/coberturas_landings/${landing}`)
    return coberturas.data
}