import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const CargaSolicitud = styled.div`
  .stepByStep {
     
    font-weight: 600;
    font-size: 18px;
    color: ${leerColor(colores.celeste)};
  }
  .stepByStep:first-child::before {
    content: none;
  }
  ul li::before {
    content: "";
    position: absolute;
    top: 50%;
    height: 6px;
    width: 100%;
    right: 50%;
    transform: translateY(-50%);
    z-index: -1;
    background: linear-gradient(
      to left,
      ${leerColor(colores.celestePasos)} 50%,
      ${leerColor(colores.verde)} 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 200ms ease-in-out;
  }
  ._2Jtxm ._2kL0S, ._2Jtxm ._2JvrO { 
    // Steps todos //
    background-color: #e7eaf3;
    height: 40px;
    width: 40px;
  }
  ._2Jtxm._2ZUAI ._2JvrO {
    // Step completado //
    background-color: ${leerColor(colores.verde)};
    border-color: ${leerColor(colores.verde)};
    color: white;
    animation: _3SDnc 400ms ease-in-out forwards;
  }
  ._2Jtxm._35Ago ._2kL0S {
    // Step actual //
    background-color: ${leerColor(colores.celeste)};
    color: white;
    animation: _JAh3L 400ms ease forwards;
  }
  ._2Jtxm ._2kL0S, ._2Jtxm ._2JvrO {
    // Step faltantes //
    background-color: ${leerColor(colores.blanco)};
    border: 2px solid ${leerColor(colores.celeste)};
  }

  ._2_g61 {   
    //Step container // 
    min-height: 60vh; 
  }

  .progressClass {
    background-color: ${leerColor(colores.blanco)}!important;
  }
  /* ul li span{
    border: 1px solid ${leerColor(colores.celeste)}!important;
    background-color: ${leerColor(colores.blanco)}!important;
  } */
  .btn-prev {
     
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 26px;
    border-radius: 5px;
    background-color: ${leerColor(colores.blanco)}!important;
    border: 1px solid ${leerColor(colores.celeste)};
    color: ${leerColor(colores.celeste)}!important;
  }
  .btn-next {
     
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 26px;
    border-radius: 5px;
    background-color: ${leerColor(colores.celeste)}!important;
    color: ${leerColor(colores.blanco)} !important;
  }
  .btn-prev:hover, .btn-next:hover{
    box-shadow: none;
  }
`;
export const Titulo = styled.h2`
   
  color: ${leerColor(colores.celeste)};
`;
