import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  .estadistica-container {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    gap: 6px;
    box-sizing: border-box;
    padding: 10px 20px;
    /* height: 142.5px; */
    background-color: ${({ theme }) => theme.background};
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    transition: all 0.5s;

    .icon-card {
      border-radius: 100%;
      display: flex;
      align-items: center;
      padding: 10px 10px;
      box-sizing: border-box;
      color: ${leerColor(colores.azulRebranding)};
      background-color: #f7f9fe;
      justify-content: center;
      width: 40px;
    }

    span {
        font-size: 14px;
        color: ${({ theme }) => theme.subtitle};
    }

    h3 {
        margin: 0;
        font-weight: 600;
        font-size: 36px;
        color: ${({ theme }) => theme.title};
    }

    .link {
        color: ${({ theme }) => theme.subtitle};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }

    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }

`;
