import React, { useState, useEffect } from "react";
import {
  Titulo,
  Grid,
  Col,
  Inner,
  Wrapper,
  NavMenu,
  BurgerMenu,
  Vendedores,
  RenovacionesPAS,
} from "../components/Index";
//import axios from "axios";
import axiosRequest from '../utils/axiosConfig'

import { Tab, TabsContainer } from "./styled";
import Emisiones from "../components/FedPat/Tabs/Emosiones";
// check componentes





const FedPat = (props) => {
    // datos sesion
    const user = JSON.parse(localStorage.getItem('userInfo'))
    const allowedRoles = [1, 2, 6];

    // menu 
    const [open, setOpen] = useState(false)

    // array productores
    const [productores, setProductores] = useState([])
    const [vendedores, setVendedores] = useState([])
    const [productoresSelect, setProductoresSelect] = useState([])
    const [mesesSelect, setMesesSelect] = useState([])
    const [vendedoresSelect, setVendedoresSelect] = useState([])

    // tabs
    const [selectedTab, setSelectedTab] = useState('productores')


    async function getVendedores(){
        const send = await axiosRequest.get(`/vendedores`);
        setVendedores(send.data)
    }

    async function getProductores(){
        const send = await axiosRequest.get(`/productores`);
        setProductores(send.data.msg)
    }

    const setMeses = ()=>{
        let toSelect = ['Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        toSelect = toSelect.map((m,index) =>{
            return{
                'label': m,
                'value': index +4 ,
                'key':  index +4
            }
        })
        setMesesSelect([...toSelect]);
    }

    // setea los productores para el filtro
    useEffect(()=>{
        if(productores.length!==0){
            let toSelect = productores.map(p => {
                return {
                    'label': p.productor,
                    'value': p.id,
                    'key':  p.id
                }
            })
            toSelect = [...toSelect, {
                    'label': 'TODOS',
                    'value': 0,
                    'key':  0
            }]
            setProductoresSelect(toSelect)
        }
    },[productores])

    useEffect(()=>{
        if(vendedores.length!==0){
            let toSelect = vendedores.map(v => {
                return {
                    'label': v.nombre,
                    'value': v.numero,
                    'key':  v.numero
                }
            })
            toSelect = [...toSelect, {
                'label': 'TODOS',
                'value': 0,
                'key':  0
            }]
            setVendedoresSelect(toSelect)
        }
    },[vendedores])

    // setea los select con productores y meses
    useEffect(()=>{
        getProductores()
        getVendedores()
        setMeses();
    },[]);

    return (
        <div className="App">
            <BurgerMenu open={open} setOpen={setOpen}/>
            <NavMenu open={open} setOpen={setOpen} user={user} active={"Usuarios"} />
                               
            <Wrapper>
                <Inner>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12}>
                            <Titulo textColor={false}>Federación Patronal</Titulo>
                        </Col>
                        <Col desktop={12}>
                                <TabsContainer disabledMargin={true}>
                                    <Tab selected={selectedTab === "productores" ? true : false} onClick={()=> setSelectedTab('productores')}>Renovaciones PAS</Tab>
                                    <Tab selected={selectedTab === "emisiones" ? true : false} onClick={()=> setSelectedTab('emisiones')}>Emisiones PAS</Tab>
                                    <Tab selected={selectedTab === "vendedores" ? true : false} onClick={()=> setSelectedTab('vendedores')}>Emisiones SeguroWeb</Tab>
                                </TabsContainer>
                        </Col>

                        {/* tabs */}
                        
                    {
                        selectedTab === "productores"?
                        <RenovacionesPAS 
                            mesesSelect={mesesSelect}
                            productoresSelect={productoresSelect}
                        />
                        :
                        null

                    }    

                    {
                        selectedTab==='emisiones'?
                        <Emisiones 
                            mesesSelect={mesesSelect}
                            productoresSelect={productoresSelect}
                        />
                        :null
                    }

                    {
                        selectedTab==='vendedores'?
                        <Vendedores 
                            mesesSelect={mesesSelect}
                            vendedoresSelect={vendedoresSelect}
                        />
                        :null
                    }
                    </Grid>            
                </Inner>
            </Wrapper>

        </div>
    );
};

export default FedPat;