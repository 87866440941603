import axiosRequest from "../utils/axiosConfig";

// Borrar archivo
export const borrarArchivo = async (path) => {
   return await axiosRequest.delete("/delete_file", {
    data: { path: "./app/public/" + path },
  });
}


