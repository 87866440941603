import { useState } from "react";
import { reordenarPlanes } from "../../../../services/Landings";

export const useDragAndDrop = (planes, handlePlanes) => {
  const [draggingPlan, setDraggingPlan] = useState(null);

  const onDragOver = (e) => e.preventDefault()

  const handleDrop = async (e, item) => {
    let planesNew = [...planes];

    const itemPositionIndex = planesNew.findIndex((plan) => plan.id === item.id)
    const draggingPlanIndex = planesNew.findIndex((plan) => plan.id === draggingPlan.id)

    planesNew.splice(draggingPlanIndex, 1);
    planesNew.splice(itemPositionIndex, 0, draggingPlan);

    handlePlanes(planesNew);

    try {
      await reordenarPlanes(planesNew)
    } catch (error) {
      console.log(error);
    }
  };

  const handleDraggingPlan = (item) => setDraggingPlan(item)

  return {
    onDragOver,
    handleDrop,
    handleDraggingPlan
  }
};
