import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  img {
    width: 100px;
    display: flex;
  }

  .logo-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h3 {
      font-size: 16px;
    }
  }

  span {
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;
  }

  .limite-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 6px;
    max-height: 290px;
    
    .divider {
      height: 1px;
      width: 100%;
      opacity: 0.2;
      margin: 0;
    }
  }


`;

export const LimiteCard = styled.div`
    background-color: ${( {theme} ) => theme.background};
    border: 1px solid ${( {theme} ) => theme.border};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    gap: 20px;

    .container-limite-card {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    span {
        color: ${({ theme }) => theme.subtitle};
        font-size: 14px;
    }

    h3 {
        color: ${({ theme }) => theme.title};
        margin: 0;
        font-size: 30px;
        font-weight: 400;
    }
`