import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DivCarousel, Video } from "./styled";
import docImage from '../../assets/images/doc_cuadrado.png'
import docVideo from '../../assets/images/docVideo.png'
import ImgGys from "../ImgGys/Index";
import DocumentGys from "../DocumentGys";
import VideoGys from "../VideoGys/Index";

function CarouselSlider({ items, openCarousel, setOpenCarousel }) {

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg width="20" height="30" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 6C16 5.448 15.6838 5 15.2941 5L0.70588 5C0.316233 5 5.34233e-09 5.448 1.19249e-08 6C1.85074e-08 6.552 0.316233 7 0.70588 7L15.2941 7C15.6838 7 16 6.552 16 6Z" fill="#ffffff" />
          <path d="M17 5.99873C17 5.75614 16.907 5.51379 16.7199 5.32925L11.6299 0.277505C11.2571 -0.0925016 10.6527 -0.0925016 10.2796 0.277505C9.9068 0.647511 9.9068 1.24738 10.2796 1.61762L14.6953 5.99988L10.2796 10.3824C9.9068 10.7524 9.9068 11.3525 10.2796 11.7225C10.6527 12.0925 11.2571 12.0925 11.6299 11.7225L16.7199 6.67052C16.907 6.48367 17 6.24131 17 5.99873V5.99873Z" fill="#ffffff" />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg width="20" height="30" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 6C1 6.552 1.31623 7 1.70588 7L16.2941 7C16.6838 7 17 6.552 17 6C17 5.448 16.6838 5 16.2941 5L1.70588 5C1.31623 5 1 5.448 1 6Z" fill="#ffffff" />
          <path d="M-2.92671e-09 6.00127C7.67704e-09 6.24386 0.0929698 6.48621 0.280075 6.67075L5.37012 11.7225C5.74293 12.0925 6.34735 12.0925 6.72039 11.7225C7.0932 11.3525 7.0932 10.7526 6.72039 10.3824L2.30467 6.00012L6.72039 1.61762C7.0932 1.24762 7.0932 0.647511 6.72039 0.277505C6.34735 -0.0925015 5.74293 -0.0925014 5.37011 0.277505L0.280075 5.32948C0.0929698 5.51633 -1.35304e-08 5.75869 -2.92671e-09 6.00127V6.00127Z" fill="#ffffff" />
        </svg>
      </div>
    );
  }

  const files = items.map((item, index) => {
    let file;
    if (item.descripcion_bien_asegurado.includes('image'))
      file = <ImgGys url={item.descripcion_bien_asegurado} index={index} descarga={true} />
    if (item.descripcion_bien_asegurado.includes('document'))
      file = <DocumentGys url={item.descripcion_bien_asegurado} index={index} />
    if (item.descripcion_bien_asegurado.includes('video'))
      file = <VideoGys url={item.descripcion_bien_asegurado} index={index} descarga={true} />

    return (
      <div key={index}> 
        {file} 
      </div>
    )
  })

  const settings = {
    customPaging: function (i) {
      let file 
      if (items[i].descripcion_bien_asegurado.includes('image')){
        file = <ImgGys url={items[i].descripcion_bien_asegurado} index={i} descarga={false} />
      }
      else if (items[i].descripcion_bien_asegurado.includes('video')){
        file = <img src={docVideo} alt={"documento"} style={{ width: "75px"}} descarga={false} />
      }
      else {
        file = <img src={docImage} alt={"documento"} style={{ width: "75px"}}/>
      }
  
      return (
        <div key={i}> 
          {file} 
        </div>
      )
    },
    /*
    customPaging: function (i) {
      console.log(i)
      console.log(files)
      console.log(items)
      console.log(files[i])
      const tipo = files[i].props.children.type.name;
      if (tipo === "ImgGys") {
        return (
          <a>
            {files[i]}
          </a>
        );
      } else if (tipo === "VideoGys") {
        return (
          <a>
            {files[i]}
          </a>
        );
      } else {
        return (
          <a>
            <img src={docImage} alt={"documento"} style={{ width: "75px"}}/>
          </a>
        );
      }
    },
    */
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  
  return (
    <DivCarousel className={!openCarousel ? 'closeCarousel' : ''} >
      <div className={'carousel'} onClick={() => setOpenCarousel(!openCarousel)}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.3182 4.21142C19.5241 4.01931 19.6902 3.7885 19.8069 3.53217C19.9237 3.27584 19.9888 2.999 19.9987 2.71746C20.0085 2.43592 19.9629 2.1552 19.8643 1.89132C19.7658 1.62744 19.6163 1.38557 19.4243 1.17953C19.2324 0.973477 19.0017 0.807284 18.7456 0.690436C18.4895 0.573587 18.2129 0.508371 17.9316 0.498511C17.6502 0.488652 17.3697 0.534343 17.1061 0.632974C16.8424 0.731605 16.6007 0.881245 16.3949 1.07335L10.1238 6.92478L4.27702 0.646503C3.88581 0.245493 3.35331 0.0136501 2.79346 0.000583692C2.23362 -0.0124827 1.6909 0.194266 1.28144 0.576589C0.871972 0.958913 0.62829 1.48644 0.602532 2.04629C0.576775 2.60615 0.770989 3.15386 1.14361 3.57222L6.99035 9.84836L0.717111 15.6998C0.503956 15.8899 0.330918 16.1208 0.208161 16.3788C0.0854039 16.6369 0.0154023 16.9168 0.00226843 17.2023C-0.0108655 17.4878 0.0331324 17.773 0.131679 18.0412C0.230225 18.3094 0.381333 18.5552 0.576126 18.7642C0.770918 18.9731 1.00547 19.141 1.266 19.258C1.52653 19.375 1.8078 19.4387 2.09327 19.4453C2.37873 19.452 2.66265 19.4015 2.92835 19.2968C3.19405 19.1921 3.43616 19.0354 3.64048 18.8357L9.91158 12.9864L15.7583 19.2626C15.9471 19.4798 16.1777 19.6568 16.4362 19.783C16.6948 19.9092 16.9762 19.982 17.2635 19.9971C17.5508 20.0121 17.8382 19.9691 18.1085 19.8707C18.3789 19.7722 18.6266 19.6203 18.8371 19.4239C19.0475 19.2276 19.2163 18.9909 19.3333 18.7279C19.4504 18.4649 19.5134 18.181 19.5185 17.8931C19.5236 17.6052 19.4707 17.3192 19.363 17.0522C19.2554 16.7852 19.0951 16.5427 18.8917 16.339L13.0471 10.0629L19.3182 4.21142Z" fill="white" />
        </svg>
      </div>
      <Slider {...settings}>
        { files }
      </Slider>
    </DivCarousel>
  );
}

export default CarouselSlider;
