import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Boton = styled.a`
  background-color: #1a56ff;
  color: #fff;
  border: none;
  width: fit-content;
  padding: 0 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: "DM Sans", sans-serif;
  height: 46px;
  transition: all 0.5s;

  //margin-right: 8px;

  background-color: ${(props) =>
    props["data-backgroundcolor"]
      ? '#1a56ff'
      : props.theme.secondary_button_background};
  border: 1px solid
    ${(props) =>
      props["data-bordercolor"] ? "#1a56ff" : "none"};
  color: ${(props) =>
    props["data-textcolor"]
      ? props.theme.secondary_button_text
      : leerColor(colores.blanco)};
  text-decoration: none;
  pointer-events: ${(props) => (props["data-disabled"] ? "none" : "")};
  font-size: ${(props) => props.tamaño == "chico" && "12px"} !important;

  ${(props) =>
    props["data-disabled"] === true &&
    props["data-backgroundcolor"] &&
    css`
      & {
        background-color: ${leerColor(colores.gris)};
      }
    `}

  ${(props) =>
    props.icon &&
    props.direction === "before" &&
    css`
      &::before {
        content: url(${(props) => props.icon});
        padding-right: 10px;
      }
    `}


  ${(props) =>
    props.icon &&
    props.direction === "after" &&
    css`
      &::after {
        content: url(${(props) => props.icon});
        padding-left: 10px;
      }
    `}
    
  
    ${(props) =>
    props["data-disabled"] === true &&
    props.icon &&
    !props["data-backgroundcolor"] &&
    css`
      & {
        color: ${leerColor(colores.gris)};

        &::before {
          content: url(${(props) => props.icon});
        }

        &::after {
          content: "";
        }
      }
    `}

  :hover {
    opacity: 0.9;
  }

  // Ícono
  span {
    margin: 4px 8px 0px 0px;
    font-size: ${(props) => (props.tamaño == "chico" ? "15px" : "16px")};
  }
`;
