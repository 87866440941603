import { FiCalendar, FiClock, FiDollarSign, FiThumbsUp } from "react-icons/fi";
import {
  AcuerdoContainer,
  Container,
  FechasContainer,
  Header,
  PeriodoContainer,
} from "./styled";
import dayjs from "dayjs";

const CardExcepcion = ({ excepcion }) => {

    console.log(excepcion)

  return (
    <Container>
      <Header>
        <h3>{excepcion.tipo_excepcion}</h3>
        <div>
          <span className="periodo">{excepcion.periodo_excepcion}</span>
        </div>
      </Header>

      <PeriodoContainer>
        {/* DESDE / HASTA */}
        {excepcion.periodo_excepcion === "Desde / Hasta" ? (
          <span className="periodo-titulo">
            <span className="icon-seccion">
              <FiCalendar />{" "}
            </span>
            Excepción desde / Hasta:
          </span>
        ) : null}
        {excepcion.periodo_excepcion === "Desde / Hasta" ? (
          <FechasContainer>
            <span>
              {dayjs(excepcion.periodo_fecha_desde_excepcion).format(
                "DD/MM/YYYY"
              )}
            </span>
            <span>
              {dayjs(excepcion.periodo_fecha_hasta_excepcion).format(
                "DD/MM/YYYY"
              )}
            </span>
          </FechasContainer>
        ) : null}

        {/* FECHA */}
        {excepcion.periodo_excepcion === "Fecha" ? (
          <span className="periodo-titulo">
            <span className="icon-seccion">
              <FiCalendar />{" "}
            </span>
            Excepción para la fecha:
          </span>
        ) : null}
        {excepcion.periodo_excepcion === "Fecha" ? (
          <FechasContainer>
            <span>
              {dayjs(excepcion.periodo_fecha_excepcion).format("DD/MM/YYYY")}
            </span>
          </FechasContainer>
        ) : null}

        {/* DIA */}
        {excepcion.periodo_excepcion === "Día" ? (
          <span className="periodo-titulo">
            <span className="icon-seccion">
              <FiCalendar />{" "}
            </span>
            Todos los {excepcion.periodo_dia_excepcion} desde / hasta:
          </span>
        ) : null}
        {excepcion.periodo_excepcion === "Día" ? (
          <FechasContainer>
            <span>
              {dayjs(excepcion.periodo_fecha_excepcion).format("DD/MM/YYYY")}
            </span>
            <span>
              {dayjs(excepcion.periodo_fecha_hasta_excepcion).format(
                "DD/MM/YYYY"
              )}
            </span>
          </FechasContainer>
        ) : null}
      </PeriodoContainer>
      <AcuerdoContainer>
        <span className="periodo-titulo">
          <span className="icon-seccion">
            <FiClock />{" "}
          </span>
          Horario de la excepción
        </span>
        {excepcion.hora_desde_excepcion && excepcion.hora_hasta_excepcion ? (
          <FechasContainer>
            <span>{excepcion.hora_desde_excepcion.includes("-") ? dayjs(excepcion.hora_desde_excepcion).format(
                "HH:mm"
              ) : excepcion.hora_desde_excepcion}hs
            </span>
            <span>
            {excepcion.hora_hasta_excepcion.includes("-") ? dayjs(excepcion.hora_hasta_excepcion).format(
                "HH:mm"
              ) : excepcion.hora_hasta_excepcion}hs
            </span>
          </FechasContainer>
        ) : 
        <FechasContainer>
            <span>Jornada completa</span>
        </FechasContainer>
        }
      </AcuerdoContainer>
      {excepcion.tipo_excepcion === "Acuerdo" ?
      <AcuerdoContainer>
        <span className="periodo-titulo">
          <span className="icon-seccion">
            <FiThumbsUp />{" "}
          </span>
          Motivo del acuerdo
        </span>
        {excepcion.motivo_acuerdo ? (
          <FechasContainer><span>{excepcion.motivo_acuerdo}</span></FechasContainer>
        ) : (
          <FechasContainer><span>No se especificó motivo</span></FechasContainer>
        )}
      </AcuerdoContainer>
      : null}
      {excepcion.tipo_excepcion === "Licencia" ? 
      <AcuerdoContainer>
        <span className="periodo-titulo">
          <span className="icon-seccion">
            <FiDollarSign />{" "}
          </span>
          {excepcion.goce_sueldo ? "Goce de sueldo" : "Sin goce de sueldo"}
        </span>

        {excepcion.tipo_goce_sueldo_otros && excepcion.goce_sueldo ? (
          <FechasContainer><span>{excepcion.tipo_goce_sueldo_otros}</span></FechasContainer>
        ) : (
          null
        )}
        {excepcion.tipo_goce_sueldo && excepcion.goce_sueldo && !excepcion.tipo_goce_sueldo_otros ? (
          <FechasContainer><span>{excepcion.tipo}</span></FechasContainer>
        ) : (
          null
        )}
        {excepcion.motivo_sin_goce_sueldo && !excepcion.goce_sueldo ? (
          <FechasContainer><span>{excepcion.motivo_sin_goce_sueldo}</span></FechasContainer>
        ) : (
          null
        )}

      </AcuerdoContainer>
      : null}
    </Container>
  );
};

export default CardExcepcion;
