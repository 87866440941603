import React from "react";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FiArrowLeft } from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import { useForm } from "../../../hooks/useForm";
import { usuarioForm } from "../../../utils/formInitialValues";
import { datosUsuariosReglas } from "../../../utils/formRules";
import { useUsuarios } from "../../../hooks/useUsuarios";
import NewButton from "../../../components/NewButton";
import UsuarioForm from "../../../components/UsuariosForms/UsuarioForm";
import Divider from "../../../components/Divider";
import BackButton from "../../../components/BackButton";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import Success from "../../../components/Success";

const CrearUsuario = () => {
  const { loading, data, success, usuarios } = useUsuarios();
  const { estados, roles } = data;
  const location = useLocation()
  const pathname = location.pathname
  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
  } = useForm(handleSubmit, datosUsuariosReglas, usuarioForm, pathname);

  async function handleSubmit() {
    usuarios.crearUsuario(inputValues);
  }

  return (
    <div>
      <div>
        {/* <Sidebar /> */}
        <Layout>
          <NewWrapper>
            <NewInner>
              <Container>
                <div>
                  <BackButton url={"/Usuarios"} text={"Usuarios"}/>
                  <NewTitlePageSection
                    title={`Crear usuario`}
                    description={"Agregá un nuevo al CRM"}
                  ></NewTitlePageSection>
                </div>
                <Divider />
                {!success ?
                <>
                <UsuarioForm form={{inputValues, handleChange, formErrors, handleValidation}} data={{ estados, roles, loading }}/>
                <Divider />
                <div className="buttons-container">
                  <NewButton
                    backgroundColor={true}
                    onClick={handleValidation}
                    disabled={loading}
                  >
                    {loading ? "Creando usuario..." : "Crear usuario"}
                  </NewButton>
                  <Link to="/Usuarios">
                    <NewButton borderColor={true} textColor={true}>
                      <FiArrowLeft /> Volver a Usuarios
                    </NewButton>
                  </Link>
                </div>
                </>
                :
                <Success title="¡Usuario creado!" description={`El nuevo usuario fue creado correctamente`} link={"/Usuarios"} page="Usuarios" />
                }
              </Container>
            </NewInner>
          </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default CrearUsuario;
