import React, { forwardRef, useEffect, useState } from "react";
import Button from "../Button";
import { Form } from "../Form/styled";
// Icons
import { MdCleaningServices } from "react-icons/md";
import { ImSearch } from "react-icons/im";

const FormStyled = ({ setFilter, companias, estados}, ref ) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const [fieldsFilter, setFieldsFilter] = useState({
    nombre: "",
    poliza: "",
    estado: "",
    mail: "",
    id_compania: "",
    fecha_ini: "",
    fecha_fin: "",
  });

  const [selectEstados, setSelectEstados] = useState([])

  const setFiltro = () => {
    const { nombre, poliza, estado, mail, id_compania, fecha_ini, fecha_fin } =
      fieldsFilter;
    let query = '';
    if(nombre) query += `nombre=${nombre}` 
    if(poliza) query += `&poliza=${poliza}`
    if(estado && estado !== "todos") query += `&estado=${estado}`
    if(mail) query += `&mail=${mail}`
    if(id_compania) query += `&id_compania=${id_compania}`
    if(fecha_ini) query += `&fecha_ini=${fecha_ini}`
    if(fecha_fin) query += `&fecha_fin=${fecha_fin}`
    
    setFilter(query);
  };
  const resetForm = () => {
    setFilter("");
    setFieldsFilter({
      nombre: "",
      poliza: "",
      estado: "",
      mail: "",
      id_compania: "",
      fecha_ini: "",
      fecha_fin: "",
    });
  };

  const addFilter = (e) => {
    const { id, value } = e.target;
    setFieldsFilter({ ...fieldsFilter, [id]: value });
  };





  useEffect(() => {
    const formNotEmpty = Object.values(fieldsFilter).some(
      (field) => field !== ""
    );
    if (formNotEmpty) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [fieldsFilter]);


  useEffect(() => {
    // limpieza de estados no necesarios
    const filtroEstados = estados.filter(e => !["enviado 1", "contactado", "rechazado", "gestion comercial"].includes(e.estado));
    
    const aux = [...filtroEstados, {id: "todos", estado: "todos"}];
    setSelectEstados(aux);

  }, [estados]);

  return (
    <Form ref={ref} valorFechaDesde={fieldsFilter.fecha_ini}>
      {/* Input Nombre */}
      <div className="input-container" id="input-nombre-container">
        <input
          type="text"
          id="nombre"
          name="nombre"
          onChange={addFilter}
          onFocus={addFilter}
          onBlur={addFilter}
          value={fieldsFilter.nombre}
        ></input>
        <label
          className={
            fieldsFilter.nombre == null || fieldsFilter.nombre == ""
              ? "label-down"
              : "label-top"
          }
          htmlFor="nombre"
        >
          Nombre
        </label>
      </div>
      {/* Input Poliza */}
      <div className="input-container" id="input-poliza-container">
        <input
          type="text"
          id="poliza"
          name="poliza"
          onChange={addFilter}
          onFocus={addFilter}
          onBlur={addFilter}
          value={fieldsFilter.poliza}
        ></input>
        <label
          className={
            fieldsFilter.poliza == null || fieldsFilter.poliza == ""
              ? "label-down"
              : "label-top"
          }
          htmlFor="poliza"
        >
          N° Poliza
        </label>
      </div>
      {/* Input Email */}
      <div className="input-container" id="input-email-container">
        <input
          type="email"
          id="mail"
          name="email"
          onChange={addFilter}
          onFocus={addFilter}
          onBlur={addFilter}
          value={fieldsFilter.mail}
        ></input>
        <label
          className={
            fieldsFilter.mail == null || fieldsFilter.mail == ""
              ? "label-down"
              : "label-top"
          }
          htmlFor="mail"
        >
          Email
        </label>
      </div>
      {/* Select Compañias */}
      <div className="select-container" id="select-compania-container">
        <select
          id="id_compania"
          name="compania"
          value={fieldsFilter.id_compania}
          onChange={addFilter}
          onFocus={addFilter}
          onBlur={addFilter}
        >
          <option disabled></option>
          {companias
            ? companias.map((cia) => (
                <option key={cia.numero} value={cia.numero}>
                  {cia.nombre}
                </option>
              ))
            : null}
        </select>
        <label
          className={
            fieldsFilter.id_compania == null || fieldsFilter.id_compania == ""
              ? "label-down"
              : "label-top"
          }
          htmlFor="id_compania"
        >
          Compañia
        </label>
        <i></i>
      </div>
      {/* Select Estado */}
      <div className="select-container" id="select-estado-container">
        <select
          id="estado"
          name="estado"
          value={fieldsFilter.estado}
          onChange={addFilter}
          onFocus={addFilter}
          onBlur={addFilter}
        >
          <option value={''} disabled defaultValue={''}> </option>
          {
            selectEstados.map( e=> {
              return ( <option key={e.id} value={e.id}> {e.estado} </option>)
            })
          }
        </select>
        <label
          className={
            fieldsFilter.estado == null || fieldsFilter.estado == ""
              ? "label-down"
              : "label-top"
          }
          htmlFor="estado"
        >
          Estado
        </label>
        <i></i>
      </div>
      {/* Input Desde */}
      <div className="input-container" id="input-desde-container">
        <input
          type="date"
          id="fecha_ini"
          name="fecha_ini"
          onChange={addFilter}
          onFocus={addFilter}
          onBlur={addFilter}
          value={fieldsFilter.fecha_ini}
          className="fecha"
        ></input>
        <label className="label-top">Desde</label>
      </div>
      {/* Input Hasta */}
      <div className="input-container" id="input-hasta-container">
        <input
          type="date"
          id="fecha_fin"
          name="fecha_fin"
          onChange={addFilter}
          onFocus={addFilter}
          onBlur={addFilter}
          value={fieldsFilter.fecha_fin}
          className="fecha"
        ></input>
        <label className="label-top">Hasta</label>
      </div>
      <div id="botones-buscar-container">
        <Button
          backgroundColor={true}
          borderColor={false}
          textColor={false}
          onClick={setFiltro}
          target={"_blank"}
          disabled={isDisabled}
          tamaño="chico"
        >
          <span><ImSearch></ImSearch></span>
          Buscar
        </Button>
        <Button
          borderColor={true}
          textColor={true}
          onClick={resetForm}
          target={"_blank"}
          tamaño="chico"
        >
          <span>
            <MdCleaningServices></MdCleaningServices>
          </span>
          Limpiar filtros
        </Button>
      </div>
      <hr></hr>
    </Form>
  );
};

export default forwardRef(FormStyled);
