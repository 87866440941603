import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Error = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
  font-size: 14px;
`;

export const Label = styled.label`
  font-family: sans-serif;
  color: hsl(0,0%,17.6%);
  font-size: 18px;
  padding-bottom: 10px;
`;

export const SubtituloHogar = styled.h4`
  margin: 10px 0;
  color: ${leerColor(colores.azul)};
`;

export const Divider = styled.hr`
  border: 1px solid #ececec; 
`;