import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../../constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

    hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 20px 0 20px 0;
  }

  .table-container {
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 6px;
    overflow-y: scroll;
    //min-height: 400px;
    max-height: 298.5px;
    //padding: 0 10px;
    box-sizing: border-box;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    table {
      min-width: 100%;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .subtitle-container {
    display: flex;
    align-items: center;

    .icon-button {
          color: ${({ theme }) => theme.little_icon};
          font-size: 16px;
          padding: 6px;
          box-sizing: border-box;
          border-radius: 100%;
          border: 1px solid ${leerColor(colores.azulRebranding)};
        }

    /* .icon-button {
          color: ${({ theme }) => theme.little_icon};
          font-size: 16px;
          padding: 6px;
          box-sizing: border-box;
          border-radius: 100%;
          border: 1px solid ${leerColor(colores.azulRebranding)};
        }

        span {
          color: ${leerColor(colores.blanco)};
        } */
  }
  
  h2 {
    margin: 0;
    font-size: 18px;
    color: ${leerColor(colores.azul)}
  }

  .cotizador-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.subtitle};
  }
  
  .landing-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .planes-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .productos-limites-container {
    margin-bottom: 20px;
    button {
      width: auto;
      margin: 0px 16px 0px 0px;
      padding: 10px 20px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12.5px;
      line-height: 180%;
      text-align: center;
      letter-spacing: -0.015em;
      color: rgb(136, 136, 136);
      -webkit-font-smoothing: subpixel-antialiased;
      background-color: rgb(246, 246, 246);
      border: 1px solid #eaeaea;
      transition: all 0.2s ease 0s;
      box-sizing: border-box;
      border-radius: 6px;
    }
    .producto-seleccionado {
      border: 1px solid #dae4ff;
      box-shadow: inset 0px 0px 0px 1px #dae4ff;
    }
  }

  .productos-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    button {
      width: auto;
      margin: 0px 16px 0px 0px;
      padding: 10px 20px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12.5px;
      line-height: 180%;
      text-align: center;
      letter-spacing: -0.015em;
      color: ${({ theme }) => theme.subtitle};
      -webkit-font-smoothing: subpixel-antialiased;
      background-color: ${({ theme }) => theme.background};
      border: 1px solid ${({ theme }) => theme.border};
      transition: all 0.5s;
      box-sizing: border-box;
      border-radius: 6px;
    }
    
    .producto-seleccionado {
      border: 1px solid #dae4ff;
      box-shadow: inset 0px 0px 0px 1px #dae4ff;
    }
  }
`

export const Producto = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  //color: ${leerColor(colores.negro)};
  color: ${leerColor(colores.celeste)};
  //margin: ${(props) => (props.margin ? "60px 0 20px 0" : "")};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin: 40px;
`

export const Section = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: rgb(120, 120, 120);
    list-style-type: none;
  }

  li {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      display: flex;
      font-size: 20px;
      cursor: pointer;
      color: ${leerColor(colores.rojo)}
    }
  }

  .agregar-container {
    display: flex;
    flex-direction: column;
  }

  .input-agregar-container {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .botones-container {
    display: flex;
    gap: 10px;
  }
  .plan-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 20px;

    h3 {
      margin: 0;
      font-size: 14px;
    }

    span {
      color: ${leerColor(colores.grisEstados)};
    }
  }

  .empty-state {
    color: ${leerColor(colores.grisEstados)};
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #eaeaea;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .lista-titulo {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: ${leerColor(colores.grisEstados)};
    font-weight: 500;
  }

  .section-title {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 6px;
      color: ${leerColor(colores.azul)};
      display: flex;
      align-items: center;

      .icon {
        color: ${leerColor(colores.celeste)};
        display: flex;
      }
    }
    p {
      margin: 0;
    }
  }

  .switch-calculo {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${leerColor(colores.grisEstados)};
  }
`;