import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./animation.json";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    pointer-events: none;
`;

const AnimacionExito = () => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    renderer: "svg",
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Container>
    <Lottie options={animationOptions} height={220} width={220} />
  </Container>
  )
}

export default AnimacionExito
