import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const DivDescargar = styled.div` 
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  text-decoration: none;
  border-radius: 6px;
  width: 320px;
  padding: 15px 26px 12px;
  background-color: ${props => (props['data-backgroundcolor'] ? leerColor(colores.celeste) : leerColor(colores.blanco))}!important;
  border: 1px solid ${props => (props['data-bordercolor'] ? leerColor(colores.celeste) : '')};
  color: ${props => (props['data-textcolor'] ? leerColor(colores.celeste) : leerColor(colores.blanco))}!important;
  margin: 0 0 40px 0;
  width: 100%;
  box-sizing: border-box;
  p{
    color: ${leerColor(colores.grisEstados)};
    padding-bottom: 10px;
    font-weight: 600;
    margin: 0;
  }
    span{
      font-weight: 400;
    }

  .icon {
    display: flex;
  }

  ${props => props.icon && props.direction === 'after' && css`
    &::after {
      content: url(${props => (props.icon)});
      padding-left: 10px;
    } 
  `}
  a{
    align-self: flex-end;
  }

  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    
  }
  @media (min-width: ${breakpoints.notebook}) {

  }
`;
