import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../constants";

export const EmisionData = styled.div`
  position: relative;

  h6 {
    //font-family: "Raleway";
    /*
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: #bab8b8;
    margin: 0;
    */
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
    margin: 0;
  }
  h3 {
    margin-bottom: 10px;
  }
  p {
    //font-family: "Raleway";
    /*
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    color: #525252;
    margin: 10px 0;
    margin-bottom: 6px;
    */
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    color: ${leerColor(colores.grisEstados)};
    margin: 10px 0;
    margin-bottom: 6px;
  }
  .linkML {
    //font-family: "Raleway";
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    margin: 10px 0;
    margin-bottom: 6px;
    display: inline-block;
  }
  svg {
    margin: 10px 0;
    margin-bottom: 6px;
  }
  .extraCont {
    display: flex;

    svg {
      width: 20px;
      margin-right: 10px;
    }
  }
  .buttonsCont {
    display: flex;
    place-content: flex-end;
  }
  div .buttonsCont a:not(:first-child) {
    margin-left: 25px;
  }
  .actionButtonsCont {
    display: flex;
    justify-content: space-between;
    margin-top: 43px;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
  .dropDown {
    width: 110px;
    border-radius: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 105%;
    right: -3%;
    z-index: 99;
    padding: 20px;
    background-color: ${leerColor(colores.blanco)};
    -webkit-box-shadow: 12px 12px 30px 0px rgba(177, 178, 180, 0.2);
    box-shadow: 12px 12px 30px 0px rgba(177, 178, 180, 0.2);
    color: ${leerColor(colores.grisModales)};

    a {
      margin: 0 !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: white;
      bottom: -10px;
      left: 50%;
      z-index: 99;
      -webkit-transform: translate(-50%, 0) rotate(45deg);
      -ms-transform: translate(-50%, 0) rotate(45deg);
      transform: translate(-50%, 0) rotate(45deg);
    }

    a:hover {
      color: ${leerColor(colores.celeste)};
      font-weight: 700;
    }
  }
  .dropDown a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 12px;
    text-align: left;
  }
  .dropDown a:last-of-type {
    padding-bottom: 0;
  }
  .dropUp {
    display: none;
  }
  ${(props) =>
    props.apiDisabled === true &&
    css`
      .emisionAPI {
        display: none;
      }
      .dropDown a:nth-child(2) {
        padding-bottom: 0;
      }
    `}
`;

export const DivApp = styled.div`
  background-color: ${leerColor(colores.gris)};
  height: 100vh;
`;
// Antes era 'Modal', lo cambie porque se pisa con el componente Modal que tambien se usa en Emision.js //
export const ModalContainer = styled.div`
  //font-family: "Raleway";
  font-family: "DM Sans", sans-serif;
  background-color: ${leerColor(colores.gris)};
  width: 100%;
  height: initial;
  padding: 75px 0;

  > div {
    font-family: "DM Sans", sans-serif;
    min-width: 800px;
    height: auto;
    padding: 75px 115px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.gris)};
    > div {
      margin-bottom: 30px;
    }
  }
`;

export const ModalContainerEmision = styled.div`
  //font-family: "Raleway";
  font-family: "DM Sans", sans-serif;
  background-color: ${leerColor(colores.gris)};
  width: 100%;
  height: initial;
  padding: 75px 0;

  .poliza-mail-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    div {
      font-size: 13px;
      color: ${leerColor(colores.verde)};
      font-weight: 500;
      border-radius: 6px;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      gap: 6px;
      margin: 0;
    }
    .certificado {
      color: ${leerColor(colores.grisEstados)};
    }
    .icon {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: ${leerColor(colores.verde)};
    }
    .no-entregado-mensaje {
      color: ${leerColor(colores.grisEstados)};
    }
    .no-entregado {
      color: ${leerColor(colores.rojo)};
    }
  }

  > div {
    font-family: "DM Sans", sans-serif;
    width: 800px;
    height: auto;
    padding: 75px 115px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.gris)};
    > div {
      margin-bottom: 30px;
    }
  }
`;

/* Modals.js */
export const AllModals = styled.div`
  > div {
    margin: 40px auto;
  }
`;
export const ModalSolicitud = styled.div`
  width: 810px;
  > div > div {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  h1 {
    margin-bottom: 11px;
    padding-bottom: 0;
    margin-top: 0;
  }
  h3 {
    margin-bottom: 35px;
    font-weight: 600;
  }
  strong {
    font-weight: 600;
  }
  .pendiente {
    color: ${leerColor(colores.grisEstados)};
  }
  strong.pendSuscripcion {
    color: ${leerColor(colores.naranja)}!important;
  }
  .emitido {
    color: ${leerColor(colores.verde)}!important;
  }
  .rechazado {
    color: ${leerColor(colores.rojo)}!important;
  }
  .provisorio {
    color: ${leerColor(colores.celesteEstados)}!important;
  }
`;

export const ModalRechazoContainer = styled.div`
  background-color: #fff;
  padding: 48px 72px 42px 72px;
  h1 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .buttonsCont {
    display: flex;
    place-content: flex-end;
  }
  .buttonsCont a:not(:first-child) {
    margin-left: 25px;
  }
  /*
  .buttonsCont a {
    margin-left: 32px;
  }
  */
  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
    //margin-top: 43px;
  }
  .selectCont {
    width: 50%;
  }
  textarea {
    height: 137px;
    margin-bottom: 37px;
    margin-top: 20px;
  }
`;
export const ModalExito = styled.div`
  background-color: #fff;
  padding: 48px 72px 42px 72px;
  > div > div {
    padding-bottom: 48px;
  }
  h1 {
    margin-bottom: 11px;
    padding-bottom: 0;
  }
  h2 {
    margin-top: 30px;
  }
  .buttonsCont {
    display: flex;
    justify-content: space-between;
  }
  .premioCont {
    margin-top: 35px;
    width: 374px;
  }
  .buttonCont {
    display: flex;
    place-content: flex-end;
  }
`;
export const ModalRevertirContainer = styled.div`
  background-color: #fff;
  padding: 85px 60px 39px 60px !important;

  text-align: center !important;
  > div > div {
    text-align: center;
  }
  h3 {
    font-family: sans-serif;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    color: ${leerColor(colores.grisModales)};
  }
  .buttonsCont {
    display: flex;
    place-content: flex-end;
    margin-top: 56px;
  }
  .buttonsCont a {
    margin-left: 16px;
  }
`;

export const ModalEnviarEmail = styled.div`
  background-color: #fff;
  padding: 50px !important;
  text-align: center !important;

  > div > div {
    text-align: center;
  }
  h3 {
    margin: 0;
    margin-top: 20px;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: ${leerColor(colores.grisModales)};
  }
  h3:nth-child(3) {
    margin: 0;
  }

  /* LOADING SPINNER */

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    align-self: center;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${leerColor(colores.celeste)} transparent transparent
      transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ModalEmision = styled.div`
  background-color: #fff;
  padding: 50px 70px;
  width: 817px;

  h1 {
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
    font-size: 33px !important;
  }
  h3 {
    font-size: 23px !important;
  }
  .inputsCont {
    display: flex;
    justify-content: space-between;
    margin: 34px 0;
  }
  .inputsCont > div {
    width: 48%;
  }
  .buttonsCont {
    display: flex;
    place-content: flex-end;
  }
  .buttonsCont a {
    margin-left: 20px;
  }
  .archivosContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    //overflow-y: scroll;
    max-height: 500px;
    padding-right: 30px;
    width: 100%;
    flex-flow: row wrap;

    .inputContainer {
      width: 45%;
    }

    /* ScrollBar */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }
  }

  .archivosEmma {
    width: 50%;
    overflow-y: scroll;
    max-height: 400px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }
  }
`;

export const ModalProcesoExitoso = styled.div`
  background-color: #fff;
  padding: 48px 70px;
  h1 {
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
  }
  .buttonCont {
    display: flex;
    place-content: flex-end;
  }
  .premioCont {
    margin-top: 25px;
    margin-bottom: 5px;
    width: 374px;
  }
  h3 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    color: ${leerColor(colores.grisModales)};
  }
`;

export const ModalEmisionEmitir = styled.div`
  background-color: #fff;
  padding: 48px 70px;
  width: 817px;
  .error {
    color: ${leerColor(colores.rojo)};
    display: block;
  }
  h1 {
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
  }
  textarea {
    height: 102px;
    margin-bottom: 32px;
    font-size: 18px;
  }
  input {
    margin-bottom: 10px;
  }

  .actionButtonsCont {
    display: flex;
    justify-content: space-between;
  }
  .buttonsCont {
    display: flex;
  }
  .buttonsCont a {
    margin-left: 25px;
  }
  h3 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    color: ${leerColor(colores.grisModales)};
  }
`;

export const InputContainer = styled.div`
  width: ${(props) => (props.active ? "45%" : "100%")};
`;

export const ArchivosContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  //overflow-y: scroll;
  max-height: 500px;
  /* padding-right: 30px; */
  width: 100%;
  flex-flow: ${(props) => (props.active ? "row wrap" : "")};
`;

export const ModalEmisionCargar = styled.div`
  background-color: #fff;
  padding: 58px 70px;
  width: 800px;
  h1 {
    margin: 0;
    padding: 0;
    margin-bottom: 35px;
  }
  .buttonsCont,
  .buttonCont {
    display: flex;
    place-content: flex-end;
    margin-top: 48px;
  }
  .buttonsCont a,
  .buttonCont a {
    margin-left: 25px;
  }
  .archivosContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    //overflow-y: scroll;
    max-height: 500px;
    padding-right: 30px;
    width: 100%;
    //flex-flow: row wrap;
    flex-flow: ${(props) => (props.active ? "row wrap" : "")};

    .inputContainer {
      //width:45%
      width: ${(props) => console.log(props.active)};
    }

    /* ScrollBar */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }
  }

  .archivosEmma {
    width: 50%;
    overflow-y: scroll;
    max-height: 400px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }
  }
`;

export const ModalRecordatorio = styled.div`
  background-color: #fff;
  div {
    align-items: center;
  }
  > div > div {
    width: 580px;
    align-items: center;
    padding: 59px 61px 30px 61px;
  }
  p {
    margin-bottom: 32px;
    line-height: 1.4;
  }
`;

export const ModalConfirmacion = styled.div`
  background-color: #fff;
  div {
    align-items: center;
  }
  width: 817px;
  align-items: center;
  padding: 74px;
  p {
    margin-bottom: 32px;
    line-height: 1.4;
  }
`;

export const TickWrapper = styled.div`
  border: 5px solid #3d9f54;
  padding: 42px;
  border-radius: 50%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 94px;
`;

export const SuccessTitle = styled.div`
  font-size: 36px;
  color: #3d9f54;

  font-weight: 700;
`;

export const ShowModal = styled.div`
  > div {
    display: block !important;
  }
  > div > div {
    display: block !important;
  }
`;

//////////////////////////

export const ArchivoCargado = styled.span`
  color: ${leerColor(colores.negro)};
  font-weight: 600;
`;

export const NombreArchivoCargado = styled.span`
  color: ${leerColor(colores.negro)};
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Error = styled.span`
  color: ${leerColor(colores.rojo)};
  display: block;
  margin-top: 8px;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: hsl(0, 0%, 100%);
  -webkit-box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  color: hsl(0, 0%, 32.2%);
  //padding: 20px 0;
  width: 400px;
  margin: 15px 0 37px 0;
  border: 1px solid #f5f5f5;

  .cont {
    display: flex;
    align-items: center;
  }

  .cross {
    display: flex;
    padding: 0 25px;
    cursor: pointer;

    :hover {
      opacity: 0.7;
    }
  }

  .file {
    display: flex;
    padding: 0 25px;
  }
`;

export const ThumbnailTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 25px;
  border-left: 1px solid #f1f1f1;
`;

export const ThumbnailImage = styled.img`
  width: 40px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const PreviewFile = styled.img`
  width: 500px;
`;

export const Video = styled.video`
  width: auto;
  height: 50vh;
  margin: 0 auto;
`;

export const Icon = styled.img`
  width: 25px;
`;

// POP UP

export const PopUpContainer = styled.div`
  /*width: 800px;*/
  height: auto;
  /*padding: 20px 60px;*/
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.gris)};
  border-radius: 20px;

  > h3 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    color: ${leerColor(colores.grisModales)};
  }
  > p {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    color: ${leerColor(colores.grisModales)};
  }
`;

export const LinkEmision = styled.a`
  color: ${(props) =>
    props.hasAPI ? "hsl(0,0%,32.2%)" : leerColor(colores.gris)};
  pointer-events: ${(props) => (props.hasAPI ? "" : "none")};

  :hover {
    font-weight: 700 !important;
    color: ${(props) => (props.hasAPI ? leerColor(colores.celeste) : "")};
    pointer-events: none;
  }
`;

export const ClearFilterContainer = styled.div`
  display: flex;
  align-items: center;
  .icon {
    width: 22px;
    margin-right: 10px;
  }
`;

export const ClearFilter = styled.span`
  color: ${leerColor(colores.celeste)};
  font-weight: 500;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const EditOperadorIcon = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const SolEmisionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .container-acciones {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    .notificaciones-icono {
      display: flex;
      border-radius: 500px;
      //border: 1px solid #eaeaea;
      font-size: 18px;
      box-sizing: border-box;
      padding: 6px;
      cursor: pointer;
      position: relative;

      :hover {
        opacity: 0.8;
      }
    }

    .notificaciones-contador {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 200px;
      background-color: ${leerColor(colores.rojo)};
      color: white;
      position: absolute;
      top: 18px;
      right: -8px;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }

    .notificaciones-container {
      width: 400px;
      max-height: 400px;
      position: absolute;
      top: 40px;
      right: 0;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      border-radius: 6px;
      z-index: 199;
      box-sizing: border-box;
      font-family: "DM Sans", sans-serif;
      // border: 1px solid #eaeaea;
      background-color: ${({ theme }) => theme.background};
      display: flex;
      box-shadow: 0px 5px 30px #eaeaea;

      .notificaciones-titulo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaeaea;

        span {
          font-size: 13px;
          color: ${leerColor(colores.grisEstados)};
          padding-right: 20px;
          cursor: pointer;

          :hover {
            opacity: 0.8;
          }
        }
      }

      .empty-state-notificaciones {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        box-sizing: border-box;
        padding: 80px 0;
        gap: 6px;

        h4 {
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }
      }

      .tabs-container {
        padding: 20px;
      }

      .notif-container {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
      }

      .notif-item {
        font-weight: 500;
      }

      .notif {
        line-height: 1.5;
      }

      .empty-state-notificaciones-icono {
        font-size: 26px;
        color: ${leerColor(colores.azulRebranding)};
      }

      .empty-descripcion {
        color: ${leerColor(colores.grisEstados)};
        font-size: 14px;
        width: 80%;
        text-align: center;
      }

      h3 {
        margin: 0;
        padding: 20px;
        font-size: 16px;
        color: ${leerColor(colores.negro)};
      }

      .fecha-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;

        span {
          background-color: red;
          box-sizing: border-box;
          padding: 10px 20px;
          background-color: #f8f8f8;
          font-weight: 500;
          color: ${leerColor(colores.grisEstados)};
          border-radius: 100px;
          font-size: 14px;
        }
      }

      .notificacion-item {
        display: flex;
        box-sizing: border-box;
        padding: 20px;
        border-bottom: 1px solid #eaeaea;
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        flex-direction: column;
        gap: 10px;
        cursor: pointer;

        .notificacion-contenido-container {
          display: flex;
          gap: 14px;
          align-items: flex-start;
        }

        .notificacion-titulo {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            margin: 0;
            font-size: 14px;
            color: ${leerColor(colores.negro)};
          }
        }

        .notificacion-contenido {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
        }

        .notificacion-hora {
          text-align: right;
        }

        .pagina-icono {
          font-size: 18px;
          box-sizing: border-box;
          border: 1px solid #eaeaea;
          padding: 10px;
          display: flex;
          border-radius: 100%;
        }

        a {
          color: ${leerColor(colores.azulRebranding)};
          display: flex;
          align-items: center;
          gap: 4px;

          .notificacion-icono {
            display: flex;
          }
        }

        :last-of-type {
          border: none;
        }

        :hover {
          background-color: #f7f7f7;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .descripcion {
    margin: 0;
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
  }
`;

export const ModalEditarOperador = styled.div`
  width: 900px;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 450px;
  //
  background: #fff;
  font-family: "DM Sans", sans-serif;

  h2 {
    color: ${leerColor(colores.azul)};
    margin: 10px 0;
  }

  h4 {
    font-weight: 600;
    color: ${leerColor(colores.celeste)};
    margin: 10px 0;
  }

  .fedpat-warning {
    margin: 0;
    background-color: #f3fbff;
    color: ${leerColor(colores.celeste)};
    font-weight: 600;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    span {
      color: ${leerColor(colores.celeste)};
      margin-right: 10px;
    }
  }

  span {
    font-size: 16px;
    font-weight: 500;
    //color: ${leerColor(colores.gris)};
    color: rgb(82, 82, 82);
  }

  .url-example {
    color: ${leerColor(colores.grisModales)};
    display: block;
    margin-top: 8px;
    font-size: 14px;
  }

  .preview-container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .preview-url {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0;
    border: 1px dashed ${leerColor(colores.gris)};

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px;

      img {
        width: 64px;
        margin-bottom: 10px;
      }
    }

    span {
      color: ${leerColor(colores.grisModales)};
    }

    /*
    .error{
       
      color: ${leerColor(colores.rojo)};
      display: block;
      margin-top: 8px;

    }
    */

    img {
      width: 100%;
      cursor: pointer;
      transition: 0.5s;

      :hover {
        opacity: 0.7;
      }
    }
  }

  .remove-margin-bottom {
    margin-bottom: 0;
  }

  .animation-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h3 {
      color: ${leerColor(colores.celeste)};
      margin: 24px 0 8px 0;
    }

    span {
      font-weight: 400;
    }

    svg {
      width: 100%;
      margin: 15px 0;
    }
  }

  #Cursor {
    animation: cursor 2.2s ease-in-out infinite;
  }

  @keyframes cursor {
    0% {
      transform: translate(0px, 0px);
    }
    80% {
      transform: translate(-405px, -130px);
    }
    100% {
      transform: translate(-405px, -130px);
    }
  }

  #CheckboxShape {
    animation: checkbox 2.2s ease-in-out infinite;
  }

  @keyframes checkbox {
    0% {
      fill: #fff;
    }
    75% {
      fill: #fff;
    }
    80% {
      fill: #0075ff;
    }
    100% {
      fill: #0075ff;
    }
  }

  .error {
    color: ${leerColor(colores.rojo)};
    display: block;
    margin-top: 8px;
    font-size: 14px;
  }

  .actionButtonsCont {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .buttonsCont {
    display: flex;
    place-content: flex-end;
    //margin-top: 48px;
    margin-top: 10px;
    width: 100%;
  }

  .buttonsContSiniestro {
    display: flex;
    place-content: flex-end;
  }

  .buttonsCont a,
  .buttonsContSiniestro a {
    margin-left: 25px;
  }

  /* ScrollBar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e6e6e6;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${leerColor(colores.gris)};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${leerColor(colores.grisEstados)};
  }

  .operadorCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 40px 0;
    //border-top: 1px solid ${leerColor(colores.celeste)};

    a {
      margin-left: 20px;
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }

    div {
      //display: flex;
      //align-items: center;
    }
  }

  @media (min-width: ${breakpoints.mobileGrande}) {
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.laptop}) {
  }
  @media (min-width: ${breakpoints.laptopL}) {
    max-height: 600px;
  }
  @media (min-width: ${breakpoints.notebook}) {
    max-height: 600px;
  }
`;

export const ModalFichaje = styled.div`
  width: 900px;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 450px;
  background: #fff;

  .remove-margin-top {
    margin-top: 0;
  }

  .subtitulo-margin {
    margin-top: 40px;
  }

  .buttonsCont {
    display: flex;
    place-content: flex-end;
    width: 100%;
    //margin-top:40px;
    margin-top: 20px;

    a {
      margin-left: 20px;
    }
  }

  .error {
    color: ${leerColor(colores.rojo)};
    display: block;
  }

  .error-message {
    display: block;
    margin-top: 6px;
  }

  /* ScrollBar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e6e6e6;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${leerColor(colores.gris)};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${leerColor(colores.grisEstados)};
  }

  .operadorCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    //border-top: 1px solid ${leerColor(colores.celeste)};

    span {
      display: inline-block;
      margin-right: 10px;
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: ${breakpoints.mobileGrande}) {
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.laptop}) {
  }
  @media (min-width: ${breakpoints.laptopL}) {
    max-height: 600px;
  }
  @media (min-width: ${breakpoints.notebook}) {
    max-height: 600px;
  }
`;

export const ModalBorrarExcepcion = styled.div`
  padding: 20px 70px 40px 70px;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  max-width: 600px;
  justify-content: center;
  //
  font-family: "DM Sans", sans-serif;
  background: #fff;
  border-radius: 8px;

  h2 {
    text-align: center;
    font-weight: 600;
    color: ${leerColor(colores.azul)};
  }

  h4 {
    font-weight: 600;
    color: ${leerColor(colores.celeste)};
    margin: 10px 0;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    //color: ${leerColor(colores.gris)};
    color: rgb(82, 82, 82);
  }

  .buttonsCont {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }

  .buttonsCont a {
    margin-left: 25px;
  }

  .excepcionData {
    display: flex;
    margin: 10px 0 40px 0;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

    p {
      margin: 5px;
    }
  }

  .data {
    font-weight: 600;
    color: ${leerColor(colores.celeste)};
  }
`;

export const OperadorItem = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-family: "DM Sans", sans-serif;

  :last-of-type {
    border: none;
  }

  b {
    color: ${leerColor(colores.celeste)};
  }

  img {
    width: 20px;
    cursor: pointer;
    margin-left: 20px;
  }

  .operadorActual {
    display: flex;
    flex-direction: column;
    //
    font-family: "DM Sans", sans-serif;

    span:first-child {
      font-weight: 600;
      margin-bottom: 8px;
    }

    .solicitud-asignada {
      color: ${leerColor(colores.verde)};
      font-weight: 600;
    }
  }

  .editado {
    display: flex;
    align-items: center;
    //padding: 0 20px;

    span {
      color: ${leerColor(colores.verde)};
      font-weight: 600;
      font-size: 16px;
      margin-left: 10px;
    }
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: blue;
  flex-direction: column;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .headerItem {
    background-color: yellow;
    width: 125px;
    text-align: center;
  }

  .days-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .day {
    width: 125px;
    background-color: green;
  }
`;

export const GridCalendarContainerHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  font-family: "DM Sans", sans-serif;
  //
  font-weight: 600;
  color: #525252;
  margin-top: 40px;

  .dayCont {
    text-align: center;
    padding: 20px 0;
    border: 1px solid rgb(245, 245, 245);
  }
`;

export const GridCalendarContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  border: 1px solid rgb(245, 245, 245);
  font-family: "DM Sans", sans-serif;
  //
  font-weight: 600;
  color: #525252;

  .day {
    padding: 10px 10px;
    border: 1px solid rgb(245, 245, 245);
    font-size: 12px;
    transition: 0.2s;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .dayContainer {
      display: flex;
      //justify-content: space-between;
      //align-items: center;
      flex-direction: column;
    }

    .dayNumber {
      font-size: 20px;
      //text-align: left;
      margin-bottom: 10px;
    }

    span {
      //border-bottom:1px solid rgb(240,240,240);
      font-weight: 500;
    }

    .state {
      width: 100%;
      height: 4px;
      border-radius: 8px;
      background-color: ${leerColor(colores.celeste)};
    }

    p {
      font-weight: 500;
      margin: 6px 0;
    }

    .openExcepcion {
      font-size: 30px;
      position: relative;

      :hover {
        cursor: pointer;
        color: blue;

        ::before {
          content: "Acuerdo horario: desde las 11:00hs hasta las 18:00hs";
          display: flex;
          position: absolute;
          justify-content: center;
          align-items: center;
          top: -60px;
          left: -110px;
          width: 200px;
          /* height: 50px; */
          background-color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
          padding: 20px;
          color: #fff;
          border-radius: 10px;
        }
      }
    }

    .barContainer {
      display: flex;
      justify-content: space-between;
      //background-color: blue;
    }
  }
`;

export const GridFirstDay = styled.div`
  grid-column-start: ${(props) => (props.start ? props.start : "")};
  padding: 10px 10px;
  border: 1px solid rgb(245, 245, 245);
  font-size: 12px;
  transition: 0.2s;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "DM Sans", sans-serif;

  .dayNumber {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .barContainer {
    display: flex;
    justify-content: space-between;
  }

  .dayContainer {
    display: flex;
    flex-direction: column;

    p {
      font-weight: 500;
      margin: 6px 0;
    }

    span {
      font-weight: 500;
    }

    .state {
      width: 100%;
      height: 4px;
      border-radius: 8px;
      background-color: ${leerColor(colores.celeste)};
    }
  }
`;

export const BarColor = styled.div`
  width: ${(props) => {
    switch (props.quantity) {
      case 1:
        return `100%`;
      case 2:
        return `48%`;
      case 3:
        return `30%`;
    }
  }};
  height: 1px;
  border-radius: 8px;
  background-color: ${(props) => {
    switch (props.state) {
      case "extra":
        return `${leerColor(colores.verde)}`;
      case "incumplimiento":
        return `${leerColor(colores.rojo)}`;
      case "no laboral":
        return `${leerColor(colores.celeste)}`;
      case "excepcion":
        return `${leerColor(colores.naranja)}`;
    }
  }};
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  margin: ${(props) => (props.disabledMargin ? "0 0 40px 0" : "30px 0 10px 0")};
`;

export const FichajesContainer = styled.div`
  //margin: 0 0 40px 0;
`;

export const Tab = styled.div`
  width: 50%;
  background-color: ${(props) => (props.selected ? "#e9f7ff" : "")};
  color: ${(props) =>
    props.selected ? leerColor(colores.azul) : leerColor(colores.gris)};
  text-align: center;
  //
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  border-bottom: ${(props) =>
    props.selected
      ? `1px solid ${leerColor(colores.celeste)}`
      : `1px solid ${leerColor(colores.gris)}`};
  cursor: pointer;
  transition: 0.2s;
  padding: ${(props) => (props.padding ? "5px 15px" : "15px 0")};
  font-size: ${(props) => (props.small ? "14px" : "12")};
  :hover {
    background-color: ${(props) => (props.selected ? "#e9f7ff" : "#f6fafd")};
  }
`;

export const GridCumplimientoContainerHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "repeat(6, 1fr)"};
  //grid-template-columns: repeat(5, 1fr);
  //
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: ${leerColor(colores.celeste)};
  text-align: center;
  margin: ${(props) => (props.margin ? `80px 0 20px 0` : "40px 0 20px 0")};
`;

export const GridCumplimientoContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "repeat(6, 1fr)"};
  //grid-template-columns: repeat(5, 1fr);
  //
  font-family: "DM Sans", sans-serif;
  color: #525252;
  text-align: center;
  margin: 20px 0;
  align-items: center;

  .editButton {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;

    .tooltip {
      display: none;
      position: absolute;
      background-color: rgb(30, 30, 30);
      color: #fff;
      top: -45px;
      padding: 8px;
      font-size: 14px;
      text-align: center;
      font-weight: 500;
      border-radius: 5px;
      width: 120px;

      ::after {
        content: "";
        display: block;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
        top: 100%;
        left: 50%;
        position: absolute;
      }
    }

    :hover {
      .tooltip {
        display: block;
      }
    }
  }

  .actionsContainer {
    display: flex;
    justify-content: space-evenly;

    img {
      width: 24px;
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }
    }

    .delete {
      width: 22px;
    }
  }

  .positiveDifference {
    color: ${leerColor(colores.verde)};
    font-weight: 600;
  }

  .negativeDifference {
    color: ${leerColor(colores.rojo)};
    font-weight: 600;
  }
`;

export const Subtotal = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "repeat(6, 1fr)"};
  //
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  color: ${leerColor(colores.celeste)};
  text-align: center;
  //border-top: 1px solid #f1f1f1;
  padding: 10px 0;
  color: ${(props) =>
    props.color
      ? `${leerColor(colores.verde)}`
      : `${leerColor(colores.celeste)}`};
  background-color: ${(props) => (props.color ? `#f3fff5` : "#f3fbff")};
  border-radius: 4px;
`;

export const ModalDescargarEstadisticas = styled.div`
  padding: 20px 70px 40px 70px;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  //max-width: 600px;
  width: 100%;
  justify-content: center;
  //
  font-family: "DM Sans", sans-serif;
  background: #fff;

  svg {
    width: 40px;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    //color: ${leerColor(colores.gris)};
    color: rgb(82, 82, 82);
  }

  .estadisticas-formato {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #ececec;
    padding: 40px 0;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s;

    :hover {
      opacity: 0.7;
    }
  }

  .selected-formato {
    border: 1px solid ${leerColor(colores.celeste)} !important;

    span {
      font-weight: 800;
      color: ${leerColor(colores.celeste)};
    }
  }

  h2 {
    text-align: center;
    font-weight: 600;
    color: ${leerColor(colores.azul)};
  }

  .buttonsCont {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .buttonsCont a {
    margin-left: 25px;
  }

  .excepcionData {
    display: flex;
    margin: 10px 0 40px 0;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

    p {
      margin: 5px;
    }
  }

  .data {
    font-weight: 600;
    color: ${leerColor(colores.celeste)};
  }

  h4 {
    font-weight: 600;
    color: ${leerColor(colores.celeste)};
    margin: 10px 0;
  }
`;

export const OperationNumber = styled.h2`
  margin: 0;
  color: ${leerColor(colores.gris)};
  font-weight: 300;
  font-size: 1rem;
  font-family: "DM Sans", sans-serif;
`;

export const LoadingContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RechazosDescargaContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
`;
