import React from "react";
import { FiChevronDown } from "react-icons/fi";

const ViewMore = ({ action }) => {
  return (
    <>
      <div
        className="ver-mas-container"
        onClick={action}
      >
        <span>Ver más</span>
        <span className="chevron">
          <FiChevronDown />
        </span>
      </div>
    </>
  );
};

export default ViewMore;
