import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Div = styled.div`
   
  label {
     
    color: hsl(0,1.4%,72.5%);
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
  }
`;
export const InputDiv = styled.div`
  min-width: 200px;
  width: 100%;
  height: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //margin: 10px 0 0 0;
  //border: 1px solid ${leerColor(colores.gris)};
  border: 1px solid #bab8b8;
  border-style: dashed;
  label {
    //border: 2px solid ${leerColor(colores.gris)};
    cursor: pointer;
    text-align: center;
    /* min-width: 180px; */
    width: calc(100% - 20px);
    min-height: 180px;
    height: 90%;
    /* margin: 0 auto; */
    align-items: center;
    //border-style: dashed;
  }
  label span {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: inherit;
    align-items: center;
    color: ${leerColor(colores.gris)};
    justify-content: center;
  }
  label span img {
    width: 30px;
    padding-bottom: 20px;
  }
`;
export const Input = styled.input`
  display: none;
  /*  
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 20px;
  border: 1px solid ${leerColor(colores.gris)};
  &::placeholder{
    color: ${leerColor(colores.gris)};
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  } */
  @media (min-width: ${breakpoints.mobileGrande}) {
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.laptop}) {
  }
  @media (min-width: ${breakpoints.laptopL}) {
  }
  @media (min-width: ${breakpoints.notebook}) {
  }
`;

export const ModalRecordatorio = styled.div`
    width: 620px;
    align-items: center;
    padding: 42px 50px 36px;
    
    div {align-items: center; }
  p {
    margin: 0;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 34px;
    text-align: center;
    color: ${leerColor(colores.grisModales)};
  }
  .buttonCont {
    display: flex;
    place-content: flex-end;
  }
`;
